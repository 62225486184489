import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { Theme } from '@mui/material/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import AddFormsBlue50Icon from '../../../assets/icons/add_forms_blue50.svg'
import AddFormsGrey70Icon from '../../../assets/icons/add_forms_grey70.svg'
import MagicBlue50Icon from '../../../assets/icons/magic_blue50.svg'
import MagicGrey70Icon from '../../../assets/icons/magic_grey70.svg'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  ProjectOnboardingFormType,
  useSelectProjectFormsMutation,
} from '../../../common/graphql/apollo-operations'
import { SelectFormsRefetchQuery, VariantAndVersion } from '../../../common/util/Forms'
import { trackSelectedProjectForms } from '../../../common/util/MetricsTracking'
import {
  ContractOnboardingFormType,
  OnboardingFormsInstructions,
  invalidateContractsAfterOnboardingStatusChange,
  useUploadOnboardingForms,
} from '../../../common/util/ProjectOnboarding'
import { PendingFile } from '../backup/attachments/FileDragUpload'
import { CopyProjectFormsDialog } from './CopyProjectFormsDialog'
import { OnboardingButton } from './OnboardingButton'
import { OnboardingFormUploadDialog } from './OnboardingFormUploadDialog'
import { PreviewDefaultFormsDialog } from './PreviewDefaultFormsDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  formTemplateOptions: {
    display: 'flex',
    marginBottom: theme.spacing(3.5),
    '& > *': {
      flex: 1,
    },
    '& > *:not(:last-child) ': {
      marginRight: theme.spacing(2),
      flex: 1,
    },
  },
}))

const i18nBase = 'projects.subcontractors.settings.forms'

enum DialogStep {
  SELECT_TEMPLATE = 'SELECT_TEMPLATE',
  PREVIEW_DEFAULT = 'PREVIEW_DEFAULT',
  CHOOSE_COPY = 'CHOOSE_COPY',
  UPLOAD = 'UPLOAD',
}

export enum FormSelectionType {
  DEFAULT = 'DEFAULT',
  COPY = 'COPY',
  UPLOAD = 'UPLOAD',
}

interface SelectFormTemplatesDialogProps {
  selectTemplateTypeTitle: string
  open: boolean
  onClose: () => void
  selectFormsRefetchQuery?: SelectFormsRefetchQuery
  formType: ContractOnboardingFormType
  defaultFormTemplates: VariantAndVersion[]
  hasConditionalDefaultForms?: boolean
  contractId: string
  location: string
  /**
   * By default, when forms have been selected and the selection confirmed, we display
   * a success toast. This callback replaces that default behavior.
   *
   */
  onSelectionSubmitted?: (selectType: FormSelectionType) => void
}

/**
 * Dialog for onboarding project forms, presents options to upload, choose defaults, or
 * copy from a project. This is used in both the billing and vendor project onboarding flows
 */
export function SelectFormTemplatesDialog({
  selectTemplateTypeTitle,
  open,
  onClose,
  selectFormsRefetchQuery,
  formType,
  defaultFormTemplates,
  hasConditionalDefaultForms,
  contractId,
  location,
  onSelectionSubmitted,
}: SelectFormTemplatesDialogProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const { t } = useTranslation()
  const { name: projectName } = useProjectContext()

  const [dialogStep, setDialogStep] = useState<DialogStep | null>(null)
  const [isUploadingForms, setIsUploadingForms] = useState<boolean>(false)

  const [selectForms, { loading }] = useSelectProjectFormsMutation({
    ...selectFormsRefetchQuery,
    update: (cache) => {
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })
  const uploadForms = useUploadOnboardingForms({
    formType,
    contractId,
    refetchQuery: selectFormsRefetchQuery,
  })

  const handleGoToDialogFirstStep = useCallback(
    (fromButton?: boolean) => {
      if (fromButton === false) {
        onClose()
        return
      }
      setDialogStep(DialogStep.SELECT_TEMPLATE)
    },
    [onClose]
  )

  const handleGoToDefaultFormsStep = useCallback(() => {
    setDialogStep(DialogStep.PREVIEW_DEFAULT)
  }, [])

  const handleGoToCopyFormsStep = useCallback(() => {
    setDialogStep(DialogStep.CHOOSE_COPY)
  }, [])

  const handleGoToUploadFormsStep = useCallback(() => {
    setDialogStep(DialogStep.UPLOAD)
  }, [])

  const handleConfirmDefaultForms = useCallback(
    async (includeChangeOrderLogOnPayApps?: boolean) => {
      try {
        await selectForms({
          variables: {
            input: {
              contractId,
              formTypes: [formType],
              useDefaultForms: true,
              includeChangeOrderLogOnPayApps,
            },
          },
        })
        trackSelectedProjectForms({
          contractId,
          projectName,
          formsType: formType,
          location,
          selectType: 'defaultForms',
          includeChangeOrderLogOnPayApps,
        })
        if (onSelectionSubmitted) {
          onSelectionSubmitted(FormSelectionType.DEFAULT)
        } else {
          snackbar.showSuccess(t(`${i18nBase}.added_forms_success.${formType}`))
        }
      } catch {
        snackbar.showError(t('common.errors.snackbar.generic'))
      }
      onClose()
    },
    [
      contractId,
      formType,
      location,
      onClose,
      onSelectionSubmitted,
      projectName,
      selectForms,
      snackbar,
      t,
    ]
  )

  const handleConfirmCopyForms = useCallback(
    async (
      formTypes: ContractOnboardingFormType[],
      copyFromContractId: string,
      includeChangeOrderLogOnPayApps?: boolean
    ) => {
      try {
        await selectForms({
          variables: {
            input: {
              contractId,
              formTypes,
              useDefaultForms: false,
              copyFromContractId,
              includeChangeOrderLogOnPayApps,
            },
          },
        })
        trackSelectedProjectForms({
          contractId,
          projectName,
          formsType: formType,
          location,
          selectType: 'copyFromProject',
          includeChangeOrderLogOnPayApps,
        })
        if (onSelectionSubmitted) {
          onSelectionSubmitted(FormSelectionType.COPY)
        } else {
          snackbar.showSuccess(t(`${i18nBase}.added_forms_success.${formType}`))
        }
      } catch {
        snackbar.showError(t('common.errors.snackbar.generic'))
      }
      onClose()
    },
    [
      contractId,
      formType,
      location,
      onClose,
      onSelectionSubmitted,
      projectName,
      selectForms,
      snackbar,
      t,
    ]
  )

  const handleConfirmUploadForms = useCallback(
    async (
      pendingFiles: PendingFile[],
      instructions: OnboardingFormsInstructions,
      includeChangeOrderLogOnPayApps?: boolean
    ) => {
      setIsUploadingForms(true)
      try {
        await uploadForms(pendingFiles, instructions, includeChangeOrderLogOnPayApps)
        trackSelectedProjectForms({
          contractId,
          projectName,
          formsType: formType,
          selectType: 'newForms',
          location,
          includeChangeOrderLogOnPayApps,
        })
        if (onSelectionSubmitted) {
          onSelectionSubmitted(FormSelectionType.UPLOAD)
        } else {
          snackbar.showSuccess(t(`${i18nBase}.forms_uploaded`))
        }
        onClose()
      } catch (err) {
        snackbar.showError(err.message)
        // Re-throw so that the dialog doesn't close
        throw err
      } finally {
        setIsUploadingForms(false)
      }
    },
    [
      contractId,
      formType,
      location,
      onClose,
      onSelectionSubmitted,
      projectName,
      snackbar,
      t,
      uploadForms,
    ]
  )

  const formTypeForCopyDialog = useMemo(() => {
    return [formType]
  }, [formType])

  // Opening & closing the dialog is handled externally. Update here
  // whenever the open state changes
  useEffect(() => {
    if (open) {
      handleGoToDialogFirstStep()
    } else {
      setDialogStep(null)
    }
  }, [handleGoToDialogFirstStep, open])

  return (
    <>
      <SitelineDialog
        title={selectTemplateTypeTitle}
        open={dialogStep === DialogStep.SELECT_TEMPLATE}
        onClose={onClose}
        cancelLabel={t('common.actions.cancel')}
        actionsLayout="closeIcon"
      >
        <div className={classes.formTemplateOptions}>
          {defaultFormTemplates.length > 0 && (
            <OnboardingButton
              imageSrc={MagicGrey70Icon}
              hoverImageSrc={MagicBlue50Icon}
              title={t(`${i18nBase}.use_default_forms`)}
              subtitle={t(`${i18nBase}.standard_forms`)}
              onClick={handleGoToDefaultFormsStep}
              imageSize="sm"
            />
          )}
          <OnboardingButton
            imageSrc={<FileCopyOutlinedIcon />}
            title={t(`${i18nBase}.copy_forms`)}
            subtitle={t(`${i18nBase}.reuse_forms`)}
            onClick={handleGoToCopyFormsStep}
            imageSize="sm"
          />
          <OnboardingButton
            imageSrc={AddFormsGrey70Icon}
            hoverImageSrc={AddFormsBlue50Icon}
            title={t(`${i18nBase}.add_new_forms`)}
            subtitle={t(`${i18nBase}.forms_not_used`)}
            onClick={handleGoToUploadFormsStep}
            imageSize="sm"
          />
        </div>
      </SitelineDialog>
      <PreviewDefaultFormsDialog
        open={dialogStep === DialogStep.PREVIEW_DEFAULT}
        onClose={handleGoToDialogFirstStep}
        defaultFormTemplates={defaultFormTemplates}
        hasConditionalDefaultForms={hasConditionalDefaultForms}
        onConfirm={handleConfirmDefaultForms}
        submitting={loading}
        // If we're at this dialog step we can default to true because default forms have been selected
        includeChangeOrderLogInPayAppPackage={
          formType === ProjectOnboardingFormType.CHANGE_ORDER_LOG ? true : undefined
        }
      />
      <CopyProjectFormsDialog
        open={dialogStep === DialogStep.CHOOSE_COPY}
        onClose={handleGoToDialogFirstStep}
        cancelLabel={t('common.actions.back')}
        formTypes={formTypeForCopyDialog}
        onConfirm={handleConfirmCopyForms}
        submitting={loading}
        includeChangeOrderLogInPayAppPackage
      />
      <OnboardingFormUploadDialog
        open={dialogStep === DialogStep.UPLOAD}
        onClose={handleGoToDialogFirstStep}
        cancelLabel={t('common.actions.back')}
        formsType={formType}
        onUploadForms={handleConfirmUploadForms}
        submitting={isUploadingForms}
        includeChangeOrderLogInPayAppPackage
      />
    </>
  )
}
