import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import Decimal from 'decimal.js'
import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import {
  StoredMaterialsCarryoverType,
  centsToDollars,
  decimalToPercent,
  dollarsToCents,
  formatCentsToDollars,
  roundCents,
  safeDivide,
  safeDivideDecimal,
  unitPriceCentsToDollars,
} from 'siteline-common-all'
import { SitelineText } from 'siteline-common-web'
import {
  RetentionView,
  StoredMaterialsView,
  TaxesView,
} from '../../components/billing/invoice/InvoiceReducer'
import { SovLineItemProgressForEvents } from '../../components/billing/invoice/InvoiceSpreadsheet'
import { Avatar } from '../components/Avatar'
import { VISIBLE_ON_CELL_HOVER_CLASS } from '../components/SitelineTable'
import {
  SpreadsheetCell,
  SpreadsheetElement,
  SpreadsheetValue,
  ValidationFunctionResult,
  makeContentCell,
  makeDataCell,
} from '../components/Spreadsheet/Spreadsheet.lib'
import {
  SovLineItemProgressEventType,
  SovLineItemProgressProperties,
} from '../graphql/apollo-operations'
import {
  BaseInvoiceColumn,
  HistoryProgressProperties,
  getProgressEventDescription,
} from './Invoice'
import {
  EditCellIcon,
  HISTORICAL_PROGRESS_DATE_CELL_CLASS,
  RETENTION_PROGRESS_CELL_CLASS,
} from './InvoiceRow'
import {
  balanceToFinish,
  currentPayAppBilled,
  currentPayAppMaterialsStored,
  currentPayAppProgress,
  previousPayAppBilled,
} from './PayApp'
import { UnitPriceInvoiceColumn } from './UnitPriceInvoice'

/**
 * Checks if a cell value is overbilling, and if so confirms that the user intends to
 * bill that amount
 */
function checkForOverbilling(
  column:
    | UnitPriceInvoiceColumn.PROGRESS_UNITS_BILLED
    | BaseInvoiceColumn.PROGRESS_BILLED
    | BaseInvoiceColumn.STORED_MATERIALS,
  newValue: SpreadsheetValue,
  progress: SovLineItemProgressProperties,
  t: TFunction,
  storedMaterialsView?: StoredMaterialsView
): ValidationFunctionResult {
  const i18nBase = 'projects.subcontractors.pay_app.invoice.confirm_billing'
  const value = Number(newValue)
  const unitPrice = progress.sovLineItem.unitPrice

  if (column === BaseInvoiceColumn.STORED_MATERIALS && storedMaterialsView === undefined) {
    throw new Error('storedMaterialsView must be defined for STORED_MATERIALS column')
  }
  if (isNaN(value)) {
    return { type: 'error', message: t('common.spreadsheet.not_a_number') }
  }
  if (unitPrice === null) {
    return { type: 'error', message: t(`${i18nBase}.no_unit_price`) }
  }
  if (unitPrice === 0 && value !== 0) {
    return {
      type: 'error',
      message: t('projects.subcontractors.pay_app.invoice.zero_unit_price'),
    }
  }

  let centsValue = 0
  let progressWithBilling = progress
  switch (column) {
    case BaseInvoiceColumn.PROGRESS_BILLED:
      centsValue = dollarsToCents(value)
      progressWithBilling = { ...progress, progressBilled: centsValue }
      break
    case UnitPriceInvoiceColumn.PROGRESS_UNITS_BILLED: {
      centsValue = roundCents(value * unitPrice)
      progressWithBilling = { ...progress, progressBilled: centsValue }
      break
    }
    case BaseInvoiceColumn.STORED_MATERIALS: {
      centsValue =
        storedMaterialsView === StoredMaterialsView.AMOUNT
          ? dollarsToCents(value)
          : roundCents(value * unitPrice)
      progressWithBilling = { ...progress, storedMaterialBilled: centsValue }
      break
    }
  }

  const balanceToFinishWithBilling = balanceToFinish(progressWithBilling)

  const isBillingNegativeOnPositive = progress.totalValue >= 0 && centsValue < 0
  const isBillingPositiveOnNegative = progress.totalValue < 0 && centsValue > 0
  if (isBillingNegativeOnPositive || isBillingPositiveOnNegative) {
    return {
      type: 'confirm',
      title: t(`${i18nBase}.confirm_billing`),
      details: isBillingNegativeOnPositive
        ? t(`${i18nBase}.billing_positive`, { number: progress.sovLineItem.code })
        : t(`${i18nBase}.billing_negative`, { number: progress.sovLineItem.code }),
      confirmLabel: t(`${i18nBase}.override`),
      confirmationType: 'delete',
      cancelLabel: t(`${i18nBase}.revise`),
    }
  }

  const isPositiveValueOverbilled = progress.totalValue >= 0 && balanceToFinishWithBilling < 0
  const isNegativeValueOverbilled = progress.totalValue < 0 && balanceToFinishWithBilling > 0
  if (isPositiveValueOverbilled || isNegativeValueOverbilled) {
    return {
      type: 'confirm',
      title: t(`${i18nBase}.line_item_overbilled`),
      details: t(`${i18nBase}.bill_over_amount`, {
        number: progress.sovLineItem.code,
        units: safeDivide(progress.totalValue, unitPrice, 0),
        amount: formatCentsToDollars(progress.totalValue, true),
      }),
      confirmLabel: t(`${i18nBase}.override`),
      confirmationType: 'delete',
      cancelLabel: t(`${i18nBase}.revise`),
    }
  }

  return null
}

/**
 * Returns a `SpreadsheetRow` component to be displayed in the pay app invoice
 * table to represent a single unit price progress line item
 */
export function getUnitPriceProgressRowCells({
  progress,
  nameRightContent,
  onAdjustUnitPriceValue,
  showWarningIfEmpty,
  shouldIncludeCostCode,
  t,
  onProgressClick,
  storedMaterialsView,
  storedMaterialsCarryoverType,
  onEditStoredMaterialsManual,
}: {
  progress: SovLineItemProgressProperties
  nameRightContent?: SpreadsheetElement
  onAdjustUnitPriceValue?: () => void
  showWarningIfEmpty?: boolean
  shouldIncludeCostCode: boolean
  t: TFunction
  /**
   * Only applicable to invoices that support field work. This is a scenario where we want to
   * prevent back office users from directly editing the invoice when the line item has a worksheet.
   * Instead of allowing them to edit the progress cell, we open the sidebar worksheet. Undefined
   * should be passed in if this is unapplicable.
   */
  onProgressClick?: () => void
  storedMaterialsView: StoredMaterialsView
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  onEditStoredMaterialsManual?: () => void
}): SpreadsheetCell[] {
  const unitPrice = progress.sovLineItem.unitPrice
  const hasUnitPrice = unitPrice !== null && unitPrice !== 0
  const isManualStoredMaterials =
    storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL

  if (unitPrice === null) {
    // Should not be possible, just helpful for type-checking
    return []
  }

  const totalQuantity = safeDivideDecimal(progress.totalValue, unitPrice, 0)
    .toDecimalPlaces(2)
    .toNumber()

  const currentQuantity = safeDivideDecimal(progress.progressBilled, unitPrice, 0)
    .toDecimalPlaces(2)
    .toNumber()

  const previousBilled = safeDivide(progress.previousBilled, unitPrice, 0)
  const previousWorkCompleted = safeDivide(progress.previousWorkCompleted, unitPrice, 0)
  const previousAmount = isManualStoredMaterials ? previousWorkCompleted : previousBilled

  const storedMaterialsAmount = isManualStoredMaterials
    ? progress.materialsInStorageThroughCurrentPayApp
    : progress.storedMaterialBilled
  const storedMaterialsAmountFormatted = centsToDollars(storedMaterialsAmount)

  const storedMaterialsUnits = isManualStoredMaterials
    ? safeDivideDecimal(progress.materialsInStorageThroughCurrentPayApp, unitPrice, 0)
    : safeDivideDecimal(progress.storedMaterialBilled, unitPrice, 0)
  const storedMaterialsUnitsFormatted = storedMaterialsUnits.toDecimalPlaces(2).toNumber()

  const storedMaterialsFormatted =
    storedMaterialsView === StoredMaterialsView.AMOUNT
      ? storedMaterialsAmountFormatted
      : storedMaterialsUnitsFormatted
  // This is a very specific edge case where the line item has worksheet billing AND is set up
  // with stored materials manual tracking. In that case, clicking the pencil icon should open the
  // billing worksheet sidebar rather than opening the stored materials dialog.
  const handleEditStoredMaterialsClick = onProgressClick
    ? onProgressClick
    : onEditStoredMaterialsManual

  const storedMaterialsCell = isManualStoredMaterials
    ? makeDataCell(storedMaterialsFormatted, {
        leftContent: {
          content: onEditStoredMaterialsManual ? (
            <EditCellIcon
              isHoverOnly
              type="icon"
              onEdit={hasUnitPrice ? handleEditStoredMaterialsClick : undefined}
              className="leftCellIcon"
            />
          ) : null,
          dependencies: [hasUnitPrice, handleEditStoredMaterialsClick, onEditStoredMaterialsManual],
          align: 'right' as const,
        },
      })
    : makeDataCell(storedMaterialsFormatted, {
        onBeforeEdit: onProgressClick,
        validate: (value: SpreadsheetValue) => {
          const overBillingValidationResult = checkForOverbilling(
            BaseInvoiceColumn.STORED_MATERIALS,
            value,
            progress,
            t,
            storedMaterialsView
          )

          if (overBillingValidationResult || storedMaterialsView === StoredMaterialsView.AMOUNT) {
            return overBillingValidationResult
          }

          // If the user entered a valid value in units, we need to perform an extra step - calculate
          // the new value in $ from the entered quantity, then re-calculate the quantity.
          // We do this because the total value is rounded, so totalValue / unitPrice may not exactly
          // match the entered quantity. In addition to this, the current quantity itself is rounded
          // in the spreadsheet.
          const newAmountInCents = new Decimal(unitPrice).times(Number(value)).round()
          const fixedValue = safeDivideDecimal(newAmountInCents, unitPrice, 0)
            .toDecimalPlaces(2)
            .toNumber()

          return { type: 'override', value: fixedValue }
        },
      })

  const lineItemCells = [
    // Number
    makeDataCell(progress.sovLineItem.code, {
      borderColor: showWarningIfEmpty && !progress.sovLineItem.code ? 'error' : 'default',
    }),
    // Name
    makeDataCell(progress.sovLineItem.name, {
      rightContent: nameRightContent,
      borderColor: showWarningIfEmpty && !progress.sovLineItem.name ? 'error' : 'default',
    }),
    // Cost code
    ...(shouldIncludeCostCode ? [makeDataCell(progress.sovLineItem.costCode ?? '')] : []),
    // Quantity contracted
    makeDataCell(totalQuantity, {
      leftContent: {
        // If able to edit the whole line item as a change order, no need to have a separate icon
        // for editing the total value; they can just edit the original total value that way
        content: onAdjustUnitPriceValue ? (
          <Tooltip title="Adjust line item's quantity contracted" disableInteractive>
            <div>
              <EditCellIcon
                type="icon"
                onEdit={onAdjustUnitPriceValue}
                className={clsx('leftCellIcon', VISIBLE_ON_CELL_HOVER_CLASS)}
                isHoverOnly
              />
            </div>
          </Tooltip>
        ) : (
          <div />
        ),
        dependencies: [onAdjustUnitPriceValue],
        align: 'space-between',
      },
    }),
    // Unit of measure
    makeDataCell(progress.sovLineItem.unitName ?? ''),
    // Unit price
    makeDataCell(unitPriceCentsToDollars(unitPrice).toNumber()),
    // Previous units billed
    makeDataCell(previousAmount),
  ]

  const totalBalanceToFinish = balanceToFinish(progress)
  const totalAmount = progress.totalValue - totalBalanceToFinish
  const progressCells = [
    // Current quantity
    makeDataCell(currentQuantity, {
      validate: (value: SpreadsheetValue) => {
        // Check for overbilling
        const overbillingValidationResult = checkForOverbilling(
          UnitPriceInvoiceColumn.PROGRESS_UNITS_BILLED,
          value,
          progress,
          t
        )
        if (overbillingValidationResult) {
          return overbillingValidationResult
        }

        // Calculate the new total value from the entered quantity, then re-calculate the quantity.
        // We do this because the total value is rounded, so totalValue / unitPrice may not exactly
        // match the entered quantity. In addition to this, the current quantity itself is rounded
        // in the spreadsheet.
        const newOriginalTotalValueInCents = new Decimal(unitPrice).times(Number(value)).round()
        const fixedValue = safeDivideDecimal(newOriginalTotalValueInCents, unitPrice, 0)
          .toDecimalPlaces(2)
          .toNumber()

        return { type: 'override', value: fixedValue }
      },
      onBeforeEdit: onProgressClick,
    }),
    // Current amount
    makeDataCell(centsToDollars(progress.progressBilled), {
      validate: (value: SpreadsheetValue) =>
        checkForOverbilling(BaseInvoiceColumn.PROGRESS_BILLED, value, progress, t),
      onBeforeEdit: onProgressClick,
    }),
    // Stored materials
    storedMaterialsCell,
    // Total quantity
    makeDataCell(safeDivide(totalAmount, unitPrice, 0)),
    // Total amount
    makeDataCell(centsToDollars(totalAmount)),
    // Units to finish
    makeDataCell(safeDivide(totalBalanceToFinish, unitPrice, 0)),
  ]

  return [...lineItemCells, ...progressCells]
}

/**
 * Returns the totals row for a unit price invoice, with total amounts for each column
 * to be displayed as a footer row below each group and at the end of the invoice table
 */
export function getUnitPriceInvoiceTotalsCells({
  progress,
  t,
  shouldIncludeCostCode,
  totalsRowType,
  storedMaterialsView,
  storedMaterialsCarryoverType,
}: {
  progress: SovLineItemProgressProperties[]
  t: TFunction
  shouldIncludeCostCode: boolean
  /** The subtotal row is displayed at the end of each group, totals row is at the very bottom */
  totalsRowType: 'group' | 'invoice'
  storedMaterialsView: StoredMaterialsView
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
}): SpreadsheetCell[] {
  const previouslyBilled = previousPayAppBilled([...progress])
  const currentBilled = currentPayAppBilled([...progress])
  const progressBilled = currentPayAppProgress([...progress])
  const totalBilledToDate = previouslyBilled + currentBilled

  const totalStoredMaterialsAmount = currentPayAppMaterialsStored(
    [...progress],
    storedMaterialsCarryoverType
  )

  const itemCount = t('projects.subcontractors.pay_app.invoice.total_items', {
    count: progress.length,
  })

  const lineItemCells = [
    makeContentCell(
      <SitelineText variant="secondary" bold>
        {totalsRowType === 'group'
          ? t('projects.subcontractors.pay_app.invoice.subtotal')
          : t('projects.subcontractors.pay_app.invoice.total')}
      </SitelineText>,
      []
    ),
    makeDataCell(itemCount, { colSpan: shouldIncludeCostCode ? 7 : 6 }),
  ]

  const storedMaterialsCell =
    storedMaterialsView === StoredMaterialsView.AMOUNT
      ? makeDataCell(centsToDollars(totalStoredMaterialsAmount))
      : makeContentCell(null, [])

  const progressCells = [
    makeDataCell(centsToDollars(progressBilled)),
    storedMaterialsCell,
    makeContentCell(null, []),
    makeDataCell(centsToDollars(totalBilledToDate)),
    makeContentCell(null, []),
  ]

  return [...lineItemCells, ...progressCells]
}

/**
 * Returns a list of `SpreadsheetRow` event cells to be displayed in a unit price pay app invoice
 * history row
 */
export function getUnitPriceProgressHistoryCells({
  retentionView,
  taxesView,
  timeZone,
  t,
  event,
  progress,
  historyProgress,
  shouldIncludeCostCode,
  storedMaterialsCarryoverType,
  storedMaterialsView,
}: {
  retentionView?: RetentionView
  taxesView?: TaxesView
  timeZone: string
  t: TFunction
  event: SovLineItemProgressForEvents['events'][number]
  progress: SovLineItemProgressProperties
  historyProgress: HistoryProgressProperties
  shouldIncludeCostCode: boolean
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
  storedMaterialsView: StoredMaterialsView
}): SpreadsheetCell[] {
  if (progress.sovLineItem.unitPrice === null) {
    // Shouln't be possible, but added for type-checking
    return []
  }
  const unitPrice = progress.sovLineItem.unitPrice
  const formattedDate = moment.tz(event.createdAt, timeZone).format('MMM D, h:mma')
  const totalRetention = (historyProgress.retentionHeld ?? 0) + progress.previousRetention
  const totalBilled = historyProgress.scheduledValue - historyProgress.balanceToFinish
  const currentBilled = historyProgress.progressBilled + historyProgress.storedMaterialBilled

  const isManualStoredMaterials =
    storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL

  const storedMaterials = isManualStoredMaterials
    ? (historyProgress.materialsStored ?? historyProgress.storedMaterialBilled)
    : historyProgress.storedMaterialBilled

  const storedMaterialsFormatted =
    storedMaterialsView === StoredMaterialsView.UNIT
      ? safeDivideDecimal(storedMaterials, unitPrice, 0).toDecimalPlaces(2).toNumber()
      : centsToDollars(storedMaterials)

  return [
    makeDataCell(formattedDate, { className: HISTORICAL_PROGRESS_DATE_CELL_CLASS }),
    makeContentCell(
      <div className="historyDescription">
        <Avatar
          user={event.createdBy}
          isAdmin={event.isAdmin}
          color="grey30"
          textColor="grey90"
          size="sm"
        />
        <SitelineText variant="secondary">
          {getProgressEventDescription(progress.sovLineItem.isChangeOrder, event, t)}
        </SitelineText>
      </div>,
      [],
      { colSpan: shouldIncludeCostCode ? 2 : 1 }
    ),
    makeDataCell(safeDivide(historyProgress.scheduledValue, unitPrice, 0), {
      bold: event.type === SovLineItemProgressEventType.SET_LINE_ITEM_TOTAL_VALUE,
    }),
    makeDataCell(progress.sovLineItem.unitName ?? ''),
    makeDataCell(centsToDollars(unitPrice)),
    // Previous units billed
    makeDataCell(safeDivide(historyProgress.previousBilled, unitPrice, 0), {
      bold: event.type === SovLineItemProgressEventType.SET_LINE_ITEM_PREVIOUS_BILLED,
    }),
    // Current quantity
    makeDataCell(safeDivide(historyProgress.progressBilled, unitPrice, 0), {
      bold:
        event.type === SovLineItemProgressEventType.SET_PROGRESS_BILLED ||
        event.type === SovLineItemProgressEventType.RESET_FROM_PREVIOUS_PAY_APP,
    }),
    // Current amount
    makeDataCell(centsToDollars(historyProgress.progressBilled), {
      bold:
        event.type === SovLineItemProgressEventType.SET_PROGRESS_BILLED ||
        event.type === SovLineItemProgressEventType.RESET_FROM_PREVIOUS_PAY_APP,
    }),
    // Stored materials
    makeDataCell(storedMaterialsFormatted, {
      bold:
        event.type === SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED ||
        event.type === SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED_AND_INSTALLED ||
        event.type === SovLineItemProgressEventType.RESET_FROM_PREVIOUS_PAY_APP,
    }),
    // Quantity to date
    makeDataCell(safeDivide(totalBilled, unitPrice, 0)),
    // Amount to date
    makeDataCell(centsToDollars(totalBilled)),
    // Units to finish
    makeDataCell(safeDivide(historyProgress.balanceToFinish, unitPrice, 0)),
    ...(retentionView === RetentionView.HELD_CURRENT_PERCENT ||
    retentionView === RetentionView.HELD_TO_DATE_PERCENT
      ? [
          makeDataCell(
            historyProgress.retentionHeld !== undefined
              ? decimalToPercent(safeDivide(historyProgress.retentionHeld, currentBilled, 0), 0)
              : '',
            {
              bold: event.type === SovLineItemProgressEventType.RETENTION_ADJUSTED,
              className: RETENTION_PROGRESS_CELL_CLASS,
            }
          ),
        ]
      : []),
    ...(retentionView === RetentionView.HELD_TO_DATE_AMOUNT
      ? [
          makeDataCell(totalRetention !== 0 ? centsToDollars(totalRetention) : '', {
            bold: event.type === SovLineItemProgressEventType.RETENTION_ADJUSTED,
            className: RETENTION_PROGRESS_CELL_CLASS,
          }),
        ]
      : []),
    ...(retentionView === RetentionView.HELD_CURRENT_AMOUNT
      ? [
          makeDataCell(
            historyProgress.retentionHeld ? centsToDollars(historyProgress.retentionHeld) : '',
            {
              bold: event.type === SovLineItemProgressEventType.RETENTION_ADJUSTED,
              className: RETENTION_PROGRESS_CELL_CLASS,
            }
          ),
        ]
      : []),
    ...(retentionView === RetentionView.RELEASED_CURRENT_AMOUNT
      ? [makeDataCell('', { className: RETENTION_PROGRESS_CELL_CLASS })]
      : []),
    ...(taxesView ? [makeDataCell('')] : []),
    makeDataCell(''),
  ]
}
