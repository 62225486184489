import { gql } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LockIcon from '@mui/icons-material/Lock'
import PhoneIcon from '@mui/icons-material/Phone'
import {
  Button,
  Collapse,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import {
  BillingType,
  centsToDollars,
  dollarsToCents,
  formatCentsToDollars,
  pMap,
  replaceAllWhitespaces,
  StoredMaterialsCarryoverType,
} from 'siteline-common-all'
import {
  colors,
  evictWithGc,
  makeStylesFast,
  Permission,
  SitelineText,
  SitelineTooltip,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { locationInputFromLocation } from '../../../common/components/LocationAutocomplete'
import { Row } from '../../../common/components/Row'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  LocationInput,
  ProjectCompaniesForSubcontractorDocument,
  Query,
  useCreateCompanyContactMutation,
  useGetCompanyContactsQuery,
  useProjectCompaniesForSubcontractorQuery,
  useUpdateCompanyContactMutation,
  useUpdateContractWithContactsMutation,
  useUpdateProjectMutation,
} from '../../../common/graphql/apollo-operations'
import { getEmailDomainsFromContacts } from '../../../common/util/Email'
import { formatLocationOneLine, formatLocationWithEmptyFields } from '../../../common/util/Location'
import { trackStoredMaterialsCarryoverTypeUpdated } from '../../../common/util/MetricsTracking'
import { isPayAppDraftOrSyncFailed } from '../../../common/util/PayApp'
import { formatPhoneNumber } from '../../../common/util/PhoneNumber'
import { getCompanyName } from '../../../common/util/Project'
import {
  invalidateContractsAfterOnboardingStatusChange,
  makeEmptyCompanyInfo,
  makeProjectCompanyInput,
  ProjectOnboardingCompanyInfo,
} from '../../../common/util/ProjectOnboarding'
import { ContractForProjectHome, ProjectForProjectHome } from '../home/ProjectHome'
import { OnboardingAddressInput } from '../onboarding/OnboardingAddressInput'
import { ProjectCompanyAutocomplete } from '../onboarding/ProjectCompanyAutocomplete'
import { ContactChip } from '../submit-dialog/ContactChip'
import { Contact } from '../submit-dialog/NewContactForm'
import { NewContactDialog } from './NewContactDialog'
import { SettingsHeader } from './SettingsHeader'
import { SettingsRow } from './SettingsRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
    '& .addressLine': {
      marginTop: theme.spacing(0.5),
    },
  },
  input: {
    margin: theme.spacing(-1, 0),
  },
  textInput: {
    minWidth: 500,
    '& .MuiInputBase-root': {
      minWidth: 500,
    },
  },
  error: {
    marginTop: theme.spacing(1.5),
  },
  icon: {
    color: colors.grey50,
  },
  divider: {
    width: '100%',
    backgroundColor: colors.grey20,
    height: 1,
    marginBottom: theme.spacing(3),
  },
  contacts: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  payAppsCompleted: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(-1),
    '& input': {
      marginRight: theme.spacing(1.5),
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      height: theme.spacing(5),
      width: theme.spacing(6),
      ...theme.typography.body1,
    },
  },
  preSitelineBilled: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(-1),
    '& input': {
      marginRight: theme.spacing(1.5),
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      height: theme.spacing(5),
      ...theme.typography.body1,
    },
  },
  infoIcon: {
    fontSize: 16,
    color: colors.grey50,
  },
  gcContact: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: colors.grey50,
    },
    '& > * ': {
      marginRight: theme.spacing(1),
    },
  },
  storedMaterialsType: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const i18nBase = 'projects.subcontractors.settings.project_info'

gql`
  mutation updateContractWithContacts($input: UpdateContractInput!) {
    updateContract(input: $input) {
      ...MinimalContractProperties
      firstPayAppBillingEnd
      hasStartedBilling
      defaultGcContacts {
        ...CompanyContactProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      project {
        id
      }
      payApps {
        id
        payAppNumber
        balanceToFinish
        totalRetention
        emailedContacts {
          ...CompanyContactProperties
        }
      }
    }
  }
  ${fragments.minimalContract}
  ${fragments.companyContact}
  ${fragments.onboardedProjectContractStatus}
  ${fragments.companyContact}
`

interface ProjectInfoProps {
  contract?: ContractForProjectHome
}

export function companyInfoFromProjectCompany(
  company: ProjectForProjectHome['generalContractor' | 'owner' | 'architect']
) {
  return company
    ? {
        id: company.company.id,
        name: getCompanyName(company),
        locationId: company.selectedAddress?.id ?? null,
        address: company.selectedAddress
          ? locationInputFromLocation(company.selectedAddress)
          : null,
      }
    : null
}

/** Shows project info fields and allows you to edit them. */
export function ProjectInfo({ contract }: ProjectInfoProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()

  const { isContractActive } = useProjectContext()
  const { companyId, permissions, defaultStoredMaterialsCarryoverType } = useCompanyContext()
  const canEdit = permissions.includes(Permission.EDIT_PROJECT_SETTINGS) && isContractActive
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const [updateProjectMutation] = useUpdateProjectMutation({
    refetchQueries: [
      {
        query: ProjectCompaniesForSubcontractorDocument,
        variables: { subcontractorCompanyId: companyId },
      },
    ],
    update(cache) {
      if (!contract) {
        return
      }
      evictWithGc(cache, (evict) => {
        contract.payApps.forEach((payApp) =>
          evict({ id: cache.identify(payApp), fieldName: 'missingRequiredFields' })
        )
        evict({ id: cache.identify(contract), fieldName: 'missingRequiredVendorLienWaiverFields ' })
      })
    },
  })
  const [updateContractMutation] = useUpdateContractWithContactsMutation()

  const [projectName, setProjectName] = useState<string>('')
  const [projectAddress, setProjectAddress] = useState<LocationInput | null>(null)
  const [projectNumber, setProjectNumber] = useState<string>('')
  const [internalProjectNumber, setInternalProjectNumber] = useState<string | null>(null)
  const [generalContractor, setGeneralContractor] = useState<ProjectOnboardingCompanyInfo | null>(
    null
  )
  const [owner, setOwner] = useState<ProjectOnboardingCompanyInfo | null>(null)
  const [architect, setArchitect] = useState<ProjectOnboardingCompanyInfo | null>(null)
  const [pastPayAppCount, setPastPayAppCount] = useState<number>(0)
  const [preSitelineBilled, setPreSitelineBilled] = useState<number | null>(null)
  const [storedMaterialsCarryoverType, setStoredMaterialsCarryoverType] =
    useState<StoredMaterialsCarryoverType>(defaultStoredMaterialsCarryoverType)
  const [hasEdited, setHasEdited] = useState<boolean>(false)
  const { data } = useProjectCompaniesForSubcontractorQuery({
    variables: { subcontractorCompanyId: companyId },
  })
  const [generalContractors, owners, architects] = useMemo(
    () =>
      data
        ? [
            [...data.projectCompaniesForSubcontractor.generalContractors],
            [...data.projectCompaniesForSubcontractor.owners],
            [...data.projectCompaniesForSubcontractor.architects],
          ]
        : [[], [], []],
    [data]
  )
  const generalContractorLocations = useMemo(() => {
    const contractor = generalContractors.find((gc) => gc.id === generalContractor?.id)
    return [...(contractor?.locations ?? [])]
  }, [generalContractor?.id, generalContractors])

  const gcCompany = useMemo(() => {
    const generalContractor = contract?.project.generalContractor
    if (!generalContractor) {
      return undefined
    }
    return {
      id: generalContractor.company.id,
      name: generalContractor.companyName ?? generalContractor.company.name,
    }
  }, [contract?.project.generalContractor])

  const ownerLocations = useMemo(() => {
    const ownerWithLocations = owners.find((someOwner) => someOwner.id === owner?.id)
    return [...(ownerWithLocations?.locations ?? [])]
  }, [owner?.id, owners])
  const architectLocations = useMemo(() => {
    const architectWithLocations = architects.find(
      (someArchitect) => someArchitect.id === architect?.id
    )
    return [...(architectWithLocations?.locations ?? [])]
  }, [architect?.id, architects])

  const initialGeneralContractor = useMemo(
    () => companyInfoFromProjectCompany(contract?.project.generalContractor ?? null),
    [contract?.project.generalContractor]
  )
  const initialOwner = useMemo(
    () => companyInfoFromProjectCompany(contract?.project.owner ?? null),
    [contract?.project.owner]
  )
  const initialArchitect = useMemo(
    () => companyInfoFromProjectCompany(contract?.project.architect ?? null),
    [contract?.project.architect]
  )

  const canEditStoredMaterialsCarryoverType = useMemo(() => {
    return (
      !!contract && contract.payApps.every((payApp) => isPayAppDraftOrSyncFailed(payApp.status))
    )
  }, [contract])

  const initialGcContacts = useMemo(() => [...(contract?.defaultGcContacts ?? [])], [contract])
  const [gcContacts, setGcContacts] = useState<Contact[]>(initialGcContacts)
  const [editingContact, setEditingContact] = useState<Contact>()
  const [contactDialogOpen, setContactDialogOpen] = useState<boolean>(false)

  const resetState = useCallback(() => {
    if (contract) {
      setProjectNumber(contract.project.projectNumber)
      setProjectName(contract.project.name)
      setProjectAddress(contract.project.location)
      setGeneralContractor(initialGeneralContractor)
      setGcContacts(initialGcContacts)
      setOwner(initialOwner)
      setArchitect(initialArchitect)
      setPastPayAppCount(contract.pastPayAppCount)
      setPreSitelineBilled(contract.preSitelineBilled)
      setInternalProjectNumber(contract.internalProjectNumber)
      setStoredMaterialsCarryoverType(contract.storedMaterialsCarryoverType)
    }
    setHasEdited(false)
  }, [contract, initialGeneralContractor, initialGcContacts, initialOwner, initialArchitect])

  // Update the initial contacts when the contract loads
  useEffect(() => {
    setGcContacts(initialGcContacts)
  }, [initialGcContacts])

  const { data: companyContactData } = useGetCompanyContactsQuery({
    variables: { input: { companyId } },
  })
  const [createNewCompanyContact] = useCreateCompanyContactMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      cache.modify<WritableDeep<Query>>({
        id: 'ROOT_QUERY',
        fields: {
          companyContacts(existingRefs, { toReference, storeFieldName }) {
            // Don't modify the cached query for another company's contacts
            if (!storeFieldName.includes(companyId)) {
              return existingRefs
            }
            const newRef = cache.writeFragment({
              data: data.createCompanyContact,
              fragment: fragments.companyContact,
              fragmentName: 'CompanyContactProperties',
            })
            const refs = toReferences(existingRefs, toReference)
            return _.compact([...refs, newRef])
          },
        },
      })
    },
  })
  const [updateCompanyContact] = useUpdateCompanyContactMutation()

  const companyContacts = useMemo(
    () => [...(companyContactData?.companyContacts ?? [])],
    [companyContactData?.companyContacts]
  )

  const unaddedContacts = useMemo(
    () => _.differenceBy(companyContacts, gcContacts, (contact) => contact.id),
    [companyContacts, gcContacts]
  )

  const removedContacts = useMemo(
    () => _.differenceBy(contract?.defaultGcContacts ?? [], gcContacts, (contact) => contact.id),
    [contract?.defaultGcContacts, gcContacts]
  )

  const gcEmailDomains = useMemo(
    () => getEmailDomainsFromContacts(companyContacts.map(({ email }) => email)),
    [companyContacts]
  )

  const [newContacts, existingContacts] = useMemo(
    () =>
      _.partition(
        gcContacts,
        (contact) =>
          !contract?.defaultGcContacts.some((companyContact) => companyContact.id === contact.id)
      ),
    [contract?.defaultGcContacts, gcContacts]
  )
  const hasEditedContacts = useMemo(
    () =>
      gcContacts.some((contact) => {
        const originalContact = companyContacts.find(
          (originalContact) => originalContact.id === contact.id
        )
        return (
          originalContact !== undefined &&
          (originalContact.email !== contact.email ||
            originalContact.fullName !== contact.fullName ||
            originalContact.phoneNumber !== contact.phoneNumber ||
            originalContact.jobTitle !== contact.jobTitle)
        )
      }),
    [gcContacts, companyContacts]
  )
  const formattedProjectAddress = useMemo(
    () => (projectAddress ? formatLocationOneLine(projectAddress) : ''),
    [projectAddress]
  )

  const handleProjectAddressChange = useMemo(
    () =>
      _.debounce((newLocation: LocationInput | null) => {
        setProjectAddress(newLocation)
        setHasEdited(true)
      }),
    []
  )

  const handleAddOrEditContact = useCallback(
    async (contact: Contact) => {
      const newContacts = [...gcContacts]
      const existingContactIndex = gcContacts.findIndex(
        (otherContact) => otherContact.id === contact.id
      )
      newContacts.splice(
        existingContactIndex >= 0 ? existingContactIndex : newContacts.length,
        1,
        contact
      )
      const uniqueEmails = new Set(newContacts.map(({ email }) => email))
      const areAllEmailsUnique = uniqueEmails.size === newContacts.length
      if (!areAllEmailsUnique) {
        // This error is caught on `NewContactDialog`
        throw new Error(t(`${i18nBase}.unique_email_error`))
      }
      setGcContacts(newContacts)
      setHasEdited(true)
    },
    [gcContacts, t]
  )

  const handleSave = useCallback(async () => {
    if (!contract) {
      return
    }

    let success = false

    const locationInput =
      projectAddress && !_.isEqual(projectAddress, contract.project.location)
        ? formatLocationWithEmptyFields(projectAddress)
        : null
    const generalContractorInput =
      generalContractor && !_.isEqual(generalContractor, initialGeneralContractor)
        ? makeProjectCompanyInput(generalContractor)
        : null
    const ownerInput =
      owner && !_.isEqual(owner, initialOwner) ? makeProjectCompanyInput(owner) : null
    const architectInput =
      architect && !_.isEqual(architect, initialArchitect)
        ? makeProjectCompanyInput(architect)
        : null

    // Only update project if different
    if (
      projectNumber !== contract.project.projectNumber ||
      projectName !== contract.project.name ||
      locationInput !== null ||
      generalContractorInput !== null ||
      ownerInput !== null ||
      architectInput !== null
    ) {
      try {
        await updateProjectMutation({
          variables: {
            input: {
              companyId: contract.company.id,
              id: contract.project.id,
              name: projectName,
              projectNumber,
              ...(locationInput && { location: locationInput }),
              ...(generalContractorInput && { generalContractor: generalContractorInput }),
              ...(ownerInput && { owner: ownerInput }),
              ...(architectInput && { architect: architectInput }),
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateProject: {
              ...contract.project,
              name: projectName,
              projectNumber,
            },
          },
        })
        success = true
      } catch (error) {
        snackbar.showError(error.message)
      }
    }

    // Only update contract if different
    const hasStoredMaterialsChanged =
      storedMaterialsCarryoverType !== contract.storedMaterialsCarryoverType
    if (
      internalProjectNumber !== contract.internalProjectNumber ||
      pastPayAppCount !== contract.pastPayAppCount ||
      preSitelineBilled !== contract.preSitelineBilled ||
      hasStoredMaterialsChanged ||
      newContacts.length > 0 ||
      removedContacts.length > 0 ||
      hasEditedContacts
    ) {
      let timeAndMaterialsPreSitelineBilled =
        contract.billingType === BillingType.TIME_AND_MATERIALS ? preSitelineBilled : undefined
      if (timeAndMaterialsPreSitelineBilled !== undefined && pastPayAppCount === 0) {
        // If the pastPayAppCount is being reset to 0, clear any pre-Siteline billing
        timeAndMaterialsPreSitelineBilled = 0
      }

      try {
        const contactsData = await pMap(newContacts, (contact) =>
          createNewCompanyContact({
            variables: {
              input: {
                companyId,
                fullName: replaceAllWhitespaces(contact.fullName).trim(),
                email: contact.email,
                companyName: replaceAllWhitespaces(contact.companyName).trim(),
                phoneNumber: contact.phoneNumber,
                jobTitle: contact.jobTitle || null,
              },
            },
          })
        )
        const contacts = contactsData.map((data) => data.data?.createCompanyContact)
        // Pass all edited AND unedited contacts to this mutation, since we can't tell which ones
        // were edited; the backend can handle both, and will simply return existing contacts
        // that are unchanged
        const editedContactsData = await pMap(existingContacts, (contact) =>
          updateCompanyContact({
            variables: {
              id: contact.id,
              values: {
                fullName: replaceAllWhitespaces(contact.fullName).trim(),
                email: contact.email,
                companyName: replaceAllWhitespaces(contact.companyName).trim(),
                phoneNumber: contact.phoneNumber,
                jobTitle: contact.jobTitle || null,
              },
              removePhoneNumber: !contact.phoneNumber,
              removeJobTitle: !contact.jobTitle,
            },
          })
        )
        contacts.push(...editedContactsData.map((data) => data.data?.updateCompanyContact))
        const defaultGcContacts = _.compact(contacts)
        const defaultGcContactIdsSet = new Set(defaultGcContacts.map((contact) => contact.id))
        await updateContractMutation({
          variables: {
            input: {
              id: contract.id,
              internalProjectNumber,
              defaultGcContactIds: Array.from(defaultGcContactIdsSet),
              pastPayAppCount,
              preSitelineBilled: timeAndMaterialsPreSitelineBilled,
              storedMaterialsCarryoverType,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateContract: {
              ...contract,
              internalProjectNumber,
              pastPayAppCount,
              preSitelineBilled: timeAndMaterialsPreSitelineBilled ?? null,
              defaultGcContacts,
              storedMaterialsCarryoverType,
              onboardedStatus: {
                ...contract.onboardedStatus,
                addedGcContacts: true,
              },
              project: {
                __typename: 'Project',
                id: contract.project.id,
              },
              payApps: contract.payApps.map((payApp) => ({
                __typename: 'PayApp',
                id: payApp.id,
                payAppNumber: payApp.payAppNumber,
                emailedContacts: defaultGcContacts,
                balanceToFinish: payApp.balanceToFinish,
                totalRetention: payApp.totalRetention,
              })),
            },
          },
          update: (cache) => {
            // OnboardedStatus.addedGcContacts might change
            invalidateContractsAfterOnboardingStatusChange(cache)
          },
        })
        if (hasStoredMaterialsChanged) {
          trackStoredMaterialsCarryoverTypeUpdated({
            projectId: contract.project.id,
            projectName: contract.project.name,
            storedMaterialsTracking: storedMaterialsCarryoverType,
          })
        }
        success = true
      } catch (error) {
        snackbar.showError(error.message)
      }
    }

    // Display the success only if something was successful
    if (success) {
      snackbar.showSuccess(t(`${i18nBase}.updated`))
      setHasEdited(false)
    }
  }, [
    architect,
    companyId,
    contract,
    createNewCompanyContact,
    existingContacts,
    generalContractor,
    hasEditedContacts,
    initialArchitect,
    initialGeneralContractor,
    initialOwner,
    internalProjectNumber,
    newContacts,
    owner,
    pastPayAppCount,
    preSitelineBilled,
    projectAddress,
    projectName,
    projectNumber,
    removedContacts.length,
    snackbar,
    t,
    updateCompanyContact,
    updateContractMutation,
    storedMaterialsCarryoverType,
    updateProjectMutation,
  ])

  // Update the state when the project and contract load in
  useEffect(() => {
    if (!contract) {
      return
    }
    resetState()
  }, [contract, resetState])

  // Architect is not required, but if it's previously been set or an address is provided, it can't be empty
  const isIncompleteArchitect =
    (contract?.project.architect || architect?.address) && !architect?.name
  const isIncompleteOwner = (owner?.address || owner?.locationId) && !owner.name
  const requiredFieldsExist =
    projectNumber !== '' &&
    projectName !== '' &&
    projectAddress !== null &&
    generalContractor?.name &&
    !isIncompleteOwner &&
    !isIncompleteArchitect

  const shouldDisplayStoredMaterialsCarryoverType =
    !!contract &&
    (contract.billingType === BillingType.UNIT_PRICE ||
      contract.billingType === BillingType.LUMP_SUM)
  const hasOwnerAddress = !!owner?.address || !!owner?.locationId
  const hasOwnerField = !!owner?.name || hasOwnerAddress
  const bulkSaveProps = useMemo(
    () => ({
      onSave: handleSave,
      onCancel: resetState,
      hasEdited,
    }),
    [handleSave, hasEdited, resetState]
  )

  return (
    <div className={classes.root}>
      <SettingsHeader
        title={t(`${i18nBase}.title`)}
        canEdit={canEdit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        disableSave={!requiredFieldsExist}
        bulkSaveProps={bulkSaveProps}
      />
      <SettingsRow
        label={t(`${i18nBase}.project_name`)}
        isLoading={!contract}
        value={projectName}
        isEditing={isEditing}
        editingValue={
          <>
            <TextField
              className={clsx(classes.input, classes.textInput)}
              variant="outlined"
              error={projectName === ''}
              value={projectName}
              onChange={(ev) => {
                setProjectName(ev.target.value)
                setHasEdited(true)
              }}
            />
            {projectName === '' && (
              <SitelineText variant="smallText" color="red70" className={classes.error}>
                {t(`${i18nBase}.required_field`)}
              </SitelineText>
            )}
          </>
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.project_address`)}
        isLoading={!contract}
        value={formattedProjectAddress}
        isEditing={isEditing}
        editingValue={
          <div className={clsx(classes.input, classes.textInput)}>
            <OnboardingAddressInput
              dialogTitle={t(`${i18nBase}.project_address`)}
              locationOptions={[]}
              locationId={contract?.project.id || null}
              location={projectAddress}
              onLocationChange={handleProjectAddressChange}
              submitDialogLabel={t(`${i18nBase}.submit_label`)}
            />
          </div>
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.internal_project_number`)}
        isLoading={!contract}
        value={internalProjectNumber}
        isEditing={isEditing}
        editingValue={
          <TextField
            className={classes.input}
            variant="outlined"
            value={internalProjectNumber ?? ''}
            onChange={(ev) => {
              setInternalProjectNumber(ev.target.value !== '' ? ev.target.value : null)
              setHasEdited(true)
            }}
          />
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.gc_project_number`)}
        isLoading={!contract}
        value={projectNumber}
        isEditing={isEditing}
        editingValue={
          <>
            <TextField
              className={classes.input}
              variant="outlined"
              error={projectNumber === ''}
              value={projectNumber}
              onChange={(ev) => {
                setProjectNumber(ev.target.value)
                setHasEdited(true)
              }}
            />
            {projectNumber === '' && (
              <SitelineText variant="smallText" color="red70" className={classes.error}>
                {t(`${i18nBase}.required_field`)}
              </SitelineText>
            )}
          </>
        }
      />
      <div className={classes.divider} />
      <SettingsRow
        label={t(`${i18nBase}.general_contractor`)}
        isLoading={!contract}
        value={
          generalContractor ? (
            <>
              <div>{generalContractor.name}</div>
              {generalContractor.address && (
                <SitelineText variant="body1" color="grey50" className="addressLine">
                  {formatLocationOneLine(generalContractor.address)}
                </SitelineText>
              )}
            </>
          ) : null
        }
        isEditing={isEditing}
        editingValue={
          <>
            <TextField
              className={clsx(classes.input, classes.textInput)}
              variant="outlined"
              value={generalContractor?.name ?? ''}
              onChange={(ev) => {
                if (!generalContractor) {
                  return
                }
                setGeneralContractor({ ...generalContractor, name: ev.target.value })
                setHasEdited(true)
              }}
              error={!generalContractor?.name}
            />
            {!generalContractor?.name && (
              <SitelineText variant="smallText" color="red70" className={classes.error}>
                {t(`${i18nBase}.required_field`)}
              </SitelineText>
            )}
          </>
        }
      />
      {isEditing && (
        <SettingsRow
          label=""
          isLoading={!contract}
          value={
            generalContractor?.address ? formatLocationOneLine(generalContractor.address) : null
          }
          isEditing={isEditing}
          editingValue={
            <div className={clsx(classes.input, classes.textInput)}>
              <OnboardingAddressInput
                dialogTitle={t(`${i18nBase}.general_contractor_address`)}
                addButtonLabel={t(`${i18nBase}.general_contractor_address`)}
                locationOptions={generalContractorLocations}
                locationId={generalContractor?.locationId ?? null}
                location={generalContractor?.address ?? null}
                onLocationChange={(location, locationId) => {
                  setGeneralContractor({
                    id: generalContractor?.id ?? null,
                    name: generalContractor?.name ?? '',
                    locationId,
                    address: location,
                  })
                  setHasEdited(true)
                }}
              />
            </div>
          }
        />
      )}
      <SettingsRow
        label={t(`${i18nBase}.gc_contacts`)}
        isLoading={!contract}
        value={
          gcContacts.length > 0 ? (
            <>
              {gcContacts.map((contact) => (
                <div key={contact.id} className={classes.gcContact}>
                  <SitelineText variant="body1" color="grey70">
                    {contact.fullName}
                  </SitelineText>
                  <SitelineTooltip title={contact.email} arrow>
                    <IconButton onClick={() => window.open(`mailto:${contact.email}`)} size="small">
                      <EmailOutlinedIcon />
                    </IconButton>
                  </SitelineTooltip>
                  {contact.phoneNumber && (
                    <SitelineTooltip title={formatPhoneNumber(contact.phoneNumber)} arrow>
                      <PhoneIcon />
                    </SitelineTooltip>
                  )}
                </div>
              ))}
            </>
          ) : null
        }
        isEditing={isEditing}
        editingValue={
          <div className={classes.input}>
            {gcContacts.length > 0 && (
              <div className={classes.contacts}>
                {gcContacts.map((contact) => (
                  <ContactChip
                    key={contact.id}
                    contact={contact}
                    onEditContact={() => {
                      setEditingContact(contact)
                      setContactDialogOpen(true)
                    }}
                    onRemoveContact={() => {
                      setGcContacts((contacts) =>
                        contacts.filter((otherContact) => otherContact.id !== contact.id)
                      )
                      setHasEdited(true)
                    }}
                    color="grey"
                  />
                ))}
              </div>
            )}
            <Button
              variant={'outlined'}
              color={'secondary'}
              onClick={() => setContactDialogOpen(true)}
              startIcon={<AddIcon fontSize="small" />}
            >
              {t(`${i18nBase}.add_contact`)}
            </Button>
          </div>
        }
      />
      <NewContactDialog
        open={contactDialogOpen}
        initialContact={editingContact}
        onClose={() => {
          setContactDialogOpen(false)
          setEditingContact(undefined)
        }}
        availableContacts={unaddedContacts}
        subtitle={t('projects.subcontractors.settings.new_contact_dialog.contacts_saved')}
        company={gcCompany}
        existingDomains={gcEmailDomains}
        onAddOrEditContact={handleAddOrEditContact}
      />
      <div className={classes.divider} />
      <SettingsRow
        label={t(`${i18nBase}.owner`)}
        isLoading={!contract}
        value={
          owner ? (
            <>
              <div>{owner.name}</div>
              {owner.address && (
                <SitelineText variant="body1" color="grey50" className="addressLine">
                  {formatLocationOneLine(owner.address)}
                </SitelineText>
              )}
            </>
          ) : null
        }
        isEditing={isEditing}
        editingValue={
          <>
            <TextField
              className={clsx(classes.input, classes.textInput)}
              variant="outlined"
              value={owner?.name ?? ''}
              onChange={(ev) => {
                let newOwner: ProjectOnboardingCompanyInfo | null = {
                  ...(owner ?? makeEmptyCompanyInfo()),
                  name: ev.target.value || null,
                }
                if (_.isEqual(newOwner, makeEmptyCompanyInfo())) {
                  newOwner = null
                }
                setOwner(newOwner)
                setHasEdited(true)
              }}
              error={!owner?.name && hasOwnerAddress}
            />
            {!owner?.name && hasOwnerAddress && (
              <SitelineText variant="smallText" color="red70" className={classes.error}>
                {t(`${i18nBase}.required_with_address`)}
              </SitelineText>
            )}
          </>
        }
      />
      {isEditing && hasOwnerField && (
        <SettingsRow
          label=""
          isLoading={!contract}
          value={owner.address ? formatLocationOneLine(owner.address) : null}
          isEditing={isEditing}
          editingValue={
            <div className={clsx(classes.input, classes.textInput)}>
              <OnboardingAddressInput
                dialogTitle={t(`${i18nBase}.owner_address`)}
                addButtonLabel={t(`${i18nBase}.owner_address`)}
                locationOptions={ownerLocations}
                locationId={owner.locationId ?? null}
                location={owner.address ?? null}
                onLocationChange={(location, locationId) => {
                  let newOwner: ProjectOnboardingCompanyInfo | null = {
                    id: owner.id ?? null,
                    name: owner.name ?? null,
                    locationId,
                    address: location,
                  }
                  if (_.isEqual(newOwner, makeEmptyCompanyInfo())) {
                    newOwner = null
                  }
                  setOwner(newOwner)
                  setHasEdited(true)
                }}
              />
            </div>
          }
        />
      )}
      <SettingsRow
        label={t(`${i18nBase}.architect`)}
        isLoading={!contract}
        value={
          architect ? (
            <>
              <div>{architect.name}</div>
              {architect.address && (
                <SitelineText variant="body1" color="grey50" className="addressLine">
                  {formatLocationOneLine(architect.address)}
                </SitelineText>
              )}
            </>
          ) : null
        }
        isEditing={isEditing}
        editingValue={
          contract?.project.architect ? (
            <>
              <TextField
                className={clsx(classes.input, classes.textInput)}
                variant="outlined"
                value={architect?.name ?? ''}
                onChange={(ev) => {
                  if (!architect) {
                    return
                  }
                  setArchitect({ ...architect, name: ev.target.value })
                  setHasEdited(true)
                }}
                error={!architect?.name}
              />
              {!architect?.name && (
                <SitelineText variant="smallText" color="red70" className={classes.error}>
                  {t(`${i18nBase}.required_field`)}
                </SitelineText>
              )}
            </>
          ) : (
            <div className={clsx(classes.input, classes.textInput)}>
              <ProjectCompanyAutocomplete
                companies={architects}
                company={architect}
                onCompanyChange={setArchitect}
                error={!_.isNil(architect?.address) && !architect.name}
              />
            </div>
          )
        }
      />
      {isEditing && (
        <SettingsRow
          label=""
          isLoading={!contract}
          value={architect?.address ? formatLocationOneLine(architect.address) : null}
          isEditing={isEditing}
          editingValue={
            <div className={clsx(classes.input, classes.textInput)}>
              <OnboardingAddressInput
                dialogTitle={t(`${i18nBase}.architect_address`)}
                addButtonLabel={t(`${i18nBase}.architect_address`)}
                locationOptions={architectLocations}
                locationId={architect?.locationId ?? null}
                location={architect?.address ?? null}
                onLocationChange={(location, locationId) => {
                  setArchitect({
                    id: architect?.id ?? null,
                    name: architect?.name ?? '',
                    locationId,
                    address: location,
                  })
                  setHasEdited(true)
                }}
              />
            </div>
          }
        />
      )}
      <div className={classes.divider} />
      <SettingsRow
        label={t(`${i18nBase}.before_siteline`)}
        isLoading={!contract}
        value={
          <SitelineText variant="body1">
            {t(`${i18nBase}.num_pay_apps_completed`, { count: pastPayAppCount })}
          </SitelineText>
        }
        isEditing={isEditing}
        editingValue={
          <div className={classes.payAppsCompleted}>
            <NumericFormat
              value={pastPayAppCount}
              onValueChange={({ floatValue }) => {
                setPastPayAppCount(floatValue ?? 0)
                setHasEdited(true)
              }}
              decimalScale={0}
              displayType="input"
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values
                return floatValue === undefined || floatValue >= 0
              }}
            />
            <SitelineText variant="body1">
              {t(`${i18nBase}.pay_apps_completed`, { count: pastPayAppCount })}
            </SitelineText>
          </div>
        }
      />
      {contract?.billingType === BillingType.TIME_AND_MATERIALS && (
        <Collapse in={pastPayAppCount > 0}>
          <SettingsRow
            label={t(`${i18nBase}.previously_billed`)}
            isLoading={!contract}
            value={
              <SitelineText variant="body1">
                {formatCentsToDollars(contract.preSitelineBilled ?? 0, true)}
              </SitelineText>
            }
            isEditing={isEditing}
            labelEndIcon={
              <SitelineTooltip title={t(`${i18nBase}.previously_billed_tooltip`)} placement="top">
                <InfoOutlinedIcon className={classes.infoIcon} />
              </SitelineTooltip>
            }
            editingValue={
              <div className={classes.preSitelineBilled}>
                <NumericFormat
                  value={centsToDollars(preSitelineBilled ?? 0)}
                  onValueChange={({ floatValue }) => {
                    setPreSitelineBilled(floatValue === undefined ? 0 : dollarsToCents(floatValue))
                    setHasEdited(true)
                  }}
                  thousandSeparator
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  displayType="input"
                  prefix="$"
                />
              </div>
            }
          />
        </Collapse>
      )}
      {shouldDisplayStoredMaterialsCarryoverType && (
        <SettingsRow
          label={t(`${i18nBase}.stored_materials.title`)}
          isLoading={!contract}
          value={
            <SitelineText variant="body1" className="storedMaterialsType">
              {t(`${i18nBase}.stored_materials.options.${storedMaterialsCarryoverType}`)}
            </SitelineText>
          }
          isEditing={isEditing}
          className={classes.storedMaterialsType}
          editingValue={
            <Row justifyContent="flex-start" alignItems="center" gap={8}>
              <FormControl
                variant="outlined"
                className="dropdown"
                disabled={!canEditStoredMaterialsCarryoverType}
              >
                <Select
                  value={storedMaterialsCarryoverType}
                  onChange={(ev) => {
                    setStoredMaterialsCarryoverType(ev.target.value as StoredMaterialsCarryoverType)
                    setHasEdited(true)
                  }}
                >
                  <MenuItem key="automatic" value={StoredMaterialsCarryoverType.AUTOMATIC}>
                    {t(`${i18nBase}.stored_materials.options.AUTOMATIC`)}
                  </MenuItem>
                  <MenuItem key="automatic" value={StoredMaterialsCarryoverType.MANUAL}>
                    {t(`${i18nBase}.stored_materials.options.MANUAL`)}
                  </MenuItem>
                </Select>
              </FormControl>
              {!canEditStoredMaterialsCarryoverType && (
                <SitelineTooltip
                  title={t(`${i18nBase}.stored_materials.disabled_tooltip`)}
                  placement="top"
                >
                  <LockIcon fontSize="small" className={classes.icon} />
                </SitelineTooltip>
              )}
            </Row>
          }
        />
      )}
    </div>
  )
}
