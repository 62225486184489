import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Tooltip } from '@mui/material'
import { Decimal } from 'decimal.js'
import _ from 'lodash'
import {
  BillingType,
  MAX_SOV_CODE_LENGTH,
  centsToDollars,
  formatCentsToDollars,
  safeDivideDecimal,
  unitPriceCentsToDollars,
} from 'siteline-common-all'
import { SitelineText, colors } from 'siteline-common-web'
import { VISIBLE_ON_CELL_HOVER_CLASS } from '../../../common/components/SitelineTable'
import {
  SpreadsheetCell,
  SpreadsheetFooterRow,
  SpreadsheetRow,
  SpreadsheetRowType,
  makeContentCell,
  makeDataCell,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { doesWorksheetExceedTotalValue } from '../../../common/util/BillingWorksheet'
import {
  EditingSovLineItem,
  getChangeSetLineItemUpdates,
  getContractPreSitelineRetentionAmount,
} from '../../../common/util/ManageSov'
import {
  NUM_FIXED_UNIT_DECIMALS,
  assertValidLineItemUnitPrice,
} from '../../../common/util/ManageUnitPriceSovColumn'
import { isNewBilledInRange } from '../../../common/util/PayApp'
import { isOnboardingSov } from '../../../common/util/ProjectOnboarding'
import { StoredMaterialsView } from '../invoice/InvoiceReducer'
import {
  GetLineItemRowForOnboardingProps,
  GetTotalsRowProps,
  LINE_ITEM_NAME_CHARACTER_LIMIT,
  getLineItemNameRightContent,
  makeChangeOrderApprovalCell,
  makeChangeOrderToggleCell,
  makePreSitelineBillingCell,
  makePreSitelineRetentionAmountCell,
  makeRetentionPercentCell,
  makeStoredMaterialsUnitPriceCell,
} from './ManageSovRow'

/**
 * Returns a `SpreadsheetRow` component to be displayed in the sov onboarding
 * table to represent a single unit price line item
 */
export function getUnitPriceLineItemRow(
  lineItem: EditingSovLineItem,
  {
    includePreSitelineBillingColumn,
    includeMaterialsInStorageColumn,
    includePreSitelineRetentionColumns,
    includeRetentionPercentColumn,
    isRetentionPercentEditable,
    includeTotalBillingColumns,
    includeTotalRetainageColumn,
    includeChangeOrderColumn,
    includeChangeOrderApprovalColumn,
    isChangeOrderApprovalDateEditable,
    includeRevisedContractColumns,
    includeTaxGroupColumn,
    roundRetention,
    onDelete,
    onToggleIsChangeOrder,
    onNavigateToChangeOrder,
    onEditChangeOrderDate,
    onResetPreSitelineRetentionAmount,
    timeZone,
    isFirstInUngroupedBlock,
    showWarningIfEmpty,
    t,
    isEditable,
    isReorderable,
    isRowSelected,
    changeSetUpdate,
    storedMaterialsView = StoredMaterialsView.AMOUNT,
  }: GetLineItemRowForOnboardingProps
): SpreadsheetRow {
  const i18nBase = 'projects.subcontractors.sov'
  const isChangeOrder = lineItem.isChangeOrder ?? false
  const {
    code,
    isCodeUpdated,
    name,
    isNameUpdated,
    costCode,
    isCostCodeUpdated,
    originalTotalValue,
    isOriginalTotalValueUpdated,
    latestTotalValue,
    isLatestTotalValueUpdated,
    defaultRetentionPercent,
    preSitelineRetentionAmount,
    isPreSitelineRetentionAmountUpdated,
    preSitelineBilling,
    isPreSitelineBillingUpdated,
    previousMaterialsInStorage,
    changeOrderApprovedAt,
    isChangeOrderApprovedAtUpdated,
    changeOrderEffectiveAt,
    unitPrice,
    isUnitPriceUpdated,
    unitName,
    isUnitNameUpdated,
  } = getChangeSetLineItemUpdates({
    lineItem,
    update: changeSetUpdate,
    timeZone,
    includePreSitelineBillingColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeMaterialsInStorageColumn,
  })
  const bidQuantity = safeDivideDecimal(originalTotalValue, unitPrice, 0)
    .toDecimalPlaces(2)
    .toNumber()
  const cells: SpreadsheetCell[] = [
    // Number
    makeDataCell(code, {
      borderColor: showWarningIfEmpty && !lineItem.code ? 'error' : 'default',
      backgroundColor: isCodeUpdated ? 'green10' : undefined,
      bold: isCodeUpdated === true && isRowSelected === true,
    }),
    // Name
    makeDataCell(name, {
      rightContent: getLineItemNameRightContent({
        lineItemId: lineItem.id,
        onDelete,
        isEditable,
        numWorksheetLineItems: lineItem.worksheetLineItems.length,
      }),
      borderColor: showWarningIfEmpty && !lineItem.name ? 'error' : 'default',
      backgroundColor: isNameUpdated ? 'green10' : undefined,
      bold: isNameUpdated === true && isRowSelected === true,
      characterLimit: LINE_ITEM_NAME_CHARACTER_LIMIT,
    }),
    // Cost code
    makeDataCell(costCode ?? '', {
      backgroundColor: isCostCodeUpdated ? 'green10' : undefined,
      bold: isCostCodeUpdated && isRowSelected === true,
      characterLimit: MAX_SOV_CODE_LENGTH,
    }),
    // Unit name
    makeDataCell(unitName, {
      backgroundColor: isUnitNameUpdated ? 'green10' : undefined,
      bold: isUnitNameUpdated === true && isRowSelected === true,
    }),
    // Unit price
    makeDataCell(unitPriceCentsToDollars(unitPrice).toNumber(), {
      validate: (value) => {
        const unitPriceError = assertValidLineItemUnitPrice(
          Number(value),
          lineItem.latestTotalValue,
          t
        )
        return unitPriceError ? { type: 'error', message: unitPriceError } : null
      },
      backgroundColor: isUnitPriceUpdated ? 'green10' : undefined,
      bold: isUnitPriceUpdated === true && isRowSelected === true,
    }),
    // Bid quantity
    makeDataCell(bidQuantity, {
      validate: (value) => {
        const newOriginalTotalValueInCents = new Decimal(unitPrice).times(Number(value)).round()
        if (!isNewBilledInRange(lineItem.billedToDate, newOriginalTotalValueInCents.toNumber())) {
          const formattedBilledToDate = formatCentsToDollars(lineItem.billedToDate, true)
          return {
            type: 'confirm',
            title: t(`${i18nBase}.billed_to_date_too_high_confirm_title`),
            details: t(`${i18nBase}.billed_to_date_too_high_scheduled_details`, {
              amountBilled: formattedBilledToDate,
            }),
          }
        }
        const {
          exceedsTotalValue: doesWorksheetTotalValueExceedSovLineItemTotalValue,
          sumOfWorksheetLineItems,
        } = doesWorksheetExceedTotalValue({
          sovLineItem: lineItem,
          currentWorksheetLineItem: null,
          toWorksheetLineItemValue: null,
          toSovLineItemValue: newOriginalTotalValueInCents.toNumber(),
        })
        if (doesWorksheetTotalValueExceedSovLineItemTotalValue) {
          const formattedSumOfWorksheetLineItems = formatCentsToDollars(
            sumOfWorksheetLineItems,
            true
          )
          return {
            type: 'confirm',
            title: t(`${i18nBase}.worksheet_total_exceeds_sov_confirm_title`),
            details: t(`${i18nBase}.worksheet_total_exceeds_sov_confirm_details`, {
              totalWorksheetValue: formattedSumOfWorksheetLineItems,
            }),
          }
        }

        const fixedValue = safeDivideDecimal(newOriginalTotalValueInCents, unitPrice, 0)
          .toDecimalPlaces(2)
          .toNumber()
        return { type: 'override', value: fixedValue }
      },
      // If scheduled value is updated, bid quantity would be updated as well
      backgroundColor: isOriginalTotalValueUpdated ? 'green10' : undefined,
      bold: isOriginalTotalValueUpdated === true && isRowSelected === true,
    }),
  ]
  const latestQuantity = safeDivideDecimal(latestTotalValue, unitPrice, 0)
    .toDecimalPlaces(2)
    .toNumber()
  if (includeRevisedContractColumns) {
    const quantityDelta = latestQuantity - bidQuantity
    let leftContent = null
    if (isEditable && !isOnboardingSov()) {
      leftContent = (
        <Tooltip
          title={t(`${i18nBase}.adjustments`) as string}
          disableInteractive
          className={VISIBLE_ON_CELL_HOVER_CLASS}
        >
          <HelpOutlineOutlinedIcon style={{ width: 16 }} />
        </Tooltip>
      )
    } else {
      let trendIcon = null
      if (quantityDelta > 0) {
        trendIcon = <ArrowDropUpIcon style={{ color: colors.green50 }} />
      } else if (quantityDelta < 0) {
        trendIcon = <ArrowDropDownIcon style={{ color: colors.red50 }} />
      }
      const deltaSign = quantityDelta > 0 ? '+' : '-'
      const deltaAmount = Math.abs(quantityDelta).toFixed(NUM_FIXED_UNIT_DECIMALS)
      const delta = `${deltaSign}${deltaAmount}`
      leftContent = trendIcon && (
        <Tooltip title={t(`${i18nBase}.contract_adjusted`, { delta }) as string} disableInteractive>
          {trendIcon}
        </Tooltip>
      )
    }
    cells.push(
      // Revised quantity
      makeDataCell(latestQuantity, {
        leftContent: {
          content: leftContent,
          dependencies: [isEditable, quantityDelta],
          align: 'space-between',
        },
        backgroundColor: isLatestTotalValueUpdated ? 'green10' : undefined,
        bold: isLatestTotalValueUpdated === true && isRowSelected === true,
      }),
      // Revised amount
      makeDataCell(centsToDollars(latestTotalValue), {
        backgroundColor: isLatestTotalValueUpdated ? 'green10' : undefined,
        bold: isLatestTotalValueUpdated === true && isRowSelected === true,
      })
    )
  } else {
    cells.push(
      // Bid amount
      makeDataCell(centsToDollars(originalTotalValue), {
        backgroundColor: isOriginalTotalValueUpdated ? 'green10' : undefined,
        bold: isOriginalTotalValueUpdated === true && isRowSelected === true,
      })
    )
  }

  // Pre-Siteline billing
  if (includePreSitelineBillingColumn) {
    cells.push(
      makePreSitelineBillingCell({
        lineItem,
        preSitelineBilling,
        isPreSitelineBillingUpdated,
        t,
        isRowSelected,
        isEditable,
        billingType: BillingType.UNIT_PRICE,
      })
    )
  }

  // Pre-siteline stored materials
  if (includeMaterialsInStorageColumn) {
    cells.push(
      makeStoredMaterialsUnitPriceCell({
        storedMaterialsView,
        preSitelineBilling,
        previousMaterialsInStorage: previousMaterialsInStorage ?? null,
        unitPrice,
        t,
      })
    )
  }
  // Retention % (default or latest)
  if (includeRetentionPercentColumn) {
    cells.push(
      makeRetentionPercentCell({
        defaultRetentionPercent,
        latestRetentionPercent: lineItem.latestRetentionPercent,
        isRetentionPercentEditable,
        t,
      })
    )
  }

  // Pre-siteline retention $
  if (includePreSitelineRetentionColumns) {
    cells.push(
      makePreSitelineRetentionAmountCell({
        lineItem,
        defaultRetentionPercent,
        preSitelineBilling,
        preSitelineRetentionAmount,
        isPreSitelineRetentionAmountUpdated,
        roundRetention,
        includeRetentionPercentColumn,
        includePreSitelineBillingColumn,
        includePreSitelineRetentionColumns,
        onResetPreSitelineRetentionAmount,
        t,
        isRowSelected,
        isEditable,
      })
    )
  }

  if (includeTaxGroupColumn) {
    cells.push(makeDataCell(lineItem.taxGroupId ?? ''))
  }

  // Change order checkbox
  if (includeChangeOrderColumn) {
    cells.push(
      makeChangeOrderToggleCell({
        lineItem,
        isChangeOrder,
        includeChangeOrderApprovalColumn,
        onToggleIsChangeOrder,
        onNavigateToChangeOrder,
        t,
      })
    )
  }

  // Approval date. Show this cell if:
  // 1. There's a column for approval date
  // 2. There's no change order column, or this is a change order. If there is a change order column
  // and this is not a change order, the previous change order cell with span two columns.
  if (includeChangeOrderApprovalColumn && (!includeChangeOrderColumn || isChangeOrder)) {
    cells.push(
      makeChangeOrderApprovalCell({
        changeOrderApprovedAt,
        changeOrderEffectiveAt,
        onEditChangeOrderDate,
        isChangeOrderApprovedAtUpdated,
        isChangeOrderApprovalDateEditable,
      })
    )
  }

  if (includeTotalBillingColumns) {
    const quantityToDate = safeDivideDecimal(lineItem.billedToDate, unitPrice, 0)
      .toDecimalPlaces(2)
      .toNumber()
    const unitsToFinish = latestQuantity - quantityToDate
    cells.push(
      ...[
        // Quantity to date
        makeDataCell(quantityToDate),
        // Amount to date
        makeDataCell(centsToDollars(lineItem.billedToDate)),
        // Units to finish
        makeDataCell(unitsToFinish),
      ]
    )
  }

  if (includeTotalRetainageColumn) {
    cells.push(makeDataCell(centsToDollars(lineItem.retentionToDate ?? 0)))
  }

  return {
    type: SpreadsheetRowType.DEFAULT,
    id: lineItem.id,
    isFirstInUngroupedBlock,
    cells,
    isNonEditableRow: !isEditable,
    allowDragging: isReorderable,
  }
}

/**
 * Returns the totals row for a unit price SOV, with total amounts for each column
 * to be displayed as a footer row in the invoice table
 */
export function getUnitPriceTotalsRow({
  sov,
  includePreSitelineBillingColumn,
  includeRetentionPercentColumn,
  includePreSitelineRetentionColumns,
  includeMaterialsInStorageColumn,
  includeTotalBillingColumns,
  includeTotalRetainageColumn,
  includeChangeOrderColumn,
  includeChangeOrderApprovalColumn,
  includeRevisedContractColumns,
  includeTaxGroupColumn,
  isFirstInUngroupedBlock,
  t,
}: GetTotalsRowProps): SpreadsheetFooterRow {
  const lineItems = sov.lineItems
  const totalValue = _.sumBy(lineItems, (lineItem) => centsToDollars(lineItem.latestTotalValue))
  const numItems = lineItems.length
    ? t('projects.subcontractors.sov.num_items', { count: lineItems.length })
    : ''
  const preSitelineRetentionAmount = getContractPreSitelineRetentionAmount(sov)
  const totalPreSitelineRetention = centsToDollars(preSitelineRetentionAmount)

  const cells = [
    // Code
    makeContentCell(
      <SitelineText variant="secondary" bold>
        {t('projects.subcontractors.sov.total')}
      </SitelineText>,
      []
    ),
    // Name, cost code, unit name, unit price, bid quantity
    makeDataCell(numItems, { colSpan: includeRevisedContractColumns ? 6 : 5 }),
    // Bid amount
    makeDataCell(totalValue),
    // Pre-Siteline quantity
    ...(includePreSitelineBillingColumn ? [makeContentCell(null, [])] : []),
    ...(includeMaterialsInStorageColumn ? [makeContentCell(null, [])] : []),
    ...(includeRetentionPercentColumn ? [makeContentCell(null, [])] : []),
    // Pre-siteline retention
    ...(includePreSitelineRetentionColumns ? [makeDataCell(totalPreSitelineRetention)] : []),
    // Tax group column
    ...(includeTaxGroupColumn ? [makeContentCell(null, [])] : []),
    // Change order & approval date
    ...(includeChangeOrderColumn ? [makeContentCell(null, [])] : []),
    ...(includeChangeOrderApprovalColumn ? [makeContentCell(null, [])] : []),
  ]

  if (includeTotalBillingColumns) {
    const totalBilledToDate = _.sumBy(lineItems, (lineItem) =>
      centsToDollars(lineItem.billedToDate)
    )

    // Quantity to date, amount to date
    cells.push(makeContentCell(null, []), makeDataCell(totalBilledToDate))
    // Units to finish
    cells.push(makeContentCell(null, []))
  }

  if (includeTotalRetainageColumn) {
    const totalRetentionToDate = centsToDollars(sov.totalRetentionHeld)
    cells.push(makeDataCell(totalRetentionToDate))
  }

  return {
    type: SpreadsheetRowType.FOOTER,
    id: 'totals',
    cells,
    isNonEditableRow: true,
    isFixed: true,
    isFirstInUngroupedBlock,
  }
}
