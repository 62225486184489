import AddIcon from '@mui/icons-material/Add'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CheckIcon from '@mui/icons-material/Check'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import MultiCheckIcon from '@mui/icons-material/DoneAll'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Button, IconButton, Tooltip } from '@mui/material'
import clsx from 'clsx'
import { TFunction } from 'i18next'
import _ from 'lodash'
import { Moment } from 'moment-timezone'
import {
  centsToDollars,
  decimalToPercent,
  dollarsToCents,
  formatCentsToDollars,
  roundCents,
  safeDivideDecimal,
} from 'siteline-common-all'
import { SitelineText, SitelineTooltip, colors } from 'siteline-common-web'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import {
  SpreadsheetElement,
  SpreadsheetFooterRow,
  SpreadsheetRow,
  SpreadsheetRowType,
  makeContentCell,
  makeDataCell,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { BillingType, SovChangeSetProperties } from '../../../common/graphql/apollo-operations'
import { themeSpacing } from '../../../common/themes/Main'
import { SpreadsheetRowDeleteIcon } from '../../../common/util/InvoiceRow'
import {
  EditingSov,
  EditingSovLineItem,
  EditingSovLineItemGroup,
  PreviewLineItemChange,
  PreviewLineItemGroupChange,
  getChangeSetLineItemGroupUpdates,
  shouldShowPreSitelineRetentionUnlinked,
} from '../../../common/util/ManageSov'
import { isNewBilledInRange } from '../../../common/util/PayApp'
import { BillingWorksheetLinesIcon } from '../BillingWorksheetLinesIcon'
import { StoredMaterialsView } from '../invoice/InvoiceReducer'
import { ChangeOrderDatesCell } from './ChangeOrderDatesCell'
import { getLumpSumLineItemRow, getLumpSumTotalsRow } from './LumpSumSovRow'
import { getUnitPriceLineItemRow, getUnitPriceTotalsRow } from './UnitPriceSovRow'

// These limits should match those imposed by the backend. Find them in common/src/validation.ts
export const GROUP_NAME_CHARACTER_LIMIT = 100
export const LINE_ITEM_NAME_CHARACTER_LIMIT = 500

export type ChangeOrderDateField = 'changeOrderApprovedAt' | 'changeOrderEffectiveAt'
export type EditChangeOrderDateFn = (field: ChangeOrderDateField, date: Moment | null) => void

export type GetLineItemRowForOnboardingProps = {
  includePreSitelineBillingColumn: boolean
  includeMaterialsInStorageColumn: boolean
  includeRetentionPercentColumn: boolean
  isRetentionPercentEditable: boolean
  includePreSitelineRetentionColumns: boolean
  includeTotalBillingColumns: boolean
  includeTotalRetainageColumn: boolean
  includeChangeOrderColumn: boolean
  includeChangeOrderApprovalColumn: boolean
  isChangeOrderApprovalDateEditable: boolean
  includeRevisedContractColumns: boolean
  includeTaxGroupColumn: boolean
  onDelete?: (id: string) => void
  onToggleIsChangeOrder?: (id: string, checked: boolean) => void
  onNavigateToChangeOrder?: () => void
  onEditChangeOrderDate?: EditChangeOrderDateFn
  onResetPreSitelineRetentionAmount?: (id: string) => void
  timeZone: string
  isFirstInUngroupedBlock: boolean
  showWarningIfEmpty: boolean
  t: TFunction
  isEditable: boolean
  isReorderable: boolean
  isRowSelected: boolean
  roundRetention: boolean
  changeSetUpdate?: SovChangeSetProperties['updates'][number]
  storedMaterialsView?: StoredMaterialsView
}

/**
 * Returns a `SpreadsheetRow` component to be displayed in the sov onboarding
 * table to represent a single line item
 */
export function getLineItemRow(
  billingType: BillingType.LUMP_SUM | BillingType.UNIT_PRICE,
  lineItem: EditingSovLineItem,
  props: GetLineItemRowForOnboardingProps
): SpreadsheetRow {
  switch (billingType) {
    case BillingType.LUMP_SUM: {
      return getLumpSumLineItemRow(lineItem, props)
    }
    case BillingType.UNIT_PRICE: {
      return getUnitPriceLineItemRow(lineItem, props)
    }
  }
}

/**
 * There are a few icons we may show to the right of the line item name in a line item row, such as
 * a delete icon or worksheet items icon. This function returns the content to show in that space.
 */
export function getLineItemNameRightContent({
  lineItemId,
  isEditable,
  onDelete,
  numWorksheetLineItems,
}: {
  lineItemId: string
  isEditable: boolean
  onDelete?: (id: string) => void
  numWorksheetLineItems: number
}): SpreadsheetElement | undefined {
  return {
    content: (
      <div style={{ display: 'flex', alignItems: 'center', gap: themeSpacing(1) }}>
        {onDelete && <SpreadsheetRowDeleteIcon onDelete={() => onDelete(lineItemId)} />}
        <BillingWorksheetLinesIcon
          sovLineItemId={lineItemId}
          numWorksheetItems={numWorksheetLineItems}
          // We don't show the worksheet icon while editing because some line items will not yet
          // be saved in the database and we can't create worksheet line items for them until
          // they've been saved.
          displayMode={isEditable ? 'never' : 'default'}
        />
      </div>
    ),
    dependencies: [onDelete, numWorksheetLineItems, lineItemId],
  }
}

export type GetTotalsRowProps = {
  sov: EditingSov
  includePreSitelineBillingColumn: boolean
  includeMaterialsInStorageColumn: boolean
  includeRetentionPercentColumn: boolean
  includePreSitelineRetentionColumns: boolean
  includeTotalBillingColumns: boolean
  includeTotalRetainageColumn: boolean
  includeChangeOrderColumn: boolean
  includeChangeOrderApprovalColumn: boolean
  includeRevisedContractColumns: boolean
  includeTaxGroupColumn: boolean
  // Add a divider before this row if there are multiple line item groups and no previous footer row
  isFirstInUngroupedBlock: boolean
  t: TFunction
}

/**
 * Returns the totals row for the SOV, with total amounts for each column
 * to be displayed as a footer row in the invoice table
 */
export function getTotalsRow(
  billingType: BillingType.LUMP_SUM | BillingType.UNIT_PRICE,
  props: GetTotalsRowProps
): SpreadsheetFooterRow {
  switch (billingType) {
    case BillingType.LUMP_SUM: {
      return getLumpSumTotalsRow(props)
    }
    case BillingType.UNIT_PRICE: {
      return getUnitPriceTotalsRow(props)
    }
  }
}

/** Returns a row with a group header for a group of line items */
export function getLineItemGroupHeaderRow(
  group: EditingSovLineItemGroup,
  changeSetUpdate: SovChangeSetProperties['groupUpdates'][number] | null,
  {
    onDelete,
    numColumns,
    showWarningIfEmpty,
    isEditable,
    isReorderable,
    numPreCodeColumns = 0,
  }: {
    onDelete?: (groupId: string) => void
    numColumns: number
    showWarningIfEmpty: boolean
    isEditable: boolean
    isReorderable: boolean
    /** If there are any extra columns before the code, the code and header will be pushed over */
    numPreCodeColumns?: number
  }
): SpreadsheetRow {
  const { isNameUpdated, isCodeUpdated } = getChangeSetLineItemGroupUpdates({
    group,
    update: changeSetUpdate,
  })

  return {
    type: SpreadsheetRowType.DEFAULT,
    id: group.id,
    cells: [
      ..._.times(numPreCodeColumns, () => makeDataCell('')),
      makeDataCell(group.code, {
        backgroundColor: isCodeUpdated ? 'green10' : undefined,
        bold: isCodeUpdated === true,
      }),
      makeDataCell(group.name, {
        rightContent: onDelete && {
          content: <SpreadsheetRowDeleteIcon onDelete={() => onDelete(group.id)} />,
          dependencies: [onDelete],
        },
        borderColor: showWarningIfEmpty && !group.name ? 'error' : 'default',
        characterLimit: GROUP_NAME_CHARACTER_LIMIT,
        backgroundColor: isNameUpdated ? 'green10' : undefined,
        bold: isNameUpdated === true,
      }),
      makeContentCell(<div />, [], {
        // Add extra space for all columns, minus the code, name, and any additional initial columns
        colSpan: numColumns - numPreCodeColumns - 2,
      }),
    ],
    isGroupHeaderRow: true,
    isFirstInUngroupedBlock: true,
    isNonEditableRow: !isEditable,
    allowDragging: isReorderable,
  }
}

/** A row in the edit SOV spreadsheet for creating a new line item or group */
export function getAddLineItemOrGroupRow({
  onAddLineItem,
  onAddGroup,
  numColumns,
  t,
  isFirstInUngroupedBlock,
  isAddingChangeOrder,
}: {
  onAddLineItem: () => void
  onAddGroup?: () => void
  numColumns: number
  t: TFunction
  isFirstInUngroupedBlock: boolean
  isAddingChangeOrder: boolean
}): SpreadsheetFooterRow {
  return {
    type: SpreadsheetRowType.FOOTER,
    id: 'add-line-item-or-group',
    cells: [
      makeContentCell(
        <div className="addLineItemOrGroup">
          <Button
            variant="text"
            color="info"
            size="small"
            onClick={onAddLineItem}
            className="addLineItem"
          >
            <SitelineText
              variant="body2"
              bold
              color="grey70"
              startIcon={<AddIcon fontSize="small" />}
            >
              {isAddingChangeOrder
                ? t('projects.subcontractors.sov.add_change_order')
                : t('projects.subcontractors.sov.add_line_item')}
            </SitelineText>
          </Button>
          {onAddGroup && (
            <Button variant="text" color="info" size="small" onClick={onAddGroup}>
              <SitelineText
                variant="body2"
                bold
                color="grey70"
                startIcon={<CreateNewFolderIcon fontSize="small" />}
              >
                {t('projects.subcontractors.sov.add_group')}
              </SitelineText>
            </Button>
          )}
        </div>,
        [onAddLineItem, onAddGroup],
        { colSpan: numColumns, colOffset: 1 }
      ),
    ],
    isFirstInUngroupedBlock,
    isNonEditableRow: true,
    isFixed: false,
  }
}

// Icon for an added line item from a change set
export function AddLineItemIcon() {
  return <AddCircleIcon className="addLineItemIcon" />
}
// Icon for an updated line item from a change set
export function UpdateLineItemIcon() {
  return (
    <div className="updateLineItemIcon">
      <div />
    </div>
  )
}
// Icon for a deleted line item from a change set
export function DeleteLineItemIcon() {
  return <RemoveCircleIcon className="deleteLineItemIcon" />
}

/**
 * Converts a standard SOV line item into the form used when previewing a change set.
 * Adds the appropriate cell for the change indicator column and updates styling
 * if the row is being deleted.
 */
export function makePreviewLineItemRow(params: {
  row: SpreadsheetRow
  change: PreviewLineItemChange
  canChooseLineItemsToImport: boolean
  isChecked: boolean
  onRowChecked: (isChecked: boolean) => void
}): SpreadsheetRow {
  const { row, change, canChooseLineItemsToImport, isChecked, onRowChecked } = params
  let changeSetIcon
  switch (change) {
    case PreviewLineItemChange.ADDED:
      changeSetIcon = <AddLineItemIcon />
      break
    case PreviewLineItemChange.DELETED:
      changeSetIcon = <DeleteLineItemIcon />
      break
    case PreviewLineItemChange.UPDATED:
      changeSetIcon = <UpdateLineItemIcon />
      break
    case PreviewLineItemChange.UNCHANGED:
      changeSetIcon = null
  }
  const deletedCellOptions = {
    color: 'red50' as const,
    backgroundColor: 'red10' as const,
    strikethrough: isChecked,
  }
  const addedCellOptions = {
    backgroundColor: 'green10' as const,
    bold: isChecked,
  }

  const content = canChooseLineItemsToImport ? (
    <SitelineCheckbox
      value={isChecked}
      checked={isChecked}
      onChange={(ev, checked) => onRowChecked(checked)}
      // Reduce left margin to align with change indicators in groups
      sx={{ marginLeft: -1.5 }}
    />
  ) : (
    changeSetIcon
  )

  return {
    ...row,
    cells: [
      // Add change set indicator column, with the appropriate icon
      makeContentCell(
        changeSetIcon ? content : null,
        [changeSetIcon, canChooseLineItemsToImport, onRowChecked],
        {
          ...(change === PreviewLineItemChange.DELETED && { backgroundColor: 'red10' as const }),
          ...(change === PreviewLineItemChange.ADDED && { backgroundColor: 'green10' as const }),
        }
      ),
      ...row.cells.map((cell) => ({
        ...cell,
        // If this row was deleted in the change set, show it as red with strikethrough text
        ...(change === PreviewLineItemChange.DELETED && deletedCellOptions),
        ...(change === PreviewLineItemChange.ADDED && addedCellOptions),
      })),
    ],
  }
}

/**
 * Converts a standard SOV line item group into the form used when previewing a change set.
 * Adds the appropriate cell for the change indicator column and updates styling
 * if the row is being deleted.
 */
export function makePreviewLineItemGroup(params: {
  row: SpreadsheetRow
  change: PreviewLineItemGroupChange
}): SpreadsheetRow {
  const { row, change } = params
  let changeSetIcon
  switch (change) {
    case PreviewLineItemGroupChange.ADDED:
      changeSetIcon = <AddLineItemIcon />
      break
    case PreviewLineItemGroupChange.DELETED:
      changeSetIcon = <DeleteLineItemIcon />
      break
    case PreviewLineItemGroupChange.UPDATED:
      changeSetIcon = <UpdateLineItemIcon />
      break
    case PreviewLineItemGroupChange.UNCHANGED:
      changeSetIcon = null
  }
  const deletedCellOptions = {
    color: 'red50' as const,
    backgroundColor: 'red10' as const,
    strikethrough: true,
  }
  const addedCellOptions = {
    backgroundColor: 'green10' as const,
    bold: true,
  }

  return {
    ...row,
    cells: [
      // Add change set indicator column, with the appropriate icon
      makeContentCell(changeSetIcon, [changeSetIcon], {
        ...(change === PreviewLineItemGroupChange.DELETED && { backgroundColor: 'red10' as const }),
        ...(change === PreviewLineItemGroupChange.ADDED && { backgroundColor: 'green10' as const }),
      }),
      ...row.cells.map((cell) => ({
        ...cell,
        // If this row was deleted in the change set, show it as red with strikethrough text
        ...(change === PreviewLineItemGroupChange.DELETED && deletedCellOptions),
        ...(change === PreviewLineItemGroupChange.ADDED && addedCellOptions),
      })),
    ],
  }
}

type PreSitelineBillingCellProps = {
  lineItem: EditingSovLineItem
  preSitelineBilling: number
  isPreSitelineBillingUpdated: boolean
  isRowSelected: boolean
  t: TFunction
  isEditable: boolean
  billingType: BillingType
}

const i18nBase = 'projects.subcontractors.sov'

export function makePreSitelineBillingCell({
  lineItem,
  preSitelineBilling,
  isPreSitelineBillingUpdated,
  isRowSelected,
  t,
  isEditable,
  billingType,
}: PreSitelineBillingCellProps) {
  // If it's not editable, we don't have to worry about disabling separately. We only want to display the
  // disabled tooltip when the spreadsheet is in an editable state and the cell itself is disabled.
  const isDisabled = isEditable && lineItem.worksheetLineItems.length > 0
  return makeDataCell(centsToDollars(preSitelineBilling), {
    validate: (value) => {
      const oldPreSitelineBilling = lineItem.preSitelineBilling ?? 0
      const newBilledToDate =
        lineItem.billedToDate + (dollarsToCents(Number(value)) - oldPreSitelineBilling)
      if (
        !isNewBilledInRange(
          centsToDollars(newBilledToDate),
          centsToDollars(lineItem.latestTotalValue)
        )
      ) {
        const formattedAmountBilled = formatCentsToDollars(newBilledToDate, true)
        const formattedTotalValue = formatCentsToDollars(lineItem.latestTotalValue, true)
        return billingType === BillingType.UNIT_PRICE
          ? {
              type: 'confirm',
              title: t(`${i18nBase}.billed_to_date_too_high_confirm_title`),
              details: t(`${i18nBase}.billed_to_date_too_high_billed_details`, {
                amountBilled: formattedAmountBilled,
                scheduledValue: formattedTotalValue,
              }),
            }
          : { type: 'error', message: t(`${i18nBase}.billed_to_date_too_high`) }
      }
      return null
    },
    backgroundColor: isPreSitelineBillingUpdated ? 'green10' : undefined,
    bold: isPreSitelineBillingUpdated === true && isRowSelected === true,
    isEditable: !isDisabled,
    tooltipTitle: isDisabled ? t(`${i18nBase}.pre_siteline_disabled_worksheet`) : undefined,
  })
}

type StoredMaterialsCellProps = {
  preSitelineBilling: number
  previousMaterialsInStorage: number | null
  t: TFunction
  /** Null if not unit price project */
  unitPrice: number | null
}

export function makeStoredMaterialsCell({
  preSitelineBilling,
  previousMaterialsInStorage,
  t,
  unitPrice,
}: StoredMaterialsCellProps) {
  return makeDataCell(centsToDollars(previousMaterialsInStorage ?? 0), {
    validate: (value) => {
      if (!_.isNumber(value)) {
        return null
      }
      const inCents = dollarsToCents(Number(value))
      if (unitPrice !== null && unitPrice === 0) {
        return { type: 'error', message: t(`${i18nBase}.stored_materials_zero_unit_price`) }
      }
      if (inCents > preSitelineBilling) {
        return { type: 'error', message: t(`${i18nBase}.stored_materials_too_high`) }
      }
      if (value < 0) {
        return { type: 'error', message: t(`${i18nBase}.stored_materials_negative`) }
      }
      return null
    },
  })
}

export function makeStoredMaterialsUnitPriceCell({
  preSitelineBilling,
  storedMaterialsView,
  t,
  previousMaterialsInStorage,
  unitPrice,
}: StoredMaterialsCellProps & { storedMaterialsView: StoredMaterialsView; unitPrice: number }) {
  if (storedMaterialsView === StoredMaterialsView.AMOUNT) {
    return makeStoredMaterialsCell({ preSitelineBilling, previousMaterialsInStorage, t, unitPrice })
  }
  const storedUnits = safeDivideDecimal(previousMaterialsInStorage ?? 0, unitPrice, 0)
    .toDecimalPlaces(2)
    .toFixed()
  return makeDataCell(storedUnits, {
    validate: (value) => {
      if (!_.isNumber(value)) {
        return null
      }
      const amount = value * unitPrice
      if (unitPrice === 0) {
        return { type: 'error', message: t(`${i18nBase}.stored_materials_zero_unit_price`) }
      }
      if (amount > preSitelineBilling) {
        return { type: 'error', message: t(`${i18nBase}.stored_materials_too_high`) }
      }
      if (amount < 0) {
        return { type: 'error', message: t(`${i18nBase}.stored_materials_negative`) }
      }
      return null
    },
  })
}

type DefaultRetentionPercentCellProps = {
  defaultRetentionPercent: number | null | undefined
  latestRetentionPercent: number | null | undefined
  isRetentionPercentEditable: boolean
  t: TFunction
}

export function makeRetentionPercentCell({
  defaultRetentionPercent,
  latestRetentionPercent,
  isRetentionPercentEditable,
  t,
}: DefaultRetentionPercentCellProps) {
  let percent = defaultRetentionPercent
  if (!isRetentionPercentEditable && _.isNumber(latestRetentionPercent)) {
    percent = latestRetentionPercent
  }
  return makeDataCell(decimalToPercent(percent ?? 0, 2), {
    isEditable: isRetentionPercentEditable,
    validate: (value) => {
      if (!_.isNumber(value)) {
        return null
      }
      if (value > 100) {
        return { type: 'error', message: t(`${i18nBase}.default_retention_percent_too_high`) }
      }
      if (value < 0) {
        return { type: 'error', message: t(`${i18nBase}.default_retention_percent_too_low`) }
      }
      return null
    },
  })
}

type PreSitelineRetentionAmountCellProps = {
  lineItem: EditingSovLineItem
  defaultRetentionPercent: number | null | undefined
  preSitelineBilling: number
  preSitelineRetentionAmount: number | null
  isPreSitelineRetentionAmountUpdated: boolean
  roundRetention: boolean
  includeRetentionPercentColumn: boolean
  includePreSitelineBillingColumn: boolean
  includePreSitelineRetentionColumns: boolean
  onResetPreSitelineRetentionAmount?: (id: string) => void
  t: TFunction
  isRowSelected: boolean
  isEditable: boolean
}

export function makePreSitelineRetentionAmountCell({
  lineItem,
  defaultRetentionPercent,
  preSitelineBilling,
  preSitelineRetentionAmount,
  isPreSitelineRetentionAmountUpdated,
  roundRetention,
  includePreSitelineBillingColumn,
  includePreSitelineRetentionColumns,
  onResetPreSitelineRetentionAmount,
  isRowSelected,
  isEditable,
  t,
}: PreSitelineRetentionAmountCellProps) {
  // Determine whether we should show the "pre-siteline retention unlinked" icon/tooltip
  const showUnlinked = shouldShowPreSitelineRetentionUnlinked({
    defaultRetentionPercent: defaultRetentionPercent ?? 0,
    preSitelineBilling,
    preSitelineRetentionAmount: preSitelineRetentionAmount ?? 0,
    roundRetention,
    includePreSitelineBillingColumn,
    includePreSitelineRetentionColumns,
  })

  const leftItems: JSX.Element[] = []
  if (showUnlinked) {
    const canResetRetention = isEditable && onResetPreSitelineRetentionAmount
    leftItems.push(
      <Tooltip
        placement="top"
        title={t(
          canResetRetention
            ? `${i18nBase}.pre_siteline_retention_unlinked_reset`
            : `${i18nBase}.pre_siteline_retention_unlinked`,
          {
            percent: decimalToPercent(defaultRetentionPercent ?? 0, 2),
          }
        )}
        disableInteractive
      >
        <div style={{ display: 'flex' }}>
          {canResetRetention && (
            <IconButton
              onClick={() => onResetPreSitelineRetentionAmount(lineItem.id)}
              // Using an icon button adds extra padding on the row, which would cause it to pop up
              // as soon as you override pre-siteline retention amount. Negative margin compensates
              // for this.
              sx={{ margin: -1 }}
            >
              <LinkOffIcon fontSize="small" htmlColor={colors.grey50} />
            </IconButton>
          )}
          {!canResetRetention && <LinkOffIcon fontSize="small" htmlColor={colors.grey50} />}
        </div>
      </Tooltip>
    )
  }

  const showUnrounded =
    roundCents(preSitelineRetentionAmount ?? 0, roundRetention) !==
    (preSitelineRetentionAmount ?? 0)
  if (showUnrounded) {
    leftItems.push(
      <Tooltip
        placement="top"
        title={t(`${i18nBase}.pre_siteline_retention_unrounded`)}
        disableInteractive
      >
        <div style={{ display: 'flex' }}>
          <WarningAmberIcon fontSize="small" htmlColor={colors.grey50} />
        </div>
      </Tooltip>
    )
  }

  return makeDataCell(centsToDollars(preSitelineRetentionAmount ?? 0), {
    validate: (value) => {
      if (!_.isNumber(value)) {
        return null
      }
      const inRange = isNewBilledInRange(value, centsToDollars(lineItem.billedToDate))
      if (!inRange) {
        return {
          type: 'error',
          message: t(`${i18nBase}.pre_siteline_retention_amount_invalid`),
        }
      }
      return null
    },
    backgroundColor: isPreSitelineRetentionAmountUpdated ? 'green10' : undefined,
    bold: isPreSitelineRetentionAmountUpdated === true && isRowSelected === true,
    leftContent: {
      content: <>{leftItems}</>,
      dependencies: [showUnlinked, showUnrounded],
      align: 'space-between',
    },
  })
}

type ChangeOrderToggleCellProps = {
  lineItem: EditingSovLineItem
  isChangeOrder: boolean
  includeChangeOrderApprovalColumn: boolean
  onToggleIsChangeOrder?: (lineItemId: string, checked: boolean) => void
  t: TFunction
  onNavigateToChangeOrder?: () => void
}

export function makeChangeOrderToggleCell({
  lineItem,
  isChangeOrder,
  onToggleIsChangeOrder,
  includeChangeOrderApprovalColumn,
  t,
  onNavigateToChangeOrder,
}: ChangeOrderToggleCellProps) {
  let navigateToCorTooltipTitle = ''
  const { changeOrderRequests: linkedChangeOrderRequests } = lineItem
  const hasLinkedChangeOrderRequests = linkedChangeOrderRequests.length > 0
  const hasOneLinkedChangeOrderRequest = linkedChangeOrderRequests.length === 1
  const hasMultipleLinkedChangeOrderRequests = linkedChangeOrderRequests.length > 1

  if (hasLinkedChangeOrderRequests) {
    const formattedInternalNumbers = linkedChangeOrderRequests.map(({ name, internalNumber }) =>
      internalNumber ? `#${internalNumber}` : name
    )
    navigateToCorTooltipTitle = t(`${i18nBase}.linked_to_cor_with_internal_number_tooltip`, {
      count: linkedChangeOrderRequests.length,
      internalNumbers: formattedInternalNumbers.join(', '),
      firstInternalNumber: formattedInternalNumbers[0],
    })
  }

  return makeContentCell(
    <>
      {/* Currently editing the spreadsheet. The checkbox is toggleable. */}
      {onToggleIsChangeOrder && (
        <div className="changeOrderCheckbox">
          <SitelineCheckbox
            name={t(`${i18nBase}.headers.change_order`)}
            value={isChangeOrder}
            checked={isChangeOrder}
            onChange={(ev, checked) => onToggleIsChangeOrder(lineItem.id, checked)}
          />
        </div>
      )}
      {/* Not editing the spreadsheet, the line item is linked to a change order. Clicking the checkbox will
      take the user to the change order log */}
      {!onToggleIsChangeOrder && onNavigateToChangeOrder && hasOneLinkedChangeOrderRequest && (
        <div className="changeOrderCheckLink" onClick={onNavigateToChangeOrder}>
          <SitelineTooltip title={navigateToCorTooltipTitle} arrow placement="bottom">
            <CheckIcon fontSize="small" />
          </SitelineTooltip>
        </div>
      )}
      {/* Not editing the spreadsheet, the line item is linked to multiple change orders. Clicking the checkbox will
      take the user to the change order log of the first linked COR. */}
      {!onToggleIsChangeOrder &&
        onNavigateToChangeOrder &&
        hasMultipleLinkedChangeOrderRequests && (
          <div
            className={clsx('changeOrderCheckLink', 'changeOrderDoubleCheck')}
            onClick={onNavigateToChangeOrder}
          >
            <SitelineTooltip title={navigateToCorTooltipTitle} arrow placement="bottom">
              <MultiCheckIcon fontSize="small" />
            </SitelineTooltip>
          </div>
        )}
      {/* Not editing the spreadsheet, the line item is not linked to a COR. Just show a regular checkbox. */}
      {!onToggleIsChangeOrder && !onNavigateToChangeOrder && isChangeOrder && (
        <div className="changeOrderCheck">
          <CheckIcon fontSize="small" />
        </div>
      )}
    </>,
    [isChangeOrder, onToggleIsChangeOrder, onNavigateToChangeOrder, lineItem.changeOrderRequests],
    { colSpan: isChangeOrder || !includeChangeOrderApprovalColumn ? 1 : 2 }
  )
}

type ChangeOrderApprovalCellProps = {
  changeOrderApprovedAt: Moment | null
  changeOrderEffectiveAt: Moment | null
  onEditChangeOrderDate?: EditChangeOrderDateFn
  isChangeOrderApprovedAtUpdated: boolean
  isChangeOrderApprovalDateEditable: boolean
}

export function makeChangeOrderApprovalCell({
  changeOrderApprovedAt,
  changeOrderEffectiveAt,
  onEditChangeOrderDate,
  isChangeOrderApprovedAtUpdated,
  isChangeOrderApprovalDateEditable,
}: ChangeOrderApprovalCellProps) {
  return makeContentCell(
    <ChangeOrderDatesCell
      approvedAt={changeOrderApprovedAt}
      effectiveAt={changeOrderEffectiveAt}
      onEditDate={onEditChangeOrderDate}
      isApprovalDateEditable={isChangeOrderApprovalDateEditable}
    />,
    [
      changeOrderApprovedAt,
      changeOrderEffectiveAt,
      onEditChangeOrderDate,
      isChangeOrderApprovalDateEditable,
    ],
    {
      backgroundColor: isChangeOrderApprovedAtUpdated ? 'green10' : undefined,
      isEditable: true,
    }
  )
}
