import { useApolloClient } from '@apollo/client'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from '@mui/material'
import moment from 'moment-timezone'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  canMarkPayAppAsSubmitted,
  canMarkPayAppAsSynced,
  getIntegrationTypeFamily,
  getPayAppLastErpSyncStatus,
  IntegrationTypeFamily,
  integrationTypes,
  isFileBasedIntegration,
  OPT_OUT_FORMAT,
  PayAppWithLastErpSync,
  sortPayAppsByBillingEnd,
  supportsMarkAsSynced,
} from 'siteline-common-all'
import {
  colors,
  evictWithGc,
  makeStylesFast,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import {
  GetContractForProjectHomeDocument,
  GetContractForProjectHomeQuery,
  GetContractForProjectHomeQueryVariables,
  PayAppProperties,
  Permission,
  useMarkPayAppAsSyncedInErpMutation,
  useOptInToBillingForMonthMutation,
  useOptOutOfBillingForMonthMutation,
} from '../../../common/graphql/apollo-operations'
import { requiresFinalLienWaiver } from '../../../common/util/LienWaiver'
import { trackOptInBilling, trackOptOutBilling } from '../../../common/util/MetricsTracking'
import {
  isPayAppCompleted,
  isPayAppDraftOrSyncFailed,
  isPayAppResettable,
} from '../../../common/util/PayApp'
import { OptOutOfBillingDialog } from '../OptOutOfBillingDialog'
import { ContractForProjectHome, PayAppForProjectHome } from '../home/ProjectHome'
import { MarkAsPaidDialog } from '../paid/MarkAsPaidDialog'

import { MarkAsSubmittedDialog } from '../../../common/components/MarkAsSubmittedDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { getIntegrationOfFamily } from '../../../common/util/Integration'
import { ResetPayAppToDraftDialog } from '../invoice/ResetPayAppToDraftDialog'
import { useMarkAsPaid } from '../paid/MarkAsPaidButton'
import { SelectLienWaiverProgressTypeDialog } from '../paid/SelectLienWaiverProgressTypeDialog'
import { CreateOrViewUnconditionalMenuItem } from './CreateOrViewUnconditionalMenuItem'

const useStyles = makeStylesFast(() => ({
  root: {
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    '& .threeDots': {
      color: colors.grey50,
    },
  },
}))

const i18nBase = 'projects.subcontractors.pay_app'

export const typedPayAppForErpSyncStatus = (
  payApp: Pick<PayAppProperties, 'createdAt' | 'lastErpSync'>
): PayAppWithLastErpSync => {
  return {
    createdAt: payApp.createdAt,
    lastErpSync: payApp.lastErpSync
      ? {
          status: payApp.lastErpSync.status,
          result: payApp.lastErpSync.result as integrationTypes.WriteSyncResult | null,
        }
      : null,
  }
}

interface AdditionalOptionsMenuProps {
  contract: ContractForProjectHome
  month: moment.Moment
  payApp?: PayAppForProjectHome
  onCreatePayApp?: (month: moment.Moment) => void
  onOpenUpdatePayApp?: () => void
  hasUnconditional?: boolean
  showMarkAsPaid?: boolean
  isPaidInIntegration?: boolean
}

export function AdditionalOptionsMenu({
  contract,
  month,
  payApp,
  onCreatePayApp,
  onOpenUpdatePayApp,
  hasUnconditional = false,
  showMarkAsPaid = false,
  isPaidInIntegration = false,
}: AdditionalOptionsMenuProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { companyId, permissions } = useCompanyContext()
  const client = useApolloClient()
  const canEdit = permissions.includes(Permission.EDIT_INVOICE)
  const { isContractActive, timeZone } = useProjectContext()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isOptingOut, setIsOptingOut] = useState<boolean>(false)
  const [isResetDialogOpen, handleOpenResetDialog, handleCloseResetDialog] = useToggle()
  const [isMarkAsPaidDialogOpen, handleOpenMarkAsPaidDialog, handleCloseMarkAsPaidDialog] =
    useToggle()
  const [isSelectLienWaiverDialogOpen, handleOpenLienWaiverDialog, handleCloseLienWaiverDialog] =
    useToggle()
  const [
    isMarkAsSubmittedDialogOpen,
    handleOpenMarkAsSubmittedDialog,
    handleCloseMarkAsSubmittedDialog,
  ] = useToggle()

  const [markAsSyncedInErpMutation] = useMarkPayAppAsSyncedInErpMutation()

  const [optInMutation] = useOptInToBillingForMonthMutation()
  const snackbar = useSitelineSnackbar()

  const [optOutDialogOpen, setOptOutDialogOpen] = useState<boolean>(false)
  const formattedMonth = month.format(OPT_OUT_FORMAT)
  const optedOut = contract.skippedPayAppMonths.includes(formattedMonth)

  const integrations = useMemo(() => [...contract.integrations], [contract])

  // This action marks the pay app as synced to the ERP. It does not affect the pay app status.
  const { shouldShowMarkSyncedErp, markAsSyncedErpText, erpIntegration } = useMemo(() => {
    const erp = getIntegrationOfFamily(contract, IntegrationTypeFamily.ERP)
    const alreadySyncedResult = {
      shouldShowMarkSyncedErp: false,
      markAsSyncedErpText: '',
      erpIntegration: null,
    }
    // Undefined pay app (loading state), don't surface anything in the UI
    if (!payApp) {
      return alreadySyncedResult
    }
    // No ERP integration, don't surface anything in the UI
    if (!erp) {
      return alreadySyncedResult
    }

    const typedPayApp = typedPayAppForErpSyncStatus(payApp)
    const payAppErpSyncStatus = getPayAppLastErpSyncStatus(typedPayApp)
    const notYetSyncedResult = {
      shouldShowMarkSyncedErp: true,
      markAsSyncedErpText: isFileBasedIntegration(erp.type)
        ? t(`${i18nBase}.mark_as_submitted.mark_as_exported`, {
            integration: erp.shortName,
          })
        : t(`${i18nBase}.mark_as_submitted.mark_as_synced`, {
            integration: erp.shortName,
          }),
      erpIntegration: erp,
    }

    switch (payAppErpSyncStatus) {
      // If pending or successful sync, don't show the option to mark as synced
      case 'synced':
      case 'pending':
        return alreadySyncedResult
      case 'notSynced':
        return notYetSyncedResult
    }
  }, [contract, payApp, t])

  // These actions correspond to either marking the pay app as submitted or marking the
  // pay app as synced to a gc portal. Both actions will result in a change to the pay app status.
  const { markAsSubmittedAction, markAsSubmittedText } = useMemo(() => {
    // Only GC portal integrations should be considered for syncing
    const markSyncedIntegrations = integrations.filter((integration) =>
      supportsMarkAsSynced(integration.type)
    )
    // There is only one gc portal integration per project
    if (markSyncedIntegrations.length > 0) {
      const gcPortalName = markSyncedIntegrations[0].shortName
      return {
        markAsSubmittedAction: 'synced' as const,
        markAsSubmittedText: t(`${i18nBase}.mark_as_submitted.mark_as_synced`, {
          integration: gcPortalName,
        }),
      }
    }
    return {
      markAsSubmittedAction: 'submitted' as const,
      markAsSubmittedText: t(`${i18nBase}.mark_as_submitted.mark_as_submitted`),
    }
  }, [integrations, t])

  const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    // Prevent the click from navigating to the project row link
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const closeMenu = useCallback((event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const {
    label: markAsPaidLabel,
    disabled: isMarkAsPaidDisabled,
    isButtonVisible: shouldShowMarkAsPaid,
    onClick: onMarkAsPaidClick,
    onNavigateToLienWaiver,
    isProcessingUnconditionalFinalTemplate,
    isProcessingUnconditionalProgressTemplate,
  } = useMarkAsPaid({
    contract,
    payApp,
    isPaidInIntegration,
    onOpenLienWaiverDialog: handleOpenLienWaiverDialog,
    onOpenMarkAsPaidDialog: handleOpenMarkAsPaidDialog,
  })

  const handleMarkAsPaidOptionClick = useCallback(
    (event: MouseEvent) => {
      closeMenu(event)
      onMarkAsPaidClick()
    },
    [closeMenu, onMarkAsPaidClick]
  )

  const handleMarkAsSyncedToErp = useCallback(async () => {
    if (!payApp || !erpIntegration) {
      return
    }
    try {
      await markAsSyncedInErpMutation({
        variables: {
          input: {
            payAppId: payApp.id,
            syncedAt: moment.tz(timeZone).toISOString(),
            integrationId: erpIntegration.id,
          },
        },
        update(cache) {
          evictWithGc(cache, (evict) => {
            evict({ id: cache.identify(payApp), fieldName: 'lastErpSync' })
          })
        },
      })
      snackbar.showSuccess(
        t(`${i18nBase}.mark_as_submitted.marked_synced_to_success`, {
          integration: erpIntegration.shortName,
        })
      )
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [erpIntegration, markAsSyncedInErpMutation, payApp, snackbar, t, timeZone])

  const optIn = useCallback(() => {
    optInMutation({
      variables: {
        input: {
          contractId: contract.id,
          month: formattedMonth,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        optInToBillingForMonth: {
          ...contract,
          skippedPayAppMonths: contract.skippedPayAppMonths.filter(
            (skippedMonth) => skippedMonth !== formattedMonth
          ),
        },
      },
    })

    trackOptInBilling({
      projectId: contract.project.id,
      projectName: contract.project.name,
      month: month.toISOString(),
    })
  }, [formattedMonth, contract, month, optInMutation])

  const [optOutMutation] = useOptOutOfBillingForMonthMutation()

  const optOut = useCallback(
    async (optOutOnIntegrationIds: string[], reason: string) => {
      setIsOptingOut(true)
      try {
        await optOutMutation({
          variables: {
            input: {
              contractId: contract.id,
              month: formattedMonth,
              reason: reason || null,
              payAppId: payApp?.id,
              optOutOnIntegrationIds,
            },
          },
          update(cache, { data }) {
            // We don't use data anywhere, but ensure that the query was successful
            if (!data?.optOutOfBillingForMonth) {
              return
            }

            // Invalidate pay app list + the relevant pay app
            evictWithGc(cache, (evict) => {
              if (payApp) {
                evict({ id: cache.identify(payApp) })
              }
              evict({ id: cache.identify(contract), fieldName: 'payApps' })
              evict({ id: cache.identify(contract), fieldName: 'firstPayAppBillingEnd' })
              evict({ id: cache.identify(contract), fieldName: 'hasStartedBilling' })
              evict({ id: cache.identify(contract), fieldName: 'progressRemaining' })
            })
          },
        })
        // `evictWithGc` will trigger a refetch whose result surfaces on ProjectHome. From that level,
        // we don't have a way of listening for fetch completed. Triggering the fetch from here allows
        // us to await it. When complete, we can clear our loading state. This is crucial because if the
        // loading state clears before the pay app is removed from the UI, the user is able to
        // interact with the invalid pay app
        await client.query<GetContractForProjectHomeQuery, GetContractForProjectHomeQueryVariables>(
          {
            query: GetContractForProjectHomeDocument,
            variables: {
              input: {
                projectId: contract.project.id,
                companyId,
              },
            },
          }
        )
        trackOptOutBilling({
          projectId: contract.project.id,
          projectName: contract.project.name,
          reason,
          month: month.toISOString(),
        })
        setIsOptingOut(false)
      } catch (error) {
        snackbar.showError(error.message)
        setIsOptingOut(false)
      }
    },
    [client, contract, formattedMonth, month, optOutMutation, payApp, snackbar, companyId]
  )

  const handlePreventEventPropagation = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }, [])

  // Per design, we only want to show the undo button iff there are no other pay apps created after
  // the month that was skipped
  const showCreateButton = contract.payApps.every((payApp) =>
    moment.tz(payApp.billingEnd, contract.timeZone).isBefore(month)
  )

  let showCreateUnconditional = false
  const sortedPayApps = sortPayAppsByBillingEnd([...contract.payApps], 'desc', timeZone)
  const latestPayApp = sortedPayApps.find((payApp) =>
    month.isAfter(moment.tz(payApp.billingEnd, timeZone))
  )
  const isFinalLienWaiver = requiresFinalLienWaiver(payApp ?? latestPayApp, contract.sov)

  // Show the unconditional menu item if the correct lien waiver template exists
  if (
    isFinalLienWaiver &&
    contract.lienWaiverTemplates?.unconditionalFinalVariant?.template.isCustomerReady
  ) {
    showCreateUnconditional = true
  } else if (
    !isFinalLienWaiver &&
    contract.lienWaiverTemplates?.unconditionalProgressVariant?.template.isCustomerReady
  ) {
    showCreateUnconditional = true
  }

  const isDraftPayApp = payApp && isPayAppDraftOrSyncFailed(payApp.status)
  const shouldShowOptInMenuItem = !payApp && optedOut && showCreateButton
  const shouldShowOptOutMenuItem = (!payApp || isDraftPayApp) && !optedOut
  const shouldShowCreateUnconditionalMenuItem = optedOut && showCreateUnconditional
  const shouldShowRevertToDraft = !!payApp && isPayAppResettable(payApp.status)
  const gcPortalIntegration = integrations.find(
    (integration) => getIntegrationTypeFamily(integration.type) === IntegrationTypeFamily.GC_PORTAL
  )
  const shouldShowMarkAsSubmitted =
    payApp &&
    (canMarkPayAppAsSubmitted(payApp.status, Boolean(gcPortalIntegration)) ||
      canMarkPayAppAsSynced(payApp.status))

  const isMarkAsPaidOptionVisible =
    payApp && shouldShowMarkAsPaid && showMarkAsPaid && !isMarkAsPaidDisabled

  const shouldShowMenu =
    shouldShowOptInMenuItem ||
    shouldShowOptOutMenuItem ||
    shouldShowRevertToDraft ||
    shouldShowCreateUnconditionalMenuItem ||
    onOpenUpdatePayApp ||
    shouldShowMarkAsSubmitted ||
    isMarkAsPaidOptionVisible

  // Return early if the pay app is completed (there are no actions to take)
  if (payApp && isPayAppCompleted(payApp.status)) {
    return null
  }

  // Return early if the contract is not active or the user does not have permission to edit
  if (!canEdit || !isContractActive) {
    return null
  }

  // If there won't be any items in the menu, don't show the button at all
  if (!shouldShowMenu) {
    return null
  }

  return (
    <div className={classes.root} onClick={handlePreventEventPropagation}>
      <IconButton color="secondary" onClick={openMenu} size="small">
        <MoreVertIcon className="threeDots" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {/* Allow you to add a pay app iff you've explicitly opted out */}
        {shouldShowOptInMenuItem && onCreatePayApp && (
          <MenuItem
            onClick={(e) => {
              closeMenu(e)
              optIn()
              onCreatePayApp(month)
            }}
          >
            {t(`${i18nBase}.create_or_opt_out.opt_in_billing`)}
          </MenuItem>
        )}
        {/* Allow you to opt out of billing if you haven't already */}
        {shouldShowOptOutMenuItem && (
          <MenuItem
            onClick={(e) => {
              closeMenu(e)
              setOptOutDialogOpen(true)
            }}
          >
            {payApp
              ? t(`${i18nBase}.create_or_opt_out.opt_out_billing`)
              : t(`${i18nBase}.create_or_opt_out.opt_out_month_billing`, {
                  month: month.format('MMMM'),
                })}
          </MenuItem>
        )}
        {shouldShowRevertToDraft && (
          <MenuItem
            onClick={(e) => {
              closeMenu(e)
              handleOpenResetDialog()
            }}
          >
            {t(`${i18nBase}.submit.revert_to_draft`)}
          </MenuItem>
        )}
        {/* Allow you to create an unconditional lien waiver if you have opted out */}
        {shouldShowCreateUnconditionalMenuItem && (
          <CreateOrViewUnconditionalMenuItem
            contract={contract}
            month={month}
            hasUnconditional={hasUnconditional}
          />
        )}
        {onOpenUpdatePayApp && (
          <MenuItem
            onClick={(e) => {
              closeMenu(e)
              onOpenUpdatePayApp()
            }}
          >
            {t(`${i18nBase}.create_or_update.edit_title`)}
          </MenuItem>
        )}
        {shouldShowMarkSyncedErp && (
          <MenuItem
            onClick={(e) => {
              closeMenu(e)
              handleMarkAsSyncedToErp()
            }}
          >
            {markAsSyncedErpText}
          </MenuItem>
        )}
        {shouldShowMarkAsSubmitted && (
          <MenuItem
            onClick={(e) => {
              closeMenu(e)
              handleOpenMarkAsSubmittedDialog()
            }}
          >
            {markAsSubmittedText}
          </MenuItem>
        )}
        {isMarkAsPaidOptionVisible && (
          <MenuItem onClick={handleMarkAsPaidOptionClick}>{markAsPaidLabel}</MenuItem>
        )}
      </Menu>
      <OptOutOfBillingDialog
        open={optOutDialogOpen || isOptingOut}
        setOpen={setOptOutDialogOpen}
        onOptedOut={optOut}
        hasPayApp={!!payApp}
        month={month}
        contract={contract}
        showCreateUnconditional={showCreateUnconditional}
        submitting={isOptingOut}
      />
      {payApp && (
        <MarkAsPaidDialog
          open={isMarkAsPaidDialogOpen}
          onClose={handleCloseMarkAsPaidDialog}
          payApp={payApp}
        />
      )}
      <SelectLienWaiverProgressTypeDialog
        open={isSelectLienWaiverDialogOpen}
        onClose={handleCloseLienWaiverDialog}
        onSubmit={onNavigateToLienWaiver}
        isProcessingFinalTemplate={isProcessingUnconditionalFinalTemplate}
        isProcessingProgressTemplate={isProcessingUnconditionalProgressTemplate}
      />
      {payApp && (
        <MarkAsSubmittedDialog
          open={isMarkAsSubmittedDialogOpen}
          onClose={handleCloseMarkAsSubmittedDialog}
          payApp={payApp}
          contract={contract}
          gcPortalIntegrationId={gcPortalIntegration?.id ?? null}
          actionType={markAsSubmittedAction}
        />
      )}
      {payApp && (
        <ResetPayAppToDraftDialog
          open={isResetDialogOpen}
          onClose={handleCloseResetDialog}
          payApp={payApp}
        />
      )}
    </div>
  )
}
