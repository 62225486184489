const translation = {
  titles: {
    main: 'Siteline | Billing',
    project: 'Siteline | {{- project}}',
    vendors: 'Siteline | Vendors',
    reporting: 'Siteline | Reporting',
    reporting_month: 'Siteline | Reporting | {{month}}',
    help: 'Siteline | Help',
    account: 'Siteline | Account',
    company: 'Siteline | Company',
    legal_requirement: 'Siteline | Compliance',
    sign_in: 'Siteline | Sign In',
  },
  footer: {
    terms_of_service: 'Terms of Service',
    privacy_policy: 'Privacy Policy',
  },
  release_check: {
    title: 'Refresh to update Siteline',
    details:
      'A new version of Siteline is available. Click here to reload the page and use the new version of Siteline.',
  },
  offline: {
    chip: 'Unstable Internet Connection',
    tooltip:
      'Your internet connection is unstable. Siteline might not be fully working while your connection is down.',
  },
  device_warning: {
    tablet_warning_title: "Looks like you're on a tablet!",
    tablet_warning_subtitle: 'Siteline works best if you flip your device to landscape',
    mobile_warning_title: "Looks like you're on a mobile device!",
    mobile_warning_subtitle: 'Siteline works best on desktop',
  },
  popup: {
    new: 'New',
  },
  pdf_error: {
    title: 'Failed to load the document. Please try again.',
    try_again: 'Reload',
  },
  auth: {
    signout: 'Sign out',
    signin: {
      title: 'Sign in to Siteline',
      email: 'Email',
      password: 'Password',
      forgot_password: 'Forgot password?',
      continue_with_password: 'Or continue with password',
      submit: 'Sign In',
      submit_sso: 'Sign in via SSO',
      send_reset_link: 'Send reset link',
      invalid_email: 'Please enter a valid email address',
      invalid_login:
        'You have entered an invalid username or password. Try again or click forgot password.',
      general_login_failure: 'Login attempt failed. Please try again or come back later.',
      link_sent: 'Reset link sent',
      link_failed: 'Unable to send reset link. Please contact support@siteline.com.',
      user_not_found:
        'Could not find an account for this email. Please contact support@siteline.com.',
      go_to_sign_in: 'Go to sign in',
    },
    invite: {
      error: {
        title: 'Something went wrong',
        having_trouble: "If you're having trouble signing into an existing account,",
        reset_your_password: 'reset your password',
        reset_password: 'Reset password',
      },
      process_invite: {
        generic_error:
          'This invite link is invalid. Please click the original invite link to try again.',
      },
      pre_signin_link: {
        title: 'Join Siteline',
        details:
          "You've been invited to join your co-workers on Siteline. This will only take a minute.<br><br>Get a magic link sent to your email to sign in.",
        details_sso:
          "You've been invited to join your co-workers on Siteline. This will only take a minute.",
        submit: 'Send magic link',
        submit_sso: 'Continue via SSO',
      },
      signout: {
        title: "You're already signed in",
        details:
          "It looks like you're already signed in with another account. To accept this invite, you will need to sign out first.",
        submit: 'Sign out and accept invite',
        cancel: 'Go to Siteline',
      },
      post_signin_link: {
        title: 'Magic link sent to your inbox',
        details:
          "We've sent a magic link to <strong>{{email}}</strong><br><br>Please check your inbox for an email from <strong>{{originEmail}}</strong>",
      },
      handle_signin_link: {
        no_email_stored: 'This sign-in link has already been used.',
        click_link: 'Please click the original invite link to try again.',
        original_email:
          'Please click the link in the original email with the subject "{{- companyName}} invited you to join Siteline" to get signed in.',
      },
      update_user: {
        title: 'Welcome to Siteline',
        intro: {
          title: 'Welcome {{firstName}}',
          details:
            'Siteline helps you streamline the entire payment application process, saving you time and providing peace of mind.',
          modal_details:
            'We need a little more information from you to finish setting up your Siteline account',
          submit: 'Get started',
        },
        job_title: {
          title: "What's your job title?",
          helper: 'e.g. Project Manager, Accountant, Owner',
          submit: 'Continue',
        },
        office: {
          title: 'What is your primary office?',
          submit: 'Continue',
        },
        password: {
          title: 'Please set a new password',
          password_label: 'Password',
          password_helper: '8+ characters',
          confirmation_label: 'Retype password',
          invalid: 'Password must have 8 characters or more',
          not_equal: 'Passwords must match each other',
          no_current_user: 'Something went wrong. Please try again later.',
          signin_again:
            'To ensure security of your account, we sent you another sign-in email. Please check your inbox.',
          submit: 'Continue',
        },
        terms: {
          title: 'Terms of service',
          terms:
            'I agree to the <strong><termsOfServiceLink>Terms</termsOfServiceLink></strong> and have read the <strong><privacyPolicyLink>Privacy Policy</privacyPolicyLink></strong>.',
          submit: 'Complete account',
        },
      },
    },
    verify_alias: {
      error: 'Unable to verify any email address',
      success: 'Email address verified',
      resent_email: "Verification code expired. We've sent a new verification link to your inbox.",
    },
    no_company: {
      title: `We couldn't log you in`,
      details: `Your account exists, but you don't currently belong to a team in Siteline.\n\nCheck your email for an invite link, or ask your admin to send you an invitation.`,
      log_out: 'Log out',
    },
  },
  upload: {
    too_large: 'This file is is more than 30mb. Please upload a smaller file.',
    too_small: 'This file is too small. Please upload a larger file.',
    too_many: 'Cannot upload more than one file',
    invalid_type: 'This file is not a valid file type',
  },
  file: {
    large:
      'Large files (>10mb) take longer to load and slow down form rendering. We recommend compressing images and PDFs.',
  },
  notifications: {
    title: 'Notifications',
    view_settings: 'View settings',
    clear_notification: 'Clear this notification',
    clear_all: {
      title: 'Clear all',
      details: 'Are you sure you want to clear and archive all of your notifications?',
    },
    zero_state: {
      title: "You're all caught up",
      details:
        "When there are important notifications, actions, or updates from your team, you'll be notified here.",
    },
    notification_titles: {
      LEGAL_REQUIREMENT_EXPIRED: 'Compliance expired',
      LEGAL_REQUIREMENT_EXPIRING: 'Compliance expiring soon',
      LEGAL_REQUIREMENT_MISSING: 'Compliance field missing (e.g. expiration date)',
      INVOICE_CHANGED: 'Invoice updated by team member',
      LOWER_TIER_LIEN_WAIVER_COMPLETE: 'Lien waiver',
      PAY_APP_PASTDUE: 'Pay app past due',
      RETENTION_COMPLETE: 'Retention',
      RETENTION_HALFWAY: 'Retention',
      RETENTION_REMINDER: 'Retention',
      UNCONDITIONAL_LIEN_WAIVER_REMINDER: 'Lien waiver',
      WELCOME: 'Welcome',
      OPTED_OUT_OF_BILLING: 'Project not billing in month',
      FORMS_REVIEW: 'Forms ready',
      MENTIONED_IN_PAY_APP_COMMENT: 'New pay app comment',
      MENTIONED_IN_PROJECT_COMMENT: 'New comment',
      MENTIONED_IN_AGING_COMMENT: 'New AR Aging comment',
      MENTIONED_IN_CASH_FORECASTING_COMMENT: 'New cash forecasting comment',
      MENTIONED_IN_CHANGE_ORDER_REQUEST_COMMENT: 'New change order request comment',
    },
    welcome: 'Welcome!',
    header: '{{date}} · {{- name}}',
    header_company: '{{date}} · {{- name}} · {{- companyName}}',
  },
  '404': {
    not_found: "That page doesn't exist.",
    return_home: 'Back to homepage',
  },
  maintenance: {
    chip: 'Maintenance Enabled',
    title: "We're currently undergoing maintenance",
    description: 'We will be back online shortly',
  },
  help: {
    nav_title: 'Help',
    how_can_we_help: 'How can we help?',
    help_center: 'Help Center',
    find_tutorials:
      "Find tutorials, guides, and documentation for Siteline's features or troubleshoot any issues you run into",
    cant_find: "Can't find what you're looking for?",
    find_answers: 'Find answers',
    email: {
      title: 'Send an email',
      questions: 'Questions or suggestions? Email us!',
      reply: 'Our team will reply as soon as possible',
    },
    phone: {
      title: 'Give us a call',
      questions: 'Need customer support? We can help!',
      reply: 'Monday - Friday from 7 am - 8 pm PT',
    },
  },
  emails: {
    opt_out: {
      error: 'There was an error opting out of digital signatures: {{ error }}',
      success: 'You have successfully opted out of digital signatures.',
    },
  },
  settings: {
    navigation: {
      profile: 'Profile',
      account: 'Account settings',
      notifications: 'Notifications',
      email: 'Email',
      permissions: 'Permissions',
      quickbooks: 'QuickBooks',
      foundation: 'Foundation',
      company: 'Company',
      email_aliases: 'Email aliases',
      company_settings: 'Company settings',
      settings: 'Settings',
      team: 'Team',
      offices: 'Offices',
      tax_groups: 'Tax groups',
    },
    account: {
      profile: 'Profile',
      email: 'Email',
      update_email:
        'Please contact <emailLink><strong>support@siteline.com</strong></emailLink> if you need to change your account email',
      name: 'Name',
      title: 'Title',
      password: 'Password',
      current: 'Current',
      new: 'New',
      retype: 'Retype',
      login_email: 'This is the email you use to log in to Siteline',
      errors: {
        password_match: 'Passwords do not match',
        generic: 'Something went wrong. Please try again.',
        wrong_password: 'Your current password is incorrect. Please try again.',
        weak_password: 'Your password must have at least 6 characters. Please try again.',
      },
    },
    notifications: {
      subtitle: 'You will receive notifications for any preferences enabled here',
      description: {
        WELCOME: 'Welcome notification',
        LEGAL_DOCUMENT: 'Compliance',
        LEGAL_REQUIREMENT_EXPIRING: 'Compliance expiring soon',
        LEGAL_REQUIREMENT_EXPIRED: 'Compliance expired',
        LEGAL_REQUIREMENT_MISSING: 'Compliance field missing (e.g. expiration date)',
        OPTED_OUT_OF_BILLING: 'Project not billing for month',
        FORMS_REVIEW: 'Pay app forms ready to sign',
        MENTIONED_IN_PAY_APP_COMMENT: 'Mentioned in pay app comment',
        MENTIONED_IN_PROJECT_COMMENT: 'Mentioned in project-level comment',
        MENTIONED_IN_AGING_COMMENT: 'Mentioned in AR aging comment',
        MENTIONED_IN_CASH_FORECASTING_COMMENT: 'Mentioned in cash forecasting comment',
        MENTIONED_IN_CHANGE_ORDER_REQUEST_COMMENT: 'Mentioned in change order request comment',

        RETENTION: 'Retention',
        RETENTION_HALFWAY: 'Retention - Halfway through project',
        RETENTION_COMPLETE: 'Retention - Done with project',
        RETENTION_REMINDER:
          'Retention - Every month after project completion until retention is zero',

        PAY_APP: 'Pay App',
        PAY_APP_PASTDUE: 'Overdue pay app',
        INVOICE_CHANGED: 'Invoice ready for review',

        LIEN_WAIVER: 'Lien Waiver',
        LOWER_TIER_LIEN_WAIVER_COMPLETE: 'Lower Tier Lien Waiver completed',
        UNCONDITIONAL_LIEN_WAIVER_REMINDER: 'Unconditional Lien Waiver reminder',
      },
    },
    emails: {
      subtitle: 'You will receive emails for any preferences enabled here',
      description: {
        WEEKLY: 'Weekly task email',
        WEEKLY_PAY_APP: 'Pay app deadlines & reminders',
        WEEKLY_LEGAL_REQUIREMENT: 'Compliance deadlines & reminders',

        DAILY: 'Daily high priority email',
        DAILY_PAY_APP: 'Pay app due or past due',
        DAILY_LEGAL_REQUIREMENT: 'Compliance due tomorrow, past due, or missing',

        GENERAL: 'General',
        INVOICE_CHANGED: 'Invoice is ready for review',
        LOWER_TIER_LIEN_WAIVER_COMPLETE: 'Lower-tier lien waiver signed',
        CREATE_PAY_APP: 'Reminder to create pay app (monthly on the 12th)',
        TEXTURA_SYNC: 'Textura sync report',
        PAY_APP_PAID: 'Pay app marked as paid',
        MONTHLY_REPORT: 'Monthly billing report',
        OPTED_OUT_OF_BILLING: 'Project not billing for month',
        ONBOARDED_PROJECT_FORMS: 'Project forms have been processed',
        ADDED_TO_PROJECT: 'Added to a project',
      },
    },
    field_guest_emails: {
      description: {
        DAILY_PAY_APP: 'Pay app due or past due (daily)',
        OPTED_OUT_OF_BILLING: 'Project not billing for month',
        ADDED_TO_PROJECT: 'Added to a project',
      },
    },
    permissions: {
      no_permission:
        'You do not have permission to modify this page. Please contact your team admin with any questions.',
      allowed: 'Allowed',
      not_allowed: 'Not allowed',
      description: {
        EDIT_BACKUP: 'Manage backup',
        MANAGE_VENDORS: 'Manage vendors',
        EDIT_LEGAL_REQUIREMENT: 'Manage compliance',
        EDIT_INVOICE: 'Edit invoice',
        SIGNER: 'Sign documents',
        SUBMIT: 'Submit pay apps',
        SUBMIT_DRAFT: 'Submit draft pay apps',
        EDIT_CHANGE_ORDER: 'Manage change orders',
        CREATE_QUICK_BILL_PROJECT: 'Create quick bill project',
        EDIT_COMPANY_USERS: 'Manage users',
        EDIT_PROJECT_SETTINGS: 'Create and edit projects',
        DELETE_PROJECT: 'Delete projects',
        ERP_SYNC: 'Write to accounting system',
      },
    },
    company: {
      profile: 'Profile',
      name: 'Name',
      short_name: 'Short name',
      short_name_info: 'A nickname or abbreviated name used to reference your company',
      addresses: 'Addresses',
      add_address: 'Add address',
      edit_address: 'Edit address',
      new_address: 'New address',
      start_billing_reminder: 'Start billing reminder',
      generate_audit_trail: 'Generate digital signature audits',
      aging_calculation: 'A/R Aging Calculation',
      BILLING_END: 'End of billing period',
      DATE_SUBMITTED: 'Invoice date',
      disabled: 'Disabled',
      enabled: 'Enabled',
      audit_trail_info:
        'By default, include a digital signature audit trail in all generated forms. This can always be enabled or disabled by project.',
      default_contract_retention: 'Default contract retention',
      retention_percent_info:
        'This will be the default retention percent when creating new contracts. You can always change this for individual contracts.',
      default_due_date: 'Default pay app due date',
      due_date_info:
        'This will be the default date pay apps are due to the GC when creating new contracts. You can always change this for individual contracts.',
      default_days_before_pay_app_due: 'Default internal deadline',
      default_days_before_pay_app_due_info:
        'This is the number of days prior to the date pay apps are due to the GC that will display as the internal deadline. You can always change this for individual contracts.',
      before_pay_app: 'day before pay app due',
      before_pay_app_other: 'days before pay app due',
      before_pay_app_with_number: '{{count}} day before pay app due',
      before_pay_app_with_number_other: '{{count}} days before pay app due',
      of_the_month: 'of the month',
      are_you_sure: 'Are you sure?',
      confirm_enable_audit:
        "An audit trail will now be included on forms generated on all projects. You can always disable this for individual projects in the project's settings.",
      confirm_disable_audit:
        "This will remove audit trails from forms generated on all projects. You can always turn it on for individual projects in the project's settings.",
      nickname_too_short: "A company's short name must be at least {{minChars}} characters long",
      lien_waiver_reminder_frequency: 'Vendor auto-reminder frequency',
      lien_waiver_reminder_frequency_info:
        'Siteline automatically reminds vendors to sign requested lien waivers, until either the waiver is completed or a month has passed. You can always send manual reminders as well, regardless of this setting.',
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      NEVER: 'Do not send recurring reminders',
      default_stored_materials_tracking: 'Default stored materials tracking',
      default_stored_materials_tracking_tooltip:
        'This is the default for how stored materials are tracked for each new job created in Siteline. You can always change this for individual jobs.',
      AUTOMATIC: 'Automatically move to previous billed',
      MANUAL: 'Manually adjust amount each month',
      labels: {
        nickname: 'Office name',
        street1: 'Street address',
        street2: 'Street address 2',
        city: 'City*',
        optional_city: 'City',
        county: 'County',
        state: 'State*',
        optional_state: 'State',
        postalCode: 'Postal code',
        country: 'Country*',
        optional_country: 'Country',
      },
      email_alias: {
        title: 'Email aliases',
        description:
          "If you use a different company email address than your log-in, you can add it as an alias. Emails sent and received for those company's projects will default to the corresponding alias. You will continue logging into Siteline with {{- primaryEmail}}.",
        invalid_email: '{{- emailAlias}} is not a valid email address',
        email_aliases_updated: 'Email alias updated',
        email_aliases_updated_other: 'Email aliases updated',
        verified: 'Verified',
        verified_email: 'Verified {{- email}}',
        verify_email:
          "We've sent an email to {{- email}}. Please check your inbox and click the link to verify this address.",
        verify_email_other:
          "We've sents email to {{count}} new aliases. Please check your inbox and click the link to verify these addresses.",
        verification_required: 'Verification required',
        verification_description:
          'Before we can use this email alias, please check your inbox and click the link for verification',
        verification_expired: 'Verification expired. <sendLink>Resend email?</sendLink>',
        expired_description: 'Before we can use this email alias, we need to verify it via email',
      },
      switch_company_dialog: {
        cannot_view_project: 'This company cannot view this project',
        cannot_view_vendor: 'This company cannot view this vendor',
        cannot_view_rateTable: 'This company cannot view this rate table',
        switch_to_view_project: 'Switch companies to view this project',
        switch_to_view_vendor: 'Switch companies to view this vendor',
        switch_to_view_rateTable: 'Switch companies to view this rate table',
        company_no_access_project:
          "You're signed in to <bold>{{- wrongCompanyName}}</bold>, which does not have access to this project. To stay on this page, switch to <bold>{{- accessCompanyName}}</bold>.",
        company_no_access_vendor:
          "You're signed in to <bold>{{- wrongCompanyName}}</bold>, which does not have access to this vendor. To stay on this page, switch to <bold>{{- accessCompanyName}}</bold>.",
        company_no_access_rateTable:
          "You're signed in to <bold>{{- wrongCompanyName}}</bold>, which does not have access to this rate table. To stay on this page, switch to <bold>{{- accessCompanyName}}</bold>.",
        all_companies_no_access_project:
          "You're signed in across all companies, and cannot view this project. To stay on this page, switch to {{- accessCompanyName}}.",
        all_companies_no_access_vendor:
          "You're signed in across all companies, and cannot view this vendor. To stay on this page, switch to {{- accessCompanyName}}.",
        all_companies_no_access_rateTable:
          "You're signed in across all companies, and cannot view this rate table. To stay on this page, switch to {{- accessCompanyName}}.",
        switch_to_company: 'Switch to {{- accessCompanyName}}',
        switch_companies: 'Switch companies',
        go_to_billing: 'Go to Billing',
        go_to_compliance: 'Go to Compliance',
        go_to_vendors: 'Go to Vendors',
        go_to_reporting: 'Go to Reporting',
      },
    },
    quickbooks: {
      description:
        'Invoices exported to QuickBooks will use the following default Item and Account settings. You can override these for individual projects under project settings. <bold>These Items and Accounts must be created in QuickBooks before exporting invoices.</bold> For more information, read our guide in the <helpLink>Help Center</helpLink>.',
      ar_account: 'A/R account',
      retention_ar_account: 'Retention A/R account',
      ar_account_description:
        'This QuickBooks Account will be used for all exported invoices. If this is a subaccount, include parent accounts separated by a colon (e.g. Parent Account:Subaccount).',
      retention_ar_account_description:
        'This QuickBooks Account will be used for all exported retention billings. If this is a subaccount, include parent accounts separated by a colon (e.g. Parent Account:Subaccount).',
      invoice_item: 'Invoice item (progress)',
      invoice_item_description:
        'All non-retention line items billed on an invoice will be exported as this Item in QuickBooks',
      income_account: 'Income account (progress)',
      income_account_description:
        'The QuickBooks income account to which all progress amounts are assigned. If this is a subaccount, include parent accounts separated by a colon (e.g. Parent Account:Subaccount).',
      retention_item: 'Invoice item (retention)',
      retention_item_description:
        'When retention is included separately on an invoice, it will be exported as this Item in QuickBooks',
      retention_account: 'Income account (retention)',
      retention_account_description:
        'The QuickBooks income account to which the retention amount is assigned when included as a separate invoice item.  If this is a subaccount, include parent accounts separated by a colon (e.g. Parent Account:Subaccount).',
    },
    foundation: {
      description:
        'Invoices synced to Foundation will use the following default general ledger account and retention general ledger account. You can override these for individual projects by choosing new accounts while syncing an invoice.',
      gl_account: 'General ledger account',
      retention_gl_account: 'Retention general ledger account',
      gl_account_description: 'The GL account that will be used as the company default',
      retention_gl_account_description:
        'The retention GL account that will be used as the company default',
      search_accounts: 'Search accounts',
    },
    users: {
      title: 'Users',
      ACTIVE: 'Active',
      DISABLED: 'Deactivated',
      name: 'Name',
      job_title: 'Job title',
      role: 'Role',
      roles: {
        ADMINISTRATOR: 'Admin',
        ACCOUNTANT: 'Team member',
        PROJECT_MANAGER: 'Team member',
        FIELD_GUEST: 'Invoice-only guest',
      },
      projects: 'Projects',
      status: 'Status',
      office: 'Office',
      no_results: 'No users found',
      search_placeholder: 'Search by name, email, job title',
      deactivate_account: 'Deactivate account',
      reactivate_account: 'Reactivate account',
      invite_team_member: 'Invite team member',
      deactivate_self: "You can't deactivate your own account",
      resend_invite: 'Re-invite',
      resend_invite_title: 'Resend invite?',
      confirm_resend:
        'A reminder email will be sent to {{- name}} with a link to set up their account on Siteline',
      resent_invite: 'A new invitation to Siteline was sent to {{- name}}',
      deactivate_dialog: {
        title_deactivate: "Are you sure you want to deactivate {{- name}}'s account?",
        title_reactivate: "Are you sure you want to reactivate {{- name}}'s account?",
        body_deactivate:
          '{{- name}} will no longer be able to access <bold>{{- companyName}}</bold> on Siteline',
        body_reactivate:
          'We will email {{- name}} a new invitation to <bold>{{- companyName}}</bold> on Siteline and you will be able to add them to company projects',
        reactivate: 'Reactivate',
        deactivate: 'Deactivate',
        success_deactivate: "Deactivated {{- name}}'s account",
        success_reactivate: "Reactivated {{- name}}'s account",
      },
      project_autocomplete: {
        name_or_number: 'Project name or number',
        no_project: 'No project found, or the project has already been added',
        enter_project: "Enter the project's name or number",
      },
      dialog: {
        invite_team_member: 'Invite team member',
        edit_user: 'Edit user',
        first_name: 'First name',
        last_name: 'Last name',
        job_title: 'Job title',
        role: 'Role',
        email: 'Email',
        billing_deadline: 'Billing deadline',
        primary_office: 'Primary office',
        permissions: 'Permissions',
        add_to_projects: 'Add to projects',
        invite_to_siteline: 'Invite to Siteline',
        invited_user: 'Sent invite to {{- name}}',
        updated_account: "Updated {{- name}}'s account",
        updated_your_account: 'Updated your account',
        invalid_email: 'Please enter a valid email address',
        num_projects_zero: 'No projects',
        num_projects: '{{count}} project',
        num_projects_other: '{{count}} projects',
        add_to_all: 'Add to all current projects',
        select_specific_projects: 'Select specific projects',
        added_to_all: 'Added to all current projects',
        admin: 'Admin',
        team_member: 'Team member',
        custom_permissions: 'Custom permissions and projects',
        all_permissions: 'All permissions, all projects',
        limited_permissions: 'Limited permissions and invoice access',
        invoice_only_guest: 'Invoice-only guest',
        roles: {
          internal: 'Internal (due to accounting)',
          external: 'External (due to GC)',
        },
      },
    },
    offices: {
      search_placeholder: 'Search by name or address',
      name: 'Name',
      street_address: 'Street address',
      users: 'Users',
      set_office: 'Set as my office',
      edit: 'Edit',
      edit_office: 'Edit office',
      invite_user: 'Invite team member',
      add_new_office: 'Add new office',
      add_office: 'Add office',
      my_office: 'My office',
      no_results: 'No offices found',
      delete_office: 'Delete office',
      delete_office_success: 'Successfully deleted office',
      delete_disabled_tooltip_users:
        'A user at your company has {{- officeName}} listed as their primary office',
      delete_disabled_tooltip_users_other:
        'Users at your company have {{- officeName}} listed as their primary office',
      delete_disabled_tooltip_projects:
        'There is {{count}} active project associated with {{- officeName}}',
      delete_disabled_tooltip_projects_other:
        'There are {{count}} active projects associated with {{- officeName}}',
      confirm_delete_title: 'Delete office?',
      confirm_delete_description:
        'Are you sure you want to delete {{- officeName}}? Once deleted, it cannot be restored.',
      success_added_office: 'Added new office',
      success_updated_office: 'Updated office',
      success_updated_primary_office: 'Your office is now set to {{- officeName}}',
    },
  },
  top_navigation: {
    billing: 'Billing',
    compliance: 'Compliance',
    vendors: 'Vendors',
    reporting: 'Reporting',
    quick_bill_toast: 'Quick bills are only supported in the billing module',
    all_vendors: 'All vendors',
    projects: 'Projects',
    search_projects: 'Search projects',
    search_vendors: 'Search vendors',
    no_projects: 'No projects found',
    no_vendors: 'No vendors found',
    searching: 'Searching...',
    more_projects_filtering: '{{totalCount}} projects match your current search',
    more_projects_not_filtering: 'Search by name or number across all {{totalCount}} projects',
    more_vendors_filtering: '{{totalCount}} vendors match your current search',
    more_vendors_not_filtering: 'Search by name across all {{totalCount}} vendors',
    company_switcher: {
      all_companies: 'All companies',
      search_companies: 'Search companies',
      no_company_found: 'No company found',
      company_settings: 'Company settings',
      account_settings: 'Account settings',
      log_out: 'Log out',
    },
  },
  project_home: {
    filters: 'Filters',
    filters_applied: 'Filters applied',
    clear_filters: 'Clear filters',
    recently_viewed: 'Recently viewed',
    active_projects: 'Active projects',
    onboarding: 'Onboarding',
    fully_billed: 'Fully billed',
    archived: 'Archived',
    view_active_projects: 'View active projects',
    no_ACTIVE_projects: 'No active projects',
    no_ARCHIVED_projects: 'No archived projects',
    view_all_active_projects: 'View all active projects',
    showing_ONBOARDING: 'Showing projects with incomplete tasks or forms',
    showing_ARCHIVED: 'Showing projects that are archived',
    showing_FULLY_BILLED: 'Showing projects that are 100% completed to date',
    showing_ALL: 'Showing all projects',
    bulk_create_pay_apps: 'Create pay apps for {{month}}',
    view_rate_tables: 'View rate tables',
    onboarding_projects: 'Onboarding projects',
    no_all_projects: 'No projects',
    yesterday: 'yesterday',
    no_projects:
      'If there’s a project you expect to see here, contact your team administrator or <emailLink>{{email}}</emailLink>',
    not_billing_this_month: 'Not billing this month',
    awaiting_sov: 'Awaiting SOV',
    awaiting_rate_table: 'Awaiting rate table',
    setup_incomplete: 'Setup incomplete',
    view_month_pay_apps: "View {{month}}'s pay apps",
    view_unconditional: 'View unconditional lien waiver',
    no_search_results: 'No projects match your search',
    no_filter_results: 'No projects match all your filtering criteria',
    clear_search: 'Clear search',
    clear_all: 'Clear all',
    archive_project: 'Archive project',
    unarchive_project: 'Restore project',
    delete_project: 'Delete project',
    delete_project_title: 'Delete project #{{- projectNumber}}?',
    confirm_delete:
      'Are you sure you want to delete {{- projectName}}?\n\nThis will delete any associated pay apps, lien waivers, and compliance documents. The project will no longer appear in reporting.\n\n<bold>This CANNOT be undone.</bold> If the project is no longer active but should remain in Siteline, please archive it instead.',
    deleting_project: 'Deleting project. This may take a minute...',
    archive_instead: 'Archive instead',
    project_deleted: 'Project deleted',
    no_delete_permission:
      "You don't have permission to delete projects. Please contact your team admin with any questions.",
    you_can_still: 'You can still view, download, and export data',
    restore_project: 'Restore project',
    finish_project_setup: 'Finish project setup',
    remaining_tasks: 'There is one more task for you to complete',
    remaining_tasks_other: 'There are {{count}} more tasks for you to complete',
    processing_forms: "We're processing your forms",
    new_project: 'New project',
    billing_report: 'Billing report',
    retention_held: '{{- amount}} retention held',
    quick_bill: 'Quick bill',
    invoice: 'Invoice',
    when_forms_ready:
      "You'll get an email when they're ready. In the meantime, you can still start billing and add backup.",
    past_due_banner_title: 'The predicted payment date for this pay app has passed',
    past_due_banner_subtitle:
      "If you've been paid, be sure to mark this pay app as paid. If your timeline has changed, you can update the predicted payment date below.",
    no_pay_apps: 'No pay apps',
    no_pay_apps_subtext:
      'If there’s a pay app you expect to see here, contact your team administrator or <emailLink>{{email}}</emailLink>',
    go_home: 'Go home',
    billing_report_dialog: {
      monthly_billing_report: 'Monthly billing report',
      select_a_month: 'Select a month to compile a report on billing, A/R aging, and change orders',
      downloading: 'Downloading billing report...',
      error: 'Failed to download billing report',
      success: 'Billing report for {{month}} downloaded',
      no_pay_apps: "Cannot generate billing report: you don't have access to any pay apps",
    },
    notes: {
      title: 'Bulletin board',
      empty_state_message:
        'Add internal project notes, reminders and FYIs here. These notes will also be shown on each pay app’s overview.',
      vendors_empty_state_message: 'Add internal project notes, reminders and FYIs here',
      save_success: 'Updated project notes',
      info_read_only: 'Edit from project overview',
      edit_from_home: 'Edit',
      navigate_away_warning:
        'Changes to the Bulletin board will not be saved. Are you sure you want to leave?',
    },
    activities: {
      title: 'Aging feed',
      all_filter: 'All activity',
      comments_filter: 'Comments only',
      no_activity_title_include_name: 'No recent activity for {{- projectName}}',
      no_activity_title: 'No recent activity',
      no_activity_created_project: 'Project created',
      no_activity_aging_description: 'Aging updates and comments will be displayed here',
      no_activity_project_description: 'Project updates and comments will be displayed here',
    },
    bulk_create_dialog: {
      create_pay_apps: 'Create pay apps for {{month}}',
      batch_create:
        'Batch create pay apps for all projects, based on your current filters. Unchecked projects will not be opted out of billing.',
      create_n_pay_apps: 'Create {{count}} pay app',
      create_n_pay_apps_other: 'Create {{count}} pay apps',
      create_n_pay_apps_zero: 'Create pay apps',
      no_pay_apps: 'All projects have a pay app in {{month}}, or have opted out of billing',
      awaiting_sov: '(awaiting SOV)',
      completed_progress_billing: '(completed progress billing)',
      pay_apps_created: 'Created {{count}} pay app',
      pay_apps_created_other: 'Created {{count}} pay apps',
      pay_apps_created_with_errors:
        'Created {{count}} of {{total}} pay apps. You can try again or create remaining pay apps individually.',
      all_awaiting_sov: 'Cannot create a pay app for the project below until it has an SOV',
      all_awaiting_sov_other: 'Cannot create pay apps for the projects below until they have SOVs',
      creating_pay_apps: 'Creating pay apps. This may take a few minutes...',
      error:
        'Unable to create a pay app for {{- projectNumber}} {{- projectName}}. Try unchecking it or creating it yourself before continuing.',
    },
    confirm_archive: {
      title: 'Archive {{- projectName}}?',
      body: 'Your team will not be able to make changes to the project once it is archived',
      archived_project: 'Archived project',
      outstanding_progress_body:
        'This project is not fully billed. Your team will not be able to continue billing on this project once it is archived.',
    },
    confirm_unarchive: {
      title: 'Unarchive {{- projectName}}?',
      body: 'The project will be moved to your active projects and your team will be able to edit it again',
      restored_project: 'Restored project',
    },
    archive_banner: {
      title: `You've been paid in full!`,
      details:
        'Archive this project to hide it in project lists. The project data will be preserved.',
      archive: 'Archive project',
    },
    sort_by: {
      number_asc: 'Sorting by project number (ascending)',
      number_desc: 'Sorting by project number (descending)',
      name_asc: 'Sorting by project name (ascending)',
      name_desc: 'Sorting by project name (descending)',
      completed_asc: 'Sorting by percent completed (ascending)',
      completed_desc: 'Sorting by percent completed (descending)',
      due_date_asc: 'Sorting by due date (ascending)',
      due_date_desc: 'Sorting by due date (descending)',
      pm_asc: 'Sorting by Lead PM (ascending)',
      pm_desc: 'Sorting by Lead PM (descending)',
      edited_asc: 'Sorting by last edited (earliest first)',
      edited_desc: 'Sorting by last edited (latest first)',
    },
    header: {
      all: 'All',
      search_placeholder: 'Job name, number, GC',
      reset_filters: 'Clear',
      filters: {
        filters: 'Filters',
        apply_filters: 'Apply filters',
        clear_all: 'Clear all',
        only_retention_pay_apps: 'Only retention pay apps',
        project_status: {
          title: 'Project status',
          ACTIVE: 'Active',
          ARCHIVED: 'Archived',
        },
        project_type: {
          title: 'Project type',
          LUMP_SUM: 'Lump sum',
          QUICK: 'Quick bill',
          UNIT_PRICE: 'Unit price',
          TIME_AND_MATERIALS: 'Time and materials',
        },
        office: {
          title: 'Office',
          search: 'Search offices',
          no_results: 'No offices found',
        },
        pay_app_status: {
          title: 'Pay app status',
          AWAITING_SOV: 'Awaiting SOV',
          DRAFT_SUBMITTED: 'Draft submitted',
          IN_PROGRESS: 'In progress',
          INVOICE_READY: 'Invoice ready',
          NOT_BILLING_THIS_MONTH: 'Not billing this month',
          NOT_STARTED: 'Not started',
          PAST_DUE: 'Past due',
          SIGNED: 'Signed',
          SUBMITTED_SYNCED: 'Submitted / synced to GC portal',
          SYNC_FAILED: 'Failed sync to GC portal',
          SYNC_PENDING: 'Pending sync to GC portal',
          NOT_ERP_SYNCED: 'Not yet synced to ERP',
          SUBMITTED_SYNCED_PAID: 'Submitted / synced / paid',
          PAID: 'Paid',
          DRAFT: 'Draft',
        },
        submit_type: {
          title: 'Submit via',
          SITELINE: 'Siteline',
          GC_PAY: 'GCPay',
          TEXTURA: 'Textura',
          TEST: 'Test',
          SAGE_100_CONTRACTOR: 'Sage 100 Contractor',
          SAGE_300_CRE: 'Sage 300 CRE',
          SPECTRUM: 'Viewpoint Spectrum',
          VISTA: 'Viewpoint Vista',
          PROCORE: 'Procore',
        },
        lead_pm: {
          title: 'Lead PM',
          search: 'Search lead PMs',
          no_results: 'No PMs found',
        },
      },
    },
    headers: {
      number: '#',
      name: 'Name',
      month_pay_app: '{{month}} pay app',
      prior_month: "Showing {{month}} pay apps until tomorrow, or until they're all submitted",
      prior_month_other:
        "Showing {{month}} pay apps for {{count}} more days, or until they're all submitted",
      completed_to_date: 'Completed to date',
      lead_pm: 'Lead PM',
      last_edited: 'Last edited',
    },
    pay_app: {
      pay_app_number: 'Pay App #{{payAppNumber}}',
      invoice: 'Invoice',
      due_date: 'Due {{date}}',
      not_created_yet: 'Not created yet',
      create_pay_app: 'Pay App #{{payAppNumber}}',
      bill_for_retention: 'Bill for retention',
      fully_billed: 'Fully billed',
      no_pay_app: 'No pay app',
      add_pay_app: 'Add pay app',
    },
  },
  compliance_home: {
    title: 'Compliance',
    no_all_projects: 'No projects',
    no_projects:
      'If there’s a project you expect to see here, contact your team administrator or <emailLink>{{email}}</emailLink>',
    not_billing_this_month: 'Not billing this month',
    search: 'Search for projects or general contractors',
    no_search_results: 'No items match your search',
    no_filter_results: 'No items match all your filtering criteria',
    clear_search: 'Clear search',
    clear_all: 'Clear all',
    header: {
      overview: 'Overview',
      requirements: 'Requirements',
    },
    sort_by: {
      number_asc: 'Sorting by project number (ascending)',
      number_desc: 'Sorting by project number (descending)',
      name_asc: 'Sorting by project name (ascending)',
      name_desc: 'Sorting by project name (descending)',
      compliant_asc: 'Sorting by compliance status (ascending)',
      compliant_desc: 'Sorting by compliance status (descending)',
    },
    filters: {
      legal_requirement_status: {
        title: 'Status',
        COMPLIANT: 'Compliant',
        EXPIRING: 'Expiring',
        EXPIRED: 'Not compliant',
      },
    },
    headers: {
      number: '#',
      name: 'Name',
      due_this_month: 'Due this month',
      legal_requirement_status: 'Compliant',
    },
  },
  projects: {
    billing: 'Billing',
    empty_results: {
      clear_filters: 'Clear filters',
      clear_search: 'Clear search',
      no_results: 'No projects found',
      no_active_results: 'No active projects',
    },
    navigation: {
      pay_apps: 'Pay Apps',
      legal_requirement: 'Compliance',
    },
    status: {
      submitted: 'Submitted',
      approved: 'Approved',
      not_started: 'Not started',
      in_progress: 'In progress',
      draft: 'Draft',
      invoice_ready: 'Invoice ready',
      draft_submitted: 'Draft submitted',
      signed: 'Signed',
      paid: 'Paid',
      partially_paid: 'Partially paid',
      synced: 'Synced',
      synced_to: 'Synced to {{- integration}}',
      notarizing: 'Paid (notarizing)',
      past_due: 'Past due',
      rejected: 'Rejected',
      sync_failed: 'Sync failed',
      sync_pending: 'Sync pending',
      authorized_to_proceed: 'Auth to proceed',
    },
    billing_type: {
      lump_sum: 'Lump sum',
      quick_bill: 'Quick bill',
    },
    errors: {
      project_load_failed:
        'Unable to load this project. It either does not exist or you do not have access to it.',
      pay_app_load_failed:
        'Unable to load this pay app. It either does not exist or you do not have access to it.',
      vendor_load_failed:
        'Unable to load this vendor. It either does not exist or you do not have access to it.',
    },
    new_project: {
      add_new_project: 'Add new project',
      lump_sum: 'Lump sum',
      unit_price: 'Unit price',
      new_quick_bill: 'New quick bill project',
      edit_quick_bill: 'Edit quick bill project',
      project_name: 'Project name*',
      project_number: 'Project number*',
      project_location: 'Project location',
      tax_group: 'Tax group',
      create_project: 'Create project',
      link: 'Link',
      link_and_continue: 'Link and continue',
      lump_sum_description: 'Fixed price contract,\nbilled on monthly progress',
      unit_price_description: 'Unit-based contract,\nbilled on monthly progress',
      quick_bill: 'Quick bill',
      quick_bill_description: 'One-off job with fewer line items',
      t_and_m: 'Time & Materials',
      t_and_m_description: 'Bill by hourly fixed wage and cost of materials',
      choose_bulk_source: 'Choose a source to bulk import from',
      import_from_integration: 'Import projects from {{integrationName}}',
      import_projects: 'Import {{count}} project',
      import_projects_other: 'Import {{count}} projects',
      import_projects_zero: 'Import',
      integration: 'integration',
      bulk: 'Bulk import',
      bulk_description: 'Create or link multiple projects at once',
      new_project: 'New project',
      does_hold_retention: 'Does this project hold retention?',
      holds_retention: 'Holds retention',
      holds_retention_description: 'The GC holds retainage each month',
      holds_retention_t_and_m_description: 'The GC holds retainage each billing cycle',
      no_retention: 'No retention',
      no_retention_description: "The GC won't hold any retainage for this job",
      import: 'Import',
      sync_from: 'Sync from Textura or GCPay',
      no_quick_template: 'Please contact {{email}} to set up quick bills',
      quick_subject: 'Set up quick bills',
      quick_body:
        'Hello,\n\n{{- company}} would like to add quick bill projects on Siteline. Please help us get started!',
      no_location_error: 'Unable to create project, please add a valid location',
      incomplete_location: 'Project locations must include a street address',
      invalid_location:
        'Could not find the time zone for this address. Please provide a valid city and state.',
      generic_error: 'Something went wrong. Unable to create project.',
      how_will_you_submit: 'How will you submit pay apps?',
      email_via_siteline: 'Email (via Siteline)',
      refresh: 'Refresh',
      retry: 'Retry',
      submit_options: 'How will you submit pay apps?',
      erp_options: 'Select an ERP',
      link_to: 'Link to {{integration}}',
      active_only: 'Active projects only',
      setting_up: 'Importing {{count}} project',
      setting_up_other: 'Importing {{count}} projects',
      match_integration: 'Importing project info and SOV from {{integration}}',
      bulk_import:
        'This import may take {{time}}. You can close this window and we will email you when this is complete.',
      bulk_import_times: {
        minutes: 'up to {{amount}} minutes',
        hours: 'an hour or longer',
      },
      bulk_import_options_description:
        'Please import projects from {{gcPortals}} before importing from Excel',
      bulk_import_options_description_erp:
        'Please import projects from {{gcPortals}} before importing from {{erpNames}} or Excel',
      import_status: '{{numCompleted}} of {{numTotal}} processed',
      excel_or_csv: 'Excel or CSV',
      bulk_import_finished: {
        all_failed_integration: 'Unable to import {{count}} project from {{integrationName}}',
        all_failed_integration_other:
          'Unable to import {{count}} projects from {{integrationName}}',
        all_failed: 'Unable to create {{count}} project',
        all_failed_other: 'Unable to create {{count}} projects',
        some_failed_integration:
          'Imported {{numSuccessful}} of {{totalProjects}} projects from {{integrationName}}',
        some_failed: 'Created {{numSuccessful}} of {{totalProjects}} projects',
        all_success_integration: 'Finished importing {{count}} project from {{integrationName}}',
        all_success_integration_other:
          'Finished importing {{count}} projects from {{integrationName}}',
        all_success: 'Finished creating {{count}} project',
        all_success_other: 'Finished creating {{count}} projects',
      },
      skip: 'Skip',
      import_error:
        'Something went wrong importing your project info. Try again or skip this step for now.',
      mismatch: {
        title: 'Project type mismatch',
        body: "You're creating a {{newType}} project, but the project linked from {{integrationName}} has {{integrationType}} billing. Which would you like to use in Siteline?",
        LUMP_SUM: 'lump sum',
        UNIT_PRICE: 'unit price',
        QUICK: 'quick',
        TIME_AND_MATERIALS: 'time and materials',
        change: 'Change to {{type}}',
        keep: 'Keep {{type}}',
      },
    },
    onboarding: {
      new_lump_sum: 'New lump sum project',
      new_unit_price: 'New unit price project',
      new_t_and_m: 'New T&M project',
      create_project: 'Create project',
      add_address: 'Add address',
      new_address: 'New address',
      missing_fields: 'Please complete all required fields',
      invalid_fields: "You've entered an invalid input",
      required_field: 'This field is required',
      invalid_field: 'This input is invalid',
      invalid_state_field: 'State must be a 2 or 3-letter state code',
      use_company: 'Use "{{- companyName}}"',
      no_options: 'Enter the name of the company',
      navigate_away: 'Are you sure you want to leave? Your project will not be saved.',
      sections: {
        PROJECT_INFO: 'Project info',
        CONTRACT: 'Contract',
        QUICKBOOKS: 'QuickBooks',
        RETENTION: 'Retention',
        TAXES: 'Taxes',
      },
      project_info: {
        project_name: 'Project name*',
        project_name_info: 'This is the name that appears on pay app forms',
        project_address_required: 'Project address*',
        project_address: 'Project address',
        gc_project_number: 'GC project #*',
        internal_project_number_info: 'If your company uses a different project number internally',
        internal_project_number: 'Internal project #',
        general_contractor: 'General contractor*',
        gc_address: 'GC address',
        general_contractor_address: 'General contractor address',
        owner: 'Owner',
        owner_address: 'Owner address',
        add_architect: 'Add architect (if used on forms)',
        architect: 'Architect',
        architect_address: 'Architect address',
        due_to_gc: 'Pay apps due to GC',
        first_due: 'First pay app due date',
        of_the_month: 'of the month',
        disable_new_project:
          'Importing an SOV that has already billed, so this is not considered a new project',
        project_status: 'Project status',
        new_project: 'New project',
        existing_project: 'Existing project (previously billed)',
        previously_billed: 'Previously billed',
        previously_billed_tooltip: 'Enter the gross amount previously billed on this project',
        pay_apps_completed: 'Pay apps completed',
        quickbooks_subtitle:
          'The following settings will be used when exporting invoices, either individually or as part of a bulk export. If overriding any default settings, make sure the names exactly match the corresponding names in QuickBooks.',
        import: 'Import',
        import_from: 'Import from {{- integration}}',
        looking_for_projects: 'Looking for projects. This may take a moment...',
        already_on_siteline: 'Already on Siteline',
        already_linked: '{{- integration}} job already linked',
        already_linked_details:
          'This {{- integration}} job is already linked to 1 or more Siteline projects. Confirm that your new project is related to {{- projectName}} before linking.',
        go_to_project: 'Go to project',
        import_body:
          'This will connect your project to {{- integration}}, and import project info and the SOV.',
        sync_pay_apps: ' It will also allow you to sync pay apps back to {{- integration}}.',
        sage_delay_warning:
          ' If you recently made changes in Sage, it may take up to 10 minutes to be reflected in Siteline.',
        import_erp_body:
          'This will connect your project to {{- integration}}, and import any additional project info',
        how_to_import_gc_portal:
          'No project found. Make sure the Siteline account is added to it on {{- integration}}, then click Refresh.',
        how_to_import_erp:
          "No project found. Make sure you've created it in {{- integration}}, then refresh this project list.",
        how_to_import_hh2:
          "No project found. Make sure you've created it in {{- integration}}, then refresh this project list. It may take up to an hour to appear.",
        no_import_projects_gc_portal:
          "We can't find a new project to import from {{- integration}}. If you're looking for a certain project, make sure you've added the Siteline account to it on {{- integration}}, and then click refresh.",
        no_import_projects_erp:
          "We can't find a new project to import from {{- integration}}. Please contact support if the issue persists.",
        no_import_projects_hh2:
          "We can't find a new project to import from {{- integration}}. Note that projects might take up to an hour to appear. Please contact support if the issue persists.",
        integration_error:
          'Unable to connect to {{- integration}}. Please contact {{- email}} to make sure your account is set up correctly.',
        search_projects: 'Search by project name, number, or general contractor',
        procore_app_warning:
          'If your project does not appear, the GC might not have completed the necessary steps. Please follow <Link>these instructions</Link> if the issue persists.',
        stored_materials_tracking: {
          title: 'Stored materials tracking',
          options: {
            AUTOMATIC: 'Automatically move to previous billed',
            MANUAL: 'Manually adjust amount each month',
          },
        },
      },
      contract: {
        contract_number: 'Contract #',
        contract_date: 'Contract date',
        subcontractor_address: 'Subcontractor address',
        payment_terms: 'Payment terms',
        pay_when_paid: 'Pay-when-paid',
        pay_if_paid: 'Pay-if-paid',
        none_selected: 'None selected',
        net_payment: 'Net payment',
        days: 'days',
        days_expected: 'days expected (optional)',
        payment_terms_info: 'The number of days after an invoice is issued that payment is due',
        vendor_number: 'Vendor number',
      },
      retention: {
        retention_level: 'Retention level',
        contract_retention: 'Contract retention',
        contract_retention_info:
          'Default retention % written into your contract. You can adjust this throughout the project.',
        round_retention: 'Round retention to nearest dollar',
        advanced_settings: 'Advanced settings',
        retention_names: {
          STANDARD: 'Line item, this month (most common)',
          LINE_ITEM: 'Line item, totaled',
          PAY_APP: 'Pay app',
          PROJECT: 'Project',
        },
        retention_descriptions: {
          STANDARD: 'Retention is calculated per line item each month',
          LINE_ITEM: "Retention is calculated per each line item's total billing",
          PAY_APP: "Retention is calculated per each pay app's total billing",
          PROJECT: "Retention is calculated per the entire project's billing",
        },
        help_center: 'Read our Help Center guide',
      },
      checklist: {
        integration_project: '{{- integration}} project',
        integration_subtitle:
          'Your pay app forms, change orders, primary lien waivers, and GC contacts are managed there',
        use_default_forms: 'Use default forms',
        upload_as_backup: 'Upload COR forms as backup',
        sov: 'SOV',
        rate_table: 'Rate table',
        rate_table_description:
          'Select the rate table that this project will reference for billing. This selection can be updated at any time.',
        fees: 'Fees',
        add_fee: 'Add fee',
        add_group_fee: 'Add category fee',
        add_fee_global_subtitle:
          'This fee will apply to all line items and can be can overridden at the category level',
        edit_fee: 'Edit fee',
        no_fees_yet: 'No fees added yet',
        apply_fee_to: 'Apply fee to',
        fee_type: 'Fee description',
        delete_fee_confirmation_title: 'Are you sure you want to remove this fee?',
        delete_fee_confirmation_details: 'This action cannot be undone',
        delete_override_fee_confirmation_onboarding:
          'If you remove this fee, its value will be changed to 0%. You can adjust the fee at any time in project settings.',
        delete_global_fee_confirmation_onboarding:
          'If you remove this fee, it will also be removed from each category. You can adjust fees at any time in project settings.',
        delete_override_fee_confirmation_settings:
          'If you remove this fee, its value will be changed to 0%',
        delete_global_fee_confirmation_settings:
          'If you remove this fee, it will also be removed from each category',
        percent: 'Percent',
        fees_subtitle:
          'Find advanced options by clicking into category fees below. Fees can be updated at any time from project settings.',
        general_fees: 'General fees',
        category_fees: 'Category-based fees',
        general_fees_subtitle:
          'These fees apply to all line items unless the fee has been overridden for a specific category',
        general_fees_subtitle_cor:
          'These fees apply to all COR pricing tool line items unless the fee has been overridden for a specific category',
        category_fees_subtitle: 'Add fees to a rate table category',
        category_fees_subtitle_click:
          'Add fees to a rate table category or click on an existing fee to make edits',
        upload_or_input:
          'You can upload a .csv file using our template, or input line items directly',
        import: 'Import',
        upload_excel: 'Upload Excel file',
        enter_manually: 'Enter manually',
        create_rate_table: 'Create',
        create_rate_table_description: 'Create a new rate table',
        duplicate_rate_table: 'Duplicate',
        duplicate_existing: 'Duplicate an existing rate table',
        duplicate_submit: 'Duplicate rate table',
        duplicate_rate_table_description: 'Duplicate and edit an existing rate table',
        duplicate_dropdown_title: 'Select the rate table you want to duplicate',
        choose_rate_table: 'Choose existing',
        choose_existing: 'Choose an existing rate table',
        choose_rate_table_description: 'Select a rate table from your library',
        clear_rate_table: 'Clear rate table',
        input_your_sov: 'Input your SOV directly in Siteline',
        project_team: 'Project team',
        select_team:
          "Select who you're working with on this project. You can always update this in Settings.",
        admin_access: 'Admins have access to all company projects',
        remove_from_project: 'Remove from project',
        cannot_remove_self: "You can't remove yourself",
        make_lead_pm: 'Make Lead PM',
        click_to_undo: 'Click to undo',
        no_users: 'No users found',
        teammate_name: 'Teammate name',
        lead: 'Lead',
        start_billing: 'Start billing',
        first_pay_app: 'Create your first pay app to start billing',
        create_pay_app: 'Create pay app',
        no_sov: 'You must add your Schedule of Values above before you can start billing',
        no_rate_table: 'You must add your rate table above before you can start billing',
        skip: 'Skip',
        not_now: 'Not now',
        no_lead_pm: 'No Lead PM selected',
        to_choose_lead_pm: 'To choose a Lead PM, click on the <starIcon />',
        lead_pm_tooltip:
          'Choose the primary contact for this project.\nThe Lead PM appears on the Billing homepage and in Reporting.',
        required: 'Required',
        not_required: 'Not required',
        confirm: 'Confirm',
        send_forms: 'Please send your forms to <emailLink><strong>{{email}}</strong></emailLink>',
        reusing_forms: 'If you are reusing any forms, please list them in your email',
        mark_as_sent: 'Mark as sent',
        added_forms: 'Added project forms',
        no_forms: 'No forms required',
        tasks_complete: 'Your project is fully set up!',
        no_permission:
          'You do not have permission to set up projects. Please contact your team administrator if you need help.',
        submit_to: 'Submit to',
        add_recipients:
          "Add recipients for your pay apps. You'll be able to modify this when you submit, and you'll automatically be CC'd on every pay app.",
        add_recipient: 'Add recipient',
        added_recipients: 'Added initial pay app recipients',
        pay_app_number: 'Pay App #{{number}}',
        select_rate_table_disabled_tooltip:
          'Your rate table library is empty. Create a new rate table to get started.',
        form_upload_dialog: {
          upload_instructions_examples:
            'Preferred naming format: [Your company or GC] [Form type] [State, if relevant]\ne.g. "Swinerton Conditional Progress FL"',
          subtitle:
            'To get your forms built ASAP, read our <strong><hcLink>form upload best practices</hcLink></strong>',
          prefixed_subtitle:
            ' To get your forms built ASAP, read our <strong><hcLink>form upload best practices</hcLink></strong>.',
          upload_pay_app_forms_title: 'Upload pay app forms',
          upload_primary_lien_waiver_forms_title: 'Upload primary lien waiver forms',
          upload_vendor_lien_waiver_forms_title: 'Upload vendor lien waiver forms',
          upload_cor_forms_title: 'Upload change order request forms',
          upload_cor_log_forms_title: 'Upload change order log forms',
          upload_cor_log_forms_subtitle:
            'Only add this form if you are managing change orders in Siteline.',
          pay_app_upload_reminder: 'Only upload pay app forms here',
          primary_lien_waiver_upload_reminder: 'Only upload primary lien waiver forms here',
          vendor_lien_waiver_upload_reminder: 'Only upload vendor lien waiver forms here',
          cor_upload_reminder: 'Only upload change order request forms here',
          submit_forms: 'Submit forms',
          signature_type: 'Signature type(s)',
          disable_submit_tooltip: 'Please select the signature types that appear on these forms',
          form_values: 'Form values',
          form_values_subtitle:
            'Siteline will automatically fill in form fields with available project info. For any fields that may be ambiguous, please specify how they should be filled.',
          form_values_placeholder:
            'e.g. use GC for "Owner Name", use internal project # as "Job number"',
          form_values_placeholder_pay_app:
            'e.g. use GC for "Owner Name" on G703, "Job number" should be internal project #',
          repeating_inputs: 'Repeating inputs',
          repeating_inputs_subtitle:
            "Your team will fill out all form inputs that Siteline can't auto-fill. Specify any inputs that should automatically copy over from the first pay app to future months.",
          repeating_inputs_placeholder: 'e.g. fax number, company tax ID, scope of work',
          special_conditions: 'Special conditions',
          special_conditions_subtitle:
            'Please indicate any forms that should be included only in certain cases or pay apps',
          special_conditions_placeholder:
            'e.g. use different cover sheet for retention-only pay apps, include special cover sheet when billing stored materials',
          additional_info: 'Additional info',
          additional_info_subtitle:
            'Is there anything else we should know to help us build these forms?',
          additional_info_placeholder:
            'e.g. reuse our default cover sheet, ignore last tab in workbook',
          signature_types: {
            NONE: 'No signature',
            NOTARIZATION: 'Requires notarization',
            DIGITAL_SIGNATURE: 'Digital signature',
            WET_SIGNATURE: 'Wet signature',
          },
        },
      },
      sov_onboarding: {
        sov: 'SOV',
        import_your_sov: 'Import your schedule of values',
        enter_manually: 'Enter manually instead',
        using_our_template:
          'Using our formatted Excel template will allow us to import your SOV automatically',
        paste_your_sov: 'Paste your SOV into our template',
        make_a_group:
          'To make a line item group, add an asterisk before the group name, e.g. Materials → *Materials.\nYou can end a group with a new group header, or with a row containing only an asterisk (*).\n\nFor a walkthrough of how to import your SOV, watch <demoLink>this short video</demoLink>.',
        upload_formatted_sov: 'Upload formatted SOV',
        schedule_of_values: 'Schedule of values',
        continue_editing: 'You can continue editing your SOV through the first billing cycle',
        cannot_edit_integration:
          'If you need to make changes, edit the SOV in {{- integration}} and re-sync to Siteline',
        import: 'Import',
        import_from_excel: 'Import from Excel',
        sov_template: 'ScheduleOfValues.xlsx',
        sync: 'Sync',
        sync_from: 'Sync from {{- integration}}',
        cannot_sync: 'Cannot re-sync SOV once a pay app is created',
        sov_synced: 'Synced  SOV from {{- integration}}',
        navigate_away: 'You have not yet saved your SOV. Are you sure you want to leave?',
        sov_saved: 'Your SOV has been saved',
        cannot_import: 'Cannot edit a project SOV once a pay app has been signed or submitted',
        import_error: {
          match_template: 'Uploaded file does not match expected SOV template',
          missing_value: 'A row is missing a value for a required column',
          stored_materials_too_high:
            'Stored materials amount ({{ previousMaterialsInStorage }}) cannot exceed previously billed amount ({{ previousBilled }})',
          previously_billed_too_high:
            'Previously billed amount ({{ previousBilled }}) cannot exceed the scheduled value ({{ scheduledValue }})',
          negative_stored_materials: 'Materials held in storage cannot be negative',
        },
        cancel_edit_dialog: {
          title: 'Cancel SOV changes?',
          description: 'Your changes will be cleared',
          confirm: 'Cancel changes',
          cancel: 'Keep editing',
        },
      },
    },
    subcontractors: {
      pay_app_list: {
        title: 'Pay Apps',
        total_value: 'Total value',
        due_to: 'Due to: {{dueTo}}',
        accounting: 'Accounting',
        accounting_short: 'ACCT',
        general_contractor: 'General Contractor',
        general_contractor_short: 'GC',
        location: 'Location',
        pending_erp_sync: 'Pending sync to {{- integration}}',
        name: 'Name',
        amount: 'Amount',
        status: 'Invoice status',
        revision_count_tooltip: 'Revised {{count}} time',
        revision_count_tooltip_other: 'Revised {{count}} times',
        erp: 'ERP',
        automatically_updated: 'Automatically updated from {{- name}}',
        automatically_updated_gc_portal:
          'Automatically updated from {{- name}}. It may take some time for the disbursed funds to appear in your account.',
        mark_as_paid: 'Mark as paid',
        generate_unconditional: 'Generate unconditional waiver',
        processing_unconditional: "We're still processing your unconditional lien waiver form",
        upload_notarized: 'Upload notarized waiver',
        clear_filters: 'Clear filters',
        project_number_num: 'Project #{{- projectNumber}}',
        create_pay_app: 'Add new pay app',
        export_cash_flow_forecast: 'Export cash flow forecast',
        amount_billed: '{{amount}}% billed',
        due: 'Due {{date}}',
        action_on: 'on {{date}}',
        disabled_tooltip_status: 'This pay app is no longer in draft and cannot be edited',
        disabled_tooltip_inactive:
          'This pay app cannot be edited because this contract is not active',
        not_yet_synced: 'Not yet synced to {{- integration}}',
        forecast_summary: {
          billed_to_date: 'Billed to date',
          billed_to_date_info: 'Total gross billed (submitted billing only)',
          collected_to_date: 'Collected to date',
          collected_to_date_tooltip: 'Includes all pre-Siteline billing',
          collected_to_date_aging_tooltip:
            'Includes all pre-Siteline billing, minus the amount aging in pre-Siteline pay apps',
          contract_value: 'Contract value',
          left_to_bill: 'Left to bill',
          retention_held: 'Retention held',
          num_days: '{{count}} days',
          average_time_to_paid: 'Avg time to paid',
          average_time_info_billing_date:
            'Time from pay app billing end date to payment date, averaged across all paid pay apps',
          average_time_info_invoice_date:
            'Time from pay app submission date to payment date, averaged across all paid pay apps',
          create_quick: 'Create quick forecast',
          go_to: 'Project forecast',
        },
        lien_waivers: {
          processing_final:
            "This project's unconditional final lien waiver template is still being processed. An unconditional progress form has not been selected.",
          processing_progress:
            "This project's unconditional progress lien waivers are still being processed. An unconditional final form has not been selected.",
          processing_unconditionals: "This project's unconditional forms are still being processed",
          select_progress_type_title: 'Select pay app type',
          select_progress: 'Progress pay app',
          select_progress_subtitle: 'Project is ongoing',
          select_final: 'Final pay app',
          select_final_subtitle: 'Project is complete',
        },
      },
      pre_siteline_pay_app_list: {
        title: 'Submitted before Siteline',
        add_pay_app: 'Add Pay App #{{payAppNumber}}',
        add_info: 'Add info',
        pay_app: 'Pay App #{{payAppNumber}}',
        no_pay_app: 'No pay app',
        amount_billed: '{{amount}}% billed',
        retention_only: 'Retention only',
        action_on: 'on {{date}}',
        mark_as_paid: 'Mark as paid',
        upload_notarized: 'Upload notarized waiver',
        create_unconditional: 'Create unconditional',
        processing_unconditional: "We're still processing your unconditional lien waiver form",
        download_file: 'Download file',
        view_file: 'View file',
        view_unconditional: 'View unconditional',
        delete_pay_app: 'Delete pay app',
        delete_confirm: 'Are you sure you want to delete this pay app?',
        deleting: 'Deleting pay app',
        delete_success: 'Deleted pay app',
        why: 'Why track past pay apps?',
        why_details:
          'Add your previously submitted pay apps so you and your team can access all your pay apps in one place. When you get paid, you can easily generate unconditional lien waivers, and also ensure your A/R aging data is accurate.',
      },
      project_info: {
        percent_billed: '{{amount}}% billed',
        billed_to_date: 'Billed to date',
        contract_sum_to_date: 'Contract sum to date',
        paid_to_date: 'Paid to date',
        retention_held: 'Retention held',
        project_team: 'Project team',
        select_lead_pm: 'Select lead PM',
        add_teammates: 'Add teammates',
        name_lead_pm: '{{- name}} (Lead PM)',
        project_info: 'Project info',
        project_number: 'Project #{{- num}}',
        vendor_number: 'Vendor #{{- num}}',
        address: 'Address',
        gc: 'General Contractor',
        owner: 'Owner',
        architect: 'Architect',
        gc_contact: 'GC contact',
        gc_contacts: 'GC contacts',
      },
      sov: {
        headers: {
          number: 'Number',
          name: 'Name',
          cost_code: 'Cost code',
          description: 'Description',
          units: 'Unit',
          unit_of_measure: 'Unit of measure',
          unit_price: 'Unit price',
          scheduled_value: 'Scheduled value',
          bid_quantity: 'Bid QTY',
          bid_amount: 'Bid amount',
          revised_quantity: 'Revised QTY',
          contract_amount: 'Contract amount',
          pre_siteline_billing: 'Pre-Siteline billing',
          materials_stored: 'Materials stored',
          materials_stored_unit: 'Materials stored (QTY)',
          materials_stored_amount: 'Materials stored ($)',
          retention_percent: 'Retention %',
          retention_percent_tooltip: 'Latest retention percent used on Siteline pay apps',
          pre_siteline_retention_percent: 'Pre-Siteline retention %',
          pre_siteline_retention_amount: 'Pre-Siteline retention ($)',
          change_order: 'Change order',
          change_order_date: 'Change order date',
          billed_to_date: 'Billed to date',
          quantity_to_date: 'QTY to date',
          amount_to_date: 'Amount to date',
          balance_to_finish: 'Balance to finish',
          units_to_finish: 'Units to finish',
          retainage: 'Retainage',
          tax_group: 'Tax group',
        },
        insert_change_order_above: 'Add change order above',
        insert_change_order_below: 'Add change order below',
        insert_line_item_above: 'Add line item above',
        insert_line_item_below: 'Add line item below',
        add_tax_group: 'Add tax group',
        search_tax_groups: 'Search tax groups',
        linked_to_cor_with_internal_number_tooltip:
          'Linked to COR {{- internalNumbers}}. Click to go to this COR.',
        linked_to_cor_with_internal_number_tooltip_other:
          'Linked to CORs {{- internalNumbers}}. Click to go to COR {{- firstInternalNumber}}.',
        total: 'Total',
        num_items: '{{count}} item',
        num_items_other: '{{count}} items',
        contract_adjusted: 'Contract adjusted by {{delta}} units',
        adjustments:
          'Once billing starts, you can adjust the contract quantity from a pay app invoice',
        billed_to_date_too_high: 'The amount billed to date cannot exceed the scheduled value',
        billed_to_date_too_high_confirm_title:
          'The amount billed to date exceeds the scheduled value',
        billed_to_date_too_high_scheduled_details:
          'This change will cause the scheduled value for this line item to be less than the amount billed to date ({{amountBilled}})',
        billed_to_date_too_high_billed_details:
          'This change will bring the amount billed to date ({{amountBilled}}) above the scheduled value of {{scheduledValue}}',
        worksheet_total_exceeds_sov:
          'The SOV line item total value cannot be less than the sum of its worksheet line item values ({{worksheetLineItemsTotalValue}})',
        worksheet_total_exceeds:
          "Worksheet line items cannot exceed the SOV line item's total value of {{totalValue}}",
        worksheet_total_exceeds_sov_confirm_title:
          'The SOV line item total value is less than its worksheet total amount',
        worksheet_total_exceeds_sov_confirm_details:
          'This change will cause the total worksheet amount {{totalWorksheetValue}} to exceed the SOV line item total value',
        worksheet_total_exceeds_confirm_title:
          'Worksheet units contracted exceeds SOV line item total value',
        worksheet_total_exceeds_confirm_details:
          'This change will cause the total contracted worksheet amount to exceed the SOV line item total value of {{totalValue}}',
        stored_materials_too_high:
          'The amount of materials stored cannot exceed the pre-Siteline billing amount',
        stored_materials_negative: 'The amount of materials stored cannot be negative',
        default_retention_percent_too_high: 'Retention cannot exceed 100%',
        default_retention_percent_too_low: 'Retention cannot be below 0%',
        pre_siteline_retention_amount_invalid:
          'Pre-Siteline retention amount must less than Pre-Siteline billing',
        pre_siteline_retention_unlinked:
          "This amount does not match the line item's default retention percent ({{percent}}%)",
        pre_siteline_retention_unlinked_reset:
          "This amount does not match the line item's default retention percent ({{percent}}%). Click to reset.",
        pre_siteline_retention_unrounded:
          'Retention rounding is enabled on this project, but the retention amount is not rounded',
        pre_siteline_retention_override_chip: 'Overridden',
        pre_siteline_retention_override_warning:
          'The total amount of retention held pre-Siteline has been overridden. This amount will be used when calculating retention totals, but it may not match the retention held per line item on your invoices and G703s.',
        min_approval_date:
          'The approval date cannot be before {{- date}}, or earlier pay apps must be reverted',
        missing_fields: 'Your SOV is incomplete. Fill in missing information and try saving again.',
        sov_saved: 'Your SOV has been saved',
        pre_siteline_disabled_worksheet:
          "Edit pre-Siteline billing from this line item's worksheet",
        sov: 'SOV',
        import: 'Import from Excel',
        add_line_item: 'Add line item',
        add_change_order: 'Add change order',
        add_group: 'Add group',
        change_orders_permission:
          "You don't have permission to edit change orders, so your ability to edit may be limited. Please contact your team admin with any questions.",
        something_went_wrong:
          'Something went wrong. Please wait a moment and try again, or contact support.',
        confirm_empty_groups: 'An empty group will be removed',
        confirm_empty_groups_other: 'Empty groups will be removed',
        confirm_empty_groups_description:
          "Group headers with no line items will be removed, as they don't appear on invoices or forms. Are you sure you want to continue?",
        zero_unit_price_error:
          'A line item with a unit price of $0 must have a contracted quantity of 0',
        stored_materials_zero_unit_price:
          'Cannot add stored materials to a line item with a unit price of $0',
        update_approval_date: 'Update approval date?',
        update_effective_date: 'Update effective date?',
        confirm_update_date:
          'This may affect which pay apps the change order appears on. Make sure you include the change order only on pay apps that should sync the line item to {{- integration}}.',
        renumbering_your_line_items:
          'Re-numbering your line items? We can automatically re-number the rest.',
        keep_sequential:
          'Want to keep your line items sequential? We can automatically re-number the ones below.',
        no_longer_sequential:
          'These line item numbers are no longer sequential. Would you like Siteline to re-number them for you?',
        renumber: 'Re-number',
        delete_dialog: {
          group_title: 'Delete group',
          group_description:
            'Are you sure you want to delete the group <strong>{{- name}}</strong>?',
          group_description_untitled: 'Are you sure you want to delete this group?',
          delete_line_items: 'Delete entire group',
          delete_header_only: 'Delete header only',
        },
        preview_dialog: {
          import_sov: 'Import SOV changes from {{- integrationName}}',
          import_change_orders: 'Import change orders from {{- integrationName}}',
          review_changes:
            'Review changes to your SOV. If something looks off, check if it matches {{- integrationName}}, and contact support if needed.',
          submit_disabled: "We don't detect any changes to import into Siteline",
          import: 'Import',
          num_changes: '{{ count }} line item will be modified',
          num_changes_zero: 'No line items will be modified',
          num_changes_other: '{{ count }} line items will be modified',
          added_line_item: 'Added line item',
          updated_value: 'Updated value',
          removed_line_item: 'Removed line item',
          import_success: 'Imported SOV from {{- integrationName}}',
          signed_pay_apps:
            'You may need to revert signed pay apps to draft before importing certain changes to the SOV',
        },
        adjust_pre_siteline_retention: {
          retention_held: 'Retention held pre-Siteline',
          edit_retention: 'Edit retention',
          override_retention: 'Override retention',
          default_retention_percent: 'Default retention',
          default_retention_percent_tooltip:
            'Retention percent to use on the first Siteline pay app',
          pre_siteline_retention_unlinked:
            "This amount does not match the contract's default retention percent",
          dialog_title_edit: 'Edit pre-Siteline retention',
          dialog_title_override: 'Override pre-Siteline retention',
          dialog_subtitle_edit: 'Change the amount of retention held before Siteline',
          dialog_subtitle_override: 'Override the amount of retention held before Siteline',
          line_item_code: 'Line Item {{code}}',
          pre_siteline_retention_held: 'Total held before Siteline',
          pre_siteline_retention_held_tooltip:
            'Total retention held before Siteline, excluding retention billed',
          override_warning:
            'This override amount will be used when calculating retention totals, but it may not match the retention held per line item on your invoices and G703s.',
          unrounded_warning:
            'Retention rounding is enabled on this project, but the retention amount is not rounded',
          reset_override: 'Remove override',
          help_center: 'Read our Help Center guide',
        },
        change_order_date: {
          approved: 'Approved',
          effective: 'Effective',
          edit_approval_date: 'Edit approval date',
          edit_effective_date: 'Edit effective date',
          add_effective_date: 'Add effective date',
          effective_date_tooltip:
            'The date a change order may start being billed, if different than the approval date',
          remove: 'Remove effective date',
        },
      },
      taxes: {
        add_tax_group: 'Add tax group',
        edit_tax_group: 'Edit tax group',
        create_description: 'This tax group can be used on any SOV or quick bill',
        name: 'Name',
        linked_to_erp: 'Linked to ERP',
        tax_rate: 'Tax rate',
        tax_rate_percent: 'Tax rate (%)',
        single_tax_rate:
          '<bold>{{- name}} ({{percent}}%)</bold> will be applied to all SOV line items',
        search_by_name: 'Search tax groups by name',
        no_results: 'No tax groups found',
        no_tax_groups: 'No tax groups added yet',
        link_tax_group: 'Link tax group to your integrations',
        no_integration_groups: 'No tax groups found for this integration',
      },
      forecast: {
        info_link: 'How projections work',
        edit_projections: 'Edit projections',
        reset_changes: 'Reset changes',
        click_to_edit: 'Click here to edit this forecast',
        total_contract_value: 'Total contract value',
        left_to_bill: 'Left to bill',
        projected_billing_start: 'Projected billing start',
        billing_start: 'Billing start',
        billing_end: 'Billing end',
        projected_billing_end: 'Projected billing end',
        projected_duration: 'Projected duration',
        update_your_forecast: 'Update your forecast to match project billing',
        billing_date_warning_forward:
          'Your forecast starts in {{- projectedStart}}, but this project started billing in {{- billingStart}}. Do you want to shift your forecast forward {{count}} month or reduce the project duration?',
        billing_date_warning_forward_other:
          'Your forecast starts in {{- projectedStart}}, but this project started billing in {{- billingStart}}. Do you want to shift your forecast forward {{count}} months or reduce the project duration?',
        billing_date_warning_back:
          'Your forecast starts in {{- projectedStart}}, but this project started billing in {{- billingStart}}. Do you want to shift your forecast back {{count}} month or extend the project duration?',
        billing_date_warning_back_other:
          'Your forecast starts in {{- projectedStart}}, but this project started billing in {{- billingStart}}. Do you want to shift your forecast back {{count}} months or extend the project duration?',
        shift_forecast: 'Shift forecast',
        extend_duration: 'Extend duration',
        reduce_duration: 'Reduce duration',
        duration: 'Duration',
        show_sov: 'Show SOV',
        projected_key: 'Proj.',
        actual_key: 'Actual',
        smart_distribute: 'Smart distribute',
        num_months: '{{count}} month',
        num_months_other: '{{count}} months',
        navigate_away:
          'Are you sure you want to leave? Changes to your forecast will not be saved.',
        confirm_cancel_title: 'Are you sure you want to cancel?',
        confirm_cancel_details: 'Changes to your forecast will not be saved.',
        confirm_save_title: 'Are you sure you want to save?',
        confirm_save_details_pos: 'The projected total is under-allocated by {{remainder}}',
        confirm_save_details_neg: 'The projected total is over-allocated by {{remainder}}',
        last_edited_warning:
          'This forecast was last edited by <bold>{{- firstName}} {{- lastName}}</bold>',
        cancel_label: 'Cancel changes',
        keep_editing: 'Keep editing',
        empty_title: 'Forecast your monthly billing',
        empty_description:
          'Siteline can help you generate, edit, and track your monthly billing projections',
        get_started: 'Create forecast',
        apply_preset_curve: 'Apply preset curve',
        apply: 'Apply',
        overallocated: 'Over-allocated',
        underallocated: 'Under-allocated',
        allocate: 'Allocate',
        allocate_to: 'Allocate to {{- month}}',
        allocate_from: 'Allocate from {{- month}}',
        allocate_to_next: 'Allocate to next month ({{- month}})',
        allocate_from_next: 'Subtract from next month ({{- month}})',
        allocate_to_final: 'Allocate to final month ({{- month}})',
        allocate_from_final: 'Subtract from final month ({{- month}})',
        distribute_proportional: 'Distribute remainder',
        remove_proportional: 'Subtract across months',
        remainder_info_negative:
          'The projected total is over-allocated. Subtract the remaining amount from your projections by using the Allocate options to the right, or by manually editing the projections below.',
        remainder_info_positive:
          'The projected total is under-allocated. Add the remaining amount to your projections by using the Allocate options to the right, or by manually editing the projections below.',
        amount_billed_tooltip:
          'Total amounts billed on submitted Siteline and pre-Siteline pay apps',
        proj: 'Proj.',
        projected_paren: '(Projected this month)',
        projected_amount_not_started: 'Not started ({{dollarValue}} projected)',
        missing_pre_siteline_billing:
          'The amount billed on a pre-Siteline pay app has not been entered. For accurate billing history, enter billing information for that pay app in the Pay Apps tab.',
        missing_pre_siteline_billing_other:
          'The amount billed on all pre-Siteline pay apps has not been entered. For accurate billing history, enter billing information for those pay apps in the Pay Apps tab.',
        edit_pre_siteline_pay_apps: 'Edit pre-Siteline pay apps',
        delete_forecast: 'Delete forecast',
        delete_confirmation_title: 'Are you sure you want to delete your forecast?',
        delete_confirmation_details:
          'You will not be able to recover your forecast after it has been deleted.',
        delete_success: 'Successfully deleted forecast',
      },
      settings: {
        no_permission: "You don't have permission to edit",
        navigate_away: 'Are you sure you want to leave? Any changes will not be saved.',
        archive: 'Archive project',
        unarchive: 'Unarchive project',
        tabs: {
          CONTRACT: 'Contract',
          INTEGRATIONS: 'Integrations',
          REMINDERS: 'Reminders',
          RETENTION: 'Retention',
          USERS: 'Project team',
          PROJECT_INFO: 'Project info',
          FORMS: 'Forms',
          FEES: 'T&M Fees',
          COR_FEES: 'COR pricing fees',
          TAXES: 'Taxes',
        },
        project_info: {
          title: 'Project info',
          project_name: 'Project name',
          project_address: 'Project address',
          submit_label: 'Update address',
          general_contractor: 'General contractor',
          gc_address: 'GC address',
          gc_contacts: 'GC contacts',
          add_contact: 'Add contact',
          unique_email_error: 'A contact with that email has already been added to this contract',
          general_contractor_address: 'General contractor address',
          owner: 'Owner',
          owner_address: 'Owner address',
          architect: 'Architect',
          architect_address: 'Architect address',
          internal_project_number: 'Internal project #',
          gc_project_number: 'GC project #',
          before_siteline: 'Before Siteline',
          previously_billed: 'Previously billed',
          previously_billed_tooltip: 'The gross amount previously billed on this project',
          pay_apps_completed: 'pay app completed',
          pay_apps_completed_other: 'pay apps completed',
          num_pay_apps_completed: '{{count}} pay app completed',
          num_pay_apps_completed_other: '{{count}} pay apps completed',
          num_pay_apps_completed_zero: 'No pre-Siteline pay apps',
          updated: 'Project info settings have been saved',
          required_field: 'This field is required',
          required_with_address: 'A name is required if an address is provided',
          same_company: 'Is this the same company?',
          stored_materials: {
            title: 'Stored materials tracking',
            disabled_tooltip:
              'Cannot edit stored materials tracking on projects with signed pay apps',
            options: {
              AUTOMATIC: 'Automatically move to previous billed',
              MANUAL: 'Manually adjust amount each month',
            },
          },
        },
        new_contact_dialog: {
          add_contact: 'Add contact',
          add_another_contact: 'Add another contact',
          contacts_saved: 'Your recipients will be saved for future pay apps',
        },
        contract: {
          title: 'Contract',
          subcontractor_address: 'Subcontractor address',
          contract_number: 'Contract #',
          contract_date: 'Contract date',
          updated: 'Contract settings have been saved',
          required_field: 'This field is required',
          edit_in_settings: 'Edit address in Settings',
          payment_terms: 'Payment terms',
          days: 'days',
          net_days: 'Net {{count}} day',
          net_days_other: 'Net {{count}} days',
          pay_when_paid: 'Pay-when-paid',
          pay_when_paid_days_expected: 'Pay-when-paid ({{count}} day expected)',
          pay_when_paid_days_expected_other: 'Pay-when-paid ({{count}} days expected)',
          pay_if_paid: 'Pay-if-paid',
          pay_if_paid_days_expected: 'Pay-if-paid ({{count}} day expected)',
          pay_if_paid_days_expected_other: 'Pay-if-paid ({{count}} days expected)',
          vendor_number: 'Vendor number',
          uploaded_contract_file: 'Uploaded contract file',
          uploaded_contract_file_other: 'Uploaded contract files',
          deleted_contract_file: 'Deleted {{fileName}}',
          upload_files: 'Upload contract files',
          upload: 'Upload',
          upload_more: 'Upload more',
          contract_files: 'Contract files',
          none_uploaded: 'No files selected',
        },
        taxes: {
          taxes: 'Taxes',
          radio_buttons: {
            NONE: 'Do not calculate taxes',
            SINGLE_TAX_GROUP: 'One tax group is applied to all SOV line items',
            MULTIPLE_TAX_GROUPS: 'Multiple tax groups are applied to different SOV line items',
          },
          current_setting: {
            NONE: 'Does not calculate taxes',
            SINGLE_TAX_GROUP:
              'One tax group is applied to all SOV line items: <bold>{{- name}} ({{percent}}%)</bold>',
            MULTIPLE_TAX_GROUPS: 'Multiple tax groups are applied to different SOV line items',
          },
          select_tax_group: 'Select tax group',
          select_default_tax_group: 'Select default tax group',
          new_tax_group: 'New tax group',
          add_tax_group: 'Add tax group',
          success: 'Updated tax settings',
          apply_to_retention: 'Apply tax net of retention',
          on: 'On',
          off: 'Off',
          not_supported_by_integration:
            'Siteline will always sync pre-tax amounts to {{integrationName}} regardless of tax settings, as {{integrationName}} does not support taxes',
        },
        import_tax_group: {
          title: 'Taxes',
          details:
            'Tax group <bold>{{- name}} ({{percent}}%)</bold> does not exist yet in Siteline',
          imported:
            'Tax group <bold>{{- name}} ({{percent}}%)</bold> has been imported into Siteline',
          radio_buttons: {
            create: 'Create new tax group',
            merge: 'Link to existing tax group',
          },
          select_tax_group: 'Select tax group',
          not_supported_by_integration:
            'Siteline will always sync pre-tax amounts to {{integrationName}} regardless of tax settings, as {{integrationName}} does not support taxes.',
        },
        integrations: {
          title: 'Integrations',
          erp: 'ERP',
          tax_group: 'Tax group',
          customer: 'Customer',
          gc_portal: 'GC Portal',
          delete: 'Delete integration',
          updated: 'Integrations settings have been saved',
          none: 'None',
          no_integrations: 'This project has no integrations',
          gc_link_warning:
            'After linking this integration, please import the SOV to resolve potential conflicts',
          missing_quickbooks_job: 'Please enter the name of the job in QuickBooks',
          missing_quickbooks_customer: 'Please enter the name of the customer in QuickBooks',
          enable_mark_as_paid: 'Detect paid pay apps',
          enable_mark_as_paid_off: 'Off',
          enable_mark_as_paid_on: 'On',
          delete_confirm: {
            title: 'Are you sure you want to delete this integration?',
            details_erp: 'You will be able to re-link this integration at any time',
            details_gc_portal:
              'Synced pay apps will be marked as submitted. You will be able to re-link this integration at any time.',
            reminders_title: 'Update due date?',
            reminders_details:
              'This will affect pay apps created in the future. The due dates for any existing pay apps will not be changed, but can be edited from the project page.',
          },
        },
        quick_bill: {
          title: 'Project info',
          project_name: 'Project name',
          project_number: 'Project #',
          street_address: 'Street address',
          updated: 'Project settings have been saved',
        },
        reminders: {
          title: 'Reminders',
          pay_app_due_date: 'Pay app due date',
          start_billing: 'Start billing reminder',
          pay_app_due_reminder: 'Internal due date',
          second_monday: '2nd Monday of the month',
          num_days_before_due_date: '{{count}} day before pay app due',
          num_days_before_due_date_other: '{{count}} days before pay app due',
          days_before_due_date: 'day before due date',
          days_before_due_date_other: 'days before due date',
          day_of_the_month: '{{day}} of the month',
          of_the_month: 'of the month',
          updated: 'Reminder settings have been saved',
          edit_in_settings: 'Edit in Settings',
        },
        retention: {
          title: 'Retention',
          tracking_level: 'Tracking level',
          tracking_levels: {
            NONE: 'None',
            PROJECT: 'Project',
            PAY_APP: 'Pay app',
            STANDARD: 'Standard',
            LINE_ITEM: 'Line item',
          },
          email_support:
            'If this does not match the figure on your contract, please email {{email}}. To bill for retention, go to the retention tab within a pay app.',
          round_to_nearest_dollar: 'Round to nearest dollar',
          help_center: 'Read our Help Center guide',
          on: 'On',
          off: 'Off',
          updated: 'Retention settings have been saved',
        },
        fees: {
          title: 'Fees',
        },
        users: {
          title: 'Project team',
          manage: 'Manage',
          user_permissions_and_settings: 'User permissions & settings',
          lead: 'Lead',
          name: 'Name',
          job_title: 'Job title',
          dialog: {
            title: 'Manage users',
            subtitle: 'Select who has access to this project',
            save_success: 'Project team has been updated',
            num_team_members: '{{count}} team member',
            num_team_members_other: '{{count}} team members',
          },
        },
        forms: {
          loading: 'Loading...',
          confirm_forms: 'Confirm forms',
          all_forms_banner: 'Start with existing forms',
          forms: 'Forms',
          copy_all_forms: 'Copy another project',
          use_all_defaults: 'Add company defaults',
          title: 'Forms',
          upload_forms_later: 'You can upload forms in Settings later if needed',
          optional: 'Optional',
          clear: 'Clear forms',
          preview: 'Preview',
          search_placeholder: 'Search for projects by name, number, or GC',
          searching: 'Searching...',
          preview_default_forms: 'Preview default forms',
          show_audit: 'Digital signature audit',
          on: 'Included',
          off: 'Not included',
          updated: 'Form settings have been saved',
          pay_app: 'Pay app',
          primary_lien_waivers: 'Primary lien waivers',
          vendor_lien_waivers: 'Vendor lien waivers',
          change_order_request: 'Change order request',
          change_order_log: 'Change order log',
          select_pay_app_forms: 'Select pay app forms',
          select_primary_lien_waiver_forms: 'Select primary lien waiver forms',
          select_vendor_lien_waiver_forms: 'Select vendor lien waiver forms',
          select_change_order_forms: 'Select change order request forms',
          select_change_order_log_forms: 'Select change order log forms',
          processing_other_forms:
            'These forms are still being processed. If selected, they will be applied to this project when ready.',
          pay_app_forms: 'Pay app forms',
          change_order_forms: 'Change order request forms',
          primary_lien_waiver_forms: 'Primary lien waiver forms',
          vendor_lien_waiver_forms: 'Vendor lien waiver forms',
          change_order_log_forms: 'Change order log forms',
          no_forms_selected: 'No forms selected',
          same_gc: 'Same GC',
          archived: 'Archived',
          select_forms: 'Select forms',
          upload_new: 'Upload new',
          use_default_forms: 'Use default forms',
          standard_forms: "Your company's standard forms",
          copy_forms: 'Copy from another project',
          added_lien_waiver_forms: 'Added lien waiver forms',
          added_cor_forms: 'Added COR forms',
          forms_uploaded: "Thanks! We'll get to work on processing your forms.",
          added_forms: 'Added project forms',
          include_cor_toggle_disabled: 'You must select a change order log template',
          include_cor_log_pay_apps: 'Attach change order log to every pay app',
          include_co_log_toggled_on: 'The change order log will be attached to pay apps',
          include_co_log_toggled_off: 'The change order log will not be attached to pay apps',
          added_forms_success: {
            PAY_APP: 'Added pay app forms',
            PRIMARY_LIEN_WAIVER: 'Added primary lien waiver forms',
            VENDOR_LIEN_WAIVER: 'Added vendor lien waiver forms',
            CHANGE_ORDER_REQUEST: 'Added change order request forms',
            CHANGE_ORDER_LOG: 'Added change order log forms',
          },
          cleared_forms: 'Cleared project forms',
          cleared_pay_app_form: 'Removed template from pay app package',
          removed_form: 'Removed form template',
          confirm_remove_template_title: 'Are you sure you want to remove this template?',
          confirm_remove_template_details: 'This change will not affect signed pay apps',
          confirm_clear_title: 'Are you sure you want to clear these forms?',
          reuse_forms: 'Reuse forms from another project',
          processing_forms:
            "We're processing the forms you uploaded. This may take up to 5 business days.",
          processing_additional_forms:
            "We're processing the additional forms you uploaded. This may take up to 5 business days.",
          add_new_forms: 'Add new forms',
          forms_not_used: 'Forms not yet on Siteline',
          copy_forms_from_project: 'Copy forms from another project',
          select_project: "Select which project's forms to reuse",
          empty_state_copy_instructions: 'Select a project above to preview forms',
          cancel: 'Cancel',
          conditional_forms:
            'Forms are listed in the order they appear, but the exact subset of forms included may vary by pay app',
          no_projects: {
            change_order_request:
              'None of your projects are set up with a change order template yet',
            change_order_log:
              'None of your projects are set up with a change order log template yet',
            pay_app: 'None of your projects are set up with a pay app template yet',
            primary_lien_waivers: 'None of your projects are set up with lien waiver templates yet',
            vendor_lien_waivers:
              'None of your projects are set up with vendor lien waiver templates yet',
            multiple_form_types: 'None of your projects are set up with templates to copy from',
            loading: 'Loading projects...',
          },
        },
        confirm: {
          title: 'Are you sure you want to make this change?',
          details: 'This will not affect pay apps that have already been marked as paid',
          quick_details:
            'This will not affect the quick bill if it has already been marked as paid',
          reminders_title: 'Update due date?',
          reminders_details:
            'This will affect pay apps created in the future. The due dates for any existing pay apps will not be changed, but can be edited from the project page.',
        },
      },
      form_signing: {
        actions: 'Actions',
      },
      pay_app: {
        not_found: 'The requested pay app no longer exists.',
        pay_app_revised_count: 'Pay app revised {{count}} time',
        pay_app_revised_count_other: 'Pay app revised {{count}} times',
        show_history: 'Show revision history',
        hide_history: 'Hide revision history',
        revision_date: '{{date}} at {{time}}',
        revision_row: 'Reverted to draft {{date}} by {{name}}',
        you: 'you',
        today: 'today',
        on_date: 'on {{date}}',
        view_invoice_permission: 'You do not have permission to view this pay app',
        project_not_found: 'Unable to find project',
        forms_not_ready:
          "We're still processing forms for this project. We'll let you know when they're ready.",
        submit_permission: "You don't have permission to submit pay apps",
        submit_for_review: 'Submit for review',
        permission_contract_inactive:
          'This pay app cannot be edited because the project is not active',
        permission_draft: 'This pay app cannot be edited because it is no longer in draft',
        permission_retention_only:
          'This is a retention-only pay app. Progress on the invoice cannot be edited.',
        create_or_update: {
          create_title: 'Create pay app',
          create_title_with_number: 'Create Pay App #{{number}}',
          edit_title: 'Update pay app dates',
          create_subtitle: '{{- projectName}} / {{- projectNumber}}',
          type: 'Type',
          progress: 'Progress',
          retention: 'Retention-only',
          pay_period: 'Pay period*',
          due_to_gc: 'Due to GC*',
          duplicate_error: 'These dates overlap with the pay period of Pay App #{{payAppNumber}}.',
          inverted_dates_error: "The pay app's end date must be after the start date",
          due_date_error: "The pay app's due date cannot be before the start date",
          concurrent_pay_app:
            'Pay App #{{payAppNumber}} is already billing in this period, so a concurrent pay app must have the same period',
          overlapping_pay_app:
            'This overlaps partially with Pay App #{{payAppNumber}} ({{- billingStart}} – {{- billingEnd}}). Please match the dates to Pay App #{{payAppNumber}} or choose a different period.',
          conflicting_progress_pay_app:
            'There is already a progress pay app for this period (Pay App #{{payAppNumber}}). Please choose another period or edit that pay app.',
          conflicting_retention_pay_app:
            'There is already a retention pay app for this period (Pay App #{{payAppNumber}}). Please choose another period or edit that pay app.',
          match_dates: 'Match dates',
          updated: 'Pay app was successfully updated',
        },
        create_or_opt_out: {
          create_pay_app: 'Add new pay app',
          pay_app_not_created: 'Pay app not created',
          not_billing: 'Not billing this month',
          opt_in_billing: 'Opt in to billing',
          opt_out_billing: 'Opt out of billing',
          opt_out_month_billing: 'Opt out of {{month}} billing',
          create_unconditional: 'Create unconditional lien waiver',
          view_unconditional: 'View unconditional lien waiver',
          opt_out_dialog: {
            title: 'Opt out of billing?',
            body: 'This will erase any changes to invoice, backup, or lien waivers you may have made in this pay app',
            body_no_pay_app:
              'This will opt you out of billing for {{month}} and you won’t get any late reminders. You can always opt back in to billing.',
            opt_out: 'Opt out',
            generate_unconditional: 'Generate unconditional lien waiver',
            opt_out_on_integration: 'Opt out on {{integrationName}}. You cannot undo this action.',
            reason: 'Reason',
            optional: 'Optional',
          },
        },
        mark_as_submitted: {
          button: 'Mark as {{action}}',
          mark_as_submitted: 'Mark as submitted',
          mark_as_synced: 'Mark as synced to {{- integration}}',
          mark_as_exported: 'Mark as exported to {{- integration}}',
          synced_action: 'synced',
          submitted_action: 'submitted',
          dialog_title: 'Mark this pay app as {{action}}',
          editing_title: 'Edit {{action}} date',
          dialog_description:
            'This will update the pay app status. Make sure the billing values are correct before confirming.',
          submitted_on: '{{action}} on',
          marked_synced_to_success: 'Marked as synced to {{integration}}',
        },
        header: {
          title: 'Pay App #{{num}}',
          retention_only: 'Retention-only',
          retention_only_title: 'Pay App #{{num}} (Retention-only)',
          quick_bill: 'Quick bill',
          status: 'Status',
          payment_due: 'Payment due',
          amount_paid: 'Amount paid',
          retention_held: 'Retention held to date',
          revert_to_draft: 'Revert to draft',
          edit_sov: 'Edit SOV',
          add_change_order: 'Add change order',
          import_from: 'Import from {{- integration}}',
          import_from_other: 'Import from...',
          full_sov: 'Full SOV',
          change_orders: 'Change orders',
          change_order_permission: "You don't have permission to edit change orders",
          adjust_retention: 'Adjust retention',
          import_worksheet: 'Import worksheet',
        },
        g702_values: {
          title: 'Totals for pay app #{{payAppNumber}}',
          originalContractSum: 'Original contract sum',
          netChangeByChangeOrders: 'Net change by change orders',
          contractSumToDate: 'Contract sum to date',
          totalCompletedToDate: 'Total completed & stored to date',
          totalCompletedToDateTnM: 'Total billed to date',
          retainage: 'Retainage',
          progressRetentionPercent: '{{percent}}% of completed work',
          materialsRetentionPercent: '{{percent}}% of stored material',
          totalRetention: 'Total retainage',
          totalLessRetainage: 'Total earned less retainage',
          previousPayments: 'Less previous certificates for payment',
          currentPaymentDue: 'Current payment due',
          balanceToFinishWithRetention: 'Balance to finish, incl. retainage',
          tooltip: 'Show breakdown',
        },
        cost_and_projection: {
          over_under_tooltip:
            "Over or under billing is calculated by comparing the project's costs to its total billed. For more details, visit the <helpLink>help center</helpLink>.",
          button: {
            under: 'Under',
            over: 'Over',
            unknown: 'Over/Under',
            tooltip: 'Show costs & projections',
          },
          dropdown: {
            title: 'Costs & Projections',
            updated: 'Updated {{date}}',
            updated_manually: 'Updated manually {{date}}',
            under: 'Underbilled by',
            over: 'Overbilled by',
            unknown: 'Over/Underbilled by',
            cost_to_date: 'Cost to date',
            additional_committed_cost: 'Additional committed cost',
            total_estimated_cost: 'Current budget',
            earned_revenue: 'Earned revenue',
            contract_total_billed: 'Total billed',
            forecasted: 'Forecasted this month',
            adjust_integration: 'Update from {{integrationName}}',
            adjust_manually: 'Adjust manually',
            clear: 'Clear costs',
            edit_forecast: 'Edit forecast',
          },
        },
        update_contract_cost: {
          title: 'Adjust costs',
          cost_to_date: 'Cost to date',
          additional_committed_cost: 'Additional committed cost',
          additional_committed_cost_tooltip:
            'Committed costs are not usually used to calculate over/under billing, but you can add them here to model your future cost load.',
          total_estimated_cost: 'Current budget',
          submit: 'Save costs',
        },
        navigation: {
          payApps: 'Pay Apps',
          setup: 'Setup',
          settings: 'Settings',
          changeOrderLog: 'Change Order Log',
          forecast: 'Forecast',
          sov: 'SOV',
          overview: 'Overview',
          invoice: 'Invoice',
          retention: 'Retention',
          backup: 'Backup',
          lienWaivers: 'Vendor lien waivers',
          forms: 'Forms',
          items_complete: '{{filled}} of {{count}} completed',
        },
        quick_bill_tabs: {
          overview: 'Bill',
          settings: 'Settings',
        },
        invoice: {
          no_change_order_permission: "You don't have permission to edit change orders",
          units_contracted_unit_name: 'Units contracted ({{- unitName}})',
          invoice: 'Invoice',
          pay_app_number: 'Pay app #{{number}}',
          headers: {
            number: 'Number',
            name: 'Name',
            code: 'Cost code (optional)',
            cost_code: 'Cost code',
            description: 'Description',
            scheduled_value: 'Scheduled Value',
            retention_percent: 'Retention %',
            percent_complete: '% Complete',
            previously_billed: 'Previously billed',
            work_completed: 'Prev. work completed',
            stored_materials: 'Stored materials',
            stored_materials_unit: 'Stored materials (QTY)',
            stored_materials_amount: 'Stored materials ($)',
            pre_siteline_billing: 'Pre-Siteline billing',
            progress_this_month: 'Progress this month',
            completed_this_month: 'Compl. this month',
            total_completed: 'Total completed',
            total_billed: 'Total billed',
            balance: 'Balance to finish',
            HELD_CURRENT_AMOUNT: 'Ret. this month ($)',
            HELD_CURRENT_PERCENT: 'Ret. this month (%)',
            HELD_TO_DATE_PERCENT: 'Total ret. (%)',
            HELD_TO_DATE_AMOUNT: 'Total retention held',
            RELEASED_CURRENT_AMOUNT: 'Ret. billed this month',
            show_retention_to_date: 'Total retention held ($)',
            show_retention_current: 'Retention held this month ($)',
            current_retention_percent: 'Retention held this month (%)',
            total_retention_percent: 'Total retention held (%)',
            show_retention_released: 'Retention billed this month ($)',
            units_contracted: 'Units contracted',
            total_units: 'Total units',
            unit_of_measure: 'Unit',
            unit_price: 'Unit price',
            previous_units_billed: 'Prev. billed',
            current_quantity: 'Current QTY',
            current_amount: 'Current amount',
            quantity_to_date: 'QTY to date',
            amount_to_date: 'Amount to date',
            billed_to_date_qty: 'Billed to date (QTY)',
            billed_to_date_amount: 'Billed to date ($)',
            units_to_finish: 'Units to finish',
            units_left_to_bill: 'Units left to bill',
            quantity: 'Quantity',
            amount: 'Amount (quantity x unit price)',
            TOTAL_POST_TAX: 'Total after tax ($)',
            TAX_AMOUNT: 'Tax amount ($)',
            TAX_RATE: 'Tax rate (%)',
          },
          altered_future_payapps: {
            title: 'Subsequent pay app(s) will be modified',
            details:
              'Changing this value will reset subsequent pay apps to draft and any line items that are now overbilled will be reset to $0.',
            submitted_details:
              'Changing this value will reset subsequent pay apps to draft and any line items that are now overbilled will be reset to $0. Your submitted pay apps will need to be resubmitted.',
          },
          reset_to_draft: {
            title: 'Revert to draft',
            reason: 'Reason',
            optional: 'Optional',
            body: 'This pay app has already been signed. Are you sure you want to modify this invoice? You will need to re-sign and submit the pay app.',
            body_signed_synced_erp:
              'This pay app has already been signed and synced to {{- integration}}. Are you sure you want to modify this invoice? You will need to sign, submit, and sync the pay app again.',
            body_sync:
              'This pay app has already been synced to {{- integration}}. Are you sure you want to modify this invoice? You will need to re-sync the pay app.',
            body_sync_erp_gc:
              'This pay app has already been synced to {{- integration1}} and {{- integration2}}. Are you sure you want to modify this invoice? You will need to re-sync the pay app.',
            body_pending:
              'This pay app has a pending sync to {{- integration}}. Are you sure you want to modify this invoice? The pending sync will be canceled and you will need to re-sync the pay app.',
            body_pending_erp_gc:
              'This pay app has pending syncs to {{- integration1}} and {{- integration2}}. Are you sure you want to modify this invoice? The pending sync will be canceled and you will need to re-sync the pay app.',
            body_pending_success:
              'This pay app has a pending sync to {{- pendingIntegration}} and has already been synced to {{- syncedIntegration}}. Are you sure you want to modify this invoice? The pending sync will be canceled and you will need to re-sync the pay app.',
            body_quick:
              'This quick bill has already been signed. Are you sure you want to modify this invoice? You will need to re-sign and submit the quick bill.',
            body_paid:
              'This pay app has already been marked as paid. Are you sure you want to modify this invoice? You will need to re-sign and submit the pay app.',
            body_paid_erp_pending:
              'This pay app has already been marked as paid and has a pending sync to {{- integration}}. Are you sure you want to modify this invoice? The pending sync will be canceled and the pay app will need to be signed, submitted and synced again.',
            body_paid_sync:
              'This pay app has already been marked as paid. Are you sure you want to modify this invoice? You will need to re-sync the pay app to {{- integration}}.',
            body_paid_synced_erp:
              'This pay app has already been marked as paid and synced to {{- integration}}. Are you sure you want to modify this invoice? You will need to re-sign and submit the pay app, and it will need to be re-synced.',
            body_paid_sync_erp_gc:
              'This pay app has already been marked as paid. Are you sure you want to modify this invoice? You will need to re-sync the pay app to {{- integration1}} and {{- integration2}}.',
            body_generic:
              'These forms have already been completed. Are you sure you want to revert to draft? You will need to re-sign any forms and submit again.',
            preserve_annotations: 'Preserve form field values',
            success: 'Pay app successfully reverted to draft',
            success_edit_invoice: 'Pay app reverted to draft. You can now edit the invoice.',
            success_quick: 'Quick bill reverted to draft. You can now edit the invoice.',
          },
          round_progress: {
            title: 'Round all line items to whole dollars?',
            body: 'This will round all values in completed this month and stored materials to the nearest whole dollar amount',
            button_text: 'Round all line items',
            snackbar_loading: 'Rounding values ...',
            snackbar_info: 'All line items are already rounded to the nearest whole dollar.',
            snackbar_success: '{{count}} line item rounded to the nearest whole dollar',
            snackbar_success_other: '{{count}} line items rounded to the nearest whole dollar',
          },
          notify_team: {
            button: 'Request review',
            send: 'Send request',
            snackbar: 'Your team member has been notified',
            snackbar_other: 'Your team members have been notified',
            default_subject: 'Review Pay App #{{payAppNumber}} for {{- projectName}}',
            modal_title: 'Request review from team',
            no_teammates: 'No teammates on this project',
            manage_users:
              'You can add teammates to this project in <settingsLink>Settings</settingsLink>',
            contact_support:
              'Contact your team administrator or <emailLink><strong>{{email}}</strong></emailLink> to add teammates',
            add_teammates_subject: 'Add project teammates',
            add_teammates_body:
              "Hello,\n\nI'd like to add teammates to {{- projectName}} on Siteline.",
            field_notify_team_title: 'Submitting to {{- companyName}} for review',
            field_notify_team_subtitle:
              'After you’ve submitted, {{- companyName}} will reach out if there are further updates needed',
          },
          unit_price_value: {
            adjust_quantity: 'Adjust quantity contracted',
            enter_contract_quantity: 'Enter the new contract quantity approved by the GC',
            description: 'Description',
            quantity: 'Quantity',
            amount: 'Amount',
            billed_to_date: 'Billed to date',
            units_to_finish: 'Units to finish',
            success: 'Updated contract quantity',
          },
          retention: {
            tooltip: 'The maximum allowed percentage is {{num}}%',
            update_retention: 'Adjust retention for line item',
            edit_retention: 'Edit pre-Siteline retention for line item',
            update_more_retention: 'Adjust retention on line item',
            progress_override: 'Retention has been manually overridden for this line item',
            progress_past_override:
              'Retention was manually overridden for this line item on Pay App #{{payAppNumber}}',
            progress_override_group:
              'Retention has been manually overridden for one or more line items in this group',
            progress_past_override_group:
              'Retention was manually overridden for this invoice group on Pay App #{{payAppNumber}}',
            progress_released: 'Retention is being billed on this line item',
            progress_released_group: 'Retention is being billed in this invoice group',
            pay_app_override: 'Retention has been manually overridden for this pay app',
            pay_app_past_override: 'Retention was manually overridden on Pay App #{{payAppNumber}}',
            pay_app_pre_siteline_override:
              'Pre-Siteline retention was manually overridden on the SOV',
            pay_app_released: 'Retention is being billed on this pay app',
          },
          stored_materials_dialog: {
            title: 'Adjust stored materials',
            subtitle: 'Line item #{{- number}}',
            stored_through_previous: 'Stored through previous pay app',
            move_to_completed: 'Move to work completed',
            move_to_completed_tooltip: 'This amount will be included in Column E on your G703',
            additional_stored: 'Additional stored this month',
            stored_this_month: 'Materials presently stored',
            quantity_title: 'Qty',
            amount_title: 'x {{- unitPrice}} per unit',
            max_error: 'This amount cannot exceed {{- max}}',
            max_helper: 'Max. {{- max}}',
            updated_materials: 'Updated stored materials',
          },
          actions_menu: {
            actions: 'Actions',
            export_data: 'Export data',
            round_line_items: 'Round line items',
          },
          export: {
            title: 'Export data',
            ready: 'File ready for download',
            error: 'There was an error generating your file. Please try again.',
            yesterday: 'yesterday',
            last_exported_recent: 'This invoice was exported to {{- integration}} {{- date}}',
            last_exported: 'This invoice was exported to {{- integration}} on {{- date}}',
            last_synced_recent: 'This invoice was synced to {{- integration}} {{- date}}',
            last_synced: 'This invoice was exported to {{- integration}} on {{- date}}',
            csv: {
              title: 'Download as .csv',
              body: 'Use in Excel or Google Sheets',
            },
            quickbooks_enterprise: {
              title: 'Export to QuickBooks Enterprise (.iif)',
              subtitle:
                'The QuickBooks export file is generating using the Job and Customer from your project settings, and the invoice Items and Accounts in your company QuickBooks settings',
              subtitle_no_integration:
                'The QuickBooks export file is generating using the invoice Items and Accounts in your company QuickBooks settings',
              body: 'Download an IIF file to import into QuickBooks Enterprise 2019 or later',
              bulk_export: 'Select pay apps below to import into QuickBooks Enterprise',
              bulk_info: 'Additional information',
              job_name: 'Name of job',
              job_subtitle:
                'This should exactly match the Job Name in QuickBooks for the customer entered above',
              customer_name: 'Name of customer',
              customer_subtitle: 'This should exactly match the Customer Name in QuickBooks',
              invoice_number: 'Invoice no.',
              use_non_default_items: 'Use non-default Items or Accounts',
              subaccount_subtitle:
                'If this is a subaccount, include parent accounts separated by a colon (e.g. Parent Account:Subaccount)',
              include_retention: 'Include retention separately in invoice',
              include_retention_subtitle:
                'An additional invoice item will be added to deduct the total amount of retention held',
              include_retention_subtitle_bulk:
                'An additional invoice item will be added to deduct the total amount of retention held. Some pay apps will always have retention included separately, e.g. if retention is overridden or tracked at the project level.',
              combine_as_single_item: 'Collapse billing into single lump sum item',
              all_line_items: 'Include each line item as a separate invoice item',
              combine_as_single_item_bulk:
                "Collapse each invoice's billing into a single lump sum item",
              all_line_items_bulk: "Include each invoice's line items as separate invoice items",
              single_item_description: 'Lump sum item description',
              missing_company_metadata:
                'To export invoices to QuickBooks, please configure default Item and Account values in your <settingsLink>company settings</settingsLink>. This only needs to be done once.',
            },
            foundation: {
              title: 'Export to Foundation Software (.csv)',
              body: 'Download a CSV file to import into Foundation Software',
              invoice_description: 'Pay App #{{payAppNumber}} - {{date}}',
              import_type: 'Import type',
              type_fsi_invoice: 'FSI Importer (A/R Invoice)',
              type_genie_aia: 'Genie import (A/R AIA Billings)',
              help: 'Import instructions',
              genie_template: 'Download Genie (.FGF)',
              export_to_foundation: 'Export to Foundation',
              edit_customer: 'Edit customer number',
              import_guide: 'Import guide for Foundation',
              bulk_fsi_import:
                'Select pay apps below to import A/R Invoices into Foundation using FSI Importer',
              save_integration_error:
                'There was an error saving the integration. Please try again.',
            },
            computer_ease: {
              title: 'Export to ComputerEase (.xml)',
              body: 'Download an XML file to import into ComputerEase',
              bulk_file_name: 'Bulk_ComputerEase_Invoices',
              customer_id: 'Customer ID',
              job_id: 'Job ID',
              invoice_date: 'Invoice date',
              due_date: 'Due date',
              ar_account: 'AR account',
              sales_account: 'Sales account',
              export_to_computer_ease: 'Export to ComputerEase',
              bulk_xml_import:
                'Select pay apps below to import invoices into ComputerEase via bulk invoice import',
            },
            bulk: {
              pay_app_number: 'Pay App #{{number}}',
              amount_billed: 'Amount billed',
              retention: 'Retention',
              pay_app: 'Pay App',
              customer: 'Customer No.',
              number: 'Number',
              project: 'Project',
              general_contractor: 'General contractor',
              num_pay_apps_selected: '{{count}} pay app selected',
              num_pay_apps_selected_zero: 'No pay apps selected',
              num_pay_apps_selected_other: '{{count}} pay apps selected',
              no_pay_apps: 'No pay apps to export in {{month}}',
              billing_month: 'Billing month',
              none: 'None',
              exclude_already_exported: 'Exclude already exported pay apps',
              sort_by: {
                number_asc: 'Sorting by project number (ascending)',
                number_desc: 'Sorting by project number (descending)',
                name_asc: 'Sorting by project name (ascending)',
                name_desc: 'Sorting by project name (descending)',
                gc_name_asc: 'Sorting by general contractor name (ascending)',
                gc_name_desc: 'Sorting by general contractor name (descending)',
                pay_app_number_asc: 'Sorting by pay app number (ascending)',
                pay_app_number_desc: 'Sorting by pay app number (descending)',
                customer_number_asc: 'Sorting by customer number (ascending)',
                customer_number_desc: 'Sorting by customer number (descending)',
                amount_billed_asc: 'Sorting by amount billed (ascending)',
                amount_billed_desc: 'Sorting by amount billed (descending)',
                retention_asc: 'Sorting by retention (ascending)',
                retention_desc: 'Sorting by retention (descending)',
              },
            },
          },
          search: 'Search line items',
          this_month: 'This month',
          all: 'All items',
          percent_complete_maximum: 'The maximum percent complete is {{num}}',
          percent_complete_minimum: 'The minimum percent complete is {{num}}',
          line_item_value_high_specific: 'Cannot bill for more than {{amount}}',
          line_item_value_low_specific: 'Cannot bill for less than {{amount}}',
          line_item_value_high: 'This amount is too high',
          line_item_value_low: 'This amount is too low',
          no_unit_price: 'Every line item on a unit price contract must have a unit price',
          category: 'Category',
          uncategorized: 'Uncategorized',
          total: 'Total',
          subtotal: 'Subtotal',
          add_line_item: 'Add line item',
          edit_line_item: 'Edit line item',
          add_line_item_subtitle:
            'New line items added to this T&M pay app will not carry over to the next pay app. If the new rate will be used again, consider editing or duplicating the rate table.',
          invoice_total: 'Invoice total',
          cor_total: 'Total change order request amount',
          total_items: '{{count}} items',
          total_items_1: '{{count}} item',
          created_line_item: 'Added invoice line item',
          updated_line_item: 'Updated invoice line item',
          deleted_line_item: 'Deleted invoice line item',
          history: 'History',
          zero_scheduled_value: 'Cannot bill on a line item with no contract value',
          zero_unit_price: 'Cannot bill on a line item when unit price is $0',
          confirm_billing: {
            line_item_overbilled: 'Line item overbilled',
            bill_over_amount:
              'Line item #{{- number}} is not supposed to bill over either {{units}} units or {{- amount}}',
            bill_over_amount_lump_sum:
              'Line item #{{- number}} has a total value of {{- amount}}. Do you want your billing to exceed this amount?',
            confirm_billing: 'Confirm billing',
            billing_positive:
              'Line item {{- number}} has a positive contract value. Are you sure you want to bill a negative amount?',
            billing_negative:
              'Line item {{- number}} has a negative contract value. Are you sure you want to bill a positive amount?',
            no_unit_price:
              'No unit price found for this line item. You may need to refresh and try again.',
            revise: 'Revise',
            override: 'Override',
          },
          validation: {
            billing_positive: 'Line item {{- number}} has a positive contract value',
            billing_negative: 'Line item {{- number}} has a negative contract value',
            bill_over_amount: 'Line item {{- number}} cannot bill over {{units}} units',
          },
          empty_state: {
            empty_month: 'No items billed this month',
            show_all_items: 'Show all items',
            no_match: 'No items match your search',
            clear_search: 'Clear search',
          },
          add_change_order: {
            add_change_order: 'Add change order',
            quick_add_change_order: 'Quick add change order',
            import_line_items: 'Import line items',
            add_manually: 'Add manually',
            enter_co_details: 'Enter CO details',
            add_dialog: {
              add_title: 'Add change order',
              edit_title: 'Edit change order',
              delete_title: 'Delete change order',
              delete_details:
                'Please confirm you want to permanently delete this change order from your SOV.',
              code: 'Line item #',
              name: 'Name',
              description: 'Description',
              amount: 'Scheduled value',
              units_contracted: 'Units contracted',
              unit_of_measure: 'Unit of measure',
              unit_price: 'Unit price',
              date: 'Approval date',
              retention: 'Retention %',
              date_range: 'Date must be within pay app billing period',
              group: 'Group',
              no_group: 'None',
              new_group: 'New group',
              group_code: 'Group code (optional)',
              group_name: 'Group name',
              tax_group: 'Tax group',
            },
            sync: {
              success: 'Imported change orders from {{integrationName}}',
              success_no_changes: 'No changes found to import from {{integrationName}}',
              loading: 'Importing from {{integrationName}} ...',
              import_change_orders_from: 'Import change orders from {{integrationName}}',
            },
            total_too_high_error:
              'The total value for the change order must be less than {{totalBilled}}, the total being billed for.',
            arrow_tooltip:
              "If your change order was approved in a different month, please add it to that month's pay app",
          },
          import_sov: {
            success: 'Imported SOV from {{integrationName}}',
            success_no_changes: 'No changes found to import from {{integrationName}}',
            loading: 'Importing SOV from {{integrationName}} ...',
            import_from: 'Import SOV from {{integrationName}}',
          },
          adjust_retention: {
            title: 'Adjust retention',
            subtitle_pay_app:
              'This will update retention for all line items. To adjust retention for individual line items, go to the retention column (the last column on the invoice).',
            subtitle_progress:
              'This will update retention for this line item only. To apply this change to all line items, click "Adjust retention" above the invoice.',
            line_item_code: 'Line Item #{{code}}',
            adjust_current_retention: 'Adjust current retention',
            adjust_subtitle_pay_app:
              'This will update the retention held on this pay app for all line items.',
            adjust_subtitle_progress:
              'This will update the retention held on this pay app for this line item only.',
            bill_for_retention: 'Bill for retention',
            bill_for_retention_subtitle:
              'The new retention percent will apply to past pay apps. This will bill for previously held retention, but <italic>not</italic> affect retention held on this or future pay apps.',
            reset_retention_billed: 'Reset retention billed',
            reset_billed_tooltip: 'This will clear the retention billed on this pay app',
            reset_retention_override: 'Reset override',
            reset_override_tooltip: 'This will clear the retention override on this pay app',
            reset_override_success: 'Retention override has been cleared',
            reset_billed_success: 'Retention billed has been cleared',
            bill_for_retention_disabled: 'There is no retention held prior to this pay app',
            bill_for_past_retention: 'Bill for past retention',
            bill_for_past_retention_subtitle: 'Bill for retention held on past pay apps',
            adjust_current_retention_subtitle: 'Set retention % held going forward',
            new_retention_percent: 'New retention percent',
            new_retention_tooltip:
              'All line items will be bumped down to this percent, if previously higher',
            held_percent: {
              title: 'Retention held',
              tooltip: 'Amount held by GC every pay app',
              previous: 'Prev. {{percent}}%',
              bill_all: 'Bill for all',
            },
            held_current_amount: {
              title: 'Retention held this pay app',
            },
            held_to_date_amount: {
              title: 'Retention held to date',
              tooltip: 'Total retention held by GC, including this pay app',
              releasing_tooltip:
                'Total retention held by GC, including {{- amount}} held this pay app',
              save_tooltip: 'This value will no longer be tied to retention held',
            },
            released_amount: {
              title: 'Retention billing this month',
              tooltip: 'Amount billed in this pay app',
            },
            override: 'Override',
            help_center: 'Read our Help Center guide',
          },
        },
        sworn_statements: {
          vendors_for_sworn_statement: 'Vendors for sworn statement',
          update_from_erp: 'Update from {{- erpName}}',
          add_vendors: 'Add vendors',
          setup_vendors:
            "To complete this pay app's sworn statement, click 'Update from {{- erpName}}' or add vendors to this project in the <vendorsLink>Vendors module</vendorsLink>",
          setup_project_vendors:
            "To complete this pay app's sworn statement, click 'Update from {{- erpName}}' or manually add vendors from the dropdown options above",
          no_vendors: "No vendors were added to this pay app's sworn statement",
          permissionless_no_vendors: "No vendors have been added to this pay app's sworn statement",
          add_vendors_module:
            'Add new vendors to this project in the <vendorsLink>Vendors module</vendorsLink>',
          select_vendors: 'Select vendors',
          vendor: 'Vendor',
          open_commitments: 'Commitments',
          count_vendors: '{{- count}} vendor',
          count_vendors_other: '{{- count}} vendors',
          paid_to_date: 'Paid to date',
          outstanding_invoice: 'Outstanding invoices',
          adjust_amounts: 'Adjust amounts',
          remove_from_sworn_statement: 'Remove from sworn statement',
          are_you_sure_remove:
            'Are you sure you want to remove this vendor from the sworn statement?',
          are_you_sure_remove_details:
            'The vendor will be removed from the sworn statement on all draft and future pay apps',
          adjust_amounts_on_sworn_statement: 'Adjust amounts on sworn statement',
          open_commitment_total: 'Commitment total',
          add_new_commitment: 'Add new commitment',
          edit_commitment: 'Edit commitment',
          mark_as_closed: 'Mark as closed',
          add_open_commitment: 'Add open commitment',
          edit_open_commitment: 'Edit open commitment',
          open_commitments_label: 'Commitments ({{- count}})',
          open_commitments_label_zero: 'Commitments',
          vendor_name_added: '{{- name}} (Added)',
          type: 'Type',
          number: '#',
          date: 'Date',
          amount: 'Amount',
          closed: 'Closed',
          added_vendors_success: 'Added vendor to sworn statement',
          added_vendors_success_other: 'Added {{- count}} vendors to sworn statement',
          vendor_removed: 'Removed vendor from sworn statement',
          updated_vendor_success: 'Updated vendor on sworn statement',
          hidden_commitments:
            "{{- count}} commitment is not included in this pay app's sworn statement because its date is in the future",
          hidden_commitments_other:
            "{{- count}} commitments are not included in this pay app's sworn statement because their date is in the future",
          updated_manually: 'Updated manually {{date}}',
          updated: 'Updated {{date}}',
          invoices_through_date: 'Updated with invoices through {{- date}}',
          default_amounts:
            "Click 'Update from {{- erpName}}' to pull the most recent outstanding and paid totals",
          commitment_type_labels: {
            PURCHASE_ORDER: 'Purchase order',
            SUB_CONTRACT: 'Subcontract',
          },
          integration_dialog: {
            vendors_for_sworn_statement: 'Vendors for sworn statement',
            update_amounts_from_integration:
              'Update outstanding and paid amounts from {{- integrationName}}',
            invoices_through: 'Invoices through',
            finding_invoices:
              'Finding invoices in {{- integrationName}}. This may take a moment...',
            other_vendors:
              'We found {{count}} vendor from {{- integrationName}} that is not on this project',
            other_vendors_other:
              'We found {{count}} vendors from {{- integrationName}} that are not on this project',
            no_invoices: 'No invoices found for this period',
            invoices_from_company: 'Invoices from {{- companyName}}',
            add_integration_vendors_to_siteline: 'Add {{- integrationName}} vendors to project',
            add_or_link:
              'Select vendors to add to this project. If there is already a matching vendor in Siteline, search by name in the last column to link them together. Otherwise, a new vendor will be created in Siteline.',
            add_and_go_back: 'Add and go back',
            invoices_from_erp: 'Invoices from {{- integrationName}}',
            vendor: 'Vendor',
            selected_invoices: 'Selected invoices',
            paid_to_date: 'Paid to date',
            outstanding_invoices: 'Outstanding invoices',
            invoice_date: 'Invoice date',
            code_description: 'Code / description',
            invoice_amount: 'Invoice amount',
            num_selected_invoices: '{{numSelected}} of {{numTotal}}',
            add_vendors: 'Add vendors',
            updated_sworn_statement: 'Updated {{count}} vendor on the sworn statement',
            updated_sworn_statement_other: 'Updated {{count}} vendors on the sworn statement',
          },
        },
        attachments: {
          image: 'Image',
          not_billing_tnm: 'You are not billing for one or more of these line items this month',
          attachment: 'Attachment',
          all: 'Attachments',
          edit: 'Edit attachment',
          edit_other: 'Edit attachments',
          view: 'View attachment',
          view_request: 'View request',
          awaiting_signature: 'Awaiting vendor signature',
          view_other: 'View attachments',
          added_date: 'Added {{- date}}',
          save: 'Save attachment',
          download: 'Download attachment',
          download_other: 'Download attachments',
          prepare_download: 'Downloading attachments...',
          num: '{{count}} attachment',
          num_other: '{{count}} attachments',
          not_billing: 'You are not billing for this line item this month',
          delete_attachment: 'Delete attachment',
          delete_attachment_other: 'Delete attachments',
          check_recurring: 'Auto-add to future pay apps',
          recurring_subtitle: 'Recurring attachment',
          email_subtitle: 'Attached separately',
          disabled_recurring_tooltip: 'Un-link the line item above to enable recurring backup',
          search_rate_item_placeholder:
            'Search for an invoice line item to link this attachment to',
          delete_recurring: {
            title: 'Remove recurring backup',
            single: 'This pay app only',
            all: 'This pay app and all going forward',
          },
          delete: {
            title: 'Delete this attachment?',
            title_other: 'Delete attachments?',
            body: 'Are you sure you want to delete this attachment? You will not be able to recover the file later.',
            body_other:
              'Are you sure you want to delete all attachments? You will not be able to recover these files later. You can also click the pencil to remove individual attachments.',
          },
          attach: 'Attach documentation to {{- name}}',
          add: {
            title: 'Attach documentation',
            attach_backup_title: 'Upload backup',
            submit_backup: 'Upload',
            edit_backup_title: 'Edit backup',
            submit_edited_backup: 'Save',
            display_link_line_item: 'Link to a line item',
            assign_to_line_item: 'Assign to invoice line item (optional)',
            info: 'This line item has Stored Materials or Change Orders associated with it. Your file will be added to that section when you attach it.',
            add_description_link: 'Add description',
            description: 'Add description (optional)',
            drag_drop_file: 'Drag and drop file or <bold>upload</bold>',
            drag_drop_files: 'Drag and drop files or <bold>upload</bold>',
            types: 'pdf, docx, jpg, png, or xlsx',
            invalid: '{{- name}} is not a valid attachment. Please use either {{types}}.',
            added: '{{- lineItem}} (Added)',
            truncated:
              'File names must be 100 characters or fewer. Your file name has been shortened.',
            submit_as_attachment: 'Submit as separate attachment',
            submitted_as_attachment: 'Submitted as separate attachment',
            internal_only_attachment: 'Set as internal-only attachment',
            internal_only: 'Internal-only attachments are not included in the pay app package',
            an_excel: 'an Excel',
            a_suffix: 'a .{{suffix}}',
            attached_type:
              'This backup will be attached to the pay app submission email as {{type}} file. It will not be included in the packaged pay app PDF.',
            attached:
              'This backup will be attached to the pay app submission email directly. It will not be included in the packaged pay app PDF.',
            is_internal_only:
              'This backup is internal-only and will be not be included in the generated pay app package and submission emails',
            recurring: 'This attachment is automatically added as backup to all future pay apps',
          },
        },
        backup: {
          no_stored_materials:
            'There are no stored materials being billed for this pay app. You can bill for stored materials on the invoice and it will show up here.',
          no_change_orders:
            'There are no change orders being billed for this pay app. You can add change orders on the invoice and it will show up here.',
          no_legal_requirement_added: 'There are no compliance documents added to this pay app',
          no_lien_waivers_added: 'There are no vendor lien waivers added to this pay app',
          request_change_order: 'There are no change orders in your schedule of values',
          no_additional: 'There is no additional backup added to this pay app',
          add_additional:
            'Add any additional backup to attach to the pay app, like certified payroll, field photos, safety logs, or anything else requested by the client',
          legal_requirement_instructions:
            'Add compliance requirement items to be sent with the pay app',
          no_legal_documents: 'No compliance documents have been uploaded',
          no_legal_requirements: 'No compliance requirements have been set up yet',
          vendor_lien_waivers_instructions: 'Add vendor lien waivers to be sent with the pay app',
          request_lien_waivers: 'Request lien waivers',
          no_vendor_lien_waivers: 'No vendor lien waivers have been added',
          back_to_invoice: 'Back to invoice',
          add_change_order: 'Add change order',
          add_new_backup: 'Upload backup',
          add_legal_requirement: 'Attach compliance',
          setup_legal_requirement: 'Set up compliance',
          add_documents: 'Add documents',
          goto_legal_requirement: 'Go to compliance',
          dont_include: 'Remove from backup',
          attach_lien_waivers: 'Attach lien waivers',
          subtier_to_vendor: 'subtier to {{- vendorName}}',
          date_added: 'Date added',
          sidebar: {
            STORED_MATERIALS: 'Stored materials',
            CHANGE_ORDERS: 'Change orders',
            ADDITIONAL_BACKUP: 'Additional backup',
            LEGAL_DOCUMENT: 'Compliance',
            VENDOR_LIEN_WAIVERS: 'Vendor lien waivers',
            TIME_AND_MATERIALS_BACKUP: 'Backup',
          },
          panels: {
            lien_waivers: 'Vendor lien waivers',
            unconditional_lien_waivers: 'Unconditional lower-tier lien waivers',
            conditional_lien_waivers: 'Conditional lower-tier lien waivers',
            stored_materials: 'Stored materials',
            change_orders: 'Change orders',
            additional: 'Additional backup',
            legal_requirement: 'Compliance',
            vendor_lien_waivers: 'Vendor lien waivers',
            backup: 'Backup',
          },
          request_legal_requirement: {
            subject: 'Request compliance requirement setup',
            body: 'Please list all compliance requirement items for this project (i.e. COI, certified payroll, safety statement, etc.) that you would like to track on Siteline.\n\nProject: {{project}}',
          },
          add_legal_requirement_dialog: {
            title: 'Add compliance document',
            name: 'Name',
            time_period: 'Time period',
            select: 'Select...',
            add_another: 'Add another',
            errors: {
              no_documents: 'No documents were added',
            },
          },
          add_lien_waivers_dialog: {
            title: 'Add vendor lien waiver',
            vendor: 'Vendor',
            date: 'Date',
            type: 'Type',
            select: 'Select...',
            add_another: 'Add another',
            subtier_to_vendor: 'subtier to {{- vendorName}}',
            unsigned_alert:
              'Incomplete lien waivers will not be included in the pay app package until they are signed or uploaded',
            lien_waiver_type: {
              UNCONDITIONAL_PROGRESS_PAYMENT: 'Unconditional Progress',
              UNCONDITIONAL_FINAL_PAYMENT: 'Unconditional Final',
              CONDITIONAL_PROGRESS_PAYMENT: 'Conditional Progress',
              CONDITIONAL_FINAL_PAYMENT: 'Conditional Final',
            },
            errors: {
              no_waivers: 'No lien waivers were added',
            },
          },
        },
        sign: {
          title: 'Review & sign',
          start: 'Get started',
          next_field: 'Next field',
          complete: 'Complete forms',
          clear_forms: 'Clear forms',
          reset_forms: 'Reset forms',
          reset_pay_app: 'Reset pay app',
          sign_notary: 'Sign all (non-notary)',
          prepare_for_notary: 'Prepare for notary',
          prepare_for_witness: 'Prepare for witness',
          prepare_for_signature: 'Prepare for signature',
          notary_required: 'Notary required to submit',
          witness_required: 'Witness signature required to submit',
          wet_required: 'Wet signature required to submit',
          switch_to_unconditional_progress: 'Switch to progress lien waiver',
          switch_to_unconditional_final: 'Switch to final lien waiver',
          notarized: 'Notarized',
          signed: 'Signed',
          replace: 'Replace',
          attachment_stored_materials: 'Stored Materials',
          attachment_change_orders: 'Change Orders',
          attachment_additional: 'Additional Backup',
          conditional_lien_waiver: 'Conditional Lien Waiver',
          unconditional_lien_waiver: 'Unconditional Lien Waiver',
          lower_tier_lien_waiver: 'Lower-Tier Lien Waiver',
          signing: 'Completing...',
          adopt_and_sign: 'Adopt and Sign',
          type_in_signature: 'Type in signature',
          full_name: 'Full Name',
          preview: 'Preview',
          upload_image: 'Upload image',
          upload_signature: 'Upload an image of your signature',
          upload_type: 'png with transparent background',
          upload_error: '{{- name}} is not a valid attachment. Please upload a png.',
          no_upload: 'Please upload a valid signature.',
          change_style: 'Change style',
          choose_style: 'Choose signature style',
          select_style: 'Select style',
          type_tab: 'Type',
          upload_tab: 'Upload',
          white_background: 'Seeing a white background?',
          create_png: 'Create a transparent png',
          no_name: 'Type in name to preview the signature',
          forms_not_ready: "We're still processing forms for this pay app",
          legal_note:
            'By clicking Adopt and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents on the Siteline platform, including legally binding contracts — just the same as a pen-and-paper signature or initial.',
          signing_error:
            'There was an error completing your pay app. Please make sure all required fields are filled out and try again.',
          saving: 'Saving . . .',
          saved: 'Saved',
          no_changes: 'There are no form changes to save',
          no_changes_signature: 'Signatures cannot be saved until forms are completed',
          send_to_signer: 'Send to signer',
          navigate_away_warning:
            'You have not completed signing your forms, are you sure you want to leave?',
          navigate_away_unsigned_warning:
            'Your forms have not finished saving, are you sure you want to leave?',
          physical_signature_dialog: {
            prepare_for_notary: 'Prepare for notary',
            prepare_for_signature: 'Prepare for signature',
            print_or_download_lien_waiver_notary:
              'Print or download your lien waiver to have it notarized',
            print_or_download_lien_waiver_signature:
              'Print or download your lien waiver to have it signed',
            print_or_download_pay_app_notary: 'Print or download your pay app to have it notarized',
            print_or_download_pay_app_signature: 'Print or download your pay app to have it signed',
            download_pay_app: 'Download pay app',
            download_lien_waiver: 'Download lien waiver',
            print_pay_app: 'Print pay app',
            print_lien_waiver: 'Print lien waiver',
            skip_for_now: 'Skip for now',
          },
          send_to_signer_dialog: {
            send: 'Send',
            snackbar: 'Your team member has been notified',
            snackbar_other: 'Your team members have been notified',
            default_subject: 'Ready to sign: Pay App #{{payAppNumber}} for {{- projectName}}',
            dialog_title: 'Forms ready for signature',
            no_teammates: 'No teammates on this project',
            manage_users:
              'You can add teammates to this project in <settingsLink>Settings</settingsLink>',
            contact_support:
              'Contact your team administrator or <emailLink><strong>{{email}}</strong></emailLink> to add teammates',
            add_teammates_subject: 'Add project teammates',
            add_teammates_body:
              "Hello,\n\nI'd like to add teammates to {{- projectName}} on Siteline.",
          },
        },
        submit: {
          request_review_dropdown: 'Share for review',
          generate_forms: 'Generate forms',
          request_review: 'Review internally',
          last_synced_today: 'Last synced at {{- time}}',
          last_synced_yesterday: 'Last synced yesterday at {{- time}}',
          last_synced_date: 'Last synced on {{- date}}',
          marked_synced_today: 'Marked as synced at {{- time}}',
          marked_synced_yesterday: 'Marked as synced yesterday at {{- time}}',
          marked_synced_date: 'Marked as synced on {{- date}}',
          request_review_consolidated_dropdown: 'Request internal review',
          review: 'Review forms',
          submit_draft: 'Submit draft to GC',
          submit_backup: 'Submit backup',
          view_forms: 'View forms',
          view_unnotarized_forms: 'View unnotarized forms',
          view_notarized_forms: 'View notarized forms',
          view_unconditional_lien_waiver: 'View unconditional lien waiver',
          sign_unconditional_lien_waiver: 'Sign unconditional lien waiver',
          download_backup: 'Download backup',
          submit_pay_app: 'Submit pay app',
          archived_project_warning: 'Archived projects cannot be modified',
          set_up_forms: 'Set up forms',
          revert_to_draft: 'Revert to draft',
          no_permission: {
            sign_and_submit:
              "You don't have permission to sign or submit pay apps. Please contact your team admin with any questions.",
            sign: "You don't have permission to sign pay app forms. Please contact your team admin with any questions.",
            sign_short: 'No signing permission',
            submit:
              "You don't have permission to submit pay apps. Please contact your team admin with any questions.",
            sync_all:
              "You don't have permission to sync pay app documents. Please contact your team admin with any questions.",
            sync_invoice:
              "You don't have permission to sync pay app invoices. Please contact your team admin with any questions.",
            sync_lien_waivers:
              "You don't have permission to sync lien waivers. Please contact your team admin with any questions.",
          },
          banner: {
            submitted: 'Submitted',
            paid_amount: 'Paid {{amount}}',
            notarizing: 'Notarizing unconditional lien waiver',
            synced: 'Synced',
            signed: 'Signed',
            view_forms: 'View forms',
            open_balance: 'Open balance of {{- amount}}',
            sync_failed: 'Last sync failed',
            sync_pending: 'Automatic sync pending',
            sync_pending_info:
              "We'll sync this pay app for you when the GC opens the Textura draw. You'll get an email when the sync is complete.",
            queued_in_hh2: 'Invoice queued in Sage',
            queued_in_hh2_info:
              "{{- entityDescription }} has been queued but not yet created in Sage. We'll monitor it in the background and email you when the invoice is ready.",
            read_sync_info: 'Automatically synced from Textura to match GC changes.',
            sign_on_integration: 'Sign on {{integrationName}}',
            try_again: 'Try again or email {{email}} if the problem persists.',
          },
          metrics: {
            billed_to_date: 'Billed to date',
            of_total_contract: 'of total contract',
            left_to_bill: 'Left to bill',
            contract_sum_to_date: 'Contract sum to date',
          },
          invoice: {
            title: 'Invoice',
            start_billing: 'Start billing',
            not_started: 'Not started',
            payment_due: 'Payment due',
            due: 'Due',
            retention_held: 'Retention held',
            completed_this_month: 'Completed this month',
            stored_materials: 'Stored materials',
            stored_materials_manual: 'Newly stored materials',
            taxes_this_month: 'Taxes this month',
          },
          retention: {
            title: 'Retention',
            billing_this_month: 'Billing this month',
            held_to_date: 'Held to date',
            bill_partial: 'Bill for partial',
            bill_by_line_item: 'Bill by line item',
            bill_all: 'Bill for all',
            bill_retention: 'Bill for retention',
            billed_success: 'Billed for all retention',
          },
          lien_waivers: {
            title: 'Vendor lien waivers',
            request_lien_waivers: 'Request lien waivers',
            none_requested: 'None requested',
            not_required: 'Not required',
            conditional: 'Conditional',
            unconditional: 'Unconditional',
            complete: 'Complete',
            incomplete: 'Incomplete',
            completed_of_total: '{{completed}} of {{total}}',
          },
          backup: {
            title: 'Backup',
            attach_backup: 'Attach backup',
            no_attachments: 'No attachments',
            stored_materials: 'Stored materials',
            change_orders: 'Change orders',
            additional_backup: 'Additional backup',
            legal_requirement: 'Compliance item',
            legal_requirement_other: 'Compliance items',
            vendor_lien_waivers: 'Vendor lien waiver',
            vendor_lien_waivers_other: 'Vendor lien waivers',
            completed_of_total: '{{completed}} of {{total}}',
          },
          predicted_payment: {
            title: 'Predicted payment date',
            optional: 'Optional',
            date_updated: 'Updated predicted payment date',
            date_removed: 'Removed predicted payment date',
            select_another: 'Select another date',
            use_suggested_label: 'Use suggested date: {{- date}}',
            estimation_method_tooltip: {
              PROJECT_AVERAGE_PAYMENTS:
                'This suggested date is based on your average days to paid for this project',
              PROJECT_AVERAGE_PREDICTED_PAYMENTS:
                'This suggested date is based on the average predicted days to paid for pay apps on this project',
              GC_AVERAGE_SUB:
                'This suggested date is based on the average days to paid for this GC across your projects',
              GC_AVERAGE_ALL:
                'This suggested date is based on days to paid for this GC across all companies using Siteline',
              CONTRACT_PAYMENT_TERMS:
                "This suggested date is based on the contract's {{paymentTerms}}-day payment terms",
              CONTRACT_NET_PAYMENT_TERMS:
                "This suggested date is based on the contract's net-{{paymentTerms}} payment terms",
              CONTRACT_EXPECTED_PAYMENT_TERMS:
                "This suggested date is based on the contract's expected {{paymentTerms}}-day payment",
              FIXED_DEFAULT:
                'This suggested date is based on standard {{paymentTerms}}-day payment terms',
            },
          },
          history: {
            admin: 'Siteline Admin',
            assist: 'Siteline Assist',
            title: 'Activity',
            all_filter: 'All activity',
            comments_filter: 'Comments only',
            empty_description: 'There is no recent activity on this pay app',
            empty_comments_title: 'Start the conversation',
            empty_comments_description: 'Ask a question, write a note, or give feedback',
            empty_activities_title: 'No recent activity',
            empty_activities_description:
              'Billing updates, pay app submissions, and comments will show up here',
            empty_activities_created_pay_app_title: 'Pay app created',
            empty_activities_created_pay_app_subtitle: '{{date}} at {{time}}',
            auto_generated: 'Auto-generated',
            textbox_placeholder: 'Leave a comment',
            textbox_subtext: 'Tag with @ to notify others',
            textbox_subtext_tooltip:
              'Team members tagged in your comment will be notified via email',
            navigate_away_warning:
              'Are you sure you want to leave? Your comment will not be saved.',
            submit_comment: 'Comment',
            cancel_comment: 'Cancel',
            navigate_to_pay_app: 'Go to pay app',
            events: {
              submitted: 'submitted pay app',
              marked_as_submitted: 'marked as submitted',
              sent_draft: 'sent a draft',
              sent_to: 'To: {{- name}}',
              sent_to_more: 'To: {{- name}} & 1 other',
              sent_to_more_other: 'To: {{- name}} & {{count}} others',
              pay_app_read_synced:
                'automatically synced the invoice from {{integrationName}} to match GC changes',
              pay_app_synced: 'synced the invoice to {{integrationName}}',
              pay_app_synced_invoice_number:
                'synced invoice #{{invoiceNumber}} to {{integrationName}}',
              pay_app_exported: 'exported the invoice to {{integrationName}}',
              pay_app_exported_invoice_number:
                'exported invoice #{{invoiceNumber}} to {{integrationName}}',
              marked_pay_app_synced: 'marked the invoice as synced to {{integrationName}}',
              marked_pay_app_synced_invoice_number:
                'marked invoice #{{invoiceNumber}} as synced to {{integrationName}}',
              marked_exported: 'marked the invoice as exported to {{integrationName}}',
              marked_exported_invoice_number:
                'marked invoice #{{invoiceNumber}} as exported to {{integrationName}}',
              lien_waiver_synced: 'synced vendor lien waivers to {{integrationName}}',
              sync_failure_status_text: 'Error message',
              pay_app_sync_failed: 'synced unsuccessfully to {{integrationName}}',
              pay_app_sync_pending: 'started a pending sync to {{integrationName}}',
              marked_as_paid_from_integration: 'marked as paid from {{- name }}',
              marked_as_paid: 'marked as paid',
              marked_as_partially_paid: 'marked as partially paid',
              opened_the_balance: 're-opened the pay app balance',
              closed_the_balance: 'closed the pay app balance',
              updated_amount: 'updated the amount paid',
              updated_fully_paid: 'updated to fully paid',
              unconditional_submitted: 'submitted unconditional lien waiver',
              submitted_backup: 'submitted backup',
              pay_app_created: 'created pay app',
              signed: 'signed pay app',
              reverted_to_draft: 'reverted to draft',
              edited_line_item_name_only: 'Updated  billing on {{- name}}',
              edited_line_item: 'updated billing on {{- name}} ({{- code}})',
              edited_worksheet_line_item: 'updated worksheet billing on {{- name}} ({{- code}})',
              added_backup: 'added backup',
              added_backup_to: 'added backup to {{- name}} ({{- code}})',
              added_change_order: 'added change order for {{amount}}',
              edited_sov: 'edited SOV',
              edited_quick_bill: 'edited invoice',
              edited_retention: 'edited retention to now hold {{amount}}',
              updated_sworn_statement: 'updated the sworn statement',
              billed_retention: 'billed for {{amount}} of retention',
              cleared_retention_billed: 'cleared all retention billed',
              edited_retention_line_item:
                'edited retention to now hold {{amount}} on line item #{{code}}',
              billed_retention_line_item:
                'billed for {{amount}} of retention on line item #{{code}}',
              cleared_retention_billed_line_item:
                'cleared all retention billed on line item #{{code}}',
              removed_backup: 'removed backup',
              removed_backup_from: 'removed backup from {{- name}} ({{- code}})',
              rounded_line_items: 'rounded all line items',
              requested_review: 'requested internal review',
              pay_app_notarizing_unconditional: 'prepared unconditional lien waiver for notary',
              grouped_added_backup: 'added backup',
              grouped_added_change_order: 'added {{amount}} change orders',
              grouped_edited_line_item: 'updated {{amount}} line items',
              grouped_edited_worksheet_line_item: 'updated {{amount}} worksheet line items',
              grouped_edited_sov: 'edited SOV',
              grouped_edited_quick_bill: 'edited invoice',
              commented: 'commented',
              commented_on_aging: 'left a comment on the aging dashboard',
              commented_on_cash_forecast: 'left a comment on the cash forecast dashboard',
              project_created: 'created project',
              forecast_created: 'created forecast',
              forecast_edited: 'edited forecast',
              forecast_deleted: 'deleted forecast',
              opted_out_billing: 'opted out of {{month}} billing',
              selected_forms: 'added project forms',
              archived_project: 'archived project',
              updated_gc_contacts: 'updated GC contacts',
              updated_settings: 'updated project settings',
              updated_retention_settings: 'updated retention settings',
              updated_lead_pms: 'updated lead PM',
              updated_lead_pms_other: 'updated lead PMs',
              updated_lead_pms_zero: 'updated lead PMs',
              updated_users: 'updated project users',
              added_users_one: 'added a user to the project',
              added_users: 'added users to the project',
              removed_users_one: 'removed a user from the project',
              removed_users: 'removed users from the project',
              marked_pay_app_submitted: 'marked pay app #{{number}} as submitted',
              submitted_pay_app: 'submitted pay app #{{number}}',
              created_pay_app_number: 'created pay app #{{number}}',
              edited_pay_app_retention: 'edited retention on pay app #{{number}}',
              billed_pay_app_retention: 'billed for retention on pay app #{{number}}',
              cleared_pay_app_retention_billed:
                'cleared all retention billed on pay app #{{number}}',
              edited_pay_app_retention_line_item:
                "edited line item #{{code}}'s retention on pay app #{{number}} ",
              billed_pay_app_retention_line_item:
                'billed for retention on line item #{{code}} on pay app #{{number}}',
              cleared_pay_app_retention_billed_line_item:
                'cleared all retention billed on line item #{{code}} on pay app #{{number}}',
              marked_pay_app_paid: 'marked pay app #{{number}} as paid',
              marked_pay_app_partially_paid: 'marked pay app #{{number}} as partially paid',
              marked_pay_app_as_paid_from_integration:
                'marked pay app #{{number}} as paid from {{- name }}',
              reverted_pay_app_to_draft: 'reverted pay app #{{number}} to draft',
              cleared_forms: 'reset forms',
              saved_form_progress: 'saved form progress',
              sent_to_signer: 'sent forms for signature',
              closed_pay_app_balance: 'closed the balance on pay app #{{number}}',
              opened_pay_app_balance: 're-opened the balance on pay app #{{number}}',
              updated_pay_app_amount: 'updated the amount paid on pay app #{{number}}',
              updated_pay_app_amount_fully_paid: 'updated pay app #{{number}} to fully paid',
            },
          },
          notary: {
            notarize_pay_app: 'Notarize pay app',
            witness_sign_pay_app: 'Sign pay app with witness',
            wet_sign_pay_app: 'Sign pay app',
            notarize_lien_waiver: 'Notarize lien waiver',
            sign_lien_waiver: 'Sign lien waiver',
            scan_and_upload: 'Scan and upload the entire notarized document',
            scan_and_upload_witness:
              'These forms require a witness signature. Scan and upload the entire signed document.',
            scan_and_upload_wet:
              'These forms require a wet signature. Scan and upload the entire signed document.',
            upload: 'Upload notarized form',
            upload_signed: 'Upload signed form',
            no_permission: 'You don’t have permission to upload notarized pay apps',
            no_permission_signed: 'You don’t have permission to upload signed pay apps',
            uploading: 'Uploading...',
            error: '{{- name}} is not a valid attachment. Please upload a pdf.',
            upload_complete: 'Notarized form uploaded',
            upload_complete_signed: 'Signed form uploaded',
          },
          add_recipient: 'Add contact',
          contact_exists: 'Contact is already on the list.',
          select_forms: {
            include_forms: 'Include forms with pay app',
            include_forms_subtitle:
              "Select the backup you'd like to include in the generated pay app PDF",
            conditionalLienWaiver: 'Conditional lien waiver',
            allLowerTierLienWaivers: 'Vendor lien waivers',
            allStoredMaterials: 'Stored materials backup',
            allChangeOrders: 'Change orders backup',
            changeOrderLog: 'Change order log',
            allAdditionalBackup: 'Additional backup',
            allLegalRequirements: 'Compliance',
            conditionalLienWaiverUnsigned: 'Conditional lien waiver (unsigned)',
            attachments: '{{count}} attachment',
            attachments_other: '{{count}} attachments',
            attached_separately: '{{count}} file will be separately attached to the email',
            attached_separately_other: '{{count}} files will be separately attached to the email',
          },
          email_dialog: {
            submit_title: 'Submit Pay App #{{num}}',
            submit_title_quick: 'Submit quick bill',
            resubmit_title: 'Resubmit Pay App #{{num}}',
            resubmit_title_quick: 'Resubmit quick bill',
            draft_title: 'Submit draft',
            backup_title: 'Submit backup',
            add_recipient: 'Add recipient',
            select_recipients: 'Select recipients',
            no_contacts: 'You currently have no team members on this project',
            to: 'To',
            subject: 'Subject',
            add_note: 'Add note',
            note: 'Note',
            optional: 'Optional',
            attachment: 'Attachment',
            attachments: '{{count}} attachment',
            attachments_other: '{{count}} attachments',
            plus_attachments: '+ {{count}} attachment',
            plus_attachments_other: '+ {{count}} attachments',
            include_watermarks: 'Include "draft" watermark in attachment',
            you_will_receive: 'You will receive a copy of the PDF in your email',
            default_subject:
              '{{- companyName}} pay app for #{{-projectNumber}} {{- projectName}} — {{billingEnd}}',
            default_subject_quick:
              '{{- companyName}} invoice for #{{-projectNumber}} {{- projectName}}',
            default_subject_draft:
              '{{- companyName}} draft pay app for #{{-projectNumber}} {{- projectName}} — {{billingEnd}}',
            default_subject_quick_draft:
              '{{- companyName}} draft invoice for #{{-projectNumber}} {{- projectName}}',
            default_subject_backup:
              '{{- companyName}} pay app backup for #{{-projectNumber}} {{- projectName}} — {{billingEnd}}',
            no_subject: 'A subject line is required to submit your document by email',
            too_many_characters_subject: 'The subject must be under {{maxChars}} characters',
            too_many_characters_note: 'Your note must be under {{maxChars}} characters',
            create_contact_error: 'Something went wrong adding your contact. Please try again.',
            submitted_backup: 'Submitted backup',
            submitted_draft: 'Submitted draft',
            resubmitted_quick: 'Resubmitted quick bill',
            resubmitted_pay_app: 'Resubmitted pay app',
            submitted_quick: 'Submitted quick bill',
            submitted_pay_app: 'Submitted pay app',
          },
          new_contact: {
            add_recipient: 'Add recipient',
            invalid_email: 'Please enter a valid email address',
            invalid_phone_number: 'Please enter a valid phone number',
            did_you_mean_email: 'Did you mean <bold>{{email}}</bold>?',
            new_contact_headers: {
              company: 'Company*',
              full_name: 'Full name*',
              job_title: 'Job title',
              email: 'Email*',
              phone_number: 'Phone number',
              add: 'Add',
              update: 'Update',
              add_new: 'Add "{{- inputValue}}"',
              not_found: 'Not found, contact support@siteline.com',
              opted_out: `This recipient has opted out of recipient digital signature emails. When you click Request, we'll generate a draft that you can send manually in your email client.`,
            },
          },
          summary: {
            notarized: 'Notarized',
            completed: 'Completed',
            upload_tooltip: 'Replace attachment',
          },
        },
        lien_waiver: {
          back_to_pay_app: 'Back to Pay Apps',
          back_to_pay_app_list: 'Back to Pay App list',
          clear_lien_waiver: 'Clear lien waiver',
          confirm_clear:
            'Are you sure you want to clear the lien waiver? All fields and signatures will need to be re-entered.',
          submit_dialog: {
            title_progress: 'Submit Unconditional Progress lien waiver',
            title_final: 'Submit Unconditional Final lien waiver',
            submitted: 'Submitted unconditional lien waiver',
            default_subject:
              '{{- companyName}} unconditional lien waiver for {{- projectName}} — {{billingEnd}}',
          },
          sidebar: {
            review_and_sign: 'Review & sign',
            conditional_progress: 'Conditional Progress Lien Waiver',
            unconditional_progress: 'Unconditional Progress Lien Waiver',
            conditional_final: 'Conditional Final Lien Waiver',
            unconditional_final: 'Unconditional Final Lien Waiver',
            reminder: 'Remember to only sign this form after you have been paid for this month',
            submit: 'Submit lien waiver',
            send_to: 'Send to',
            sent_to: 'Sent to',
            delete_confirmation: 'Are you sure you want to delete "{{- name}}"?',
            notary: {
              notarization_required: 'Notarization required',
              submit: 'After notarizing, email the lien waiver directly to your GC.',
              error: '{{- name}} is not a valid attachment. Please upload a pdf.',
            },
          },
        },
        mark_as_paid: {
          title: 'Mark pay app as paid',
          edit_title: 'Edit payment details',
          no_integration: 'This will lock the pay app from further changes or submissions',
          with_integrations: 'This will lock the pay app and prevent syncing',
          generate_lien_waiver: 'Generate lien waiver',
          mark_as_paid: 'Mark as paid',
          paid_on: 'Paid on',
          submitted_on: 'Submitted on',
          synced_on: 'Synced on',
          amount: 'Amount paid',
          due: 'Due: {{- amount}}',
          balance_closed: 'Balance closed',
          balance_closed_description:
            'If this pay app is considered fully paid, the remaining {{- balance}} will not appear in A/R aging',
          regenerate_unconditional: 'Regenerate unconditional',
        },
        download: {
          title: 'Download pay app',
          downloaded: 'Downloaded',
          packages: {
            payApp: 'Full pay app',
            invoice: 'Invoice',
            integrationSync: 'Sync',
            backup: 'Backup',
            lienWaivers: 'Lien waivers',
            lienWaiver: 'Conditional lien waiver',
            vendorLienWaiverPreview: 'Preview lien waiver',
            unconditionalLienWaiver: 'Unconditional lien waiver',
            legalDocument: 'Document',
            legalDocuments: 'Compliance package',
            vendorLegalDocuments: 'Vendor documents',
            legalRequirement: 'Compliance package',
            templatesPreview: 'Template preview',
            projectFormsPreview: 'Project forms preview',
            projectLienWaiversPreview: 'Project lien waivers preview',
            changeOrderRequest: 'Change order request',
            changeOrderLog: 'Change order log',
          },
          pdf: {
            pdf: 'PDF',
            preparing: 'Preparing PDFs for download...',
            ready: 'PDFs ready for download',
            error: 'There was an error generating your PDFs. Please try again.',
          },
          csv: {
            csv: 'Invoice as CSV',
            file_name: 'PayApp_{{- projectName}}_{{date}}',
            file_name_quick: 'Invoice_{{- projectName}}',
            ready: 'CSV ready for download',
            error: 'There was an error generating your CSV. Please try again.',
          },
        },
        print: {
          title: 'Print pay app',
          preparing: 'Preparing PDFs to print...',
          ready: 'PDFs ready for print',
          error: 'There was an error generating your PDFs. Please try again.',
          sent_to_printer: 'Sent to printer',
          security_error:
            'Your browser was configured to save PDFs instead of printing them. Enable "Open PDFs in Chrome" in your Chrome settings to continue.',
        },
        progress: {
          events: {
            admin: 'Siteline Admin',
            empty: "This line item hasn't been updated yet",
            CREATE_LINE_ITEM: '{{- name}} created this line item',
            CREATE_CHANGE_ORDER: '{{- name}} created this change order',
            SET_PROGRESS_BILLED: '{{- name}} updated completed this month',
            SET_STORED_MATERIAL_BILLED: '{{- name}} updated stored materials',
            SET_STORED_MATERIAL_BILLED_AND_INSTALLED: '{{- name}} updated stored materials',
            SET_LINE_ITEM_NAME: '{{- name}} updated the name',
            SET_LINE_ITEM_CODE: '{{- name}} updated the code',
            SET_LINE_ITEM_COST_CODE: '{{- name}} updated the cost code',
            SET_LINE_ITEM_TOTAL_VALUE: '{{- name}} updated the total value',
            SET_LINE_ITEM_PREVIOUS_BILLED: '{{- name}} updated the previous billed',
            SET_LINE_ITEM_ORDER: '{{- name}} reordered the line item',
            SET_LINE_ITEM_UNIT_NAME: '{{- name}} updated the unit name',
            SET_LINE_ITEM_UNIT_PRICE: '{{- name}} updated the unit price',
            RESET_FROM_PREVIOUS_PAY_APP:
              '{{- name}} reset this line item by modifying a past pay app',
            RETENTION_ADJUSTED: {
              released: '{{- name}} billed {{retentionReleased}} retention',
              held: '{{- name}} increased retention by {{retentionHeld}}',
            },
          },
        },
        quick_bill: {
          backup: {
            title: 'Backup',
            description:
              'Add any additional backup to attach to the pay app, like certified payroll, field photos, safety logs, or anything else requested by the client',
            empty_paid_description: 'No backup added',
            add_backup: 'Add backup',
            add_attachment: 'Add attachment',
            edit_attachment: 'Edit attachment',
          },
          invoice: {
            title: 'Invoice',
            actions: 'Actions',
            request_review: 'Request review',
            revert_to_draft: 'Revert to draft',
            description: 'Description',
            amount: 'Amount',
            tax_group: 'Tax group',
            subtotal: 'Subtotal',
            tax_amount: 'Tax amount',
            add_line_item: 'Add line item',
            total: 'Total',
            too_many_line_items:
              'Your form may not fit this many line items. You can preview by clicking “Generate forms.”',
            add_tax_group: 'Add tax group',
            search_tax_groups: 'Search tax groups',
          },
        },
        missing_fields: {
          title: 'We need a little more info',
          description:
            "These forms require information that hasn't been provided yet. Please complete the missing field below to continue.",
          description_other:
            "These forms require fields that haven't been provided yet. Please complete the missing fields below to continue.",
          permissionless_description:
            "These forms require information that hasn't been provided yet",
          permission_alert:
            "You don't have permission to edit the project information needed to generate these forms. Please contact your team admin with any questions.",
          general_contractor: 'General contractor',
          gc_address: 'GC address',
          general_contractor_address: 'General contractor address',
          owner: 'Owner',
          owner_address: 'Owner address',
          enter_address: 'Enter address',
          same_company: 'Is this the same company?',
          known_addresses: 'Known addresses',
          success: 'Project updated! Ready to generate forms.',
          not_now: 'Not now',
        },
        rate_table_banners: {
          banner_swap_title: 'This project now uses a different rate table',
          banner_swap_subtitle:
            'This project now uses <bold>{{- rateTableName}}</bold>, which is different from the rate table that is being used on this pay app. Updating the rate table will reset all line item billing for this pay app.',
          banner_swap_subtitle_cor:
            'This project now uses <bold>{{- rateTableName}}</bold>, which is different from the rate table that is being used on this change order request. Updating the rate table will reset the pricing tool.',
          banner_swap_link: 'Update rate table',
          banner_update_title: 'The rates have changed on this rate table',
          banner_update_subtitle:
            'The latest changes are not reflected on this pay app. Updating the rates will reset all line item billing for this pay app.',
          banner_update_subtitle_cor:
            'The latest changes are not reflected on this change order request. Updating the rates will reset the pricing tool.',
          banner_update_link: 'Update rates',
          confirm_swap_title: "Are you sure you want to update this pay app's rate table?",
          confirm_swap_title_cor:
            "Are you sure you want to update this change order request's rate table?",
          confirm_swap_details:
            "Updating this pay app's rate table will reset all line item billing. Backup may be unlinked from invoice line items.",
          confirm_swap_details_cor:
            "Updating this change order request's rate table will reset all line items",
          confirm_update_title: 'Are you sure you want to update the rates on this invoice?',
          confirm_update_title_cor:
            'Are you sure you want to update the rates on this change order request?',
          confirm_update_details:
            'This update will remove all invoice progress for this pay app. Backup may be unlinked from invoice line items.',
          confirm_update_details_cor:
            "This update will reset this change order request's pricing tool. All previously entered values will be cleared.",
          update_fees_title: 'The fees on this project have been updated',
          update_fees_subtitle:
            "This project's fees have changed. This update is not reflected on the current pay app.",
          update_cor_fees_subtitle:
            "This project's fees have changed. This update is not reflected on this change order request's pricing.",
          update_fees_link: 'Update fees',
          update_pay_app_success: "This pay app's rate table has been updated",
          update_cor_success: "This change order request's rate table has been updated",
          update_pay_app_fees_success: 'Successfully updated fees for this pay app',
          update_cor_fees_success: 'Successfully updated fees for this change order request',
        },
      },
      pre_siteline_pay_app: {
        create_or_update: {
          title: 'Pay App #{{payAppNumber}}',
          subtitle: 'Add details for this pay app',
          type: 'Type',
          progress: 'Progress',
          retention: 'Retention-only',
          pay_period: 'Pay period',
          payment_due: 'Payment due*',
          payment_due_tooltip: 'Line 8 on a G702',
          current_billed: 'Grand total for this month*',
          current_billed_tooltip: 'Usually Column E + F on a G703',
          amount_paid: 'Amount paid*',
          min_date_message: 'Cannot be before date submitted',
          max_date_message: 'Cannot be after date paid',
          status: 'Status',
          statuses: {
            proposed: 'Submitted (not paid)',
            notarizing: 'Paid (notarizing)',
            paid: 'Paid',
          },
          paid_at: 'Date paid (for A/R aging)*',
          submitted_at: 'Date submitted (for A/R aging)',
          balance_closed: 'Balance closed',
          unconditional_lien_waiver: 'Unconditional lien waiver',
          already_submitted: 'Already submitted',
          not_yet_submitted: 'Not yet submitted',
          pay_app_package: 'Pay app package',
          view_file: 'View',
          download_file: 'Download',
          duplicate_error: 'These dates overlap with the pay period of Pay App #{{payAppNumber}}.',
          inverted_dates_error: "The pay app's end date must be after the start date",
          due_date_error: "The pay app's due date cannot be before the start date",
          invalid_amounts:
            'Payment due on a progress pay app must be less than or equal to Grand total for this month',
          missing_paid_at: 'Please enter a payment date',
          concurrent_pay_app:
            'Pay App #{{payAppNumber}} is already billing in this period, so a concurrent pay app must have the same period',
          overlapping_pay_app:
            'This overlaps partially with Pay App #{{payAppNumber}} ({{- billingStart}} – {{- billingEnd}}). Please match the dates to Pay App #{{payAppNumber}} or choose a different period.',
          conflicting_progress_pay_app:
            'There is already a progress pay app for this period (Pay App #{{payAppNumber}}). Please choose another period or edit that pay app.',
          conflicting_retention_pay_app:
            'There is already a retention pay app for this period (Pay App #{{payAppNumber}}). Please choose another period or edit that pay app.',
          match_dates: 'Match dates',
          updated: 'Pay app was successfully updated',
        },
        mark_as_paid: {
          title: 'Mark pay app as paid',
          edit_title: 'Edit payment date',
          generate_lien_waiver: 'Generate lien waiver',
          mark_as_paid: 'Mark as paid',
          paid_on: 'Paid on',
          amount: 'Amount',
        },
        lien_waiver: {
          back_to_pay_app: 'Back to Pay Apps',
          back_to_pay_app_list: 'Back to Pay App list',
          clear_lien_waiver: 'Clear lien waiver',
          confirm_clear:
            'Are you sure you want to clear the lien waiver? All fields and signatures will need to be re-entered.',
          submit_dialog: {
            title_progress: 'Submit Unconditional Progress lien waiver',
            title_final: 'Submit Unconditional Final lien waiver',
            submitted: 'Submitted unconditional lien waiver',
            default_subject:
              '{{- companyName}} unconditional lien waiver for {{- projectName}} — {{billingEnd}}',
          },
          sidebar: {
            review_and_sign: 'Review & sign',
            conditional_progress: 'Conditional Progress Lien Waiver',
            unconditional_progress: 'Unconditional Progress Lien Waiver',
            conditional_final: 'Conditional Final Lien Waiver',
            unconditional_final: 'Unconditional Final Lien Waiver',
            reminder: 'Remember to only sign this form after you have been paid for this month',
            submit: 'Submit lien waiver',
            send_to: 'Send to',
            sent_to: 'Sent to',
            delete_confirmation: 'Are you sure you want to delete "{{- name}}"?',
            notary: {
              notarization_required: 'Notarization required',
              submit: 'After notarizing, email the lien waiver directly to your GC.',
              error: '{{- name}} is not a valid attachment. Please upload a pdf.',
            },
          },
        },
      },
      job_costs: {
        title: 'Bulk import job costs',
        job_costs: 'Job costs',
        subtitle:
          'Providing up-to-date job cost information allows you to track over/under billing across all your projects',
        filename: 'JobCosts.xlsx',
        format_title: 'Enter job cost information into the template',
        format_instructions:
          'The template you downloaded lists your projects. Fill in as many rows as you wish with your latest cost data.',
        upload_formatted: 'Upload completed template',
        dropzone_title: 'Drag and drop template',
        imported_projects: 'Imported job costs for {{count}} project',
        imported_projects_other: 'Imported job costs for {{count}} projects',
        import_error: {
          match_template: 'Uploaded file does not match expected Excel template',
          missing_value: 'A row is missing a value for a required column',
          invalid_value: 'An invalid value was found in the following column: "{{- columnName}}"',
          multiple_matches: 'Multiple rows have the same project information',
          no_matches: 'Row with project number {{- number}} does not match any projects',
          empty: 'No rows found with job cost data entered',
          invalid_row: 'Missing required information in row {{rowNumber}}',
        },
      },
      legal_requirement: {
        summary_title: 'Compliance',
        start_tracking: 'Start tracking compliance',
        no_legal_requirement: 'No compliance documents tracked for this project',
        submit_success: 'Completed {{- name}}',
        send_to: 'Send to...',
        sync_to: 'Sync to {{integrationName}}',
        summary_button: 'Summary',
        week_of: 'Week of {{week}}',
        no_permission:
          'You do not have permission to modify compliance. Please contact your team admin with any questions.',
        add_requirement: 'Add requirement',
        summary: {
          recurring: 'Recurring',
          standard: 'One-time',
          chips: {
            due_this_month: 'Due this month',
            up_to_date: 'Up to date',
            past_due: 'Past due',
            completed: 'Completed',
            expiring: 'Expiring',
            expiring_date: 'Expiring {{date}}',
            expired: 'Expired',
            expired_date: 'Expired {{date}}',
            incomplete: 'Incomplete',
          },
          headers: {
            name: 'Name',
            status: 'Status',
            outstanding: 'Outstanding',
            expiration_date: 'Expiration date',
          },
        },
        onboarding: {
          header: 'Setup for compliance',
          submit_to: 'Submit to',
          submit_to_task_subtitle:
            "Add GC recipients for your legal documents and forms. You'll be able to modify this when you submit, and you'll automatically be CC'd on every submission.",
          add_recipient: 'Add recipient',
          use_pay_app_recipients: 'Copy pay app recipients',
          copy_project: 'Copy from another project',
          not_now: 'Not now',
          add_contact_dialog_subtitle:
            'This will be a default recipient of compliance forms and documents',
          complete_setup: 'Complete setup',
          complete_setup_task_subtitle: 'You will be able to make changes later if needed',
          complete_setup_task_disabled_subtitle: 'Select requirements to get started',
          incomplete_requirements:
            'You must address the required fields before you can finish project setup',
          completed_onboarding: 'Successfully onboarded project',
          document: 'Document',
          type: 'Type',
          frequency: 'Frequency',
          start_date: 'Start date',
          requirements_task_title: 'Requirements',
          requirements_task_subtitle:
            'Add compliance requirements for this project. After set up is complete, you will be able to fill out or upload documents as they are required.',
          add_requirement_title: 'Add compliance requirement',
          edit_requirement_title: 'Edit {{- name}}',
          document_type: {
            GENERAL_LIABILITY_INSURANCE: 'General Liability Insurance',
            CERTIFICATE_OF_INSURANCE: 'Certificate of Insurance',
            CERTIFIED_PAYROLL: 'Certified Payroll',
            AUTOMOBILE_LIABILITY_INSURANCE: 'Automobile Liability Insurance',
            WORKERS_COMPENSATION_INSURANCE: "Workers' Compensation Insurance",
            OTHER: 'Another document',
          },
          recurring_frequency_options: {
            WEEKLY: 'Weekly',
            MONTHLY: 'Monthly',
            YEARLY: 'Yearly',
            QUARTERLY: 'Quarterly',
          },
          copy_project_dialog: {
            copy_requirements_title: 'Copy requirements from another project',
            subtitle: 'You can make edits after confirming your project selection',
          },
          add_requirement_dialog: {
            name_label: 'Name',
            name_placeholder: 'Document name',
            frequency_label: 'Frequency',
            upload_label: 'Template',
            expires_on: 'Expires on',
            starting_on: 'Starting on',
            use_template: 'Form template',
            template_tooltip: 'You will be reminded to fill this form out periodically',
            submit_forms: 'Submit forms',
            processing_forms: "We're processing your compliance forms",
            provide_template: 'Provide a template',
            requires_forms: 'Upload a form template or uncheck the form option to continue',
            requires_name: 'Your requirement must have a name before it can be added',
            period_helper_text:
              'The first period for this requirement is {{startDate}} to {{endDate}}',
            end_of_month_note:
              'Note: Requirements starting on the 29th-31st will be set to recur on the last day of each month',
            template_instructions:
              'If you have a form, add the template here to have it generated in Siteline. It may take up to 5 business days to process new forms.',
          },
        },
        sub_header: {
          WEEKLY: 'Required weekly on {{recurrenceStartDate}}',
          MONTHLY: 'Required monthly on the {{recurrenceStartDate}}',
          QUARTERLY: 'Required quarterly on the {{recurrenceStartDate}}',
          YEARLY: 'Required annually on {{recurrenceStartDate}}',
          USER_INPUT: 'Expires {{endDate}}',
          END_OF_MONTH: 'Required at the end of each month',
          NEVER: 'One-time requirement',
        },
        detail: {
          outstanding: 'Outstanding',
          period: 'Period',
          document: 'Document',
          last_sent: 'Last sent',
          last_synced: 'Last synced',
          expiration_date: 'Expiration date',
          unsent: 'Unsent',
          not_synced: 'Not synced',
          completed_by: '{{time}} by {{- name}}',
          not_yet_due_title: "You're up to date for this requirement",
          document_not_yet_due: 'You can upload documents for the next period starting on {{date}}',
          form_not_yet_due: 'You can complete the form for the next period starting on {{date}}',
          queued_message_send: 'This document will be attached to your next compliance email',
          queued_message_send_other: 'You have {{count}} compliance documents ready to send',
          queued_message_sync: 'This document will be included in your next sync',
          queued_message_sync_other: 'You have {{count}} compliance documents ready to sync',
          unqueued_message_send: 'Removed document from compliance email',
          unqueued_message_sync: 'Removed document from next sync',
          skip_this_requirement: 'Skip this requirement',
          skipped: 'Skipped',
          requirement_skipped: 'Marked this document as no longer required',
          tooltips: {
            queue_to_send: 'Queue to send',
            queue_to_sync: 'Queue to send or sync',
            remove_from_queue: 'Remove from queue',
            download: 'Download file',
            view: 'View file',
            more: 'View more options',
          },
        },
        upload_dialog: {
          title: 'Upload {{- name}}',
          title_multiple: 'Upload files to {{- name}}',
          file: 'File',
          expiration_date: 'Expiration date',
          date: 'Date',
          choose_date: 'Choose date',
          max_files_allowed: 'A maximum of {{count}} file is allowed',
          max_files_allowed_other: 'A maximum of {{count}} files is allowed',
          bulk_upload: 'Bulk upload',
          choose_files: 'Choose files or drag them here',
          accepted_files: 'Accepted file types: pdf, docx, jpg, png, or xlsx',
          uploading_toast: 'Uploading your document...',
          uploading_toast_other: 'Uploading {{count}} documents...',
          uploaded: 'Upload complete',
          error: 'Something went wrong uploading your document',
        },
        update_dialog: {
          title: 'Update {{- name}}',
          file: 'File',
          expiration_date: 'Expiration Date',
          date: 'Date',
          choose_date: 'Choose date',
          document_updated: 'Document updated',
        },
        download_dialog: {
          title: 'Select documents to download',
          select_all: 'Select all',
          select_none: 'Select none',
          num_documents: '{{count}} document',
          num_documents_other: '{{count}} documents',
          no_documents: 'No documents',
          filename: 'Compliance_{{- projectName}}_{{date}}',
        },
        empty_dialog: {
          how_to_send_title: 'How to send compliance',
          how_to_sync_title: 'How to sync compliance',
          to_send_documents: "To send a document, open the requirement's detail page and click",
          to_sync_documents: "To sync a document, open the requirement's detail page and click",
          documents_you_upload: 'Documents you upload or fill out will automatically be added.',
        },
        send_dialog: {
          review_documents: 'Review documents to send',
          send_compliance: 'Send compliance',
          default_subject: 'Compliance for {{- projectName}} — {{- date}}',
          clear_all: 'Clear all',
          send_total: '{{count}} total',
          send_row: '{{- requirementName}} - {{- formName}}',
          send_row_exp: '{{- requirementName}} - Exp. {{- date}}',
          no_documents_queued: 'No documents added',
          documents_included: '{{count}} document included',
          documents_included_other: '{{count}} documents included',
          email_sent: 'Your compliance document has been emailed',
          email_sent_other: 'Your compliance documents have been emailed',
        },
        sync_dialog: {
          title: 'Sync documents',
        },
        delete_dialog: {
          title: 'Delete "{{- documentName}}"',
          description: 'This will permanently delete this compliance document.',
        },
        forms: {
          navigate_away_warning:
            'You have not finished completing this form, are you sure you want to leave?',
          remove_file: 'Remove file',
          expiration_date: 'Expires {{date}}',
          select_expiration: 'Select expiration date',
          update_expiration: 'Update expiration date',
          file_name: 'Compliance_{{- requirementName}}_{{- formName}}_{{- projectName}}',
          file_name_exp: 'Compliance_{{- requirementName}}_Exp{{date}}_{{- projectName}}',
          sidebar: {
            completed: 'Completed',
            skip_this_form: 'Skip this form',
            expiration: 'Expiration',
            clear_form: 'Clear form',
            download_template: 'Download template',
          },
        },
      },
      change_order_requests: {
        no_permission:
          'You do not have permission to edit change orders. Please contact your team admin with any questions.',
        status_title: 'Status',
        total_requested: 'Total outstanding requests',
        total_approved: 'Total approved',
        total_proceeding_with_work: 'Proceeding without approval',
        add_rate_table: 'Add rate table',
        rate_table: 'Rate table',
        go_to_rate_table: 'Go to rate table',
        use_different_rate_table: 'Use a different rate table',
        set_fees: 'Set fees (Settings)',
        original_contract: 'Original contract',
        contract_sum_to_date: 'Contract sum to date',
        search_placeholder: 'Search by name or number',
        create_change_order_request: 'Create change order request',
        no_change_order_requests: 'No change order requests, yet',
        empty_subtitle:
          "You'll be able to compile, track, and submit your pending change orders here",
        import_log: 'Import change order log',
        no_results: 'No change order requests found',
        csv_filename: 'Siteline_ChangeOrderLog_{{- projectName}}.csv',
        revert_backup_only: 'Are you sure you want to revert this change order request?',
        revise_backup_only: 'Are you sure you want to revise this change order request?',
        revert_unlink_sov:
          'Are you sure you want to revert this change order request? Doing so will unlink it from the SOV.',
        revert_unlink_sov_and_cors:
          'Are you sure you want to revert this change order request? Doing so will unlink it from the SOV and from all other linked CORs.',
        revise_with_forms:
          'These forms have already been completed. Are you sure you want to revise? You will need to re-sign any forms and submit again.',
        co_log_forms: {
          import_co_log: 'Import change order log',
          download_co_log: 'Export change order log',
          export_excel: 'Export to Excel',
          download_pdf: 'Download PDF',
          export: 'Export',
          download: 'Download',
          downloading: 'Downloading...',
          preparing_export: 'Preparing your change order log...',
          select_export_option: 'Select an option to export',
          select_download_option: 'Select an option to download',
          gc_log_option: 'GC log',
          gc_log_option_description: 'Excludes change order requests that are internal-only',
          internal_option: 'Change order log (internal use)',
          internal_option_description: 'Includes all change order requests',
          select_forms: 'Select change order log forms',
          select_template: 'Select CO log form template',
          forms_submitted: 'Forms submitted and processing',
          forms_submitted_subtitle:
            'We’re processing the forms you uploaded. This may take up to 5 business days.',
          forms_still_loading: 'The forms you submitted are being processed',
          forms_still_processing_subtitle:
            "We're working on processing your forms. This may take up to 5 business days. In the meantime",
        },
        status: {
          DRAFT: 'Draft',
          SIGNED: 'Signed',
          SUBMITTED: 'Submitted',
          APPROVED: 'Approved',
          REJECTED: 'Rejected',
          ADDED_TO_SOV: 'Added to SOV',
          PROCEEDING_WITH_WORK: 'Proceeding with work',
          AUTHORIZED_TO_PROCEED: 'Authorized to proceed',
          all_statuses: 'All statuses',
        },
        table: {
          cor_number: 'COR #',
          gc_number: 'GC #',
          name: 'Name',
          status: 'Status',
          amount: 'Amount',
          created: 'Created',
          submitted_approved: 'Submitted / Approved',
          schedule_impact: 'Schedule impact',
          add_to_sov: 'Add to SOV',
          cor_response: 'COR response',
          approved_as_is: 'Approved as is',
          approved_new_amount: 'Approved, new amount',
          revise_and_resubmit: 'Revise and resubmit',
          rejected: 'Rejected',
          mark_as_submitted: 'Mark as submitted',
          auth_to_proceed: 'Auth to proceed',
          missing_submitted_fields: 'Cannot mark as submitted without both a name and COR #',
          added_to_sov: 'Added to SOV',
          auth_to_proceed_success: 'Successfully marked change order authorized to proceed',
          no_cost_impact: 'No cost impact',
          impact_days: '{{count}} day',
          impact_days_other: '{{count}} days',
          none: 'None',
          export: 'Export',
          submitted_amount: 'Submitted {{- amount}}',
          attachments: '{{count}} backup attachment',
          attachments_other: '{{count}} backup attachments',
          sco: 'Uploaded SCO',
          confirm_delete: 'Delete this COR?',
          confirm_delete_number: 'Delete COR #{{- number}}?',
          cannot_recover:
            'You will not be able to recover this change order request once it is deleted',
          success: 'Rejected change order',
          reject: 'Reject',
          revert_to_draft: 'Revert to draft',
          confirm_reject_title: 'Are you sure you want to continue?',
          confirm_reject_details:
            'Marking the current change order request as rejected will unlink it from its linked change order requests',
          view_forms: 'View forms',
          view_submitted_forms: 'View submitted forms',
          total: 'Total',
          num_items: '{{count}} item',
          num_items_other: '{{count}} items',
          no_matches: 'No change order requests match your filter',
          proceeding_with_work: 'Proceeding with work',
          internal_only: 'Internal-only change orders are excluded from the GC log',
          upload_sco: 'Upload SCO',
          exclude_from_gc: 'Exclude from GC log (internal-only)',
          include_in_gc: 'Include in GC log',
          marked_visible: 'Change order request will be included in GC log',
          marked_invisible: 'Change order log will be excluded from GC log',
          excel_export: {
            title: 'Siteline Change Order Log',
            generated: 'Generated on {{- date}}',
            project_name: 'Project name',
            project_number: 'Project number',
            gc: 'GC',
            lead_pms: 'Lead PM',
            lead_pms_other: 'Lead PMs',
            total_requested: 'Total outstanding requests',
            total_approved: 'Total approved',
            original_contract: 'Original contract',
            contract_sum_to_date: 'Contract sum to date',
            proceeding_with_work: 'Proceeding without executed CO***',
            authorized_to_proceed: 'Total authorized to proceed',
            status: 'Status',
            date_created: 'Date created',
            name: 'Name',
            internal_number: 'Internal number',
            gc_number: 'GC number',
            proposed_amount: 'Proposed amount',
            gc_approved_amount: 'GC approved amount',
            schedule_impact: 'Schedule impact (days)',
            date_submitted: 'Date submitted',
            date_approved: 'Date approved',
            reason: 'Reason',
            excel_filename: 'Siteline_ChangeOrderLog_{{- projectName}}.xlsx',
          },
        },
        request_review: {
          default_subject: 'Review COR #{{- number}} for {{- projectName}}',
          default_subject_log: 'Review CO Log for {{- projectName}}',
          request_review: 'Request review',
          send: 'Send request',
          snackbar: 'Your team member has been notified',
          snackbar_other: 'Your team members have been notified',
          modal_title_cor: 'Request review of COR #{{- number}}',
          modal_title: 'Request review',
          modal_title_log: 'Request review of CO Log',
          no_teammates: 'No teammates on this project',
          manage_users:
            'You can add teammates to this project in <settingsLink>Settings</settingsLink>',
          contact_support:
            'Contact your team administrator or <emailLink><strong>{{email}}</strong></emailLink> to add teammates',
          add_teammates_subject: 'Add project teammates',
          add_teammates_body:
            "Hello,\n\nI'd like to add teammates to {{- projectName}} on Siteline.",
        },
        pricing_tool: {
          all_filter: 'All',
          price_filter: 'Priced',
          helmet: 'Siteline | COR Pricing Tool',
          pricing_tool: 'Pricing tool',
          search_line_items: 'Search line items',
          not_found: 'Unable to find pricing tool for this change order request',
          uncategorized: 'Uncategorized',
          created_line_item: 'Added pricing tool line item',
          updated_line_item: 'Updated pricing tool line item',
          deleted_line_item: 'Deleted pricing tool line item',
          add_line_item: 'Add line item',
          edit_line_item: 'Edit line item',
          category: 'Category',
          excel_export: {
            excel_filename: 'Siteline_ChangeOrderLog_PricingTool{{- changeOrderRequestName}}.xlsx',
            title: 'Siteline Change Order Pricing',
            generated: 'Generated on {{- date}}',
            project_name: 'Project name',
            project_number: 'Project number',
            internal_number: 'Internal number',
            gc: 'GC',
            reason: 'Reason',
            cor_name: 'Change order request',
            cor_internal_number: 'Internal number',
            gc_number: 'GC number',
            group_subtotal: 'Group subtotal',
            group_total: 'Group total',
            total_amount: 'Total change order request amount',
            rate_table: 'Rate table',
          },
        },
        details: {
          new_change_order_request: 'New change order request',
          cor_number: 'COR #{{- number}}',
          change_order_info: 'Change order info',
          cor_number_label: 'COR #',
          gc_number: 'GC #',
          name: 'Name',
          amount: 'Amount',
          number: 'Number',
          unit: 'Unit',
          unit_price: 'Unit price',
          unit_quantity: 'Unit quantity',
          schedule_impact: 'Schedule impact',
          proceeding_with_work: 'Proceeding with work',
          include_in_gc_log: 'Include in GC log',
          use_pricing_tool: 'Use pricing tool',
          view_pricing: 'View pricing',
          download_as_excel: 'Download as Excel',
          edit_pricing: 'Edit pricing',
          preparing_export: 'Preparing your pricing worksheet for export...',
          confirm_clear_pricing_title: 'Are you sure you want to clear pricing?',
          confirm_clear_pricing_details:
            'This will remove all values that have been entered into the pricing tool worksheet',
          clear_pricing_success: 'Reset pricing tool',
          remove_total_amount: 'Remove total amount',
          total_amount_approved: 'Total approved amount',
          disabled_pricing_tooltip_status: 'Only draft change order requests can be priced',
          disabled_pricing_tooltip_permission:
            'You do not have permission to edit this change order request',
          total_amount_priced: 'Total amount via the pricing tool',
          create_rate_table_first: 'Create a rate table to price out change orders',
          add_rate_table_first: 'Add rate table to price out change orders',
          visible_to_gc_details:
            'This will make the change order request visible in pay app packages and change order log exports for the GC',
          disabled_visible_to_gc_tooltip:
            'Submitted change order requests are always visible to the GC',
          days: 'days',
          num_days: '{{count}} day',
          num_days_other: '{{count}} days',
          reason: 'Reason for change',
          private_to_your_team: 'Private to your team',
          missing_name: 'Please enter a name to create this COR',
          missing_name_update: 'A change order request must have a name',
          created_change_order_request: 'Your change order request has been created',
          generate_forms: 'Generate forms',
          preview_and_submit: 'Preview and submit',
          request_review: 'Request review',
          revert_to_draft: 'Revert to draft',
          revise: 'Revise change order request',
          missing_fields: 'Cannot generate forms without a COR #, name and amount',
          add_to_sov: 'Add to SOV',
          link_to_sov_disabled: 'Linked COR {{- internalNumbers}} has not yet been approved',
          link_to_sov_disabled_other:
            'linked CORs {{- internalNumbers}} have not yet been approved',
          saving: 'Saving...',
          all_changes_saved: 'All changes saved',
          not_found: 'Could not find the requested change order request',
          no_forms:
            'No forms to submit. Please attach forms as backup or contact support to set up a COR template.',
          processing_forms: "We're processing your COR forms. This may take up to 5 business days.",
          signing_error:
            'There was an error completing your forms. Please make sure all required fields are filled out and try again.',
          rejected: '[Rejected]',
          view_sco: 'View SCO',
          upload_sco: 'Upload SCO',
          sco_number: 'SCO #{{- number}}',
          edit_linked_sov: 'Edit line items',
          edit_linked_zero: 'Link CORs',
          edit_linked: 'Link additional CORs',
          edit_linked_other: 'Link additional CORs',
          added_to_sov: 'Added to SOV',
          linked_items: 'SOV line items',
          add_to_sov_empty_card_description:
            'This change order request has not yet been linked to any line items on the SOV',
          add_to_sov_disabled_tooltip:
            'Cannot add this group of linked CORs to the SOV until all are marked approved',
          linked_cors: 'Linked CORs',
          linked_cors_tooltip:
            "These linked CORs are not editable because they've been added to the SOV",
          no_linked_cors: 'There are no other CORs linked',
          link_cors: 'Link CORs',
          unlink_cor_confirm_title_internal_number:
            'Are you sure you want to unlink COR #{{- internalNumber}}?',
          unlink_cor_confirm_title: 'Are you sure you want to unlink this COR?',
          unlink_cor_details: 'Unlinking this COR will also unlink it from the SOV',
          unlink: 'Unlink',
          linked_cors_success: 'Linked COR',
          linked_cors_susccess_other: 'Linked {{count}} additional CORs',
          unlinked_cor_success: 'Unlinked COR',
          unlinked_cor_success_internal_number: 'Unlinked COR #{{- internalNumber}}',
          unequal_sov_warning:
            'The SOV line item total of {{- sovTotalValue }} does not match the total approved amount of {{- corTotalValue }}',
          create_change_order_request: 'Create change order request',
        },
        attachments: {
          backup: 'Backup',
          backup_subtitle:
            'Attach any required backup to the change order, e.g. drawings, T&M logs, or email correspondence',
          upload_backup: 'Upload backup',
          attach_documentation: 'Attach documentation',
          description: 'Add description (optional)',
          attach: 'Attach',
          internal_only_attachment: 'Set as internal-only attachment',
          internal_only:
            'Internal-only attachments are not included in the change order request package',
          is_internal_only:
            'This backup is internal-only and will be not be included in the generated change order request package and submission emails',
        },
        approval: {
          confirm_approval: 'Confirm approval of COR #{{- number}}',
          approval_date: 'Approval date',
          approved_amount: 'Approved amount',
          gc_sco_number: 'GC SCO #',
          approve: 'Approve',
          update_sov: "You'll be able to update the SOV once approved",
          upload_sco: 'Upload SCO',
          upload_sco_number: 'Upload SCO #{{- number}}',
          uploaded_sco: 'Uploaded SCO',
        },
        import: {
          helmet: 'Siteline | Import CO Log',
          change_orders: 'Change orders',
          import_log: 'Import your change order log',
          enter_manually: 'Enter manually instead',
          download_template: 'Download template',
          formatted_template:
            'Using our formatted Excel template will allow us to import your change order log automatically',
          copy_log: 'Copy your CO Log into our template',
          copy_log_subtitle:
            'For change order requests that are not yet submitted or approved, leave the corresponding columns blank',
          upload_log: 'Upload formatted CO Log',
          co_log: 'CO Log',
          file_name: 'ChangeOrderLog.xlsx',
          error: {
            name_too_long:
              'This change order name exceeds the maximum of {{length}} characters: "{{- name}}"',
          },
        },
        mark_submitted: {
          title: 'Mark as submitted',
          description:
            'If this change order request was submitted outside of Siteline, enter the details of the original submission to mark it as submitted',
          proposed_amount: 'Proposed amount',
          date_submitted: 'Date submitted',
          success: 'Marked COR #{{- number}} as submitted',
        },
        link_to_sov: {
          title_no_internal_number: 'Link COR to existing line items',
          title_no_linked_cors: 'Link COR #{{- internalNumber}} to existing line items',
          title_linked_cors:
            'Link CORs {{- internalNumbers}} and {{- lastInternalNumber}} to existing line items',
          description:
            'You can select any SOV line items that have been marked as change orders and are not linked to a different COR',
          count_line_items_zero: 'None selected',
          count_line_items: '{{count}} selected',
          select_line_item: 'Select line item',
          add_line_item: 'Add line item',
          already_linked: 'Already linked to CORs',
          success: 'Updated linked SOV line items',
          clear_all: 'Clear all',
          no_change_orders:
            'There are no change orders on your SOV that are not already linked to a COR',
          unlink_sov_alert: 'Unlinking SOV line items will apply to linked change order requests',
        },
        link_cors: {
          link_to_internal_number_title: 'Link CORs to #{{- internalNumber}}',
          link_to_name_title: 'Link CORs to {{- name}}',
          no_linkable_cors:
            'There are no CORs that can be linked. Note that two CORs that have been added to the SOV cannot be linked together.',
          link_approved_cors:
            'Only approved change orders that have not yet been added to the SOV can be linked',
          link_valid_cors:
            'Change order requests that have not yet been added to the SOV can be linked',
          count_items: '{{count}} selected',
          link_cors: 'Link COR',
          link_cors_other: 'Link CORs',
          placeholder: 'Search by COR number or name',
          clear_all: 'Clear all',
          draft: 'Draft',
          added_to_sov: 'Added to SOV',
          already_added_to_sov: 'Already added to SOV',
          not_yet_approved: 'Not yet approved',
          linked_to_unapproved: 'Linked to unapproved CORs',
          updated_linked_success: 'Updated linked CORs',
          linked_cors_list_item_label_internal_number:
            'CORs linked to #{{- selectedLineItemInternalNumber}} that will also be linked to #{{- originalCorInternalNumber}}:',
          linked_cors_list_item_label: 'Other CORs that will be linked:',
        },
        banner: {
          signed: 'Signed',
          submitted: 'Submitted',
          approved: 'Approved',
          rejected: 'Rejected',
          added_to_sov: 'Added to SOV',
          authorized_to_proceed: 'Authorized to proceed',
          view_forms: 'View forms',
          view_submitted_forms: 'View submitted forms',
        },
        activity: {
          title: 'Activity',
          all_filter: 'All activities',
          comments_filter: 'Comments only',
          empty_title: 'There is no activity yet on this change order request',
          empty_description:
            'Change order request updates, submissions, and comments will show up here',
          change_order_request_created: 'Change order request created',
          events: {
            authorized_to_proceed: 'marked as authorized to proceed',
            submitted_to: 'submitted the change order request to {{- name}} for {{- amount}}',
            submitted_to_more:
              'submitted the change order request to {{- name}} & 1 other for {{- amount}}',
            submitted_to_more_other:
              'submitted the change order request to {{- name}} & {{count}} others for {{- amount}}',
            submitted_to_more_zero: 'marked as submitted',
            marked_as_approved: 'marked as approved for {{- amount}}',
            marked_as_approved_sco: 'marked as approved for {{- amount}} and uploaded the SCO',
            uploaded_sco_file: 'uploaded the SCO',
            linked_to_change_order_requests: 'linked COR {{- numbers}}',
            linked_to_change_order_requests_other: 'linked CORs {{- numbers}}',
            unlinked_from_change_order_requests: 'unlinked COR {{- numbers}}',
            unlinked_from_change_order_requests_other: 'unlinked CORs {{- numbers}}',
            added_to_sov: 'added to SOV',
            changed_line_items: 'changed linked SOV line items',
            unlinked_from_sov: 'unlinked from SOV',
            marked_as_rejected: 'marked as rejected',
            change_order_request_created: 'created the change order request',
            change_order_request_created_amount:
              'created the change order request for {{- amount}}',
            change_order_request_imported: 'imported the change order request',
            change_order_request_imported_amount:
              'imported the change order request for {{- amount}}',
            signed: 'signed the change order request',
            reverted_to_draft: 'reverted to draft',
            edited_name: 'edited the name',
            added_amount: 'added an amount of {{- amount}}',
            removed_amount: 'removed the amount',
            edited_amount: 'edited the amount',
            changed_amount: 'changed the amount from {{- oldAmount}} to {{- newAmount}}',
            edited_reason: 'edited the reason for change',
            edited_internal_notes: 'edited internal notes',
            edited_gc_number: 'edited the GC number',
            edited_internal_number: 'edited the internal number',
            edited_schedule_impact: 'edited the schedule impact',
            added_backup: 'added backup',
            removed_backup: 'removed backup',
            requested_review: 'requested review from {{- finalName}}',
            requested_review_other: 'requested review from {{- names}} and {{- finalName}}',
            added_proceeding_with_work: 'added "Proceeding with work"',
            removed_proceeding_with_work: 'removed "Proceeding with work"',
            internal_only: 'Internal only',
            included_in_log: 'Included in GC log',
            excluded_from_log: 'Excluded from GC log',
          },
        },
        add_to_sov: {
          title: 'Add COR {{- number}} to SOV',
          title_linked_cors: 'Add CORs {{- numbers}} and {{- lastNumber}} to SOV',
          formatted_cor_gc_number: 'GC #{{- gcNumber}}',
          add_sco_to_sov: 'Add SCO #{{- gcNumber}} to SOV',
          approved_amount: 'Approved amount',
          add_to_sov: 'Add to SOV',
          skip: 'Skip',
          confirm_title: 'Are you sure you want to add to SOV?',
          link_to_sov: 'Link to SOV line item',
          link_to_cor: 'Link additional CORs',
          missing_fields: 'Every row must have a number and name',
          wrong_total_amount:
            'The sum of all line item values ({{- lineItemSum}}) is not equal the approved COR amount of {{- amount}}',
          already_added: 'This COR has already been added to the SOV',
          added_to_sov: 'COR #{{- number}} added to SOV',
          zero_unit_price: 'Cannot bill against $0.00 line items',
          go_to_latest_pay_app: 'Go to latest pay app',
          signed_pay_app:
            "This change order was approved on {{approvedDate}}, but the {{month}} pay app has already been {{status}}. If you're billing for this change order in {{month}}, please revert that pay app to draft before clicking Add to SOV.",
          paid_pay_app:
            'This change order was approved on {{approvedDate}}, but the {{month}} pay app has already been paid.',
          otherwise_line_items: ' Otherwise, new line items will appear on the {{month}} pay app.',
          line_items: ' New line items will appear on the {{month}} pay app.',
          revert_pay_app: 'Revert Pay App #{{payAppNumber}} to draft',
          navigate_away:
            'Your line items will not be saved unless you click Add to SOV. Are you sure you want to leave?',
          reverted_to_draft: 'Pay App #{{payAppNumber}} reverted to draft',
          jump_to_change_order: 'Jump to change order',
        },
        submit: {
          title: 'Submit COR #{{- number}}',
          resubmit_title: 'Resubmit COR #{{- number}}',
          default_subject: 'Change order request for {{- projectName}} - {{- companyName}}',
          default_subject_number: 'COR #{{- number}} - {{- projectName}} - {{- companyName}}',
          submitted: 'Submitted COR #{{- number}}',
          resubmitted: 'Resubmitted COR #{{- number}}',
        },
      },
      worksheet: {
        search_placeholder: 'Search worksheet items by number or description',
        add_worksheet_item: 'Add worksheet item',
        saved_worksheet: 'Saved worksheet',
        worksheet_icon_tooltip: 'Open worksheet',
        empty_worksheet: 'This worksheet is empty',
        empty_line_item_worksheet: 'The worksheet for this line item is empty',
        go_back: 'Go back to pay app list',
        scheduled_value: 'Scheduled value',
        units_overview: '({{- numUnits}} qty x {{- unitPrice}} per unit)',
        retention_only_permission:
          'This is a retention-only pay app. Progress on this worksheet cannot be edited.',
        existing_progress_status_banner: {
          title: 'Cannot edit the worksheet for this line item',
          subtitle: 'You cannot add worksheet items to line items that have already been billed',
        },
        import_from_excel: {
          helmet: 'Siteline | Import worksheet',
          title: 'Import your worksheet from Excel',
          subtitle:
            'Your worksheet will be imported automatically when you use our formatted Excel template',
          filename: 'Worksheet.xlsx',
          format_title: 'Paste your worksheet into our template',
          format_instructions:
            'You worksheet should be split into tabs, with each tab name matching a line item number on the SOV',
          format_instructions_overwrite:
            '$t(projects.subcontractors.worksheet.import_from_excel.format_instructions).\n\nExisting items on your worksheet will be overwritten for each tab you import.',
          upload_formatted: 'Upload formatted worksheet',
          dropzone_title: 'Worksheet',
          confirm_import: 'Confirm import',
          adding_items: 'Adding {{count}} item to the worksheet',
          adding_items_other: 'Adding {{count}} items to the worksheet',
          import_worksheet: 'Import worksheet',
          overwrite_worksheet:
            'This will overwrite {{count}} existing item and reset its progress billed',
          overwrite_worksheet_other:
            'This will overwrite {{count}} existing items and reset their progress billed',
          imported_worksheet: 'Imported billing worksheet',
          duplicate_codes:
            'Multiple line items on this SOV have the same line item number ({{- duplicateCodes }}), and will not appear in the template. Worksheet items can be added manually for those line items, or you can edit the SOV to set unique numbers.',
          tab_error:
            'The name of tab "{{- tabName}}" does not match any line item codes in this SOV',
        },
        cancel_confirmation: {
          leave_title: 'Are you sure you want to leave?',
          cancel_title: 'Are you sure you want to cancel?',
          details: 'Changes to this worksheet will not be saved',
          prompt: 'Are you sure you want to leave? Changes to this worksheet will not be saved.',
        },
      },
    },
  },
  tracker: {
    view_by_project: 'View by project',
    view_by_vendor: 'View by vendor',
    lien_waiver_type: 'Lien waiver type',
    conditionals: 'Conditionals',
    unconditionals: 'Unconditionals',
    all_lien_waivers: 'All lien waivers',
    ratio_complete: '{{complete}} of {{total}}',
    not_billing: 'Not billing',
    collected_final: 'Collected final',
    con: 'Con',
    uncon: 'Uncon',
    no_vendors: 'This project has no vendors, or all vendors have collected final lien waivers',
    no_projects: 'This vendor is not active on any projects in this time range',
    setup_lien_waivers: 'Set up lien waivers',
    project_vendors: 'Project vendors',
    projects: 'Projects',
    go_to_project: 'Go to project',
    go_to_vendor: 'Go to vendor',
    search_projects_placeholder: 'Job name, number, GC',
    search_vendors_placeholder: 'Search by vendor',
    no_vendors_found: 'No vendors found',
    no_vendors_tracker: 'You have not yet added any vendors',
    sort_by: {
      vendor_name_asc: 'Sorting by vendor name (ascending)',
      vendor_name_desc: 'Sorting by vendor name (descending)',
      project_name_asc: 'Sorting by project name (ascending)',
      project_name_desc: 'Sorting by project name (descending)',
      project_number_asc: 'Sorting by project number (ascending)',
      project_number_desc: 'Sorting by project number (descending)',
    },
    requirement_type_selector: {
      requirement: 'Requirement',
      CERTIFIED_PAYROLL: 'Certified Payroll',
      CERTIFICATE_OF_INSURANCE: 'Certificate of Insurance',
      INVOICE: 'Invoice',
      DAILY_REPORT: 'Daily Report',
      TAXES: 'Taxes',
      EMPLOYMENT: 'Employment',
      LABOR_RATES: 'Labor Rates',
      OTHER: 'Miscellaneous',
      PRELIMINARY_NOTICE: 'Preliminary Notice',
      LIEN_WAIVERS: 'Lien Waivers',
      WEEKLY: 'weekly',
      MONTHLY: 'monthly',
      QUARTERLY: 'quarterly',
      YEARLY: 'annually',
      END_OF_MONTH: 'monthly',
      USER_INPUT: 'one time',
      NEVER: 'one time',
    },
    headers: {
      number: '#',
      project_name: 'Project name',
      vendor_name: 'Vendor name',
      type: 'Type',
      waived_to_date: 'Waived to date',
    },
  },
  vendor_home: {
    no_lien_waivers: 'No vendor lien waivers requested',
    helper_text:
      'To request a lien waiver, open a pay app and go to the Vendor Lien Waivers tab.\nOnce you send a request, it will appear here.',
    unconditionals_only: 'Unconditionals only',
    all_lien_waivers: 'All lien waivers',
    archived: 'Archived',
    add_vendor: 'Add vendor',
    new_vendor: 'New vendor',
    by_project: 'By project',
    by_vendor: 'By vendor',
    upload_document: 'Upload document',
    view_project_billing: 'View project billing',
    subtier: 'sub-tier',
    tracker_disabled: 'Set up a project with vendor lien waivers to view the lien waiver tracker',
    expiring_soon: 'Expiring soon',
    no_permission:
      'You do not have permission to edit vendor documents or settings. Please contact your team admin with any questions.',
    delete_vendor: {
      title: 'Are you sure you want to delete {{- vendorName }}?',
      details:
        'If you delete this vendor from Siteline you will not be able to add it to projects going forward.',
      button: 'Delete vendor',
      success: 'Successfully deleted vendor',
      disable_tooltip:
        "There are lien waivers or legal documents tied to this vendor, so it can't be deleted",
    },
    preliminary_notice: {
      edit_prelim: 'Edit preliminary notice',
      edit_prelim_other: 'Edit preliminary notices',
      prelim_count: '{{count}} preliminary notice',
      prelim_count_other: '{{count}} preliminary notices',
      preliminary_notice: 'Preliminary notice',
      preliminary_notice_other: 'Preliminary notices',
      filed: 'Filed {{- date}}',
      date_filed: 'Date filed',
      upload_prelim: 'Upload prelim',
      upload_preliminary_notice: 'Upload preliminary notice',
      saved_prelim: 'Saved preliminary notice',
      saved_prelim_other: 'Saved preliminary notices',
      removed_prelim: 'Removed preliminary notice',
      removed_prelim_other: 'Removed preliminary notices',
    },
    lien_waiver_category: {
      conditional: 'conditional',
      unconditional: 'unconditional',
    },
    missing_vendors_dialog: {
      no_vendors: 'No vendors',
      no_vendors_subtitle:
        'There are no vendors currently set up on this project. Add new vendors to this project in Settings.',
      go_to_settings: 'Go to Settings',
    },
    table_headers: {
      thru: 'THRU',
      week_of: 'DUE WEEK OF',
      due_in: 'DUE IN',
      cond: 'COND',
      ucond: 'UCOND',
      requests_header_status: '{{complete}} of {{total}}',
      status: 'Status',
    },
    tooltip: {
      REQUESTED: 'Requested',
      REMINDED: 'Reminded',
      VIEWED: 'Viewed',
      SIGNED: 'Signed',
      COMPLETED: 'Completed',
      UPLOADED: 'Uploaded',
      SYNCED: 'Synced',
      SUBMITTED: 'Submitted',
      NOT_REQUESTED: 'Not requested',
      submitted_type: '{{type}} submitted to GC',
      synced_type_integration: '{{type}} synced to {{- integrationName}}',
    },
    not_billing: 'Not billing',
    skipped: 'Skipped',
    tabs: {
      projects: 'Projects',
      vendors: 'All vendors',
      tracker: 'Tracker',
      'lien-waivers': 'Lien Waivers',
      settings: 'Settings',
      documents: 'Documents',
      vendor_info: 'Vendor Info',
    },
    add_vendors: {
      button: 'Add vendors',
      already_exists: 'A vendor with this name already exists',
      added_vendor_success: 'Added {{- vendorName}}',
      name: 'Company name*',
      internal_notes: 'Internal notes',
      add_another_vendor: 'Add another vendor',
      phone_number: 'Phone number',
      invalid_phone_number: 'Please enter a valid phone number',
      options: {
        title: 'Add vendors',
        manual: 'Add vendor manually',
        excel: 'Import from Excel or CSV',
        integration: 'Import from {{integrationName}}',
      },
      titles: {
        options: 'Add vendors',
        manual: 'Add vendor manually',
        excel: 'Import from Excel or CSV',
        integration: 'Import from {{integrationName}}',
      },
      bulk: {
        body: 'Select all vendors that you want to import into Siteline',
        body_delay:
          'Select all vendors that you want to import into Siteline. If you recently made changes in {{integrationName}}, it may take up to 10 minutes to be reflected in Siteline.',
        no_vendors:
          'All vendors from {{integrationName}} have already been added to Siteline. If you recently made changes in {{integrationName}}, it may take up to 10 minutes to show up. If you have any questions, please reach out to support@siteline.com.',
        no_vendors_delay:
          'All vendors from {{integrationName}} have already been added to Siteline. If you have any questions, please reach out to support@siteline.com.',
        link: 'Will link with existing vendor of the same name',
        name: 'Name',
        num_contacts: '# of contacts',
        submit: 'Import vendors',
        success: 'Imported {{count}} vendor',
        success_other: 'Imported {{count}} vendors',
        sort_by: {
          name_asc: 'Sorting by vendor name (ascending)',
          name_desc: 'Sorting by vendor name (descending)',
          link_asc: 'Sorting by already in Siteline (linked first)',
          link_desc: 'Sorting by already in Siteline (linked last)',
          num_contacts_asc: 'Sorting by # of contacts (ascending)',
          num_contacts_desc: 'Sorting by # of contacts (descending)',
        },
      },
      template: {
        title: 'Bulk create vendors',
        download_subtitle:
          'Using our formatted Excel template will allow us to create your vendors automatically',
        download_name: 'VendorImport.xlsx',
        paste_title: 'Enter your vendor info into our template',
        paste_subtitle:
          'You will be able to add contacts for each vendor, or just the company names.\nIf a vendor of the same name already exists in Siteline, new contacts will be added to that vendor.',
        upload: 'Upload',
        dropzone_title: 'Vendor info',
        import_error: {
          match_template: 'Uploaded file does not match expected SOV template',
          missing_value: 'A row is missing a value for a required column',
          invalid_email: '"{{-value}}" is not a valid email address (row {{rowNumber}})',
          invalid_phone: '"{{-value}}" is not a valid phone number (row {{rowNumber}})',
          missing_company_name: 'Missing company name for vendor contact (row {{rowNumber}})',
          address_provided_when_name_empty:
            'Cannot have a company address without a company name (row {{rowNumber}})',
          address_provided_on_existing_company:
            'Address is already provided for this company (row {{rowNumber}})',
          missing_contact_fields: 'Contacts must have a name and email address (row {{rowNumber}})',
        },
      },
    },
    tracker: {
      lien_waivers: 'Lien Waivers',
      no_request_forms: 'Could not find any documents to request',
      no_requirements: 'No documents to track',
      no_requirements_description:
        "This tab will allow you to track vendor requirements of the same type across multiple projects.<br/><br/>To use the tracker, go to a project and set up a vendor requirement under the Settings tab. Or, enable lien waivers for a project by sending your GC's vendor lien waiver forms to <emailLink>{{email}}</emailLink>.",
      required_frequency: {
        WEEKLY: 'Required weekly',
        MONTHLY: 'Required monthly',
        QUARTERLY: 'Required quarterly',
        YEARLY: 'Required annually',
        END_OF_MONTH: 'Required monthly',
        USER_INPUT: 'Required once',
        NEVER: 'Required once',
      },
      search: {
        projects: 'Search for projects',
        vendors: 'Search for vendors',
        no_results: {
          title: 'No items match your search',
          description_projects:
            "Make sure the right document type is selected, and you are searching by the project's name.",
          description_vendors:
            "Make sure the right document type is selected, and you are searching by the vendor's name.",
        },
      },
    },
    projects_page: {
      title: 'Projects',
      no_projects: 'No projects to show',
      no_projects_description:
        'If there’s a lump sum project you expect to see here, contact your team administrator or <emailLink>{{email}}</emailLink>.',
      no_search_results: 'No items match your search',
      no_filter_results: 'No items match all your filtering criteria',
      search: 'Search for projects or general contractors',
      clear_search: 'Clear search',
      clear_all: 'Clear all',
      sort_by: {
        number_asc: 'Sorting by project number (ascending)',
        number_desc: 'Sorting by project number (descending)',
        name_asc: 'Sorting by project name (ascending)',
        name_desc: 'Sorting by project name (descending)',
        compliant_asc: 'Sorting by status (non-compliant first)',
        compliant_desc: 'Sorting by status (compliant first)',
        pending_asc: 'Sorting by responses pending (ascending)',
        pending_desc: 'Sorting by responses pending (descending)',
        projects_asc: 'Sorting by assigned projects (ascending)',
        projects_desc: 'Sorting by assigned projects (descending)',
      },
      headers: {
        number: '#',
        name: 'Name',
        compliant: 'Compliant',
        pending_response: 'Pending response',
      },
      copy_project_dialog: {
        copy_vendors_title: 'Copy vendors from another project',
        subtitle: 'You can make edits after confirming your project selection',
        subtitle_append:
          'These vendors will be added to the current vendor list. You can make edits after confirming your project selection.',
        all_vendors_added: 'All vendors have already been added to the current project',
        add_vendors: 'Add vendors',
        success: 'Copied vendors from {{- projectName}}',
        table: {
          company_name: 'Company name',
          address: 'Address',
        },
      },
      onboarding: {
        header: 'Setup for vendors',
        lien_waiver_forms: 'Lien waiver forms',
        lien_waiver_task_subtitle: 'Select the forms to request from vendors',
        select_forms: 'Select forms',
        preliminary_notice: 'Prelim notice',
        select_vendor_lien_waiver_forms: 'Select vendor lien waiver forms',
        vendor_lien_waivers: 'Vendor lien waivers',
        upload_new: 'Upload new forms',
        cleared_forms: 'Successfully cleared lien waiver forms',
        uploaded_forms: 'Successfully uploaded forms',
        processing_forms: "We're processing your lien waiver forms",
        processing_additional_forms: "We're processing your additional lien waiver forms",
        remove: 'Remove',
        additional_requirements: 'Additional requirements',
        copy_project: 'Copy from another project',
        select_vendors: 'Select vendors',
        add_vendors: 'Add vendors',
        additional_requirements_task_subtitle:
          'Add any documents you need to collect from vendors, like preliminary notices, COIs, or invoices',
        vendors: 'Vendors',
        vendors_task_subtitle:
          'Choose the vendors you will request documents from. You can update this later in Settings.',
        add_name: 'Add {{- vendorName}}',
        added: '{{- vendorName}} (Added)',
        submit_to: 'Submit to',
        submit_to_task_subtitle:
          "Add GC recipients for your vendor documents. You'll be able to modify this when you submit, and you'll automatically be CC'd on every submission.",
        add_recipient: 'Add recipient',
        use_pay_app_recipients: 'Copy pay app recipients',
        add_contact: 'Add contact',
        add_contact_dialog_subtitle:
          'This will be a default recipient of signed vendor lien waivers and documents',
        not_now: 'Not now',
        complete_setup: 'Complete setup',
        complete_setup_task_subtitle: 'You can make changes in Settings later if needed',
        complete_setup_task_disabled_subtitle:
          'Select lien waiver forms and add at least one vendor to get started',
        incomplete_requirements:
          'You must address the required fields before you can finish project setup',
        completed_onboarding: 'Successfully onboarded project',
        skip_for_now: 'Skip for now',
        another_document: 'Another document',
      },
    },
    vendors_page: {
      title: 'Vendors',
      no_vendors: 'No vendors',
      no_search_results: 'No items match your search',
      search_by: 'Search by vendor',
      headers: {
        name: 'Name',
        compliant: 'Compliant',
        projects: 'Projects',
      },
    },
    request_documents: {
      request_document: 'Request document — {{- title}}',
      request_count: '{{current}} of {{total}}',
      request: 'Request',
      sign_or_upload: 'The vendor will be able to click a link to upload the completed document',
      error: 'There was a problem requesting this document. Please try again shortly.',
      default_subject: 'Requesting {{- title}} for {{- projectName}}',
      reminder_default_subject: '[Reminder] Requesting {{- title}} for {{- projectName}}',
      subtier_to: 'Sub-tier to <bold>{{- vendorName}}</bold>',
      skip_this_vendor: 'Skip this vendor',
      skip_this_project: 'Skip this project',
      success_snackbar: {
        request: 'Sent email',
        request_other: 'Sent {{count}} emails',
      },
    },
    project_documents: {
      request: 'Request',
      all_documents_requested: 'All documents have been already requested for this month',
      vendor: 'Vendor',
      status: 'Status',
      expiration_date: 'Expiration date',
      date_received: 'Date received',
      skipped: 'Skipped',
      some_submitted: '{{submitted}} of {{total}} submitted',
      all_submitted: '{{total}} submitted',
      up_to_date: 'Up to date',
      num_outstanding: '{{count}} outstanding',
      outstanding: 'Outstanding',
      no_search_results: 'Your search did not return any results',
      no_documents: 'No vendor documents added',
      no_documents_description:
        'To start tracking vendor documents for this project, set up recurring or one-time documents in the <settingsLink>Settings</settingsLink> tab',
      view_vendor: 'View {{- vendorName}}',
      not_yet_due:
        'No documents due this month. There may documents to complete starting in {{month}}.',
      submit_dialog: {
        submit_documents: 'Submit document',
        default_subject: 'Vendor Documents for {{- projectName}} - {{- date}}',
        select_documents: 'Select documents to submit',
        num_documents_selected: '{{count}} document selected',
        num_documents_selected_other: '{{count}} documents selected',
        document_submitted: 'Your document has been submitted',
        document_submitted_other: 'Your documents have been submitted',
        document_not_found:
          'No email was sent because something went wrong finding a document. Please try again.',
        num_selected: '{{numSelected}} of {{numTotal}} selected',
        count_included: '{{count}} document included',
        count_included_other: '{{count}} documents included',
      },
    },
    vendor_documents: {
      pending_response: 'Pending response',
      search_by: 'Search by project or document',
      document: 'Document',
      project: 'Project',
      status: 'Status',
      period: 'Period',
      expires_date: 'Expires {{- date}}',
      expired_date: 'Expired {{- date}}',
      received_date: 'Received {{- date}}',
      skipped: 'Skipped',
      view_project_requirements: 'View all requirements for {{- projectName}}',
      subtier: 'sub-tier',
      no_search_results: 'Your search did not return any results',
      no_documents: 'No documents required for this vendor',
      no_documents_description:
        'To start tracking documents for this vendor, go to a <projectsLink>project</projectsLink> and set up recurring or one-time documents in the Settings tab',
    },
    vendor_document: {
      request: 'Request',
      resend_request: 'Resend request',
      reject_and_request: 'Reject and resend request',
      upload_completed: 'Upload completed document',
      skip: 'Skip',
      skip_week: 'Skip this week?',
      skip_month: 'Skip this month?',
      skip_year: 'Skip this year?',
      confirm_skip:
        'This will cancel any outstanding requests for this document. If you decide to collect it later, you will need to send the vendor a new request.',
      edit_expiration: 'Edit expiration date',
      edit_upload: 'Edit uploaded document',
      successfully_updated_expiration: 'Successfully updated expiration date',
      successfully_updated_upload: 'Successfully updated upload',
    },
    vendor_settings: {
      name: 'Name',
      number: '#',
      address: 'Address',
      address_dialog_title: 'Vendor address',
      address_dialog_confirm: 'Update address',
      notes: 'Notes',
      job_title: 'Job title',
      erp_integration: 'Link to {{erpName}}',
      company_name: 'Company name',
      vendor_updated: 'Vendor updated',
      details: 'Details',
      integrations: 'Integrations',
      contacts: 'Contacts',
      add_new_contact: 'Add new contact',
      add_contact: 'Add contact',
      edit_contact: 'Edit contact',
      required_field: 'This field is required',
      required_fields_toast: 'Please fill out required fields before saving',
      email: 'Email*',
      full_name: 'Full name*',
      phone_number: 'Phone number',
      contact_updated: 'Contact updated',
      contact_removed: 'Contact removed',
      no_contacts: 'Add contacts to request lien waivers or other documents from this vendor',
      no_contacts_permissionless: 'No contacts have been added for this vendor',
      no_vendors: 'Add vendors to {{erpName}} first, and they will show up here',
      search_vendors: 'Search vendors in {{erpName}}',
      invalid_email: 'Please enter a valid email address for this contact',
      invalid_phone_number: 'Please enter a valid phone number for this contact',
      delete_contact: 'Delete contact?',
      confirm_delete:
        'This contact will no longer appear when requesting lien waivers or documents for this vendor. You can always add them back in the future.',
      confirm_cancel_edit_title: 'Are you sure you want to exit?',
      confirm_cancel_edit_details: 'Your changes will not be saved',
      confirm_prompt: 'Are you sure you want to leave? Your changes will not be saved.',
      associated_projects: 'Associated projects',
      no_associated_projects:
        "This vendor is not on any active projects. Go to a project's settings to add or edit its vendors.",
      sort_project_number_asc: 'Sorting by project number (asc)',
      sort_project_number_desc: 'Sorting by project number (desc)',
      sort_project_name_asc: 'Sorting by project name (asc)',
      sort_project_name_desc: 'Sorting by project name (desc)',
      tabs: {
        DETAILS: 'Details',
        CONTACTS: 'Contacts',
        INTEGRATIONS: 'Integrations',
        PROJECTS: 'Associated projects',
      },
    },
    project_settings: {
      project_info: 'Project info',
      vendors: 'Vendors',
      company: 'Company',
      requirements: 'Requirements',
      view_additional_settings: 'Edit project details',
      active: 'Active',
      preliminary_notice: 'Prelim notice',
      edit_prelim: 'Edit preliminary notice',
      linked_to: 'Linked to {{- integrationName}}',
      add_vendors_to_project: 'Add vendors to project',
      recurring_documents: 'Recurring documents',
      standard_documents: 'One-time documents',
      required_documents: 'Required documents',
      document: 'Document',
      project: 'Project',
      general_contractor: 'General contractor',
      owner: 'Owner',
      architect: 'Architect',
      type: 'Type',
      forms: 'Forms',
      frequency: 'Frequency',
      start_date: 'Start date',
      view_template: 'View template',
      disable_delete_vendor_has_lower_tiers:
        'This vendor cannot be deleted because it has lower tiers',
      disable_delete_vendor_has_lien_waivers:
        'This vendor cannot be deleted because it has lien waivers',
      add_requirement: 'Add requirement',
      delete_vendor_requirement: 'Delete vendor requirement?',
      confirm_delete:
        'Are you sure you want to remove "{{- name}}"? You will no longer be able to request it from vendors and any completed documents will be deleted.',
      deleted_requirement: 'Deleted requirement',
      lien_waivers: 'Lien Waivers',
      downloading_template: 'Downloading document template...',
      conditional_progress: 'Conditional Progress',
      conditional_final: 'Conditional Final',
      unconditional_progress: 'Unconditional Progress',
      unconditional_final: 'Unconditional Final',
      through_date: 'Through date',
      update_through_date: 'Update through date?',
      new_through_date:
        'All new and incomplete lien waivers on this project will now be composed through the {{day}} of their month',
      updated_through_date: 'Updated the lien waiver through date',
      last_day: 'last day',
      day_of_month: '{{day}} of the month',
      end_of_month: 'End of month',
      sub_tier_help: 'Sub-tier vendors can be added from the vendor list',
      read_help_center: 'Read our Help Center guide',
      add_subtier_for: 'Add sub-tier vendor for {{- vendorName}}',
      subtier_will_appear:
        'This vendor will appear under {{- vendorName}}. You will be able to request lien waivers and documents for them directly.',
      add_subtier_vendor: 'Add sub-tier',
      tabs: {
        PROJECT_INFO: 'Project info',
        VENDORS: 'Vendors',
        REQUIRED_DOCUMENTS: 'Required Documents',
        LIEN_WAIVERS: 'Lien waivers',
      },
      remove_vendor: {
        remove_from_project: 'Remove {{- vendorName}} from this project?',
        documents_deleted:
          'Any lien waivers and documents collected from them will be deleted. This will also cancel any outstanding requests to them.',
        remove_vendor_and_documents: 'Remove vendor & documents',
        made_inactive:
          'This vendor has been made inactive, since their lien waivers have been attached to submitted pay apps',
        cannot_remove:
          'Cannot remove this vendor. Please refresh and try again, or check if they have lien waivers attached to submitted pay apps',
        removed_vendor: 'Removed {{- vendorName}}',
      },
      vendor_requirements: {
        update_requirements: 'Update requirements for {{- vendorName}}',
        all_requirements: 'All requirements',
        choose_requirements: 'Choose requirements',
        all_documents: 'All documents',
        some_documents: '{{numDocuments}} of {{count}} document',
        some_documents_other: '{{numDocuments}} of {{count}} documents',
      },
      empty_state: {
        no_vendors: 'No vendors assigned to this project',
        no_vendors_description: 'Add vendors to request documents and track their status',
        no_documents: 'No documents added',
        legal_requirements_description:
          'You can add and track any required documents, e.g. Certified Payroll and Certficiates of Insurance',
        no_forms: 'No lien waiver forms for this project',
        setup_lien_waivers: 'Set up lien waivers',
      },
      sync_vendors: {
        button: 'Sync vendors from {{integrationName}}',
        success: 'Successfully synced vendors from {{integrationName}}',
        no_vendors: 'No vendors found to sync from {{integrationName}}',
      },
      add_or_edit_dialog: {
        add_vendor_requirement: 'Add vendor requirement',
        vendor: 'Vendor',
        date_filed: 'Date filed',
        edit_document: 'Edit document',
        edit_prelim: 'Edit preliminary notice',
        upload_prelim: 'Upload preliminary notice',
        upload_placeholder: 'Drag & drop or click to upload',
        type: 'Type',
        type_info:
          'The document type helps you track documents across all projects on the Tracker page',
        name: 'Name',
        frequency: 'Frequency',
        provide_a_template: 'Provide a template',
        template: 'Template',
        template_info:
          "If you'd like vendors to submit a specific form, you can upload it to be included with your requests",
        upload_template: 'Upload template',
        starting: 'starting',
        on: 'on',
        on_the: 'on the',
        last_day_of_month: 'Last day of month',
        once_per_project: 'Once per project',
        until_document_expires: 'Until document expires',
        recurring: 'Recurring',
        document_name: 'Document name',
        customize_requirement_name: 'Customize requirement name shown to vendors',
        duplicate_type: 'Cannot require multiple {{- type}} documents for the same project',
      },
      document_type: {
        CERTIFIED_PAYROLL: 'Certified Payroll',
        CERTIFICATE_OF_INSURANCE: 'Certificate of Insurance',
        INVOICE: 'Invoice',
        DAILY_REPORT: 'Daily Report',
        TAXES: 'Taxes',
        EMPLOYMENT: 'Employment',
        LABOR_RATES: 'Labor Rates',
        PRELIMINARY_NOTICE: 'Preliminary Notice',
        OTHER: 'Miscellaneous',
      },
      expiration_frequency: {
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
        QUARTERLY: 'Quarterly',
        YEARLY: 'Annually',
        END_OF_MONTH: 'Monthly',
        USER_INPUT: 'As needed',
        NEVER: 'One-time',
      },
    },
    lien_waiver_detail: {
      some_submitted: '{{submitted}} of {{total}} submitted',
      all_submitted: '{{total}} submitted',
      add_lien_waivers: 'Add lien waivers',
      add_projects: 'Add projects',
      submit: 'Submit',
      submitted: 'Submitted',
      view_title: 'Lien Waiver',
      lien_waivers: 'Lien Waivers',
      view_in_tracker: 'View in tracker',
      go_to_vendor: 'Go to vendor details',
      delete_lien_waiver: 'Delete lien waiver',
      deleting_lien_waiver: 'Deleting lien waiver...',
      lien_waiver_deleted: 'Lien waiver deleted',
      search_by_vendor: 'Search by vendor',
      search_by_project: 'Search by project',
      outstanding: 'Outstanding',
      up_to_date: 'Up to date',
      subtier: 'sub-tier',
      no_search_results: 'Your search did not return any results',
      confirm_delete_conditional:
        'Are you sure you want to delete the {{month}} conditional lien waiver for {{- vendorName}}? This will cancel any outstanding requests.',
      confirm_delete_unconditional:
        'Are you sure you want to delete the {{month}} unconditional lien waiver for {{- vendorName}}? This will cancel any outstanding requests.',
      amount: 'Amount',
      amount_paid: 'Amount paid',
      missing_type:
        "If you'd like to manage other lien waiver types, upload the corresponding template in Settings. The type will become available when the form is ready to use.",
      no_lien_waivers_to_sync:
        'Some of the vendors below are not mapped to Textura. Please import vendors from Textura to sync lien waivers.',
      commitments_card: {
        commitments: 'Open commitments',
        edit_open: 'Edit open commitment',
        add_new: 'Add new',
        empty_state_message: 'Add vendor purchase orders and subcontracts here',
        add_new_commitment: 'Add new commitment',
        view_attachment: 'View attachment',
        vendor: 'Vendor',
        type: 'Type',
        amount: 'Amount',
        date: 'Date',
        terms: 'Terms',
        total: 'Total open',
        pay_when_paid: 'Pay when paid',
        days: 'days',
        closed: 'Closed',
        confirm_delete_title: 'Are you sure you want to delete this commitment?',
        confirm_delete_details:
          'You will not be able to recover this commitment once it is deleted',
        edit_commitment: 'Edit commitment',
        mark_as_closed: 'Mark as closed',
        mark_as_open: 'Mark as open',
        missing_fields: 'Please complete all required fields',
        invalid_date: "The date you've entered is invalid",
        updated_status_open: 'Commitment marked as open',
        updated_status_closed: 'Commitment marked as closed',
        deleted_commitment_success: 'Deleted commitment #{{- commitmentNumber}}',
        created_commitment_success: 'Created commitment #{{- commitmentNumber}}',
        updated_commitment_success: 'Updated commitment #{{- commitmentNumber}}',
        include_in_sworn_statements: 'Include vendor in sworn statements',
        commitment_numbers: {
          PURCHASE_ORDER: 'PO #{{- number}}',
          SUB_CONTRACT: 'SC #{{- number}}',
        },
        number_labels: {
          PURCHASE_ORDER: 'Purchase order number',
          SUB_CONTRACT: 'Subcontract number',
        },
        commitment_type_labels: {
          PURCHASE_ORDER: 'Purchase order',
          SUB_CONTRACT: 'Subcontract',
        },
      },
      status_messages: {
        requested: 'Requested {{- actionTakenAt}}',
        reminded: 'Reminded {{- actionTakenAt}}',
        signed: 'Signed {{- actionTakenAt}}',
        completed: 'Completed {{- actionTakenAt}}',
        uploaded: 'Uploaded {{- actionTakenAt}}',
        viewed: 'Viewed {{- actionTakenAt}}',
        not_requested: 'No request sent',
        submitted: 'Submitted {{- actionTakenAt}}',
        synced: 'Synced {{- actionTakenAt}}',
        expiring: 'Exp. {{- date}}',
      },
      short_status_messages: {
        requested: 'Requested',
        reminded: 'Reminded',
        signed: 'Signed',
        completed: 'Completed',
        uploaded: 'Uploaded',
        viewed: 'Viewed',
        not_requested: 'No request sent',
        submitted: 'Submitted',
        synced: 'Synced',
        expiring: 'Exp. {{- date}}',
      },
      empty: {
        choose_vendors: 'Choose vendors',
        request_lien_waivers: 'Request lien waivers',
        vendor_completes: 'Vendor completes on Siteline',
        add_project_vendors: 'Add vendors who need lien waivers for any of your projects',
        select_a_vendor:
          'Select a vendor, through date, and type. Siteline sends the vendor an email for you.',
        siteline_helps:
          'Siteline helps them fill out your template digitally, or they can upload a PDF',
        to_get_started: "To get started, send us your project's vendor lien waiver forms",
        setup_project: 'To get started, set up this project with vendor lien waiver forms',
        start_tracking: 'Start tracking lien waivers',
        setup_lien_waivers: 'Set up lien waivers',
        subject: 'Vendor lien waiver templates',
        body: "Hello! I've attached the vendor lien waiver templates for {{- projectName}}.",
        processing_forms: "We're building your forms",
        processing_additional_forms:
          "We're processing your additional forms. We'll send you an email when they're ready.",
        when_ready_days:
          "You'll get an email when they're ready.\nThis may take up to 5 business days.",
      },
      request_types: {
        unconditional_progress: 'Unconditional progress',
        unconditional_final: 'Unconditional final',
        conditional_progress: 'Conditional progress',
        conditional_final: 'Conditional final',
      },
      request: {
        request: 'Request',
        request_conditionals: 'Request conditionals',
        request_unconditionals: 'Request unconditionals',
        no_lien_waivers: 'No lien waivers to request. Add vendors to request additional waivers.',
        no_unsent_projects:
          'All lien waivers have been requested or completed. Add projects to request additional waivers.',
      },
      request_dialog: {
        title: 'Request lien waiver',
        request_month_lien_waivers: 'Request {{month}} lien waivers',
        month_lien_waivers: '{{month}} lien waivers',
        lien_waivers: 'Lien waivers',
        request_count: 'Vendor {{current}} of {{total}}',
        request_lien_waivers: 'Request 1 lien waiver',
        request_lien_waivers_zero: 'Request lien waivers',
        request_lien_waivers_other: 'Request {{count}} lien waivers',
        request_lien_waiver: 'Request lien waiver',
        request_lien_waivers_title: 'Request lien waivers',
        preview_lien_waiver: 'Preview lien waiver',
        request: 'Request',
        type: 'Type',
        through: 'Through',
        through_date: 'Through date',
        date_received: 'Date received',
        amount: 'Amount',
        options: 'Options',
        amount_text: 'Paid to date',
        amount_text_value: 'Paid to date',
        amount_empty: 'If empty, the vendor may enter an amount',
        amount_formatted: 'Vendor sees: "{{- formattedAmount}}"',
        add_exceptions: 'Add exceptions',
        amount_from_conditional_tooltip:
          'This amount is automatically pulled from the Conditional Lien Waiver',
        calculate_from_erp: 'Calculate from {{ erpName }}',
        calculate_amount_from_erp: 'Calculate amount from {{ erpName }}',
        exceptions: 'Exceptions',
        update_amount: 'Update amount',
        invoice_list: 'Invoices included:\n{{- invoiceList }}',
        exception_amounts: 'Amounts',
        exception_dates: 'Dates',
        exceptions_placeholder: 'Optional',
        is_joint_check: 'Joint check',
        joint_check_note: 'Select if GC is writing a joint check to you and vendor',
        subtier_joint_check_note: 'Select if GC is writing a joint check to you and both vendors',
        attachments: 'Attachments',
        attach_file: 'Attach file',
        attach_preliminary_notice: 'Attach preliminary notice',
        drag_and_drop_file: 'Drag and drop file',
        attachment_types: 'pdf, docx, jpg, png, or xlsx',
        amount_placeholder: 'Optional',
        sign_or_upload_single:
          'The vendor will receive a link to digitally sign or upload the lien waiver',
        sign_or_upload_batch:
          'Vendors will receive a link to digitally sign or upload the lien waiver',
        error: 'There was a problem requesting your lien waiver. Please try again shortly.',
        skip_this_vendor: 'Skip this vendor',
        skip_this_project: 'Skip this project',
        no_forms_ready: 'Lien waiver forms for this project are not ready yet',
        success_snackbar: {
          sent_requests: 'Sent lien waiver request',
          sent_requests_other: 'Sent requests for {{count}} lien waivers',
          request: 'Sent lien waiver request',
          remind: 'Sent lien waiver reminder',
        },
        subtier_to: 'Sub-tier to <bold>{{- vendorName}}</bold>',
        no_recipients_error: 'All listed recipients have already received a request',
        opted_out: `{{- name }} has opted out of receiving digital signature emails. When you click Request, we'll generate a draft that you can send manually in your email client.`,
        opted_out_multiple: `{{ names }} have opted out of receiving digital signature emails. When you click Request, we'll generate a draft that you can send manually in your email client.`,
        manual_body: [
          'Please send me the <%- billingEnd %> <%- subjectType %> lien waiver for <%- projectName %>.',
          '<% if (_.isString(formattedAmount)) { %>\n   • Amount: <%- formattedAmount %><% } %>',
          '<% if (_.isString(defaultExceptionAmounts)) { %>\n   • Exceptions: <%- defaultExceptionAmounts %><% } %>',
          '<% if (_.isString(defaultExceptionDates)) { %>\n   • Exception dates: <%- defaultExceptionDates %><% } %>',
          '<% if (isJointCheck === true) { %>\n   • Joint check: yes<% } %>',
        ].join(''),
        select_lien_waivers_view: {
          vendor: 'Vendor',
          type: 'Type',
          amount: 'Amount',
          jc: 'JC',
          joint_check: 'Joint check',
          num_attachments: '{{count}} attachment',
          num_attachments_other: '{{count}} attachments',
          edit_lien_waiver_request: 'Edit lien waiver request',
          click_to_add_recipients: 'Click to add recipients',
          names_and_other: '{{- names}} & 1 other',
          names_and_other_other: '{{- names}} & {{count}} others',
          add_recipients: 'Add recipients to request this lien waiver',
          sort_by: {
            vendor_asc: 'Sorting by vendor name (ascending)',
            vendor_desc: 'Sorting by vendor name (descending)',
            type_asc: 'Sorting by lien waiver type (conditionals first)',
            type_desc: 'Sorting by lien waiver type (unconditionals first)',
            amount_asc: 'Sorting by amount (ascending)',
            amount_desc: 'Sorting by amount (descending)',
          },
          lien_waiver_type: {
            CONDITIONAL_PROGRESS_PAYMENT: 'Conditional progress',
            CONDITIONAL_FINAL_PAYMENT: 'Conditional final',
            UNCONDITIONAL_PROGRESS_PAYMENT: 'Unconditional progress',
            UNCONDITIONAL_FINAL_PAYMENT: 'Unconditional final',
          },
        },
        import_invoices: {
          loading: 'Finding invoices in {{- integrationName}}. This may take a moment...',
          error: 'An error occurred loading your invoices. Please try again.',
          refresh_invoices: 'Retry',
          no_invoices: 'No invoices found for this period. Amount is <bold>$0.00</bold>.',
          assign_to_erp: 'Link this vendor to your ERP',
          invoices_received: 'Invoices received from',
          to: 'to',
          invoices_through: 'Invoices through',
          invoice_date: 'Invoice date',
          code_description: 'Code / description',
          invoice_amount: 'Invoice amount',
          amount_due: 'Amount due',
          amount_paid: 'Amount paid',
          fully_paid: 'Paid in full',
          total: 'Total selected',
          num_invoices: '{{count}} invoice',
          num_invoices_other: '{{count}} invoices',
          paid: '{{- amount}} paid',
          due: '{{- amount}} due',
        },
      },
      upload_dialog: {
        title: 'Upload lien waiver',
        not_a_pdf: 'Your document must be a PDF',
        not_selected: 'Please select a file to continue',
        placeholder: 'Drag & drop or click to upload',
        upload: 'Upload',
        cancel: 'Cancel',
        vendor: 'Vendor',
        select_vendor: 'Select vendor',
        project: 'Project',
        select_project: 'Select project',
        new_vendor: 'New vendor',
        company_name: 'Company name',
        error: 'There was a problem uploading your document. Please try again shortly.',
        contact_error:
          'No contact exists for this vendor. Please try uploading to "New vendor" to add a contact.',
        success: 'Uploaded file',
        name_length: 'File names must be 100 characters or fewer',
        subtier_to: '{{- name}} (sub-tier to {{- vendorName}})',
        all_documents_uploaded: 'All documents have been uploaded',
        all_lien_waivers_uploaded: 'All lien waivers have been uploaded',
      },
      submit_dialog: {
        review_lien_waivers: 'Review lien waivers to send',
        total_count: '{{count}} total',
        submit_lien_waiver: 'Submit lien waiver',
        submit_lien_waiver_other: 'Submit lien waivers',
        default_subject: '{{- vendorName}} {{month}} Lien Waiver for {{- projectName}}',
        default_subject_other: 'Vendor Lien Waivers ({{month}}) for {{- projectName}}',
        count_included: '{{count}} lien waiver included',
        count_included_other: '{{count}} lien waivers included',
        email_sent: 'Your lien waiver has been submitted',
        email_sent_other: 'Your lien waivers have been submitted',
        no_lien_waivers: 'No lien waivers queued to submit',
        add_all_back: 'Add back all completed lien waivers',
      },
      menu: {
        delete: 'Delete',
        upload_completed: 'Upload completed waiver',
        resend_request: 'Resend request',
        add_conditional: 'Add conditional lien waiver',
        add_unconditional: 'Add unconditional lien waiver',
        creating_lien_waiver: 'Creating lien waiver...',
        created_lien_waiver: 'Created lien waiver',
        mark_as_submitted: 'Mark as submitted',
        marked_as_submitted: 'Marked lien waiver as submitted',
      },
      table_headers: {
        project: 'Project',
        vendor: 'Vendor',
        status: 'Status',
        type: 'Type',
      },
      lien_waiver_type: {
        CONDITIONAL_PROGRESS_PAYMENT: 'Conditional progress',
        CONDITIONAL_FINAL_PAYMENT: 'Conditional final',
        UNCONDITIONAL_PROGRESS_PAYMENT: 'Unconditional progress',
        UNCONDITIONAL_FINAL_PAYMENT: 'Unconditional final',
      },
      select_vendors_for_month: {
        select_lien_waiver_type: 'Select lien waiver type',
        select_type_subtitle:
          'Choose the type of lien waiver to request. You can repeat this workflow for each type you want to request in {{month}}.',
        missing_type:
          "Don't see the lien waiver type you need to select? Upload missing lien waiver forms in <settingsLink>Settings</settingsLink>.",
        month_and_category: '{{month}} {{category}} lien waivers',
        select_vendors_title: 'Select vendors',
        suggest_from_integration: 'Suggest from {{- integrationName}}',
        suggest_from_integration_conditional_description:
          'Select from all vendors with {{month}} invoices',
        suggest_from_integration_unconditional_description:
          'Select from all vendors with paid {{month}} invoices',
        upload_ap_via_excel: 'Upload AP via Excel',
        upload_ap_via_excel_conditional_description:
          'Use our AP template to import vendors with {{month}} invoices',
        upload_ap_via_excel_unconditional_description:
          'Use our AP template to import vendors with paid {{month}} invoices',
        select_vendors_manually: 'Select vendors manually',
        select_vendors_manually_description: 'Choose vendors to collect lien waivers from',
        select_vendors_manually_subtitle:
          "Check the vendors from whom you'll collect a {{month}} {{category}} lien waiver",
        add_new_vendors: 'Add new vendors to this project in <settingsLink>Settings</settingsLink>',
        vendors_with_invoices: 'Vendors with invoices in {{- integrationName}}',
        vendors_with_paid_invoices: 'Vendors with paid invoices in {{- integrationName}}',
        finding_invoices: 'Finding invoices in {{- integrationName}}. This may take a moment...',
        invoices_from_company: 'Invoices from {{- companyName}}',
        paid_invoices_from_company: 'Paid invoices from {{- companyName}}',
        num_selected_invoices: '{{numSelected}} of {{numTotal}} invoices selected',
        add_integration_vendors_to_siteline: 'Add {{- integrationName}} vendors to project',
        add_and_go_back: 'Add and go back',
        select_vendors_zero: 'Select vendors',
        select_vendors: 'Select {{count}} vendor',
        select_vendors_other: 'Select {{count}} vendors',
        import: 'Import',
        lien_waiver_category: {
          CONDITIONAL: 'Conditional ',
          UNCONDITIONAL: 'Unconditional',
          progress_or_final: 'progress or final',
        },
        manually_select: {
          vendor: 'Vendor',
          prelim_notice: 'Prelim notice',
          month_conditional: '{{month}} conditional amount',
          already_added: '{{- vendor}} (added)',
          added_lien_waivers: 'Added {{count}} lien waiver for {{month}}',
          added_lien_waivers_other: 'Added {{count}} lien waivers for {{month}}',
          sort_by: {
            vendor_asc: 'Sorting by vendor name (ascending)',
            vendor_desc: 'Sorting by vendor name (descending)',
          },
        },
        import_excel: {
          title: 'Upload AP via Excel',
          description:
            'Use our AP template to import vendors with {{month}} invoices. <bold>All vendors listed must already be in Siteline.</bold>',
          download_name: 'APImportTemplate.xlsx',
          paste_into_template: 'Copy your invoice details into our template',
          paste_into_template_description:
            'The invoices will be summed by vendor, and the details will be included in the notes section of the lien waiver request.',
          upload_template: 'Upload completed template',
          download_template: 'Download template',
          dropzone_title: 'Invoices',
          confirm_title: 'Confirm import',
          confirm_import_subtitle:
            'Lien waivers will be created for {{- count}} vendor. Click "Import" to proceed.',
          confirm_import_subtitle_other:
            'Lien waivers will be created for {{- count}} vendors. Click "Import" to proceed.',
          back_label: 'Re-upload',
          added_vendors: 'Added {{count}} vendor to project',
          added_vendors_other: 'Added {{count}} vendors to project',
          import_error: {
            skipped_rows:
              '{{- count}} row was skipped. Make sure all vendors listed in the spreadsheet have been added to Siteline.',
            skipped_rows_other:
              '{{- count}} rows were skipped. Make sure all vendors listed in the spreadsheet have been added to Siteline.',
            unable_to_import:
              'This spreadsheet cannot be imported. Make sure that all vendors listed in the spreadsheet have been added to Siteline.',
          },
        },
        link_vendors_from_integration: {
          add_or_link:
            'Select vendors to add to this project. If there is already a matching vendor in Siteline, search by name in the last column to link them together. Otherwise, a new vendor will be created in Siteline.',
          code: '#',
          vendor: 'Vendor',
          link_to_vendor: 'Link to existing vendor, if applicable',
          search_vendors: 'Search vendors',
          already_in_siteline: 'Already in Siteline',
          linked_to_name: 'Linked to {{- vendorName}}',
          sort_by: {
            code_asc: 'Sorting by vendor code (ascending)',
            code_desc: 'Sorting by vendor code (descending)',
            vendor_asc: 'Sorting by vendor name (ascending)',
            vendor_desc: 'Sorting by vendor name (descending)',
          },
        },
        suggest_from_integration_view: {
          vendor: 'Vendor',
          prelim_notice: 'Prelim notice',
          selected_invoices: 'Selected invoices',
          select_invoices: 'Select invoices',
          num_selected_invoices: '{{numSelected}} of {{numTotal}}',
          total_from_selected_invoices: 'Invoice amount',
          amount_due: 'Amount due',
          amount_paid: 'Amount paid',
          other_vendors:
            'We found {{count}} vendor from {{- integrationName}} that is not on this project',
          other_vendors_other:
            'We found {{count}} vendors from {{- integrationName}} that are not on this project',
          no_invoices: 'No invoices found for this period',
          no_paid_invoices: 'No paid invoices found for this period',
          add_vendors: 'Add vendors',
        },
        select_integration_invoices: {
          invoice_date: 'Invoice date',
          code_description: 'Code / description',
          invoice_amount: 'Invoice amount',
          amount_due: 'Amount due',
          amount_paid: 'Amount paid',
          paid_in_full: 'Paid in full',
          total_selected: 'Total selected',
          num_selected_invoices: '{{numSelected}} of {{numTotal}} invoices',
        },
      },
      select_vendors: {
        title: 'Add vendor lien waivers',
        add_vendor: 'Add vendor',
        company: 'Company',
        company_name: 'Company name',
        adding_vendor: 'Adding vendor...',
        adding_vendor_other: 'Adding vendors...',
        add: 'Add',
        add_name: 'Add {{- vendorName}}',
        placeholder: 'Search for existing vendors or add a new vendor',
        placeholder_add_only: 'Vendor name',
        enter_vendor: "Enter a vendor's name to add them to this project",
        enter_vendor_add_only: "Enter a vendor's name",
        vendor_already_added: 'This vendor has already been added',
        subtier_to_self: 'A vendor cannot be a sub-tier to itself',
        added: '(Added)',
        already_exists: '(already exists)',
      },
      add_lien_waivers_dialog: {
        title: 'Add lien waivers for {{- month}}',
        prelim: 'Prelim',
        vendor_submitted_prelim: 'Vendor submitted a preliminary notice',
        add: 'Add',
        add_new_vendors: 'Add new vendors to this project in <settingsLink>Settings</settingsLink>',
        add_new_projects: "Add this vendor to new projects from the project's Settings",
        added_lien_waivers: 'Added {{count}} lien waiver for {{month}}',
        added_lien_waivers_other: 'Added {{count}} lien waivers for {{month}}',
        no_projects:
          "This vendor has not yet been added to any projects. You can add vendors to a project from the project's Settings tab.",
        no_vendors:
          'No vendors have been added to this project yet. You can add vendors from the <settingsLink>Settings tab</settingsLink>.',
        subtier_to: '{{- projectName}} (sub-tier to {{- vendorName}})',
      },
    },
  },
  reporting_home: {
    include_draft_billing: 'Include draft billing',
    draft_billing_tooltip: 'When disabled, totals below include only submitted pay apps',
    tabs: {
      overview: 'Overview',
      billing: 'Billing',
      aging: 'A/R aging',
      forecast: 'Billing forecast',
      cashForecast: 'Cash forecast',
    },
    filters: {
      search_placeholder: 'Search by number, name, GC',
      project_type: 'Project type',
      aging_amount_type: 'Billing type',
      lead_pm: 'Lead PM',
      project_status: 'Status',
      lead_pm_search_placeholder: 'Search lead PMs',
      empty_pm_search: 'No PMs found',
      gc: 'GC',
      gc_search_placeholder: 'Search GCs',
      empty_gc_search: 'No GCs found',
      office: 'Office',
      office_search_placeholder: 'Search offices',
      empty_office_search: 'No offices found',
      all: 'All',
      search_term: 'Search term',
      project_types: {
        lump_sum: 'Lump sum',
        quick_bill: 'Quick bill',
        unit_price: 'Unit price',
        time_and_materials: 'Time and materials',
      },
      aging_amount_types: {
        progress_minus_retention_held: 'Progress only',
        retention_released: 'Retention only',
        amount_due: 'Progress & retention',
      },
      project_statuses: {
        past_due: 'Past due',
        not_billing: 'Not billing',
        draft_on_time: 'Draft, on time',
        submitted: 'Submitted',
      },
    },
    overview: {
      projects_billed: {
        title: 'Projects billed',
        bar_header: 'Pay app statuses',
        submitted: 'Submitted',
        draft: 'Draft, on time',
        not_billing: 'Not billing',
        past_due: 'Past due',
        overview_tooltip: 'Pay apps submitted across all projects you have access to',
        billed_fraction:
          '<numeratorComponent>{{numerator}}</numeratorComponent> of <denominatorComponent>{{denominator}}</denominatorComponent>',
      },
      monthly_billing_chart: {
        billed_title: 'Billed',
        billed_and_draft_title: 'Billed & drafts',
        retention_held_title: 'Retention held this month',
        billed_tooltip: 'Total progress billed this month, excluding draft pay apps',
        billed_and_draft_tooltip: 'Total billed this month, including draft pay apps',
        retention_held_tooltip: 'Total retention held this month, excluding draft pay apps',
        billed_key: 'Billed',
        billed_and_draft_key: 'Billed & drafts',
        chart_header: 'Monthly billing',
      },
      pay_apps_outstanding: {
        title: 'Pay apps outstanding (progress only)',
        bar_header: 'A/R aging',
        current: 'Current',
        over_num_days: '>{{num}} days old',
        num_pay_apps: '{{count}} pay app',
        num_pay_apps_other: '{{count}} pay apps',
      },
      lien_waivers_outstanding: {
        title: 'Vendor lien waivers outstanding',
        chart_header: 'Unsigned lien waivers',
        vendors_outstanding: 'Vendors with outstanding lien waivers',
        unsigned_lien_waivers_tooltip_zero: 'No unsigned lien waivers',
        unsigned_lien_waivers_tooltip_one: '{{count}} unsigned lien waiver',
        unsigned_lien_waivers_tooltip_other: '{{count}} unsigned lien waivers',
        no_lien_waivers_requested: 'No lien waivers requested',
        no_lien_waivers_description: 'Outstanding vendor lien waivers will appear here',
        start_tracking_vendors_link: 'Start tracking vendor lien waivers',
        all_signed: 'All lien waivers signed',
        all_signed_description:
          'Your vendors have completed all lien waivers requested by your team',
        go_to_tracker_link: 'Go to lien waiver tracker',
      },
    },
    billing: {
      projects_billed: 'Projects billed',
      projects_total: 'Total projects',
      billing_this_month: 'Billing this month',
      billed_this_month: 'Billed this month',
      billing_this_month_tooltip: 'Includes all draft and submitted pay apps',
      billed_this_month_tooltip: 'Includes only submitted pay apps',
      in_draft: 'In draft',
      total_retention: 'Total retention to date',
      billed_fraction:
        '<numeratorComponent>{{numerator}}</numeratorComponent> of <denominatorComponent>{{denominator}}</denominatorComponent>',
      project_number: '#',
      name: 'Name',
      line_item_number: 'Line item #',
      description: 'Description',
      percent_complete: '% Complete',
      total_contract: 'Total contract',
      total_value: 'Total value',
      total_billed: 'Total billed',
      balance_to_finish: 'Balance to finish',
      total_retainage: 'Total retainage',
      gc: 'GC',
      lead_pm: 'Lead PM',
      opted_out_tooltip: 'Opted out of billing',
      fully_billed: 'This SOV has been fully billed',
      past_due_tooltip: 'Past due',
      not_started_tooltip: 'Pay app not yet created',
      draft_tooltip: 'Pay app not yet submitted',
      totals: 'Totals',
      num_projects: '{{count}} project',
      num_projects_other: '{{count}} projects',
      navigate_to_project: 'Go to project',
      includes_draft: 'Includes draft',
      quick_bill: 'Quick bill',
      sort_by: {
        number_asc: 'Sorting by project number (ascending)',
        number_desc: 'Sorting by project number (descending)',
        name_asc: 'Sorting by project name (ascending)',
        name_desc: 'Sorting by project name (descending)',
        billed_this_month_asc: 'Sorting by amount billed this month (ascending)',
        billed_this_month_desc: 'Sorting by amount billed this month (descending)',
        percent_complete_asc: 'Sorting by percent completed (ascending)',
        percent_complete_desc: 'Sorting by percent completed (descending)',
        contract_value_asc: 'Sorting by contract value (ascending)',
        contract_value_desc: 'Sorting by contract value (descending)',
        total_billed_asc: 'Sorting by total amount billed (ascending)',
        total_billed_desc: 'Sorting by total amount billed (descending)',
        balance_to_finish_asc: 'Sorting by balance to finish (ascending)',
        balance_to_finish_desc: 'Sorting by balance to finish (descending)',
        total_retainage_asc: 'Sorting by total retainage (ascending)',
        total_retainage_desc: 'Sorting by total retainage (descending)',
      },
      export: {
        title: 'Siteline Billing Report for {{- date}}',
        generated_on: 'Generated on {{- date}}',
      },
    },
    aging: {
      outstanding: 'Pay apps outstanding',
      over_120: '>120 days',
      over_90: '>90 days',
      over_60: '>60 days',
      over_30: '>30 days',
      over_120_total: 'Total >120 days',
      over_90_total: 'Total >90 days',
      over_60_total: 'Total >60 days',
      over_30_total: 'Total >30 days',
      avg_time_paid: 'Avg. time to paid (last 6 mo.)',
      num_days_one: '{{numDays}} day',
      num_days: '{{numDays}} days',
      current: 'Current',
      over_num_days: '>{{num}} days',
      name: 'Name',
      number: '#',
      total: 'Total',
      num_projects: '{{count}} project',
      num_projects_other: '{{count}} projects',
      gc: 'GC',
      lead_pm: 'Lead PM',
      navigate_to_project: 'Go to project',
      open_details: 'Open details',
      excluding_pay_apps:
        'Excluding pre-Siteline pay apps whose submission date has not been entered',
      quick_bill: 'Quick bill',
      sort_by: {
        number_asc: 'Sorting by project number (ascending)',
        number_desc: 'Sorting by project number (descending)',
        name_asc: 'Sorting by project name (ascending)',
        name_desc: 'Sorting by project name (descending)',
        current_asc: 'Sorting by current amount outstanding (ascending)',
        current_desc: 'Sorting by current amount outstanding (descending)',
        over_30_days_asc: 'Sorting by amount outstanding over 30 days (ascending)',
        over_30_days_desc: 'Sorting by amount outstanding over 30 days (descending)',
        over_60_days_asc: 'Sorting by amount outstanding over 60 days (ascending)',
        over_60_days_desc: 'Sorting by amount outstanding over 60 days (descending)',
        over_90_days_asc: 'Sorting by amount outstanding over 90 days (ascending)',
        over_90_days_desc: 'Sorting by amount outstanding over 90 days (descending)',
        over_120_days_asc: 'Sorting by amount outstanding over 120 days (ascending)',
        over_120_days_desc: 'Sorting by amount outstanding over 120 days (descending)',
        total_asc: 'Sorting by total amount outstanding (ascending)',
        total_desc: 'Sorting by total amount outstanding (descending)',
      },
      sidesheet: {
        net_payment_is: 'Net payment is <bold>{{days}} days</bold>',
        pay_when_paid: 'Pay-when-paid',
        pay_when_paid_days: 'Pay-when-paid / <bold>{{days}} days expected</bold>',
        pay_if_paid: 'Pay-if-paid',
        pay_if_paid_days: 'Pay-if-paid / <bold>{{days}} days expected</bold>',
        add_payment_terms: 'Add payment terms in <settingsLink>project settings</settingsLink>',
        billed_to_date: 'Billed to date',
        billed_to_date_tooltip: 'Net total amount billed, excluding draft pay apps',
        collected_to_date: 'Collected to date',
        pay_apps_outstanding: 'Pay apps outstanding',
        collected_to_date_tooltip: 'Includes all pre-Siteline billing',
        collected_to_date_aging_tooltip:
          'Includes all pre-Siteline billing, minus the amount aging in pre-Siteline pay apps',
        avg_days_to_paid: 'Avg. days to paid',
        num_days: '{{count}} days',
        go_to_project: 'Go to project',
        go_to_pay_app: 'Go to pay app',
        open_invoices: 'Open invoices',
        end_of_billing_period: 'End of billing period',
        invoice_date: 'Invoice date',
        predicted_payment: 'Predicted payment',
        net_payment_due_date: 'Net payment due date',
        payment_expected_by: 'Payment expected by',
        payment_due: 'Payment due',
        pay_app_number: 'Pay App #{{payAppNumber}}',
        today: 'today',
        yesterday: 'yesterday',
        days_ago: '{{count}} days ago',
        tomorrow: 'tomorrow',
        days_until: '{{count}} days until',
        not_applicable: 'N/A',
        view_gc_contacts: 'View GC contacts',
        hide_gc_contacts: 'Hide GC contacts',
        no_gc_contacts: 'This GC has no contacts yet',
        all_invoices_paid: 'All pay apps have been marked as paid',
        no_aging_invoices: 'This project has no aging pay apps in Siteline',
      },
      export: {
        title: 'Siteline Aging Report for {{- date}}',
        generated_on: 'Generated on {{- date}}',
      },
    },
    cash_forecast: {
      search_placeholder: 'Search for projects with open invoices',
      empty_table_title: 'No projects found',
      empty_table_subtitle:
        "This table only displays projects with open invoices. It's possible that the project you're searching for doesn't have any outstanding pay apps.",
      generated_on: 'Generated on {{- date}}',
      project_name: 'Project name',
      project_number: 'Project #',
      gc: 'GC',
      cash_outstanding: 'Cash outstanding',
      progress: 'Progress',
      retention: 'Retention',
      pay_app_number: 'Pay App #',
      week_ending: 'Week ending',
      unknown: 'Unknown',
      total: 'Total',
      no_results: 'No results',
      add_dates: 'Add predicted payment dates',
      add_dates_confirmation_title: 'Add predicted payment dates to pay apps?',
      add_dates_confirmation_details:
        "This will apply to all submitted pay apps that don't already have a predicted payment date. You can update individual dates at any time, but this bulk action cannot be undone.",
      add_dates_confirmation_submit: 'Update pay apps',
      added_dates_success: 'Added a predicted payment date to {{count}} pay app',
      added_dates_success_other: 'Added predicted payment dates to {{count}} pay apps',
      empty_chart_title: 'Get started with cash forecasting',
      empty_chart_subtitle1:
        'To populate your cash forecast, ensure you have submitted pay apps with predicted payment dates',
      empty_chart_subtitle2:
        'Only pay apps with future predicted payment dates will appear. Draft pay apps and those with past prediction dates will not be shown.',
      projected_this_week: 'Projected this week',
      projected_this_month: 'Projected this month',
      open_invoice_amount: 'Open invoice amount',
      collected_amount: 'Collected amount',
      view_by: 'View by',
      month: 'Month',
      week: 'Week',
      projected: 'Projected',
      open_invoice: 'Open invoice',
      paginated_project_count: 'Displaying {{numPaginated}} of {{total}} total projects',
      retention_icon_tooltip: 'This invoice amount includes a retention pay app',
      number: '#',
      name: 'Name',
      num_projects: '{{count}} project',
      num_projects_other: '{{count}} projects',
      open_invoices: 'Open invoices',
      pre_siteline: 'Pre-Siteline',
      go_to_project: 'Go to project',
      amount_billed: 'Open amount',
      predicted_payment_date: 'Predicted payment',
      pay_app_number_label: 'Pay App #{{count}}',
      retention_only: 'Retention-only',
      partially_paid: 'Partially paid',
      joint_check: 'Joint check',
      late_payment_warning_tooltip:
        'This project contains pay apps whose predicted payment date has passed',
      late_payment_dialog_tooltip: 'The predicted payment date on this pay app has passed',
      go_to_pay_app: 'Go to pay app',
      joint_check_warning_tooltip:
        'Outstanding lower tier conditional amount exceeds invoice amount. Click to learn more.',
      joint_check_warning:
        'This project has an outstanding lower tier conditional amount (<bold>{{outstandingConditionalAmount}}</bold>) that exceeds the outstanding invoice amount (<bold>{{outstandingInvoiceAmount}}</bold>)',
      quick_bill: 'Quick bill',
      activities: {
        title: 'Cash forecasting feed',
        no_activity_title_include_name: 'No recent activity for {{- projectName}}',
        no_activity_title: 'No recent activity',
        no_activity_description: 'Cash forecasting updates and comments will be displayed here',
      },
      sort_by: {
        number_asc: 'Sorting by project number (ascending)',
        number_desc: 'Sorting by project number (descending)',
        name_asc: 'Sorting by project name (ascending)',
        name_desc: 'Sorting by project name (descending)',
      },
    },
    forecast: {
      search_placeholder: 'Search for project name or number',
      projected_this_month: 'Projected this month',
      projects: 'Projects',
      billed_key: 'Billed',
      projected_key: 'Projected',
      number: '#',
      name: 'Name',
      amount_remaining: 'Amount remaining',
      total_contract: 'Total contract',
      start_date: 'Start date',
      duration: 'Duration (mos.)',
      projected_billing: 'Projected billing',
      quick_add: 'Quick add',
      edit_presets: 'Edit presets',
      go_to_project_forecast: 'Go to project forecast',
      projects_forecasted: 'Projects forecasted',
      forecast_this_month: 'Forecasted this month',
      projected_for_month: 'Projected for {{- month}}',
      paginated_project_count: 'Displaying {{numPaginated}} of {{total}} total projects',
      no_results: 'No results',
      empty_forecast_title: 'Forecast your monthly billing',
      empty_forecast_subtitle: 'Get started by creating forecasts for the projects below',
      empty_project_list_title: 'No projects found',
      empty_project_list_subtitle: 'No projects match you filters',
      clear_filters: 'Clear filters',
      sort_by: {
        number_asc: 'Sorting by project number (ascending)',
        number_desc: 'Sorting by project number (descending)',
        name_asc: 'Sorting by project name (ascending)',
        name_desc: 'Sorting by project name (descending)',
        amount_remaining_asc: 'Sorting amount remaining (ascending)',
        amount_remaining_desc: 'Sorting amount remaining (descending)',
        total_contract_asc: 'Sorting by total contract amount (ascending)',
        total_contract_desc: 'Sorting by total contract amount (descending)',
        start_date_asc: 'Sorting by start date (earliest first)',
        start_date_desc: 'Sorting by start date (latest first)',
        duration_asc: 'Sorting by project duration (ascending)',
        duration_desc: 'Sorting by project duration (descending)',
      },
      export: {
        title: 'Siteline Billing Forecast Report for {{- date}}',
        generated_on: 'Generated on {{- date}}',
      },
    },
    export_cors: {
      summary_title: 'Siteline Aggregate Change Order Log',
      pending_title: 'Siteline Change Order Log',
      pending_subtitle:
        'This sheet includes all pending COs, including ones that have been approved but not yet added to the SOV',
      generated_on: 'Generated on {{- date}}',
      unapproved_cors: 'Unapproved CORs',
      authorized_to_proceed: 'Authorized to proceed',
      proceeding_with_work: 'Proceeding without executed CO***',
      approved_cors: 'Approved CORs',
      original_contract: 'Original Contract',
      revised_contract: 'Revised Contract',
      total_requested: 'Total outstanding requests',
      total_draft: 'Total draft',
      total_approved: 'Total approved',
      number: '#',
      project_name: 'Project name',
      gc: 'GC',
      lead_pm: 'Lead PM',
      date_created: 'Date created',
      name: 'Name',
      internal_number: 'Internal number',
      gc_number: 'GC number',
      status: 'Status',
      proposed_amount: 'Proposed amount',
      gc_approved_amount: 'GC approved amount',
      schedule_impact: 'Schedule impact',
      date_submitted: 'Date submitted',
      date_approved: 'Date approved',
      reason: 'Reason',
      total: 'Total',
      statuses: {
        APPROVED: 'Approved',
        DRAFT: 'Draft',
        REJECTED: 'Rejected',
        SIGNED: 'Signed',
        SUBMITTED: 'Submitted',
        AUTHORIZED_TO_PROCEED: 'Authorized to proceed',
        added_to_sov: 'Added to sov',
      },
    },
    billing_title: 'Siteline Billing Report for {{- month}}',
    aging_title: 'Siteline Aging Report for {{- month}}',
    forecast_title: 'Siteline Forecast Report for {{- month}}',
    generated_at: 'Generated at {{- dateTime}}',
    tips_and_help: 'Tips & help',
    month_over_month: '{{percent}}% MoM',
    month_over_month_null: 'MoM',
    view_report_link: 'View report',
    view_tracker_link: 'View tracker',
    download: 'Download',
    export: 'Export',
    import_export: 'Import/Export',
    download_report: 'Download report',
    export_as_pdf: 'Export current view as PDF',
    export_current_view_to_excel: 'Export current view to Excel',
    export_to_excel: 'Export to Excel',
    export_current_view: 'Export current view',
    export_by_line_item: 'Export by line item',
    export_to_foundation: 'Export to Foundation',
    export_to_quickbooks: 'Export to QuickBooks',
    export_to_computer_ease: 'Export to ComputerEase',
    bulk_import_job_costs: 'Bulk import job costs',
    download_cash_flow_forecasts: 'Download cash flow forecasts',
    download_monthly_report: 'Download monthly report',
    export_change_orders: 'Export pending COs',
    pdf_confirm_open_print:
      'Clicking “Download” will launch the Print dialog. To save as a PDF, change the printer destination to “Microsoft Print to PDF” or “Save as PDF”.',
  },
  onboarding_projects: {
    number: '#',
    name: 'Name',
    incomplete_tasks: 'Incomplete tasks',
    form_building_status: 'Form building status',
    lead_pm: 'Lead PM',
    pay_app_due_to_gc: 'Pay app due to GC',
    all_onboarded: 'All your projects are fully onboarded!',
    no_search_results: 'No projects match your current search criteria',
    no_filter_results: 'No projects match your current filter criteria',
    go_to_project_billing: 'Go to project billing',
    project_onboarding_guide: 'Project onboarding guide',
    num_projects: '{{count}} project',
    num_projects_other: '{{count}} projects',
    only_incomplete_projects: 'Only projects with incomplete tasks or forms are shown',
    incomplete_task: {
      select_forms: 'Select forms',
      missing_sov: 'Missing SOV',
      select_rate_table: 'Select a rate table',
      add_team_members: 'Add team members',
      add_gc_contacts: 'Add GC contacts',
      create_pay_app: 'Create pay app',
      complete: 'Complete',
      incomplete: 'Incomplete',
    },
    forms_status: {
      received: 'Received',
      building: 'Building',
      finalizing: 'Finalizing',
      complete: 'Complete',
      pay_app: 'Pay app',
      primary_lien_waiver: 'Primary lien waiver',
      vendor_lien_waiver: 'Vendor lien waiver',
      change_order_request: 'Change order request',
    },
    sort_by: {
      number_asc: 'Sorting by project number (ascending)',
      number_desc: 'Sorting by project number (descending)',
      name_asc: 'Sorting by project name (ascending)',
      name_desc: 'Sorting by project name (descending)',
      tasks_asc: 'Sorting by first task incomplete',
      tasks_desc: 'Sorting by last task incomplete',
      form_status_asc: 'Sorting by form status (incomplete first)',
      form_status_desc: 'Sorting by form status (complete first)',
      due_date_asc: 'Sorting by due date (ascending)',
      due_date_desc: 'Sorting by due date (descending)',
    },
  },
  fully_billed: {
    number: '#',
    name: 'Name',
    retention_held: 'Retention held',
    retention_outstanding: 'Retention outstanding',
    progress_outstanding: 'Progress outstanding',
    lead_pm: 'Lead PM',
    last_edited: 'Last edited',
    next_step: 'Next step',
    bill_for_retention: 'Bill for retention',
    archive_project: 'Archive project',
    waiting_for_payment: 'Waiting for payment',
    go_to_project_billing: 'Go to project billing',
    none_fully_billed: 'None of your active projects are currently fully billed',
    no_filter_results: 'No projects match your current filter criteria',
    no_search_results: 'No projects match your current search criteria',
    sort_by: {
      number_asc: 'Sorting by project number (ascending)',
      number_desc: 'Sorting by project number (descending)',
      name_asc: 'Sorting by project name (ascending)',
      name_desc: 'Sorting by project name (descending)',
      retention_held_asc: 'Sorting by retention held (ascending)',
      retention_held_desc: 'Sorting by retention held (descending)',
      retention_outstanding_asc: 'Sorting by retention outstanding (ascending)',
      retention_outstanding_desc: 'Sorting by retention outstanding (descending)',
      progress_outstanding_asc: 'Sorting by progress outstanding (ascending)',
      progress_outstanding_desc: 'Sorting by progress outstanding (descending)',
      last_edited_asc: 'Sorting by last edited (earliest first)',
      last_edited_desc: 'Sorting by last edited (latest first)',
      next_step_asc: 'Sorting by next step (paid last)',
      next_step_desc: 'Sorting by next step (paid first)',
    },
  },
  billing_projections: {
    create_dialog: {
      create_forecast: 'Create forecast',
      create_quick_forecast: 'Create quick forecast',
      update_forecast: 'Update forecast',
      forecast_with_inputs: "Forecast your project's monthly billing with a few inputs",
      projected_billing_start: 'Projected billing start',
      billing_start: 'Billing start',
      projected_duration: 'Projected duration (months)',
      projected_end_date: 'Projected end date',
      total_contract: 'Total contract',
      projection_curve: 'Projection curve',
      min_duration: "The forecast's duration must be at least {{count}} months",
      min_duration_future:
        'The projected duration must be at least {{count}} months to include months in the future',
      curve_description:
        'This gives you a quick way to project out the billing across the project timeline. You can enter exact values once the forecast is created, or test other curves.',
      curve_only_description:
        'This gives you a quick way to project out the billing across the project timeline. If billing has already started, the curve will be cropped.',
      frontload: 'Frontload',
      bell: 'Bell',
      flat: 'Flat',
    },
  },
  integrations: {
    button: {
      sync_invoice: 'Sync invoice to {{integrationName}}',
      sync_lien: 'Sync vendor lien waivers to {{integrationName}}',
      sync_to: 'Sync to {{integrationName}}',
      sync: 'Sync',
    },
    last_sync_time: 'Synced at {{date}}',
    never_synced: 'Never synced',
    internal_error: 'An internal error occurred. Please try again.',
    loading: {
      title: {
        pay_app: 'Syncing invoice to {{integrationName}}',
        pay_app_line_items: 'Syncing invoice to {{integrationName}}',
        lien_waivers: 'Syncing lien waivers to {{integrationName}}',
        legal_requirement: 'Syncing compliance to {{integrationName}}',
      },
      description: "Sync to {{integrationName}} in progress. Please don't navigate away.",
    },
    success: {
      title: 'Sync completed',
      description: {
        submit:
          'You can sign & submit on {{integrationName}} now. A copy of the information you synced was sent to your email.',
        synced:
          'Successfully synced to {{integrationName}}. A copy of the information you synced was sent to your email.',
      },
      action_view: 'View on {{integrationName}}',
      action_submit: 'Submit on {{integrationName}}',
    },
    success_legal_requirement: {
      title: 'Sync completed',
      description: 'Your compliance has been updated on {{integrationName}}',
      action: 'View on {{integrationName}}',
    },
    failure: {
      title: 'Sync could not be completed',
      sync_again: 'Sync again',
      import_and_sync: 'Import line items and sync again',
      go_to_sov: 'Go to SOV',
      round_and_sync: 'Round line items and sync again',
      journal_needed: 'Sage troubleshooting',
      open: 'Open on {{integrationName}}',
      adjust_retention: 'Adjust pay app retention',
      mark_as_synced: 'Mark as synced',
      description: 'Please address the following error and try again',
      sub_title: {
        pay_app: 'Invoice',
        legal_requirement: '{{- requirementName }}',
        lien_waiver: '{{- vendorName}} lien waiver',
        LIEN_WAIVER: '{{- companyName}} lien waiver',
      },
    },
    deferred: {
      title: 'Automatic sync pending',
      sub_title: 'No further action required',
      action: 'Ok',
      description:
        "We'll sync this pay app for you when the GC opens the Textura draw.\n\nYou'll get an email when the sync is complete.",
    },
    still_running: {
      title: 'Sync still in progress',
      action: 'Ok',
      description:
        "This sync is taking more time than expected. We'll keep processing it in the background. You'll get an email when the sync is complete.",
    },
    queued_in_hh2: {
      title: 'Invoice has been queued',
      description:
        "{{- entityDescription }} has been queued but not yet created in Sage. We'll monitor it in the background and email you when the invoice is ready.",
    },
    confirmation_dialog: {
      title: {
        pay_app: 'Sync invoice to {{integrationName}}',
        pay_app_line_items: 'Sync invoice to {{integrationName}}',
        legal_requirement: 'Sync compliance to {{integrationName}}',
        lien_waivers: 'Sync lien waivers to {{integrationName}}',
      },
      sub_title: 'The following will be synced to {{integrationName}}:',
      description: 'You will receive a copy of the PDF in your email.',
      invoice_name: 'Invoice',
    },
    sage_100_sync_dialog: {
      header: 'Sync AR invoice to Sage',
      create_in_sage: 'Create this invoice in Sage',
      amount: 'Total amount',
      invoice_number: 'Invoice #',
      invoice_date: 'Invoice date',
      due_date: 'Due date',
      due_date_tooltip: 'By default, payment is due 30 days after the invoice date',
      tax_account: 'Tax income account',
      tax_amount: 'Tax amount',
      income_account: 'Income account',
      income_account_tooltip:
        'If your income account has subsidiary acccounts, Sage requires you to select a subaccount to post an invoice',
      income_amount: 'Income amount',
      taxes: 'Tax calculation',
      no_tax: "Don't record taxes on the AR invoice",
      tax_district: 'Use Sage tax district',
      tax_separate_items: 'Record taxes as separate invoice item',
      search_accounts: 'Search general ledger accounts',
      loading_accounts: 'Loading general ledger accounts...',
      generate_code: 'Generate from Sage',
      no_generated_code: 'We were unable to generate an invoice code, please enter one manually',
      invoice_code_length_long: "Can't be longer than 15 characters",
      no_default_invoice_code:
        'The default invoice code is too long, please enter one manually or generate from Sage',
      amount_info: 'Includes progress, materials, and retention',
      help_center: 'Read our Help Center guide',
      record_taxes: 'Record taxes separately',
      tax_line_items: 'Tax line items',
      search_sov: 'Search SOV',
      no_line_items: 'Your search did not match any line items',
      num_line_items_zero: 'Select line items',
      num_line_items: '{{count}} line item',
      num_line_items_other: '{{count}} line items',
      due_date_tooltip_terms: 'Due date is based on contract payment terms',
      errors: {
        multiple_syncs: 'More than one sync request to Sage exists',
        no_billing:
          "You haven't updated the invoice yet. Please add billing for this month and try again.",
        tax_on_retention:
          'Cannot sync taxes to Sage because your contract settings apply tax to retention, which Sage does not support. Please adjust your contract settings and try again.',
      },
    },
    foundation_sync_dialog: {
      header: 'Sync AR invoice to Foundation',
      create_in_sage: 'Create this invoice in Foundation',
      amount: 'Total amount',
      generate_code: 'Generate from Foundation',
      invoice_number: 'Invoice number',
      invoice_date: 'Invoice date',
      due_date: 'Due date',
      due_date_tooltip_terms: 'Due date is based on contract payment terms',
      due_date_tooltip: 'By default, payment is due 30 days after the invoice date',
      general_ledger_account: 'GL account',
      retention_ledger_account: 'Retention GL account',
      search_accounts: 'Search general ledger accounts',
      loading_accounts: 'Loading general ledger accounts...',
      income_type: 'Income type',
      enter_manually: 'Enter manually',
      search_income_types: 'Search income types',
      loading_income_types: 'Loading income types...',
      generate_number: 'Generate from Foundation',
      no_generated_number:
        'We were unable to generate an invoice number, please enter one manually',
      invoice_number_length_long: "Can't be longer than {{maxChars}} characters",
      no_default_invoice_number:
        'The default invoice number is too long, please enter one manually or generate from Foundation',
      retention_info:
        'Retention will be automatically applied according to the job settings in Foundation',
      help_center: 'Read our Help Center guide',
      errors: {
        multiple_syncs: 'More than one sync request to Foundation exists',
        billing_retention:
          'Syncing pay apps that are billing for retention is currently not supported. Please create the invoice in Foundation manually.',
        no_billing:
          "You haven't updated the invoice yet. Please add billing for this month and try again.",
      },
      use_default_ledger_account: 'Use one ledger account for all items',
      use_multiple_ledger_accounts: 'Assign ledger accounts by SOV line item',
      select_default_ledger_account: 'Select default ledger account',
      spreadsheet: {
        line_item_id_column_header: 'Line item',
        line_item_name_column_header: 'Name',
        scheduled_value_column_header: 'Scheduled value',
        current_billed_column_header: 'Current billed',
        ledger_account_column_header: 'Ledger account',
      },
    },
    pay_app_line_items_sync_dialog: {
      matching_sov: 'Matching SOV to {{integrationName}}',
      moment: 'This may take a moment',
      header: 'Sync AR invoice to {{integrationName}}',
      create_in_integration: 'Create this invoice in {{integrationName}}',
      did_not_match: "{{count}} line item didn't match",
      did_not_match_other: "{{count}} line items didn't match",
      allocate: 'Please allocate to available line items on your {{integrationName}} contract',
      all_allocated: 'All billing allocated on your {{integrationName}} contract',
      reset: 'Reset changes',
      remainder: 'Remainder',
      enter_manually: 'Enter manually',
      edit_manually: 'Edit manually',
      auto_allocate: 'Auto-allocate',
      help_center: 'Read our Help Center guide',
      allocate_help:
        "Automatically distributes the remaining balance to line items that aren't fully billed, starting from the top of the SOV. This replaces any amounts you manually entered.",
      invoice_date: 'Invoice Date',
      due_date: 'Due Date',
      invoice_code: 'Invoice Code',
      generate_code: 'Generate from {{- integrationName}}',
      no_generated_code: 'We were unable to generate an invoice code, please enter one manually',
      no_default_invoice_code: 'The default invoice code is too long, please enter one manually',
      no_default_invoice_code_generate:
        'The default invoice code is too long, please enter one manually or generate from {{- integrationName}}',
      code_length_long: "Can't be longer than {{maxLength}} characters",
      batch: 'Batch',
      draw: 'Draw',
      reference: 'Reference',
      total: 'Total',
      code: 'Code',
      description: 'Description',
      scheduled_value: 'Scheduled value',
      previous_billed: 'Previous billed',
      tax_group: 'Tax group',
      search_tax_groups: 'Search tax groups',
      amount: 'Amount',
      units: 'Units',
      retainage: 'Retainage',
      retainage_released: 'Retainage released',
      bold: 'Bolded values will be updated in {{integrationName}}',
      errors: {
        multiple_syncs: 'More than one sync request to {{integrationName}} exists',
        need_billing: 'At least one line item with billing must be selected',
        refetch_sov:
          'There was an issue fetching your contract from {{integrationName}}. Please try again.',
        billing_retention:
          'Syncing pay apps that are billing for retention is currently not supported. Please create the invoice in {{integrationName}} manually.',
        no_contract:
          'The contract in {{integrationName}} does not have any line items on it. Please add at least one line item and try again.',
        no_billing:
          "You haven't updated the invoice yet. Please add billing for this month and try again.",
        allocate_too_much:
          'You entered more money than you billed for this month. Please enter less or you can "auto-allocate" the remainder.',
      },
    },
    bulk_import_dialog: {
      body: 'Select all projects that you want to import into Siteline. If you recently made changes in {{integrationName}}, it may take up to 10 minutes to be reflected in Siteline.',
      no_projects:
        'All projects from {{integrationName}} have already been added to Siteline. If you recently made changes in {{integrationName}}, it may take up to 10 minutes to show up. If you have any questions, please reach out to support@siteline.com.',
      number: 'Number',
      project: 'Project',
      general_contractor: 'General contractor',
      existing_contract: 'Link to existing project',
      search_projects: 'Search projects',
      sort_by: {
        number_asc: 'Sorting by project number (ascending)',
        number_desc: 'Sorting by project number (descending)',
        name_asc: 'Sorting by project name (ascending)',
        name_desc: 'Sorting by project name (descending)',
        gc_name_asc: 'Sorting by general contractor name (ascending)',
        gc_name_desc: 'Sorting by general contractor name (descending)',
      },
    },
  },
  bulk_import: {
    xlsx_xls_csv: 'xlsx, xls, or csv',
    download_template: 'Download template',
    projects: {
      new_projects: 'New projects',
      bulk_create_projects: 'Bulk create projects',
      using_our_template:
        'Using our formatted Excel template will allow us to create your projects automatically',
      enter_your_projects: 'Enter your project info into our template',
      project_import: 'ProjectImport.xlsx',
      upload_template: 'Upload completed template',
      project_info: 'Project info',
      add_other_info:
        'You will be able to select the billing type for these projects after uploading.\n\nIf importing multiple billing types, you can upload projects in multiple batches.',
      add_other_info_office:
        'You will be able to select the billing type and office for these projects after uploading.\n\nIf importing for multiple billing types or offices, upload projects in multiple batches.',
      ready_to_import: 'Ready to import',
      create_projects: 'Create project',
      create_projects_other: 'Create projects',
      create_subtitle: 'Select the billing type for the new project',
      create_subtitle_other: 'Select the billing type for the {{count}} new projects',
      create_subtitle_office: 'Select the billing type and office for the new project',
      create_subtitle_office_other:
        'Select the billing type and office for the {{count}} new projects',
      creating_projects:
        'Your project is being imported. You can close this page, we will email you when the import is complete.',
      creating_projects_other:
        'Your projects are being imported. You can close this page, we will email you when the import is complete.',
      import_status: '{{numCompleted}} of {{numTotal}} processed',
      success: 'Successfully imported {{count}} project',
      success_other: 'Successfully imported {{count}} projects',
      go_to_billing_home: 'Go to billing home',
      import_more_projects: 'Import more projects',
      office: 'Office',
      billing_type: 'Billing type',
      lump_sum: 'Lump sum',
      unit_price: 'Unit price',
      import_error: {
        match_template: 'Uploaded file does not match expected project info template',
      },
    },
    import_error: {
      match_template: 'Uploaded file does not match the expected template',
      missing_value: 'A value is missing in the following required column: "{{- columnName}}"',
      no_results:
        'No valid rows were found in the uploaded template. Please review your file and try again.',
      date_format:
        'A value in the "{{- columnName}}" column is not a valid date format: "{{- value}}"',
      number_format:
        'A value in the "{{- columnName}}" column is not in a valid format: "{{- value}}"',
      invalid_value: 'An invalid value was found in the following column: "{{- columnName}}"',
      dollar_format:
        'A value in the "{{- columnName}}" column is not a valid dollar amount: "{{- value}}"',
      quantity_amount:
        'A value in the "{{- columnName}}" column should be a quantity, not a dollar amount: "{{- value}}"',
      quantity_format:
        'A value in the "{{- columnName}}" column is not a valid quantity: "{{- value}}"',
    },
  },
  common: {
    all: 'All',
    yes: 'Yes',
    no: 'No',
    beta: 'Beta',
    none: 'None',
    actions: {
      cancel: 'Cancel',
      close: 'Close',
      clear: 'Clear',
      delete: 'Delete',
      dismiss: 'Dismiss',
      got_it: 'Got it',
      back: 'Back',
      next: 'Next',
      edit: 'Edit',
      view: 'View',
      confirm: 'Confirm',
      submit: 'Submit',
      submitting: 'Submitting',
      refreshing: 'Refreshing',
      remove: 'Remove',
      resubmit: 'Resubmit',
      revise: 'Revise',
      upload: 'Upload',
      fill_out: 'Fill out',
      download: 'Download',
      download_draft: 'Download draft',
      update: 'Update',
      send: 'Send',
      create: 'Create',
      save: 'Save',
      add: 'Add',
      undo: 'Undo',
      print: 'Print',
      print_draft: 'Print draft',
      continue: 'Continue',
      complete: 'Complete',
      select: 'Select',
      enter_fullscreen: 'Enter fullscreen',
      exit_fullscreen: 'Exit fullscreen',
      start: 'Start',
      ok: 'OK',
      skip: 'Skip',
      done: 'Done',
      reset: 'Reset',
      retry: 'Retry',
      select_all: 'Select all',
      deselect_all: 'Deselect all',
      search: 'Search',
      see_more: 'See more',
      see_less: 'See less',
      import: 'Import',
      export: 'Export',
    },
    pdfs: {
      error: 'There was an error generating your PDFs. Please try again.',
    },
    states: {
      creating: 'Creating...',
      updating: 'Updating...',
      processing: 'Processing',
    },
    companies: {
      OWNER_DEVELOPER: 'Owner',
      GENERAL_CONTRACTOR: 'General Contractor',
      SELF_PERFORM: 'General Contractor',
    },
    errors: {
      error: 'Error',
      not_found: 'Not found',
      snackbar: {
        generic: 'An error occurred, please refresh the page and try again',
      },
      dates: {
        disablePast: 'Cannot use a date that has already past',
        disableFuture: 'Cannot use a date in the future',
        minDate: 'Date cannot be before {{- minDate}}',
        maxDate: 'Date cannot be after {{- maxDate}}',
        invalidDate: 'Please enter a valid date',
      },
      incomplete_fields: 'Please fill out all necessary information before saving',
    },
    sidebar: {
      hide: 'Hide sidebar',
    },
    spreadsheet: {
      not_a_number: 'Values in this column must be numbers',
      not_a_date: 'Cells in this column must be valid dates',
      reordering_disabled_search: 'Cannot reorder while searching',
      delete_row_error: 'Something went wrong attempting to delete this row',
      no_thanks: 'No thanks',
      context_menu: {
        add_row_above: 'Add row above',
        add_row_below: 'Add row below',
      },
    },
    strings: {
      concatenate_two: '{{first}} and {{second}}',
    },
  },
  forms: {
    conditional_lien_waiver: 'Conditional Lien Waiver',
    unconditional_lien_waiver: 'Unconditional Lien Waiver',
    remove_form: 'Remove {{- userVisibleName}}',
    lower_tier_lien_waiver: {
      pdf_error: 'Could not load lien waiver',
      pdf_error_retry: 'Try again',
      through_date: 'Through {{date}}',
      via_vendor: 'via {{- vendorName}}',
      go_next: 'Go to next lien waiver',
      navigate_away_warning:
        'Leaving this tab will clear all form fields and signatures. Are you sure you want to continue?',
      request: {
        requested: 'Lien waiver requested',
        submitted: 'Lien waiver submitted!',
        copy_sent_to_email: 'A copy has been sent to your email.',
        conditional_progress: 'Conditional Progress Lien Waiver',
        unconditional_progress: 'Unconditional Progress Lien Waiver',
        conditional_final: 'Conditional Final Lien Waiver',
        unconditional_final: 'Unconditional Final Lien Waiver',
        requested_by: 'requested by <bold>{{- name}}</bold>',
        upload_button: 'Print template and upload completed forms',
        sign_digitally: 'Sign digitally',
        get_started: 'Get started',
        from_sender: 'From <bold>{{- name}}</bold>',
        legal_note_tos:
          'Use of the Siteline Platform is governed by the <termsOfServiceLink>Terms of Service</termsOfServiceLink> and <privacyPolicyLink>Privacy Policy</privacyPolicyLink>.',
        legal_note_optout:
          'You can opt out of digital signatures by <uploadLink>uploading a completed lien waiver</uploadLink>.',
        sign: 'Sign',
        upload: 'Upload',
        error: 'Something went wrong. Please try again shortly.',
        print_template: 'Print template',
        download_template: 'Download template',
      },
      upload: {
        error: '{{- name}} is not a valid attachment. Please upload a pdf.',
        action: 'Upload lien waiver',
        upload_pdf: 'Document must be in pdf format',
        upload_notarized: 'Upload notarized forms',
        ready_to_submit: 'Ready to submit',
        amount_of_check: 'Amount of check (required)',
        amount_paid: 'Amount paid (required)',
        amount: 'Amount',
      },
      review: 'Review and sign lower-tier lien waiver',
      canceled: {
        primary: 'Lien waiver request canceled',
        sub: 'This lien waiver is no longer needed',
      },
      confirmation: {
        submit_conditional: 'Submit conditional lien waiver',
        submit_unconditional: 'Submit unconditional lien waiver',
        message: 'Your lien waiver will be sent to {{- companyName}}',
        missing_amount_of_check: 'You must enter the amount of the check before submitting',
        missing_amount_paid: 'You must enter the amount paid before submitting',
      },
      complete: {
        primary: 'Your lien waiver has been sent to\n{{- companyName}}',
      },
      not_needed: {
        primary: "You're all set",
        sub: 'This lien waiver has already been completed',
      },
      resubmit_to_gc: {
        title: 'Re-send lien waiver?',
        description:
          'This lien waiver has already been sent to the General Contractor. Are you sure you want to re-send it?',
        success: 'The lien waiver was sent to {{- companyName}} on {{date}}',
      },
      plg_experiment: {
        title: 'Get Paid Weeks Faster with Siteline',
        body: 'This lightning fast and super simple lien waiver process was made possible by Siteline — the only collaborative billing tool purpose-built for trade contractors and construction vendors.',
        learn_more: 'Learn more',
      },
    },
    legal_document: {
      document_requested: '{{- name}} requested',
      submit_document: 'Submit document',
      message: 'Your document will be sent to {{- companyName}}',
      expiration_warning:
        'Your selected expiration date is in the next month. If this is incorrect, please close and update the expiration date before submitting.',
      expiration_date: 'Expiration date (required)',
      download_form: 'Download form',
      downloading_form: 'Downloading form...',
      upload_document: 'Upload document',
      canceled: {
        primary: 'Request canceled',
        sub: 'This document is no longer needed',
      },
      complete: {
        primary: 'Your document has been sent to\n{{- companyName}}',
      },
      not_needed: {
        primary: "You're all set",
        sub: 'This request has already been completed',
      },
      submitted: '{{- documentName}} submitted!',
      unknown: 'Unknown',
    },
    annotations: {
      add_signature: 'Add signature',
      signature: 'Signature',
      required: 'Required',
      optional: 'Optional',
      notary: 'Notary',
      witness: 'Witness',
      wet: 'Wet',
      click_to_sign: 'Click or press enter to sign',
      tooltip_required: '{{- name}}',
      tooltip_required_other: '{{- name}} (editing {{count}} locations)',
      tooltip_optional: '{{- name}} (optional)',
      tooltip_optional_other: '{{- name}} (optional, editing {{count}} locations)',
      requires_notarization: 'Requires notarization',
      requires_witness: 'Requires witness signature',
      requires_wet: 'Requires wet signature',
      notary_download: 'Download & print for notary after completing forms',
      witness_download: 'Download & print for witness after completing forms',
      wet_download: 'Download & print for wet signature after completing forms',
      required_fields: {
        completed: 'Required fields completed',
        remaining: 'Required field remaining',
        remaining_other: 'Required fields remaining',
      },
    },
    help: {
      see_an_issue: 'See an issue with this form?',
      get_help: 'Get help right away',
      subject: 'Priority form help request',
      body: "What is the problem you're experiencing?\nPlease explain in as much detail as possible, and include a screenshot if you can:\n\n\n\n\n\n\n\nUser: {{user}}\nProject: {{project}}\nForm name: {{formName}}\nForm ID: {{formId}}\nDeadline: {{deadline}}\n{{operatingSystem}}, {{browser}}",
    },
  },
  disable_audit: {
    title: 'Remove digital signature audit',
    details:
      'This will remove audit trails from all forms generated for this project. You can add them back in project settings.',
    confirm: 'Remove',
    updated: 'Signature audit trails have been disabled on this project',
  },
  rate_tables: {
    rate_table: 'Rate table',
    helmet_rate_tables: 'Siteline | Rate tables',
    helmet_rate_table: 'Siteline | {{- name}}',
    helmet_upload_rate_table: 'Siteline | Upload rate table',
    search_placeholder: 'Search for a rate table by name',
    rate_table_created: 'Rate table created',
    rate_table_duplicated: 'Rate table duplicated',
    rate_table_selected: 'Rate table selected',
    rate_table_unarchived: 'Rate table restored',
    rate_table_archived: 'Rate table archived',
    rate_table_deleted: 'Rate table deleted',
    rate_table_updated: 'Rate table saved',
    go_to_rate_table: 'Go to rate table',
    use_different_rate_table: 'Use a different rate table',
    select_rate_table: 'Select a rate table',
    new: 'New rate table',
    choose_new_rate_table: 'Choose a different rate table',
    select_rate_table_project_subtitle: 'This selection will apply to all future created pay apps',
    num_line_items: '{{count}} line item',
    num_line_items_other: '{{count}} line items',
    empty_state: {
      no_results: 'No rate tables found',
      no_rate_tables: 'No rate tables',
      helper_text:
        'Add a new rate table to start submitting Time and Materials orders & pricing out change order requests',
    },
    update_confirmation: {
      update_table_warning_title: 'This rate table has linked projects',
      update_table_warning_tm:
        '{{- projectName}} uses this rate table. Changes made here will be applied to future created pay apps but will not be reflected on any current pay apps. Changes to groups may affect project fees.',
      update_table_warning_tm_other:
        'There are {{count}} projects using this rate table. Changes made here will be applied to future created pay apps but will not be reflected on any current pay apps. Changes to groups may affect project fees.',
      update_table_warning_cors:
        '{{- projectName}} uses this rate table. Changes made here will be applied to future priced change order requests but will not be reflected on any current change order requests that already use the rate table. Changes to groups may affect project fees.',
      update_table_warning_cors_other:
        'There are {{count}} projects using this rate table. Changes made here will be applied to future priced change order requests but will not be reflected on any change order requests that are already priced using the rate table. Changes to groups may affect project fees.',
      update_table_warning_tm_cors:
        '{{- projectName}} uses this rate table. Changes made here will be applied to future created change order requests and pay apps but will not be reflected on any current pay apps or change order requests that are already priced. Changes to groups may affect project fees.',
      update_table_warning_tm_cors_other:
        'There are {{count}} projects using this rate table. Changes made here will be applied to future created pay apps and change order requests that have not yet been priced but will not be reflected on any current pay apps or change order requests that already use the rate table. Changes to groups may affect project fees.',
      swap_rate_table_warning_title: 'This change may affect project fees',
      swap_rate_table_warning_tnm:
        'This change may result in the deletion of project fees if the new rate table does not have the same groupings. This change will not affect pay apps that have already been created.',
      swap_rate_table_warning_cors:
        'This change may result in the deletion of project fees if the new rate table does not have the same groupings. This change will not affect change order requests that have already been created.',
    },
    validations: {
      missing_name: 'Please provide a name for this rate table',
      empty_items: 'All rates must have a description',
      empty_groups: 'All groups must have a name',
      incorrect_date_order: 'The effective date must occur before the expiration date',
      empty_groups_title: 'Empty groups will be removed',
      empty_groups_details:
        'Group headers with no line items will be removed. Are you sure you want to continue?',
      duplicate_groups: 'Each group must have a unique name',
    },
    delete_dialog: {
      title_with_archive: 'Are you sure you want to delete {{- name}}?',
      title_already_archived: 'Delete {{- name}}?',
      details_with_archive:
        'You will not be able to use this rate table for time and materials billing or pricing your change orders.\n\n<bold>This CANNOT be undone.</bold> If this rate table is no longer active but should remain in Siteline, please archive it instead.',
      details_already_archived:
        'Are you sure you want to delete {{- name}}?\n\nYou will not be able to use this rate table for time and materials billing or pricing your change orders.',
      archive_instead: 'Archive instead',
    },
    archive_dialog: {
      title: 'Are you sure you want to archive {{- name}}?',
      details:
        'Your team will not be able to make changes to this rate table once it is archived.\n\nYou can restore this rate table at any time.',
    },
    actions_menu: {
      duplicate: 'Duplicate',
      archive: 'Archive',
      delete: 'Delete',
      restore: 'Restore',
    },
    status_filters: {
      all_statuses: 'All statuses',
      active: 'Currently active',
      upcoming: 'Upcoming',
      expired: 'Expired',
      archived: 'Archived',
    },
    create_dialog: {
      title_1: 'How do you want to get started?',
      title_2: 'New rate table',
      enter_manually_title: 'Enter manually',
      enter_manually_subtitle: 'Fill out your rate table directly in Siteline',
      import_excel_title: 'Import',
      import_excel_subtitle: 'Upload an existing rate table from Excel',
      create_new: 'Create a new rate table',
      name: 'Rate table name*',
      effective: 'Effective date (optional)',
      expiration: 'Expiration date (optional)',
    },
    excel_upload_page: {
      title: 'Import your rate table from Excel',
      subtitle:
        'Your rate table will be imported automatically when you use our formatted Excel template',
      filename: 'RateTable.xlsx',
      format_title: 'Paste your rate table into our template',
      format_instructions:
        'To make a line item group, add an asterisk before the group name, e.g. Materials → *Materials.\nYou can end a group with a new group header, or with a row containing only an asterisk (*).',
      upload_formatted: 'Upload formatted rate table',
      dropzone_title: 'Rate table',
      manual_instead: 'Enter manually instead',
      import_error: {
        match_template: 'Uploaded file does not match expected Excel template',
      },
    },
    library: {
      column_titles: {
        name: 'Name',
        status: 'Status',
        effective: 'Effective on',
        expires_on: 'Expires on',
        num_projects: 'No. of linked projects',
        last_edited: 'Last edited',
      },
      status_chips: {
        active: 'Currently active',
        upcoming: 'Upcoming',
        expired: 'Expired',
        archived: 'Archived',
      },
      sort_by: {
        name_asc: 'Sorting by rate table name (ascending)',
        name_desc: 'Sorting by rate table name (descending)',
        status_asc: 'Sorting by amount status label (ascending)',
        status_desc: 'Sorting by amount status label (descending)',
        effective_asc: 'Sorting by effective date (ascending)',
        effective_desc: 'Sorting by effective date (descending)',
        expiration_asc: 'Sorting by expiration date (ascending)',
        expiration_desc: 'Sorting by expiration date (descending)',
        num_linked_asc: 'Sorting by number of linked projects (ascending)',
        num_linked_desc: 'Sorting by number of linked projects (descending)',
        last_edited_asc: 'Sorting by date last edited (ascending)',
        last_edited_desc: 'Sorting by date last edited (descending)',
      },
    },
    details: {
      name: 'Name*',
      name_sub: '',
      date_range: 'Date range',
      rates: 'Rates',
      linked_projects: 'Linked projects',
      previously_linked_cor_tooltip:
        'This project has change order requests that are using this rate table',
      previously_linked_pay_app_tooltip: 'This project has pay apps that are using this rate table',
      effective_date: 'Effective',
      expires_date: 'Expires',
      add_effective_date: 'Add effective date',
      add_expiration: 'Add expiration date',
      effective_on: 'Effective on {{date}}',
      expires_on: 'Expires on {{date}}',
      banner_subtitle: 'You can still view and export data',
      restore_rate_table: 'Restore rate table',
      previously_linked: 'Previously linked',
      navigate_away_warning:
        'You have not yet saved your rate table. Are you sure you want to leave?',
      add_rate_above: 'Add rate above',
      add_rate_below: 'Add rate below',
      delete_group_confirmation: {
        title: 'Are you sure you want to delete {{- name}}?',
        details:
          'Deleting {{- name}} will delete all associated line items. This action cannot be undone.',
      },
      spreadsheet: {
        headers: {
          cost_code: 'Cost code',
          code: 'Cost code (optional)',
          description: 'Description',
          unit_name: 'Unit',
          unit_rate: 'Price',
          percent: 'Percent',
        },
        groups: {
          materials: 'Materials',
          equipment: 'Equipment',
          labor: 'Labor',
        },
        add_rate: 'Add rate',
        add_fee: 'Add fee',
        add_group: 'Add new rate group',
        add_rate_disabled: 'You must add a group before adding line items',
      },
      export: {
        generated_on: 'Generated on {{- date}}',
      },
    },
  },
}

export default translation
