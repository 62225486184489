import { Collapse } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useNavigate } from '@tanstack/react-router'
import clsx from 'clsx'
import { Decimal } from 'decimal.js'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BillingType,
  IntegrationTypeFamily,
  StoredMaterialsCarryoverType,
  TaxCalculationType,
  centsToDollars,
  dollarsToCents,
  dollarsToUnitPriceCents,
  getIntegrationName,
  percentToDecimal,
  roundCents,
  updatedPreSitelineRetentionForPreSitelineBillingUpdate,
} from 'siteline-common-all'
import {
  Permission,
  RetentionTrackingLevel,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { DeleteGroupConfirmation } from '../../../common/components/DeleteGroupConfirmation'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { useSitelineConfirmation } from '../../../common/components/SitelineConfirmation'
import {
  Spreadsheet,
  SpreadsheetHandle,
  SpreadsheetProps,
} from '../../../common/components/Spreadsheet/Spreadsheet'
import {
  SpreadsheetFooterRow,
  SpreadsheetRow,
  SpreadsheetValue,
  makeDividerRow,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { ContextMenuLabels } from '../../../common/components/Spreadsheet/SpreadsheetRowContextMenu'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { TaxGroupProperties } from '../../../common/graphql/apollo-operations'
import { getIntegrationOfFamily } from '../../../common/util/Integration'
import { ManageLumpSumSovColumn } from '../../../common/util/ManageLumpSumSovColumn'
import {
  BaseManageSovColumn,
  EditingSov,
  EditingSovLineItem,
  EditingSovLineItemGroup,
  ManageSovColumn,
  getContractBillingType,
  getInitialGroupingRows,
  getNewChangeOrderGroupId,
  getSovColumns,
  getUpdatedBilledToDate,
  isPreSitelineRetentionColumnLinked,
  reorderGroup,
  reorderLineItem,
  sovColumnToEditingSovLineItemField,
} from '../../../common/util/ManageSov'
import {
  ManageUnitPriceSovColumn,
  getUpdatedUnitPriceFields,
  hasRevisedSovLineItems,
} from '../../../common/util/ManageUnitPriceSovColumn'
import {
  trackAcceptRenumberSovLineItemsHint,
  trackChangeOrderRequestPageViewed,
  trackDismissRenumberSovLineItemsHint,
  trackEditSovPageViewed,
  trackInsertSovLineItemFromContextMenu,
  trackShowRenumberSovLineItemsHint,
  trackViewSovPageViewed,
} from '../../../common/util/MetricsTracking'
import { isPayAppDraftOrSyncFailed } from '../../../common/util/PayApp'
import {
  isOnboardingSov,
  makeEmptyEditingSovLineItem,
  makeEmptySovLineItemGroup,
} from '../../../common/util/ProjectOnboarding'
import {
  usesPayAppOrProjectTracking,
  usesStandardOrLineItemTracking,
} from '../../../common/util/Retention'
import {
  getNextCode,
  renumberingHintForEditedCode,
  renumberingHintForReorderedLineItems,
} from '../../../common/util/Sov'
import { getChangeOrdersPath } from '../Billing.lib'
import { StoredMaterialsView } from '../invoice/InvoiceReducer'
import { AdjustPreSitelineRetentionDialog } from '../invoice/retention/AdjustPreSitelineRetentionDialog'
import { AddOrEditTaxGroupDialog } from '../invoice/taxes/AddOrEditTaxGroupDialog'
import { SingleTaxRateBanner } from '../invoice/taxes/SingleTaxRateBanner'
import { ContractForEditingSov } from '../onboarding/SovOnboarding'
import { ManageSovContractRetention } from './ManageSovContractRetention'
import {
  ChangeOrderDateField,
  getAddLineItemOrGroupRow,
  getLineItemGroupHeaderRow,
  getLineItemRow,
  getTotalsRow,
} from './ManageSovRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .alert': {
      marginBottom: theme.spacing(2),
    },
    '& .alreadyBilled': {
      backgroundColor: colors.blue10,
      padding: theme.spacing(2, 3),
      border: `1px solid ${colors.blue30}`,
      borderRadius: theme.spacing(0.5),
      whiteSpace: 'pre-wrap',
    },
    '& .noAlertMargin': {
      marginTop: theme.spacing(2),
    },
    '& .addLineItemOrGroup': {
      '& .MuiButton-root, & .MuiSvgIcon-root': {
        color: colors.grey50,
      },
      '& .addLineItem': {
        marginRight: theme.spacing(4),
      },
    },
    '& .changeOrderCheckbox': {
      // Center to the width of the "Change order" column header
      margin: theme.spacing(0, 1, 0, 2),
      display: 'flex',
      justifyContent: 'center',
    },
    '& .changeOrderCheck': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      color: colors.grey50,
    },
    '& .changeOrderCheckLink': {
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: theme.shape.borderRadius,
      color: colors.grey50,
      height: 20,
      width: 20,
      '&:hover': {
        cursor: 'pointer',
        color: colors.blue50,
        backgroundColor: colors.blue20,
      },
    },
    '& .changeOrderDoubleCheck': {
      '& .MuiSvgIcon-root': {
        fontSize: 17,
      },
    },
    '& .changeOrderEmpty': {
      paddingLeft: theme.spacing(4),
    },
  },
}))

const RENUMBER_HINT_ID = 'renumber-line-items'

export enum ManageSovState {
  VIEW = 'view',
  EDIT = 'edit',
  // This is the same view as editing, but initially includes an empty change order
  ADD_CHANGE_ORDER = 'addChangeOrder',
}

export type SovChangeParams = {
  updateSov: (sov: EditingSov) => EditingSov
  shouldWarnOnExit: boolean
  /** If true, will save the change directly. Should not be true in SOV edit mode. */
  shouldSaveImmediately?: boolean
}

export interface ManageSovProps
  extends Omit<
    SpreadsheetProps,
    'columns' | 'content' | 'onChange' | 'onReorder' | 'blurOnClickAway'
  > {
  sov: EditingSov
  contract?: ContractForEditingSov
  /**
   * Called when the SOV changes. If `shouldWarnOnExit` is true, enable the flag
   * to show a warning when navigating away from this page.
   */
  onSovChange: (params: SovChangeParams) => void
  showEditSovAlert: boolean
  manageSovState: ManageSovState
}

const i18nBase = 'projects.subcontractors.sov'

/** Spreadsheet for viewing or editing an SOV */
export function ManageSov({
  sov,
  contract,
  onSovChange,
  showEditSovAlert,
  manageSovState,
  loading,
  ...props
}: ManageSovProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { timeZone } = useProjectContext()
  const { companyId, permissions, defaultStoredMaterialsCarryoverType } = useCompanyContext()
  const navigate = useNavigate()

  const defaultRetentionPercent = useMemo(
    () => sov.defaultRetentionPercent ?? 0,
    [sov.defaultRetentionPercent]
  )

  // If all the line items have the same retention %, use that as the default when adding a new
  // line item. This percent is the current % for standard tracking and the total % for line item
  // tracking, but both work fine for this case.
  const defaultNewLineItemRetentionPercent = useMemo(() => {
    const lineItemPercents = _.chain(sov.lineItems)
      .map((lineItem) => lineItem.latestRetentionPercent ?? lineItem.defaultRetentionPercent)
      .filter((percent): percent is number => _.isNumber(percent))
      .uniq()
      .value()
    if (lineItemPercents.length === 1) {
      return lineItemPercents[0]
    }
    return defaultRetentionPercent
  }, [defaultRetentionPercent, sov.lineItems])

  // Only allow editing the SOV if the user has permission, no pay apps have been signed, and the project
  // doesn't submit through a GC portal
  const hasSignedPayApp =
    contract?.payApps.some((payApp) => !isPayAppDraftOrSyncFailed(payApp.status)) ?? false
  const gcPortalIntegration = contract
    ? getIntegrationOfFamily(contract, IntegrationTypeFamily.GC_PORTAL)
    : null
  const hasGcPortalIntegration = gcPortalIntegration !== null
  const hasEditPermission = permissions.includes(Permission.EDIT_INVOICE)
  const canEdit = hasEditPermission && !hasGcPortalIntegration
  const hasChangeOrderEditingPermission = permissions.includes(Permission.EDIT_CHANGE_ORDER)
  const canEditChangeOrders = canEdit && hasChangeOrderEditingPermission
  const canAddLineItems = canEdit && (!hasSignedPayApp || canEditChangeOrders)
  const snackbar = useSitelineSnackbar()
  const { confirm } = useSitelineConfirmation()
  const retentionTrackingLevel = contract?.retentionTrackingLevel ?? RetentionTrackingLevel.STANDARD
  const roundRetention = contract?.roundRetention ?? false
  const spreadsheetRef = useRef<SpreadsheetHandle>(null)
  const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] = useState<boolean>(false)
  const [deletingGroup, setDeletingGroup] = useState<EditingSovLineItemGroup | null>(null)
  const [updateRetentionDialogOpen, setUpdateRetentionDialogOpen] = useState<boolean>(false)
  const [storedMaterialsView, setStoredMaterialsView] = useState<StoredMaterialsView>(
    StoredMaterialsView.AMOUNT
  )

  // Both EDIT and ADD_CHANGE_ORDER states are editing views on the SOV spreadsheet
  const isEditing =
    manageSovState === ManageSovState.EDIT || manageSovState === ManageSovState.ADD_CHANGE_ORDER
  const isOnboarding = isOnboardingSov()
  // Only show the default retention % column until a pay app exists; after that,
  // all retention should be managed through the pay app retention column
  const hasAnyPayApp = (contract?.payApps ?? []).length > 0
  const includeRetentionPercentColumn =
    usesStandardOrLineItemTracking(retentionTrackingLevel) &&
    contract !== undefined &&
    (!hasAnyPayApp || isEditing)
  const isProjectHoldingRetention = retentionTrackingLevel !== RetentionTrackingLevel.NONE
  const hasPastPayApps = !!contract && contract.pastPayAppCount > 0
  // Only show a column for pre-Siteline billing if there were past pay apps before the project was
  // onboarded to Siteline. If this is the first pay app, there shouldn't be any previous billing.
  const includePreSitelineBillingColumn = hasPastPayApps
  const includePreSitelineRetentionColumns =
    hasPastPayApps && usesStandardOrLineItemTracking(retentionTrackingLevel)
  // If using standard retention and there are any pay apps, show column for total retainage. Don't show the column
  // while editing, since the value is dependent on the editable fields and we don't try to replicate the retention
  // calculations on the frontend.
  const includeTotalRetainageColumn =
    contract !== undefined &&
    hasSignedPayApp &&
    !isEditing &&
    usesStandardOrLineItemTracking(retentionTrackingLevel)
  const hasPayApps =
    contract !== undefined && (contract.payApps.length > 0 || contract.pastPayAppCount > 0)
  const includeTotalBillingColumns = hasPayApps && !isOnboarding
  // Show change order columns if (1) a change order already exists or (2) billing has started AND we're in edit mode
  const hasChangeOrder = sov.lineItems.some((lineItem) => lineItem.isChangeOrder)
  const includeChangeOrderColumns = hasChangeOrder || isEditing
  // Show revised contract columns if any line items have a revised total value, or onboarding an SOV with previous pay apps
  const includeRevisedContractColumns =
    hasRevisedSovLineItems(sov.lineItems) || (isOnboarding && includePreSitelineBillingColumn)
  // Show a tax group column if the contract calculates taxes with multiple tax groups
  const includeTaxGroupColumn =
    contract?.taxCalculationType === TaxCalculationType.MULTIPLE_TAX_GROUPS
  // Show stored materials column if the stored materials carryover type is manual and there were pay apps prior to onboarding
  const storedMaterialsCarryoverType =
    contract?.storedMaterialsCarryoverType ?? defaultStoredMaterialsCarryoverType
  const includeMaterialsInStorageColumn =
    !!contract &&
    storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL &&
    hasPastPayApps &&
    !hasAnyPayApp

  const billingType = contract && getContractBillingType(contract)

  const [addingTaxGroupLineItemId, setAddingTaxGroupLineItemId] = useState<string | null>(null)

  // The default approval date is today, or the earliest possible approval date if unable
  // to use today because of existing signed pay apps
  const defaultChangeOrderApprovalDate = useMemo(() => moment.tz(timeZone), [timeZone])

  const handleAddChangeOrder = useCallback(() => {
    if (!contract) {
      return
    }
    const code = getNextCode(sov.lineItems.map((lineItem) => lineItem.code))
    const changeOrderGroupId = getNewChangeOrderGroupId(sov.lineItems)
    const changeOrderLineItem: EditingSovLineItem = {
      ...makeEmptyEditingSovLineItem({
        includePreviousBilled: includePreSitelineBillingColumn,
        retentionTrackingLevel,
        billingType: contract.billingType,
        groupId: changeOrderGroupId,
        defaultRetentionPercent: defaultNewLineItemRetentionPercent,
        defaultTaxGroupId: contract.defaultTaxGroup?.id ?? null,
      }),
      code,
      isChangeOrder: true,
      changeOrderApprovedAt: defaultChangeOrderApprovalDate,
    }
    // Insert the change order at the end of its group, or at the end of the SOV if no group
    let insertIndex = sov.lineItems.length
    if (changeOrderGroupId) {
      insertIndex = _.findLastIndex(
        sov.lineItems,
        (lineItem) => lineItem.groupId === changeOrderGroupId
      )
      insertIndex = insertIndex >= 0 ? insertIndex + 1 : sov.lineItems.length
    }
    const newLineItems = [...sov.lineItems]
    newLineItems.splice(insertIndex, 0, changeOrderLineItem)
    onSovChange({
      updateSov: (sov) => ({ ...sov, lineItems: newLineItems }),
      shouldWarnOnExit: false,
    })
    // After the SOV updates, focus the newly-added line item (either the code cell or the
    // name cell if a default code was entered)
    setTimeout(() => {
      spreadsheetRef.current?.focusCell(
        changeOrderLineItem.id,
        code ? BaseManageSovColumn.NAME : BaseManageSovColumn.CODE
      )
    })
  }, [
    contract,
    sov,
    includePreSitelineBillingColumn,
    retentionTrackingLevel,
    defaultNewLineItemRetentionPercent,
    defaultChangeOrderApprovalDate,
    onSovChange,
  ])

  // When the SOV state changes to ADD_CHANGE_ORDER, add an empty change order line item to the SOV
  const lastSovState = useRef<ManageSovState>(manageSovState)
  useEffect(() => {
    const hasSovStateChanged = manageSovState !== lastSovState.current
    lastSovState.current = manageSovState
    if (!hasSovStateChanged || manageSovState !== ManageSovState.ADD_CHANGE_ORDER) {
      return
    }
    handleAddChangeOrder()
    lastSovState.current = manageSovState
  }, [manageSovState, handleAddChangeOrder])

  // Track when the user views the SOV tab, either in view mode or edit mode
  const hasTrackedView = useRef<boolean>(false)
  useEffect(() => {
    if (!contract || !companyId) {
      return
    }
    const params = { projectId: contract.project.id, projectName: contract.project.name, companyId }
    if (manageSovState !== ManageSovState.VIEW) {
      trackEditSovPageViewed({ ...params, isOnboarding })
    } else if (!hasTrackedView.current) {
      trackViewSovPageViewed(params)
      hasTrackedView.current = true
    }
  }, [contract, companyId, manageSovState, isOnboarding])

  // If there are any groups on the SOV that don't contain a line item, they'll be shown at the bottom of
  // the SOV. Since the SOV is sorted by line item order, groups that don't contain line items don't have
  // a position in the SOV and can only be shown at the end, in arbitrary (but stable) order of ID.
  const emptyGroups = useMemo(
    () =>
      _.chain(sov.groups)
        .filter(
          // Show only if no line items belong to the group (in which case it would be in the line item rows)
          (group) => !sov.lineItems.some((lineItem) => lineItem.groupId === group.id)
        )
        // Order by ID so we can add new line items to the last visible group if needed
        .orderBy((group) => group.id)
        .value(),
    [sov.groups, sov.lineItems]
  )

  // Add a blank new line item to the bottom of the SOV
  const handleAddLineItemToEndOfList = useCallback(() => {
    if (!contract) {
      return
    }
    const code = getNextCode(sov.lineItems.map((lineItem) => lineItem.code))
    let addToGroupId: string | null = null
    if (emptyGroups.length > 0) {
      addToGroupId = emptyGroups[emptyGroups.length - 1].id
    } else if (sov.lineItems.length > 0) {
      addToGroupId = sov.lineItems[sov.lineItems.length - 1].groupId
    }
    const newLineItem = {
      ...makeEmptyEditingSovLineItem({
        includePreviousBilled: includePreSitelineBillingColumn,
        retentionTrackingLevel,
        billingType: contract.billingType,
        groupId: addToGroupId,
        defaultRetentionPercent: defaultNewLineItemRetentionPercent,
        defaultTaxGroupId: contract.defaultTaxGroup?.id ?? null,
      }),
      code,
    }
    onSovChange({
      updateSov: (sov) => ({
        ...sov,
        lineItems: [...sov.lineItems, newLineItem],
      }),
      shouldWarnOnExit: false,
    })
    // After the SOV updates, focus the newly-added line item (either the code cell or the
    // name cell if a default code was entered)
    setTimeout(() => {
      spreadsheetRef.current?.focusCell(
        newLineItem.id,
        code ? BaseManageSovColumn.NAME : BaseManageSovColumn.CODE
      )
    })
  }, [
    contract,
    sov,
    emptyGroups,
    includePreSitelineBillingColumn,
    retentionTrackingLevel,
    defaultNewLineItemRetentionPercent,
    onSovChange,
  ])

  // Add an empty new group to the end of the SOV
  const handleAddGroup = useCallback(() => {
    const newGroup = makeEmptySovLineItemGroup()
    onSovChange({
      updateSov: (sov) => ({
        ...sov,
        groups: [...sov.groups, newGroup],
      }),
      shouldWarnOnExit: false,
    })
    // After the SOV updates, focus the newly-added group name
    setTimeout(() => {
      spreadsheetRef.current?.focusCell(newGroup.id, BaseManageSovColumn.NAME)
    })
  }, [onSovChange])

  // Remove a line item from the SOV
  const handleDeleteLineItem = useCallback(
    (lineItemId: string) => {
      const lineItem = sov.lineItems.find((lineItem) => lineItem.id === lineItemId)
      if (!lineItem) {
        snackbar.showError(t('common.errors.snackbar.generic'))
        return
      }
      onSovChange({
        updateSov: (sov) => ({
          ...sov,
          lineItems: sov.lineItems.filter((lineItem) => lineItem.id !== lineItemId),
        }),
        shouldWarnOnExit: true,
      })
    },
    [sov, onSovChange, snackbar, t]
  )

  // Update the SOV to both remove this group and detach any line items that belong to it
  const deleteGroup = useCallback(
    (groupId: string, withLineItems: boolean) => {
      const newLineItems = withLineItems
        ? sov.lineItems.filter((lineItem) => lineItem.groupId !== groupId)
        : sov.lineItems.map((lineItem) => ({
            ...lineItem,
            groupId: lineItem.groupId === groupId ? null : lineItem.groupId,
          }))
      onSovChange({
        updateSov: (sov) => ({
          ...sov,
          lineItems: newLineItems,
          groups: sov.groups.filter((group) => group.id !== groupId),
        }),
        shouldWarnOnExit: true,
      })
      setDeleteGroupDialogOpen(false)
    },
    [sov, onSovChange]
  )

  // Removes an entire group from the SOV. If there are line items in the group, first confirm with
  // the user that they intend to delete the whole group; otherwise, just delete it immediately.
  const handleDeleteGroup = useCallback(
    (groupId: string) => {
      const group = sov.groups.find((group) => group.id === groupId)
      if (!group) {
        snackbar.showError(t('common.spreadsheet.delete_row_error'))
        return
      }

      const lineItemsInGroup = sov.lineItems.filter((lineItem) => lineItem.groupId === groupId)
      if (lineItemsInGroup.length > 0) {
        setDeletingGroup(group)
        setDeleteGroupDialogOpen(true)
      } else {
        // If no line items in the group, just delete the group
        deleteGroup(groupId, false)
      }
    },
    [sov, snackbar, t, deleteGroup]
  )

  const getUpdateContractRetention = useCallback(
    (newPreSitelineBilling: number, lineItem: EditingSovLineItem) => {
      const newTotalPreSitelineBilling = _.sumBy(sov.lineItems, (sovLineItem) => {
        if (sovLineItem.id === lineItem.id) {
          return newPreSitelineBilling
        } else {
          return sovLineItem.preSitelineBilling ?? 0
        }
      })
      return roundCents(newTotalPreSitelineBilling * defaultRetentionPercent, roundRetention)
    },
    [defaultRetentionPercent, roundRetention, sov.lineItems]
  )

  // Update a line item in the SOV. This both handles translating the given value to the right
  // format for the corresponding line item field, and updating any other fields that need to
  // stay in sync with the updated field.
  const handleUpdateLineItem = useCallback(
    (lineItemId: string, column: ManageSovColumn, value: SpreadsheetValue | boolean) => {
      if (!contract) {
        return
      }
      const lineItemIndex = sov.lineItems.findIndex((item) => item.id === lineItemId)
      if (lineItemIndex < 0) {
        return
      }
      const field = sovColumnToEditingSovLineItemField(column)
      const lineItem = sov.lineItems[lineItemIndex]
      const unitPrice = lineItem.unitPrice ?? 0

      let updatedPreSitelineRetention = sov.preSitelineRetentionHeldOverride
      let lineItemUpdates: Partial<EditingSovLineItem> = {}
      let isChanged = false
      if (typeof value === 'number') {
        if (column === ManageLumpSumSovColumn.SCHEDULED_VALUE) {
          const centsValue = dollarsToCents(value)
          // Original and latest total value should be the same for lump sum SOVs
          lineItemUpdates = {
            originalTotalValue: centsValue,
            latestTotalValue: centsValue,
          }
          isChanged = centsValue !== lineItem[field]
        } else if (column === ManageUnitPriceSovColumn.BID_QUANTITY) {
          const totalValue = new Decimal(unitPrice).times(value).round().toNumber()
          // Bid quantity maps to original total value, so calculate the new scheduled value based on unit price.
          // If the latest total value is the same as the original, also update the latest total value (since we
          // can assume there have been no revisions, and the latest total value is also the original total value).
          const updateLatestTotalValue = lineItem.latestTotalValue === lineItem.originalTotalValue
          lineItemUpdates = {
            [field]: totalValue,
            ...(updateLatestTotalValue && { latestTotalValue: totalValue }),
          }
          isChanged = totalValue !== lineItem[field]
        } else if (column === ManageLumpSumSovColumn.PRE_SITELINE_BILLING) {
          // Update pre-siteline billing $
          const newPreSitelineBilling = dollarsToCents(value)
          lineItemUpdates = { [field]: newPreSitelineBilling }

          // Update the retention amount if it's linked to the pre-Siteline billing amount
          const preSitelineRetentionAmount = lineItem.preSitelineRetentionAmount ?? 0
          const defaultRetentionPercent = lineItem.defaultRetentionPercent ?? 0
          const oldPreSitelineBilling = lineItem.preSitelineBilling ?? 0
          const linkedPreSitelineRetentionAmount =
            updatedPreSitelineRetentionForPreSitelineBillingUpdate({
              defaultRetentionPercent,
              oldPreSitelineBilling,
              newPreSitelineBilling,
              preSitelineRetentionAmount,
              roundRetention,
            })
          if (linkedPreSitelineRetentionAmount !== undefined) {
            lineItemUpdates.preSitelineRetentionAmount = linkedPreSitelineRetentionAmount
          }

          // Update the contract-level pre-siteline retention override if needed
          if (usesPayAppOrProjectTracking(retentionTrackingLevel)) {
            updatedPreSitelineRetention = getUpdateContractRetention(
              newPreSitelineBilling,
              lineItem
            )
          }

          isChanged = newPreSitelineBilling !== lineItem[field]
        } else if (column === BaseManageSovColumn.STORED_MATERIALS) {
          if (
            billingType === BillingType.LUMP_SUM ||
            storedMaterialsView === StoredMaterialsView.AMOUNT
          ) {
            const centsValue = dollarsToCents(value)
            lineItemUpdates = { [field]: centsValue }
            isChanged = centsValue !== lineItem[field]
          } else {
            const storedAmount = new Decimal(unitPrice).times(value).round().toNumber()
            lineItemUpdates = { [field]: storedAmount }
            isChanged = value !== lineItem[field]
          }
        } else if (column === BaseManageSovColumn.PRE_SITELINE_RETENTION_AMOUNT) {
          const centsValue = dollarsToCents(value)
          lineItemUpdates = { [field]: dollarsToCents(value) }
          isChanged = centsValue !== lineItem[field]
        } else if (column === BaseManageSovColumn.RETENTION_PERCENT) {
          // Update the default retention percent
          const newDefaultRetentionPercent = percentToDecimal(value)
          lineItemUpdates = { [field]: newDefaultRetentionPercent }

          // Check whether pre-siteline billing and retention $ match the old default percentage.
          // If they do, update the pre-siteline retention $ automatically.
          const preSitelineRetentionAmount = lineItem.preSitelineRetentionAmount ?? 0
          const oldDefaultRetentionPercent = lineItem.defaultRetentionPercent ?? 0
          const preSitelineBilling = lineItem.preSitelineBilling ?? 0

          const isLinked = isPreSitelineRetentionColumnLinked({
            defaultRetentionPercent: oldDefaultRetentionPercent,
            preSitelineBilling,
            preSitelineRetentionAmount,
            roundRetention,
            includePreSitelineBillingColumn,
            includePreSitelineRetentionColumns,
          })
          if (isLinked) {
            // If the line item has worksheet line items, we can't directly update its pre-Siteline
            // billing because it must stay in sync with the worksheet. Only make this update if
            // there are no worksheet line items associated with the SOV line item.
            if (lineItem.worksheetLineItems.length === 0) {
              const newPreSitelineRetentionAmount = roundCents(
                newDefaultRetentionPercent * preSitelineBilling,
                contract.roundRetention
              )
              lineItemUpdates.preSitelineRetentionAmount = newPreSitelineRetentionAmount
            }
          }
          isChanged = newDefaultRetentionPercent !== lineItem[field]
        } else if (column === ManageUnitPriceSovColumn.UNIT_PRICE) {
          const decimal = dollarsToUnitPriceCents(value)
          lineItemUpdates = { [field]: decimal.toNumber() }
          isChanged = !_.isNumber(lineItem.unitPrice) || !decimal.equals(lineItem.unitPrice)
        } else if (column === ManageUnitPriceSovColumn.REVISED_QUANTITY) {
          const revisedQuantity = roundCents(value * unitPrice)
          lineItemUpdates = { [field]: revisedQuantity }
          isChanged = revisedQuantity !== lineItem[field]
        }
      } else {
        if (column === BaseManageSovColumn.CODE) {
          const newCode = value as string
          const renumberingHint = renumberingHintForEditedCode({
            lineItemIndex,
            sovLineItems: sov.lineItems,
            newCode,
            t,
          })
          if (renumberingHint) {
            const metricsParams = {
              projectId: contract.project.id,
              projectName: contract.project.name,
              type: 'edit' as const,
            }
            spreadsheetRef.current?.showHintAtCell(
              renumberingHint.showAtRowId,
              column,
              RENUMBER_HINT_ID,
              {
                body: renumberingHint.body,
                action: renumberingHint.action,
                onAccept: () => {
                  onSovChange({
                    updateSov: (sov) => ({
                      ...sov,
                      lineItems: renumberingHint.lineItemsToRenumberedLineItems(sov.lineItems),
                    }),
                    shouldWarnOnExit: true,
                  })
                  trackAcceptRenumberSovLineItemsHint(metricsParams)
                },
                onShown: () => {
                  trackShowRenumberSovLineItemsHint(metricsParams)
                },
                onDismissed: () => {
                  trackDismissRenumberSovLineItemsHint(metricsParams)
                },
              }
            )
          } else {
            // If we previously showed a hint for renumbering but the user made another code edit
            // that isn't a renumber, close the hint
            spreadsheetRef.current?.closeHint(RENUMBER_HINT_ID)
          }
        }

        lineItemUpdates = { [field]: value }
        isChanged = value !== lineItem[field]
      }
      // If the value is unchanged, return without making any changes
      if (!isChanged) {
        return
      }
      const newLineItem = {
        ...lineItem,
        ...lineItemUpdates,
        // The change order approval date should be kept in sync with the change order field. If
        // setting a change order, add an approval date of today; if unchecking a change order, set
        // the approval date to be undefined.
        ...(field === 'isChangeOrder' && {
          changeOrderApprovedAt: value === true ? moment.tz(timeZone) : undefined,
          changeOrderEffectiveAt: value === true ? null : undefined,
        }),
      }
      const newLineItems = [...sov.lineItems]
      newLineItems.splice(lineItemIndex, 1, {
        ...newLineItem,
        // Update billed to date based on changes, so we are validating changes based on the right total
        billedToDate: getUpdatedBilledToDate(lineItem, newLineItem),
        // Update all amount fields if unit price changes
        ...(column === ManageUnitPriceSovColumn.UNIT_PRICE &&
          getUpdatedUnitPriceFields(lineItem, newLineItem)),
      })
      onSovChange({
        updateSov: (sov) => ({
          ...sov,
          lineItems: newLineItems,
          preSitelineRetentionHeldOverride: updatedPreSitelineRetention,
        }),
        shouldWarnOnExit: true,
      })
    },
    [
      contract,
      sov.lineItems,
      sov.preSitelineRetentionHeldOverride,
      timeZone,
      onSovChange,
      roundRetention,
      retentionTrackingLevel,
      getUpdateContractRetention,
      billingType,
      storedMaterialsView,
      includePreSitelineBillingColumn,
      includePreSitelineRetentionColumns,
      t,
    ]
  )

  const handleAddTaxGroup = useCallback(
    async (taxGroup: TaxGroupProperties) => {
      if (!addingTaxGroupLineItemId) {
        return
      }
      handleUpdateLineItem(addingTaxGroupLineItemId, BaseManageSovColumn.TAX_GROUP, taxGroup.id)
      setAddingTaxGroupLineItemId(null)
    },
    [addingTaxGroupLineItemId, handleUpdateLineItem]
  )

  const handleUpdateChangeOrderDate = useCallback(
    (lineItemId: string, field: ChangeOrderDateField, date: Moment | null) => {
      if (!contract) {
        return
      }
      if (field === 'changeOrderApprovedAt' && !date) {
        return
      }
      const lineItemIndex = sov.lineItems.findIndex((item) => item.id === lineItemId)
      if (lineItemIndex < 0) {
        return
      }
      const lineItem = sov.lineItems[lineItemIndex]
      const newLineItem = {
        ...lineItem,
        [field]: date && moment.tz(date, timeZone),
      }
      const newLineItems = [...sov.lineItems]
      newLineItems.splice(lineItemIndex, 1, newLineItem)
      // If this change will affect which pay apps the change order appears on, confirm with the
      // user that this could affect whether the pay apps can be synced
      const willAffectRelevantPayApps =
        field === 'changeOrderEffectiveAt' || !newLineItem.changeOrderEffectiveAt
      if (hasGcPortalIntegration && willAffectRelevantPayApps) {
        confirm({
          title:
            field === 'changeOrderApprovedAt'
              ? t(`${i18nBase}.update_approval_date`)
              : t(`${i18nBase}.update_effective_date`),
          details: t(`${i18nBase}.confirm_update_date`, {
            integration: getIntegrationName(gcPortalIntegration.type, true),
          }),
          callback: (confirmed) => {
            if (confirmed) {
              onSovChange({
                updateSov: (sov) => ({ ...sov, lineItems: newLineItems }),
                shouldWarnOnExit: !hasGcPortalIntegration,
                shouldSaveImmediately: hasGcPortalIntegration,
              })
            }
          },
        })
      } else {
        onSovChange({
          updateSov: (sov) => ({ ...sov, lineItems: newLineItems }),
          shouldWarnOnExit: !hasGcPortalIntegration,
          shouldSaveImmediately: hasGcPortalIntegration,
        })
      }
    },
    [
      confirm,
      contract,
      gcPortalIntegration?.type,
      hasGcPortalIntegration,
      onSovChange,
      sov,
      t,
      timeZone,
    ]
  )

  // Default handler for editing a spreadsheet data cell. Based on the row ID, will
  // update the appropriate field for the corresponding SOV line item or group.
  const handleChange = useCallback(
    (rowId: string, columnId: string, toValue: SpreadsheetValue) => {
      const lineItemIndex = sov.lineItems.findIndex((lineItem) => lineItem.id === rowId)
      const lineItem = lineItemIndex >= 0 ? sov.lineItems[lineItemIndex] : undefined
      // If a line item matches, update it appropriately
      if (lineItem) {
        handleUpdateLineItem(rowId, columnId as ManageSovColumn, toValue)
        return
      }
      // If no line item matches, check if this is a line item group
      const groupIndex = sov.groups.findIndex((group) => group.id === rowId)
      const group = groupIndex >= 0 ? sov.groups[groupIndex] : undefined
      if (group) {
        if (!(columnId in group) || group[columnId as keyof EditingSovLineItemGroup] === toValue) {
          // If nothing changed, don't re-render
          return
        }
        const newGroup = { ...group, [columnId]: toValue }
        const newGroups = [...sov.groups]
        newGroups.splice(groupIndex, 1, newGroup)
        onSovChange({ updateSov: (sov) => ({ ...sov, groups: newGroups }), shouldWarnOnExit: true })
        return
      }
      // If no progress or group matches the row ID, show an error toast
      snackbar.showError(t('common.errors.snackbar.generic'))
    },
    [sov, onSovChange, snackbar, t, handleUpdateLineItem]
  )

  const handleResetPreSitelineRetentionAmount = useCallback(
    (id: string) => {
      const lineItem = sov.lineItems.find((item) => item.id === id)
      if (!lineItem) {
        return
      }
      if (
        !_.isNumber(lineItem.defaultRetentionPercent) ||
        !_.isNumber(lineItem.preSitelineBilling)
      ) {
        return
      }
      const defaultRetentionPercent = lineItem.defaultRetentionPercent
      const preSitelineBilling = lineItem.preSitelineBilling
      const newPreSitelineRetentionAmount = roundCents(
        defaultRetentionPercent * preSitelineBilling,
        contract?.roundRetention
      )
      handleUpdateLineItem(
        id,
        BaseManageSovColumn.PRE_SITELINE_RETENTION_AMOUNT,
        centsToDollars(newPreSitelineRetentionAmount)
      )
    },
    [contract?.roundRetention, handleUpdateLineItem, sov.lineItems]
  )

  const columns = useMemo(() => {
    if (!billingType) {
      return []
    }
    return getSovColumns(billingType, t, {
      includePreSitelineBillingColumn,
      includeMaterialsInStorageColumn,
      includeRetentionPercentColumn,
      includePreSitelineRetentionColumns,
      includeTotalRetainageColumn,
      includeTotalBillingColumns,
      includeChangeOrderColumn: includeChangeOrderColumns,
      includeChangeOrderApprovalColumn: includeChangeOrderColumns,
      includeRevisedContractColumns,
      taxGroups: includeTaxGroupColumn ? [...contract.company.taxGroups] : null,
      onAddTaxGroupForLineItemId: includeTaxGroupColumn ? setAddingTaxGroupLineItemId : null,
      isEditable: canEdit && isEditing,
      timeZone,
      storedMaterialsView,
      onStoredMaterialsViewChange: setStoredMaterialsView,
    })
  }, [
    billingType,
    t,
    includePreSitelineBillingColumn,
    includeMaterialsInStorageColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeTotalRetainageColumn,
    includeTotalBillingColumns,
    includeChangeOrderColumns,
    includeRevisedContractColumns,
    includeTaxGroupColumn,
    contract?.company.taxGroups,
    canEdit,
    isEditing,
    timeZone,
    storedMaterialsView,
  ])
  // Include a empty divider at the end of the SOV if both:
  // 1. Editing the SOV
  // 2. The last visible row in the SOV is a group
  const lastLineItem = _.last(sov.lineItems)
  const addEndDivider = isEditing && (emptyGroups.length > 0 || !_.isNil(lastLineItem?.groupId))
  const addLineItemOrGroupRow: SpreadsheetFooterRow | undefined = useMemo(() => {
    // If a pay app is already signed, only allow adding change orders
    if (hasSignedPayApp) {
      if (!canEditChangeOrders) {
        // If the user doesn't have permisison to edit change orders, they can't add new line items
        return undefined
      }
      return getAddLineItemOrGroupRow({
        onAddLineItem: handleAddChangeOrder,
        onAddGroup: handleAddGroup,
        numColumns: columns.length,
        t,
        isFirstInUngroupedBlock: addEndDivider,
        isAddingChangeOrder: true,
      })
    }
    return getAddLineItemOrGroupRow({
      onAddLineItem: handleAddLineItemToEndOfList,
      onAddGroup: handleAddGroup,
      numColumns: columns.length,
      t,
      isFirstInUngroupedBlock: addEndDivider,
      isAddingChangeOrder: false,
    })
  }, [
    handleAddLineItemToEndOfList,
    handleAddGroup,
    handleAddChangeOrder,
    columns.length,
    t,
    hasSignedPayApp,
    addEndDivider,
    canEditChangeOrders,
  ])

  const totalsRow: SpreadsheetFooterRow | undefined = useMemo(() => {
    if (!billingType) {
      return undefined
    }
    return getTotalsRow(billingType, {
      sov,
      includePreSitelineBillingColumn,
      includeRetentionPercentColumn,
      includePreSitelineRetentionColumns,
      includeTotalBillingColumns,
      includeTotalRetainageColumn,
      includeChangeOrderColumn: includeChangeOrderColumns,
      includeChangeOrderApprovalColumn: includeChangeOrderColumns,
      includeRevisedContractColumns,
      includeTaxGroupColumn,
      includeMaterialsInStorageColumn,
      isFirstInUngroupedBlock: false,
      t,
    })
  }, [
    billingType,
    sov,
    includePreSitelineBillingColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeTotalBillingColumns,
    includeTotalRetainageColumn,
    includeChangeOrderColumns,
    includeRevisedContractColumns,
    includeTaxGroupColumn,
    includeMaterialsInStorageColumn,
    t,
  ])

  const lineItemRows = useMemo(() => {
    if (!billingType) {
      return []
    }
    return sov.lineItems.flatMap((lineItem, index) => {
      const rows = []

      const group = sov.groups.find((group) => group.id === lineItem.groupId)
      const canDeleteGroup = canEdit && isEditing && !hasSignedPayApp
      const { rows: groupingRows, isFirstInUngroupedBlock } = getInitialGroupingRows(
        group ?? null,
        index,
        sov.lineItems,
        null,
        {
          numColumns: columns.length,
          showWarningIfGroupEmpty: showEditSovAlert,
          isGroupEditable: isEditing,
          isGroupReorderable: isEditing,
          onDeleteGroup: canDeleteGroup ? handleDeleteGroup : undefined,
        }
      )
      rows.push(...groupingRows)

      // Allow editing change orders unless they have pre-Siteline billing that has appeared on a
      // signed pay app. It's possible that the change order has been billed on a signed pay app
      // and the backend will reject a change, but we don't prevent the user from making edits
      // on the frontend since we don't have full historical progress data.
      const isEditableChangeOrderRow =
        lineItem.isChangeOrder === true &&
        (!lineItem.preSitelineBilling ||
          contract.payApps.length === 0 ||
          contract.payApps.every((payApp) => isPayAppDraftOrSyncFailed(payApp.status)))
      let isRowEditable = isEditing && (!hasSignedPayApp || isEditableChangeOrderRow)
      if (isRowEditable && lineItem.isChangeOrder) {
        isRowEditable = canEditChangeOrders
      }

      const lineItemBelongsToAnyPayApp = contract.payApps.some((payApp) => {
        return payApp.progress.some(({ sovLineItem }) => sovLineItem.id === lineItem.id)
      })
      const isRetentionPercentEditable = !lineItemBelongsToAnyPayApp

      const isChangeOrderEditable =
        canEdit && isRowEditable && !hasSignedPayApp && canEditChangeOrders
      const isGcPortalChangeOrderEditable =
        hasGcPortalIntegration &&
        hasEditPermission &&
        hasChangeOrderEditingPermission &&
        isEditableChangeOrderRow
      const lineItemRow = getLineItemRow(billingType, lineItem, {
        includePreSitelineBillingColumn,
        includeRetentionPercentColumn,
        isRetentionPercentEditable,
        includePreSitelineRetentionColumns,
        includeTotalBillingColumns,
        includeTotalRetainageColumn,
        includeChangeOrderColumn: includeChangeOrderColumns,
        includeChangeOrderApprovalColumn: includeChangeOrderColumns,
        isChangeOrderApprovalDateEditable: !hasGcPortalIntegration,
        includeRevisedContractColumns,
        includeTaxGroupColumn,
        roundRetention,
        onDelete: canEdit && isRowEditable ? handleDeleteLineItem : undefined,
        // Only allow toggling change orders on and off until the project has a signed pay app;
        // at that point, all new line items are change orders and change orders can't be switched
        // back to line items
        onToggleIsChangeOrder: isChangeOrderEditable
          ? (id, checked) => handleUpdateLineItem(id, BaseManageSovColumn.CHANGE_ORDER, checked)
          : undefined,
        onNavigateToChangeOrder:
          !isEditing && lineItem.changeOrderRequests.length > 0
            ? () => {
                navigate(
                  getChangeOrdersPath({
                    changeOrderRequestId: lineItem.changeOrderRequests[0].id,
                    projectId: contract.project.id,
                  })
                )
                trackChangeOrderRequestPageViewed({ fromLocation: 'SOV' })
              }
            : undefined,
        onEditChangeOrderDate:
          isEditing || isGcPortalChangeOrderEditable
            ? (field, date) => handleUpdateChangeOrderDate(lineItem.id, field, date)
            : undefined,
        onResetPreSitelineRetentionAmount: handleResetPreSitelineRetentionAmount,
        timeZone,
        isFirstInUngroupedBlock,
        showWarningIfEmpty: showEditSovAlert,
        t,
        isEditable: isEditing,
        isReorderable: isEditing,
        isRowSelected: true,
        includeMaterialsInStorageColumn,
        storedMaterialsView,
      })
      rows.push(lineItemRow)

      return rows
    })
  }, [
    billingType,
    sov.lineItems,
    sov.groups,
    canEdit,
    isEditing,
    hasSignedPayApp,
    columns.length,
    showEditSovAlert,
    handleDeleteGroup,
    contract?.payApps,
    contract?.project.id,
    canEditChangeOrders,
    hasGcPortalIntegration,
    hasEditPermission,
    hasChangeOrderEditingPermission,
    includePreSitelineBillingColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeTotalBillingColumns,
    includeTotalRetainageColumn,
    includeChangeOrderColumns,
    includeRevisedContractColumns,
    includeTaxGroupColumn,
    roundRetention,
    handleDeleteLineItem,
    handleResetPreSitelineRetentionAmount,
    timeZone,
    t,
    includeMaterialsInStorageColumn,
    storedMaterialsView,
    handleUpdateLineItem,
    navigate,
    handleUpdateChangeOrderDate,
  ])

  const emptyGroupRows: SpreadsheetRow[] = useMemo(() => {
    if (!isEditing) {
      return []
    }
    return emptyGroups.flatMap((group, index) => {
      const rows = []
      // Show a divider if there are line item or group rows above this row
      if (index > 0 || sov.lineItems.length > 0) {
        rows.push(makeDividerRow(`${group.id}-divider`))
      }
      const groupHeaderRow = getLineItemGroupHeaderRow(group, null, {
        onDelete: canEdit ? handleDeleteGroup : undefined,
        numColumns: columns.length,
        showWarningIfEmpty: showEditSovAlert,
        isEditable: canEdit,
        isReorderable: isEditing,
      })
      rows.push(groupHeaderRow)
      return rows
    })
  }, [
    canEdit,
    emptyGroups,
    sov.lineItems,
    handleDeleteGroup,
    columns.length,
    showEditSovAlert,
    isEditing,
  ])

  const contentRows = useMemo(() => {
    const rows = [...lineItemRows, ...emptyGroupRows]
    if (addEndDivider) {
      const endDividerRow = makeDividerRow('end-divider-row')
      rows.push(endDividerRow)
    }
    return rows
  }, [lineItemRows, emptyGroupRows, addEndDivider])

  const content = useMemo(() => {
    const footerRows = []
    if (canEdit && isEditing && addLineItemOrGroupRow) {
      footerRows.push(addLineItemOrGroupRow)
    }
    if (totalsRow) {
      footerRows.push(totalsRow)
    }
    return {
      rows: contentRows,
      footerRows,
      enableReorderRows: canEdit,
    }
  }, [canEdit, contentRows, addLineItemOrGroupRow, totalsRow, isEditing])

  const handleReorder = useCallback(
    (rowId: string, toRowIndex: number) => {
      if (!contract) {
        return false
      }

      const lineItemIndex = sov.lineItems.findIndex((lineItem) => lineItem.id === rowId)
      const lineItem = lineItemIndex >= 0 ? sov.lineItems[lineItemIndex] : undefined
      const group = sov.groups.find((group) => group.id === rowId)
      const fromRowIndex = contentRows.findIndex((row) => row.id === rowId)
      let newLineItems: EditingSovLineItem[] | undefined
      let fromLineItemIndex, toLineItemIndex
      // If the SOV has groups, need to make sure the line item is appropriately
      // added and/or removed from the respective SOV line item groups
      if (lineItem) {
        const reorderedLineItems = reorderLineItem(
          fromRowIndex,
          toRowIndex,
          lineItem,
          lineItemIndex,
          contentRows,
          sov.lineItems
        )
        newLineItems = reorderedLineItems.newLineItems
        fromLineItemIndex = reorderedLineItems.fromLineItemIndex
        toLineItemIndex = reorderedLineItems.toLineItemIndex
      } else if (group) {
        // If moving a group, need to move all line items that belong to that group as well
        try {
          const reorderedGroup = reorderGroup(
            fromRowIndex,
            toRowIndex,
            group,
            contentRows,
            sov.lineItems
          )
          newLineItems = reorderedGroup.newLineItems
          fromLineItemIndex = reorderedGroup.fromLineItemIndex
          toLineItemIndex = reorderedGroup.toLineItemIndex
        } catch {
          // An error may be throw if attempting an invalid move, in which case we just
          // return false to cancel the reorder
          return false
        }
      }

      if (newLineItems && _.isNumber(fromLineItemIndex) && _.isNumber(toLineItemIndex)) {
        const renumberingHint = renumberingHintForReorderedLineItems({
          fromLineItemIndex,
          toLineItemIndex,
          fromRowIndex,
          toRowIndex,
          originalLineItems: sov.lineItems,
          reorderedLineItems: newLineItems,
          t,
        })
        if (renumberingHint) {
          const metricsParams = {
            projectId: contract.project.id,
            projectName: contract.project.name,
            type: 'reorder' as const,
          }
          // The timeout is needed so the drag-and-drop interaction completes before the hint is
          // rendered
          setTimeout(() =>
            spreadsheetRef.current?.showHintAtCell(
              renumberingHint.showAtRowId,
              BaseManageSovColumn.CODE,
              RENUMBER_HINT_ID,
              {
                body: renumberingHint.body,
                action: renumberingHint.action,
                onAccept: () => {
                  onSovChange({
                    updateSov: (sov) => ({
                      ...sov,
                      lineItems: renumberingHint.lineItemsToRenumberedLineItems(sov.lineItems),
                    }),
                    shouldWarnOnExit: true,
                  })
                  trackAcceptRenumberSovLineItemsHint(metricsParams)
                },
                onShown: () => {
                  trackShowRenumberSovLineItemsHint(metricsParams)
                },
                onDismissed: () => {
                  trackDismissRenumberSovLineItemsHint(metricsParams)
                },
              }
            )
          )
        }

        onSovChange({
          updateSov: (sov) => ({ ...sov, lineItems: newLineItems }),
          shouldWarnOnExit: true,
        })
        return true
      }

      return true
    },
    [contract, sov.lineItems, sov.groups, contentRows, t, onSovChange]
  )

  const handleBeforeReorder = useCallback(() => {
    // Before reordering, close any renumbering hint that may be open
    spreadsheetRef.current?.closeHint(RENUMBER_HINT_ID)
  }, [])

  // Handles inserting a row in the middle of the spreadsheet. Copies the group of the line
  // that this new line is being added from (i.e. the row that was right clicked).
  const handleInsertRowAtIndex = useCallback(
    (anchorRowIndex: number, direction: 'up' | 'down') => {
      if (!contract || !canAddLineItems) {
        return
      }

      const lineItems = sov.lineItems
      const anchorRow = contentRows[anchorRowIndex]
      const anchorLineItemIndex = lineItems.findIndex((lineItem) => lineItem.id === anchorRow.id)
      if (anchorLineItemIndex === -1) {
        console.error('No line item was found for the clicked row')
        return
      }

      const anchorSovLineItem = lineItems[anchorLineItemIndex]
      let insertAtIndex
      switch (direction) {
        case 'up':
          insertAtIndex = anchorLineItemIndex
          break
        case 'down':
          insertAtIndex = anchorLineItemIndex + 1
          break
      }

      const newLineItem: EditingSovLineItem = {
        ...makeEmptyEditingSovLineItem({
          includePreviousBilled: includePreSitelineBillingColumn,
          retentionTrackingLevel,
          billingType: contract.billingType,
          groupId: anchorSovLineItem.groupId ?? null,
          defaultRetentionPercent: defaultNewLineItemRetentionPercent,
          defaultTaxGroupId: contract.defaultTaxGroup?.id ?? null,
        }),
        isChangeOrder: hasSignedPayApp,
        changeOrderApprovedAt: hasSignedPayApp ? defaultChangeOrderApprovalDate : undefined,
      }
      const newLineItems = [...sov.lineItems]
      newLineItems.splice(insertAtIndex, 0, newLineItem)
      onSovChange({
        updateSov: (sov) => ({ ...sov, lineItems: newLineItems }),
        shouldWarnOnExit: true,
      })

      // After the SOV updates, focus the code cell of the newly-added line item
      setTimeout(() => spreadsheetRef.current?.focusCell(newLineItem.id, BaseManageSovColumn.CODE))

      trackInsertSovLineItemFromContextMenu({
        projectId: contract.project.id,
        projectName: contract.project.name,
        type: hasSignedPayApp ? 'changeOrder' : 'lineItem',
        direction,
      })
    },
    [
      canAddLineItems,
      contentRows,
      contract,
      defaultChangeOrderApprovalDate,
      defaultNewLineItemRetentionPercent,
      hasSignedPayApp,
      includePreSitelineBillingColumn,
      onSovChange,
      retentionTrackingLevel,
      sov,
    ]
  )

  // Only 1 of the values will be provided at a time. Changes the contract pre-siteline retention.
  const handleUpdateContractRetention = useCallback(
    (preSitelineRetentionHeldOverride: number | null) => {
      onSovChange({
        updateSov: (sov) => ({ ...sov, preSitelineRetentionHeldOverride }),
        shouldWarnOnExit: true,
      })
    },
    [onSovChange]
  )

  const onDefaultRetentionPercentChange = useCallback(
    (percent: number) => {
      onSovChange({
        updateSov: (sov) => ({ ...sov, defaultRetentionPercent: percent }),
        shouldWarnOnExit: true,
      })
    },
    [onSovChange]
  )

  const contextMenuLabels: ContextMenuLabels = useMemo(() => {
    if (hasSignedPayApp) {
      return {
        insertAbove: t(`${i18nBase}.insert_change_order_above`),
        insertBelow: t(`${i18nBase}.insert_change_order_below`),
      }
    }
    return {
      insertAbove: t(`${i18nBase}.insert_line_item_above`),
      insertBelow: t(`${i18nBase}.insert_line_item_below`),
    }
  }, [hasSignedPayApp, t])

  const showChangeOrdersPermissionAlert = isEditing && !hasChangeOrderEditingPermission

  return (
    <div className={classes.root}>
      <Collapse in={showChangeOrdersPermissionAlert} unmountOnExit>
        <SitelineAlert severity="info" className="alert">
          {t(`${i18nBase}.change_orders_permission`)}
        </SitelineAlert>
      </Collapse>
      <Collapse in={showEditSovAlert} unmountOnExit>
        <SitelineAlert severity="error" className="alert">
          {t(`${i18nBase}.missing_fields`)}
        </SitelineAlert>
      </Collapse>
      {contract && (
        <>
          <ManageSovContractRetention
            defaultRetentionPercent={defaultRetentionPercent}
            onDefaultRetentionPercentChange={onDefaultRetentionPercentChange}
            contract={contract}
            sov={sov}
            isEditing={isEditing}
            onEditPreSitelineRetention={() => setUpdateRetentionDialogOpen(true)}
          />
          {contract.taxCalculationType === TaxCalculationType.SINGLE_TAX_GROUP &&
            contract.defaultTaxGroup && <SingleTaxRateBanner taxGroup={contract.defaultTaxGroup} />}
        </>
      )}
      <div
        className={clsx({
          noAlertMargin:
            (!isEditing || !hasSignedPayApp) &&
            !showEditSovAlert &&
            !showChangeOrdersPermissionAlert,
        })}
      >
        <Spreadsheet
          ref={spreadsheetRef}
          columns={columns}
          content={content}
          loading={!contract || loading}
          onChange={handleChange}
          onReorder={handleReorder}
          onBeforeReorder={handleBeforeReorder}
          onInsertRow={canAddLineItems ? handleInsertRowAtIndex : undefined}
          blurOnClickAway
          contextMenuLabels={contextMenuLabels}
          {...props}
        />
      </div>
      {deletingGroup && (
        <DeleteGroupConfirmation
          open={deleteGroupDialogOpen}
          group={deletingGroup}
          onCancel={() => setDeleteGroupDialogOpen(false)}
          onConfirm={(withLineItems: boolean) => deleteGroup(deletingGroup.id, withLineItems)}
        />
      )}
      {contract && isProjectHoldingRetention && (
        <AdjustPreSitelineRetentionDialog
          open={updateRetentionDialogOpen}
          onClose={() => setUpdateRetentionDialogOpen(false)}
          contract={contract}
          sov={sov}
          onSubmit={handleUpdateContractRetention}
        />
      )}
      <AddOrEditTaxGroupDialog
        open={addingTaxGroupLineItemId !== null}
        onClose={() => setAddingTaxGroupLineItemId(null)}
        taxGroup={null}
        onAddTaxGroup={handleAddTaxGroup}
        location="sov"
      />
    </div>
  )
}
