import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { FormControlLabel, Grow } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useNavigate } from '@tanstack/react-router'
import { clsx } from 'clsx'
import moment from 'moment-timezone'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { centsToDollars, dollarsToCents, formatCentsToDollars } from 'siteline-common-all'
import {
  SitelineText,
  SitelineTooltip,
  colors,
  evictWithGc,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import {
  DatePickerInput,
  DatePickerValue,
  isMissingDate,
  makeDatePickerValue,
} from '../../../common/components/DatePickerInput'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  PayAppActivityDocument,
  PayAppForOverviewProperties,
  PayAppStatus,
  useMarkPayAppAsPaidMutation,
  useUpdatePayAppPaidAtMutation,
} from '../../../common/graphql/apollo-operations'
import { BillingPathType, getBillingPath } from '../Billing.lib'
import { SendEmailDialogRow } from '../submit-dialog/SendEmailDialogRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .inputContainer': {
      gap: theme.spacing(4),
    },
    '& .paymentRow': {
      '&.withBody': {
        marginTop: theme.spacing(3),
      },
      '& .datePicker': {
        marginTop: theme.spacing(0.5),
        maxWidth: 344,
      },
    },
    '& .amountRow': {
      '& .amountInput': {
        backgroundColor: colors.white,
        border: `1px solid ${colors.grey30}`,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1),
        marginRight: theme.spacing(2),
        // Match height of the other inputs
        height: 39,
        width: 160,
        ...theme.typography.body1,
        '&:hover': {
          borderColor: colors.grey40,
        },
      },
      '& .MuiSvgIcon-root': {
        color: colors.grey50,
        fontSize: 16,
      },
      '& .Mui-disabled .MuiSvgIcon-root': {
        color: colors.grey40,
      },
      '& .amountDue': {
        marginTop: theme.spacing(0.5),
      },
    },
  },
  tooltip: {
    maxWidth: 325,
    lineHeight: 1.5,
  },
}))

interface MarkAsPaidDialogProps {
  open: boolean
  onClose: () => void
  payApp: Pick<
    PayAppForOverviewProperties,
    | 'id'
    | 'status'
    | 'statusChangedAt'
    | 'amountDuePostTax'
    | 'amountPaid'
    | 'isBalanceManuallyClosed'
    | 'lastSubmitted'
  >
  regenerateUnconditionalId?: string
}

const i18nBase = 'projects.subcontractors.pay_app.mark_as_paid'

/** A dialog for setting the payment date for a pay app and marking it as paid */
export function MarkAsPaidDialog({
  open,
  onClose,
  payApp,
  regenerateUnconditionalId,
}: MarkAsPaidDialogProps) {
  const classes = useStyles()
  const { timeZone, contract } = useProjectContext()
  const snackbar = useSitelineSnackbar()
  const { t } = useTranslation()
  const { id: projectId } = useProjectContext()
  const navigate = useNavigate()
  const initialPaidAtValue = useMemo(
    () =>
      makeDatePickerValue(
        payApp.status === PayAppStatus.PAID
          ? moment.tz(payApp.statusChangedAt, timeZone)
          : moment.tz(timeZone)
      ),
    [payApp.statusChangedAt, payApp.status, timeZone]
  )
  const [paidAtValue, setPaidAtValue] = useState<DatePickerValue>(initialPaidAtValue)
  const initialSubmittedAtValue = useMemo(
    () =>
      makeDatePickerValue(
        // The last submitted log will be the latest log from a submit or a sync event
        payApp.lastSubmitted
          ? moment.tz(payApp.lastSubmitted.statusUpdatedAt, timeZone)
          : moment.tz(timeZone)
      ),
    [payApp.lastSubmitted, timeZone]
  )
  const [submittedAtValue, setSubmittedAtValue] = useState<DatePickerValue>(initialSubmittedAtValue)
  const initialAmountPaid = useMemo(() => {
    let defaultPaid = payApp.amountPaid
    if (!defaultPaid) {
      defaultPaid = payApp.amountDuePostTax
    }
    return defaultPaid
  }, [payApp])
  const [amountPaid, setAmountPaid] = useState<number | null>(initialAmountPaid)
  const [isBalanceManuallyClosed, setIsBalanceManuallyClosed] = useState<boolean>(
    payApp.isBalanceManuallyClosed
  )
  const [shouldRegenerateUnconditional, setShouldRegenerateUnconditional] = useState<boolean>(false)
  const refetchPayAppActivityQueries = useMemo(
    () => ({
      refetchQueries: [{ query: PayAppActivityDocument, variables: { payAppId: payApp.id } }],
    }),
    [payApp.id]
  )
  const [updatePayAppPaidAt, { loading: updating }] = useUpdatePayAppPaidAtMutation()
  const [markAsPaid, { loading: markingAsPaid }] = useMarkPayAppAsPaidMutation()

  // Update the dialog with the right amount any time the pay app values change
  useEffect(() => {
    if (payApp.amountPaid) {
      setAmountPaid(payApp.amountPaid)
    }
  }, [payApp])

  const handleResetDialog = useCallback(() => {
    setPaidAtValue(initialPaidAtValue)
    setAmountPaid(initialAmountPaid)
    setIsBalanceManuallyClosed(payApp.isBalanceManuallyClosed)
    setShouldRegenerateUnconditional(false)
  }, [initialPaidAtValue, initialAmountPaid, payApp.isBalanceManuallyClosed])

  const isPayAppPaid = payApp.status === PayAppStatus.PAID
  const shouldShowSubmittedAt = isPayAppPaid

  const handleSubmit = useCallback(async () => {
    const { date: paidAt } = paidAtValue
    if (paidAt === null) {
      return
    }

    // Variables are the same for both updating existing pay apps and newly marking as paid
    const variables = {
      input: {
        payAppId: payApp.id,
        paidAt: paidAt.toISOString(),
        amountPaid,
        isBalanceManuallyClosed,
        clearUnconditionalLienWaiver: shouldRegenerateUnconditional ? true : undefined,
        ...(shouldShowSubmittedAt &&
          submittedAtValue.date !== null && { submittedAt: submittedAtValue.date.toISOString() }),
      },
    }

    // If the pay app is already paid, only need to update the date and then close the dialog
    if (isPayAppPaid) {
      await updatePayAppPaidAt({
        variables,
        ...refetchPayAppActivityQueries,
      })
      onClose()
      if (shouldRegenerateUnconditional) {
        navigate(
          getBillingPath({
            pathType: BillingPathType.PayAppUnconditional,
            projectId,
            payAppId: payApp.id,
            payAppTab: 'unconditional',
          })
        )
      }
      return
    }

    try {
      await markAsPaid({
        variables,
        update(cache, { data }) {
          if (!data || !contract) {
            return
          }
          evictWithGc(cache, (evict) => {
            evict({ id: cache.identify(contract), fieldName: 'invoiceAmountOutstanding' })
            evict({ id: 'ROOT_QUERY', fieldName: 'paginatedCashForecastContracts' })
            evict({ id: 'ROOT_QUERY', fieldName: 'aggregateCashForecast' })
          })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          markPayAppAsPaid: {
            __typename: 'PayApp',
            id: payApp.id,
            status: PayAppStatus.PAID,
            statusChangedAt: paidAt.toISOString(),
            amountPaid,
            isBalanceManuallyClosed,
          },
        },
        ...refetchPayAppActivityQueries,
      })
    } catch (err) {
      snackbar.showError(err.message)
    }

    onClose()
  }, [
    paidAtValue,
    payApp.id,
    amountPaid,
    isBalanceManuallyClosed,
    shouldRegenerateUnconditional,
    shouldShowSubmittedAt,
    submittedAtValue.date,
    isPayAppPaid,
    onClose,
    updatePayAppPaidAt,
    refetchPayAppActivityQueries,
    navigate,
    projectId,
    markAsPaid,
    contract,
    snackbar,
  ])

  const amountDue = useMemo(() => payApp.amountDuePostTax, [payApp])

  const remainingBalance = useMemo(() => {
    return amountDue - (amountPaid ?? 0)
  }, [amountPaid, amountDue])

  // If the remaining balance is 0, the balance is closed and we disable the manual balance toggle
  const isFullyPaid = useMemo(() => remainingBalance === 0, [remainingBalance])

  const handleToggleBalanceClosed = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setIsBalanceManuallyClosed(checked)
    },
    []
  )

  const disableSubmit = useMemo(() => {
    const isMissingSubmittedDate = shouldShowSubmittedAt && isMissingDate(submittedAtValue)
    const isMissingPaidDate = isMissingDate(paidAtValue)
    const isMissingPaidAmount = amountPaid === null
    return isMissingSubmittedDate || isMissingPaidDate || isMissingPaidAmount
  }, [amountPaid, paidAtValue, shouldShowSubmittedAt, submittedAtValue])

  // Should not be possible to prompt the dialog without a contract (or in the wrong state), this is for typechecking
  if (!contract) {
    return null
  }

  const hasIntegrations = contract.integrations.length > 0
  const submitLabel = isPayAppPaid ? t('common.actions.save') : t(`${i18nBase}.mark_as_paid`)
  const hasEditedPaidAmount = isPayAppPaid && payApp.amountPaid !== amountPaid

  const bodyText = hasIntegrations
    ? t(`${i18nBase}.with_integrations`)
    : t(`${i18nBase}.no_integration`)

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      disableSubmit={disableSubmit}
      submitting={updating || markingAsPaid}
      title={isPayAppPaid ? t(`${i18nBase}.edit_title`) : t(`${i18nBase}.title`)}
      onResetDialog={handleResetDialog}
      submitLabel={submitLabel}
      maxWidth="sm"
      subscript={
        <Grow in={regenerateUnconditionalId !== undefined && hasEditedPaidAmount}>
          <FormControlLabel
            control={
              <SitelineCheckbox
                name={t(`${i18nBase}.regenerate_unconditional`)}
                value={shouldRegenerateUnconditional}
                checked={shouldRegenerateUnconditional}
                onChange={(ev, checked) => setShouldRegenerateUnconditional(checked)}
              />
            }
            label={t(`${i18nBase}.regenerate_unconditional`)}
          />
        </Grow>
      }
    >
      <div className={classes.root}>
        {!isPayAppPaid && (
          <SitelineText variant="body1" color="grey50">
            {bodyText}
          </SitelineText>
        )}
        <div className="inputContainer">
          <SendEmailDialogRow
            label={t(`${i18nBase}.paid_on`)}
            alignLabel="center"
            className={clsx('paymentRow', { withBody: !isPayAppPaid })}
          >
            <DatePickerInput
              value={paidAtValue}
              onChange={setPaidAtValue}
              timeZone={timeZone}
              className="datePicker"
            />
          </SendEmailDialogRow>
          {shouldShowSubmittedAt && (
            <SendEmailDialogRow
              label={
                payApp.lastSubmitted?.status === PayAppStatus.SYNCED
                  ? t(`${i18nBase}.synced_on`)
                  : t(`${i18nBase}.submitted_on`)
              }
              alignLabel="center"
              className={clsx('paymentRow', { withBody: !isPayAppPaid })}
            >
              <DatePickerInput
                value={submittedAtValue}
                onChange={setSubmittedAtValue}
                timeZone={timeZone}
                className="datePicker"
              />
            </SendEmailDialogRow>
          )}
          <SendEmailDialogRow
            label={t(`${i18nBase}.amount`)}
            alignLabel="start"
            className={clsx('paymentRow', { withBody: !isPayAppPaid })}
          >
            <div className="amountRow">
              <NumericFormat
                value={amountPaid !== null ? centsToDollars(amountPaid) : ''}
                onValueChange={({ floatValue }) =>
                  setAmountPaid(floatValue !== undefined ? dollarsToCents(floatValue) : null)
                }
                decimalScale={2}
                fixedDecimalScale
                displayType="input"
                thousandSeparator
                prefix="$"
                className="amountInput"
              />
              <FormControlLabel
                control={
                  <SitelineCheckbox
                    name={t(`${i18nBase}.balance_closed`)}
                    value={isBalanceManuallyClosed || isFullyPaid}
                    checked={isBalanceManuallyClosed || isFullyPaid}
                    onChange={handleToggleBalanceClosed}
                    className="endOfMonth"
                  />
                }
                label={
                  <SitelineText
                    variant="body1"
                    endIcon={
                      <Grow in={amountPaid !== null && !isFullyPaid}>
                        <SitelineTooltip
                          title={t(`${i18nBase}.balance_closed_description`, {
                            balance: formatCentsToDollars(remainingBalance, true),
                          })}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <HelpOutlineIcon fontSize="small" />
                        </SitelineTooltip>
                      </Grow>
                    }
                  >
                    {t(`${i18nBase}.balance_closed`)}
                  </SitelineText>
                }
                disabled={isFullyPaid || amountPaid === null}
              />
              <SitelineText variant="smallText" color="grey50" className="amountDue">
                {t(`${i18nBase}.due`, { amount: formatCentsToDollars(amountDue, true) })}
              </SitelineText>
            </div>
          </SendEmailDialogRow>
        </div>
      </div>
    </SitelineDialog>
  )
}
